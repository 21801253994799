import * as React from "react";

import {Header} from "../../Components/Header";
import {Sidebar} from "../../Components/Sidebar";

import {TodoProjectAdd} from "../../Components/Todo/TodoProjectAdd";

export function ProjectAdd() {
  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="todo" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle="Add a Project" />

          <div className="flex-1 overflow-auto">
            <main className="h-full p-3">
              <TodoProjectAdd />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
