import * as React from "react";

import {BreadcrumbChevron} from "./Icons/BreadcrumbChevron";

type Props = {
  children?: React.ReactNode;
};

export function BreadcrumbItem({children}: Props) {
  return (
    <li className="flex">
      <div className="flex items-center space-x-4">
        <BreadcrumbChevron className="flex-shrink-0 w-6 h-full text-coolGray-200" />

        {children}
      </div>
    </li>
  );
}
