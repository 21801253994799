import * as React from "react";

import {ActivityList} from "./Activities/ActivityList";

type Attachment = {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
};

type Tag = {
  id: string;
  document: Attachment;
};

type ActivityNote = {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
};

type FlexiblePolicyActivity = {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNote[];
  documenttagSet: Tag[];
};

type Client = {
  id: string;
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
};

type Props = {
  client: Client;
};
export function ReferralsPane({client}: Props) {
  const [adding, setAdding] = React.useState(false);

  function toggleAdding() {
    setAdding(adding ? false : true);
  }

  return (
    <div className="rounded shadow-lg">
      <div>
        <div>
          <div>
            <h4>Referrals</h4>

            {adding ? null : (
              <button className="bg-green-500" onClick={toggleAdding}>
                +
              </button>
            )}
          </div>
          <div>
            <ul>
              map here
              {adding ? (
                <li>
                  [AddClientReminder client=props.client
                  toggleAdding=toggleAdding /]
                </li>
              ) : null}
            </ul>

            <ActivityList
              flexiblepolicyactivitySet={client.flexiblepolicyactivitySet}
              filter="QUOTE"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
