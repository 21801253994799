import * as React from "react";

import {Switch, Route, useRouteMatch} from "react-router-dom";

import {AllForms} from "./forms/all";
import {NewForms} from "./forms/new";
import {FormBrands} from "./forms/brands";
import {Brand} from "./forms/brand";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

export function Forms() {
  let {path} = useRouteMatch();

  React.useEffect(() => {
    document.title = `Forms`;
  });

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="forms" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle="Forms" />

          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="h-full">
              <Switch>
                <Route exact path={path}>
                  <AllForms />
                </Route>
                <Route exact path={`${path}/all`}>
                  <AllForms />
                </Route>
                <Route exact path={`${path}/brands`}>
                  <FormBrands />
                </Route>
                <Route exact path={`${path}/new`}>
                  <NewForms />
                </Route>
                <Route exact path={`${path}/:brandSlug`}>
                  <Brand />
                </Route>
              </Switch>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
