interface Props {
  header: string;
  toggle: () => void;
}

export function ActivityHeader({header, toggle}: Props) {
  return (
    <div onClick={() => toggle()}>
      <h3 className="text-sm font-semibold text-gray-800">
        <a href="#header" className="hover:underline focus:outline-none">
          {/* <!-- Extend touch target to entire panel --> */}
          <span className="absolute inset-0" aria-hidden="true"></span>
          {header}
        </a>
      </h3>
      {/* <h4 className="font-medium"></h4> */}
    </div>
  );
}
