import * as React from "react";

import {Link} from "react-router-dom";

import {AddUnderwritingProfile} from "../Components/AddUnderwritingProfile";

// Adapted From https://github.com/slcnguye/plusify
function plusify(str: string): string {
  if (typeof str !== "string") {
    throw new TypeError("expected a string");
  }
  str = str.replace(/([a-z])([A-Z])/g, "$1+$2");
  str = str.replace(/[ \t\W]/g, "+");
  str = str.replace(/^\++|\++$/g, "");
  return str.toLowerCase();
}

type Location = {
  id: string;
};

type ClientAddress = {
  id: string;
  validatedData: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  description: string;
  locationSet: Location[];
  distanceFromOffice: string;
  addressType: string;
};

type Props = {
  address: ClientAddress;
  toggleEditing: () => void;
  needsLocation: boolean;
  locationId: string | null;
};

export function AddressMenu({
  address,
  toggleEditing,
  needsLocation,
  locationId,
}: Props) {
  const addressLabelUrl =
    process.env.NODE_ENV === "production"
      ? "https://agencypipeline.com/documents/mailing_label/address/" +
        address.id
      : "http://localhost:8000/documents/mailing_label/address/" + address.id;

  const googleSearchUrl =
    "https://google.com/search?q=" +
    address.street +
    " " +
    address.city +
    ", " +
    address.state +
    " " +
    address.zipcode;

  const googleMapsUrl =
    "https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=" +
    plusify(address.street) +
    ",+" +
    plusify(address.city) +
    ",+" +
    plusify(address.state) +
    "+" +
    plusify(address.zipcode);

  const street = address.street
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");

  const zillowSearchUrl =
    "https://zillow.com/homes/" +
    street +
    "-" +
    address.city +
    "," +
    address.state +
    "--" +
    address.zipcode +
    "_rb";

  return (
    <div>
      <button
        onClick={() => toggleEditing()}
        className="px-3 py-1 text-blue-500 underline hover:text-blue-700"
      >
        Edit
      </button>
      <a
        href={addressLabelUrl}
        className="px-3 py-1 text-blue-500 underline hover:text-blue-700"
        target="_blank"
        rel="noopener noreferrer"
      >
        Label
      </a>
      <a
        href={googleMapsUrl}
        className="px-3 py-1 text-blue-500 underline hover:text-blue-700"
        target="_blank"
        rel="noopener noreferrer"
      >
        Map
      </a>
      <a
        href={zillowSearchUrl}
        className="px-3 py-1 text-blue-500 underline hover:text-blue-700"
        target="_blank"
        rel="noopener noreferrer"
      >
        Zillow
      </a>
      {needsLocation ? (
        <AddUnderwritingProfile addressId={address.id} />
      ) : (
        <Link
          to={"/location/" + locationId}
          className="px-3 py-1 text-blue-500 underline hover:text-blue-700"
        >
          Underwriting
        </Link>
      )}
      <a
        href={googleSearchUrl}
        className="px-3 py-1 text-blue-500 underline hover:text-blue-700"
        target="_blank"
        rel="noopener noreferrer"
      >
        Search
      </a>
    </div>
  );
}
