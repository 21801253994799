import * as React from "react";

import {loader} from "graphql.macro";

import {useMutation} from "@apollo/client";

import {PasswordChangeMessage} from "../Components/PasswordChangeMessage";

import AuthyLogo from "../Images/authy.png";

import {MyTextInputField} from "./MyTextInputField";
import {TimezoneSelector} from "../Components/TimezoneSelector";

import {TwoFactorAuthenticationSetting} from "../Components/Settings/TwoFactorAuthentication/TFAPane";

const UPDATE_ACCOUNT = loader(
  "../Fragments/Contacts/UserSettingsUpdate.graphql"
);

type Group = {
  id: string;
  name: string;
};

type UserSettings = {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  profile: {
    id: string;
    mobile: string;
    authyId: string;
    passwordChanged: string | null;
    timezone: {id: string; name: string};
    language: string;
    otpSecret: string;
    otpVerified: string;
  };
  groups: Group[];
};

type Props = {
  userSettings: UserSettings;
};

export function SettingsAccount({userSettings}: Props) {
  const [firstName, setFirstName] = React.useState(userSettings.firstName);
  const [lastName, setLastName] = React.useState(userSettings.lastName);
  const [username, setUsername] = React.useState(userSettings.username);
  const [email, setEmail] = React.useState(userSettings.email);
  const [mobile, setMobile] = React.useState(userSettings.profile.mobile);
  // const [language, setLanguage] = React.useState(userSettings.profile.language);
  const [timezone, setTimezone] = React.useState(
    userSettings.profile?.timezone?.id
  );
  const [passwordChanged, setPasswordChanged] = React.useState(
    userSettings.profile.passwordChanged
  );
  const [authyId, setAuthyId] = React.useState(userSettings.profile.authyId);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [repeatNewPassword, setRepeatNewPassword] = React.useState("");

  const [
    accountUpdate,
    {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    accountUpdate: UserSettings;
  }>(UPDATE_ACCOUNT, {
    variables: {
      userId: userSettings.id,
      firstName: firstName,
      lastName: lastName,
      username: username,
      email: email,
      mobile: mobile,
      currentpassword: currentPassword,
      newPassword: newPassword,
      repeatNewPassword: repeatNewPassword,
      timezone: timezone,
    },
    onCompleted: () => {
      // send("SAVED");
      console.log("saved");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      // send("GRAPHQL_ERROR");
      console.log("error" + mutationError);
    },
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        accountUpdate();
      }}
      style={{fontSize: `150%`}}
    >
      <div className="max-w-6xl mx-auto">
        <h1>Account </h1>
        <hr />
        <br />
        <div>
          <div>
            <div className="ml-64">
              <MyTextInputField
                label="First Name"
                value={firstName}
                setValue={setFirstName}
                initialValue={userSettings.firstName}
                characterLimit={64}
              />

              <MyTextInputField
                label="Last Name"
                value={lastName}
                setValue={setLastName}
                initialValue={userSettings.lastName}
                characterLimit={64}
              />

              <MyTextInputField
                label="Username"
                value={username}
                setValue={setUsername}
                initialValue={userSettings.username}
                characterLimit={64}
              />

              <MyTextInputField
                label="Email"
                value={email}
                setValue={setEmail}
                initialValue={userSettings.email}
                characterLimit={64}
              />

              <MyTextInputField
                label="Mobile Number"
                value={mobile}
                setValue={setMobile}
                initialValue={userSettings.profile.mobile}
                // cleaveOptions={{
                //   numericOnly: true,
                //   blocks: [0, 3, 0, 3, 4],
                //   delimiters: ["(", ")", " ", "-"],
                // }}
                characterLimit={15}
              />
            </div>
          </div>
          <div>
            <div>
              {/* <MyInputField
                    id="language"
                    label="Languagae"
                    value={language}
                    onChangeEvent={this.handleFormContentChange}
                    initialValue={
                      props.userSettings.language
                    }
                    characterLimit={64}
                  /> */}
            </div>
            <div>
              <br />
              {/* Language: {language} */}
              {/* <button onClick={() => setLanguage("en")}></button> */}
              <br />
              <TimezoneSelector value={timezone} setValue={setTimezone} />
            </div>
          </div>
          <div>
            <div>
              <div className="ml-64">
                <h2>
                  Change Password{" "}
                  <PasswordChangeMessage passwordChanged={passwordChanged} />
                  <button onClick={() => setPasswordChanged("en")}></button>
                </h2>
                <hr />
                <MyTextInputField
                  label="Current Password"
                  value={currentPassword}
                  setValue={setCurrentPassword}
                  informUnsavedChanges={false}
                  characterLimit={512}
                />

                <MyTextInputField
                  label="New Password"
                  value={newPassword}
                  setValue={setNewPassword}
                  informUnsavedChanges={false}
                  characterLimit={512}
                />

                <MyTextInputField
                  label="Repeat New Password"
                  value={repeatNewPassword}
                  setValue={setRepeatNewPassword}
                  informUnsavedChanges={false}
                  characterLimit={512}
                />
                <br />
                <br />
                {newPassword === repeatNewPassword ? (
                  <button type="submit">Submit</button>
                ) : (
                  <span>New Passwords must match</span>
                )}

                {/* <button onClick={() => setPasswordChangeReady(false)}></button> */}
              </div>
              <h2>Two Factor Authentication</h2>
              <hr />
              <div className="ml-64">
                <TwoFactorAuthenticationSetting userSettings={userSettings} />

                {userSettings.groups
                  .filter((group) => group.name === "Two Factor Authentication")
                  .map((group) => (
                    <React.Fragment key={group.id}>
                      {group.name === "Two Factor Authentication" ? (
                        <span>
                          You are using AUTHY.
                          <br />
                          <br />
                          <img src={AuthyLogo} width={160} alt="Authy Logo" />
                          <br />
                          <br />
                          Authy ID: {authyId}
                          <MyTextInputField
                            label="Authy ID"
                            value={authyId}
                            setValue={setAuthyId}
                            initialValue={userSettings.profile.authyId}
                            characterLimit={512}
                          />
                        </span>
                      ) : (
                        <span>
                          Two-factor authentication is not enabled. Add it?
                        </span>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <br />

        <br />
        {/* <div>User Photo (can't change yet)</div> */}
        {/* <img
            src={
              "https://pipeline-media.s3.amazonaws.com/avatars/" +
              2 +
              ".128.png"
            }
            alt="Profile Avatar"
          /> */}

        <button type="submit">Save</button>
      </div>
    </form>
  );
}
