import * as React from "react";

import {gql} from "@apollo/client";
import {useHistory} from "react-router-dom";

import {useMutation} from "@apollo/client";

import {MyTextInputField} from "../../FormComponents/MyTextInputField";

import {GET_TODOS} from "../../GraphQL/Queries";

const CREATE_TODO_PROJECT = gql`
  mutation createTodoProject($name: String!) {
    createTodoProject(name: $name) {
      todoproject {
        id
        name
        incompleteLists {
          id
          name
          unclearedTodos {
            id
            created
            description
            url
            completed
            cleared
            hideuntil
            priority
          }
        }
      }
    }
  }
`;

type TodoList = {
  id: string;
  name: string;
};

type TodoProject = {
  id: string;
  name: string;
  incompleteLists: TodoList[];
};

export function TodoProjectAdd() {
  const [name, setName] = React.useState("");
  let history = useHistory();

  const [createTodoBoard] = useMutation<{
    createTodoBoard: TodoProject;
  }>(CREATE_TODO_PROJECT, {
    onCompleted() {
      history.push("/todo");
    },
  });

  return (
    <div style={{margin: `2em`}}>
      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault();

            if (name.trim() !== "") {
              createTodoBoard({
                variables: {
                  name: name.trim(),
                },
                refetchQueries: [{query: GET_TODOS}],
              });
              setName("");
            }
          }}
        >
          <MyTextInputField
            label="Name of New Project: "
            value={name}
            setValue={setName}
            characterLimit={64}
            autoFocus
            placeholder=""
          />
        </form>
      </div>
    </div>
  );
}
