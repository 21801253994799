import * as React from "react";

import {gql, useQuery} from "@apollo/client";

import {AvatarDropdown} from "./AvatarDropdown";

const USER_QUERY = gql`
  query PersonQuery {
    loggedInUser {
      id
      firstName
      lastName
    }
  }
`;

type User = {
  loggedInUser: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

export function UserAvatar() {
  const {loading, error, data} = useQuery<User>(USER_QUERY);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  function toggleDropdownState() {
    setDropdownOpen(dropdownOpen ? false : true);
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  // if (data)
  return (
    <div>
      <button
        className="flex text-sm transition duration-150 ease-in-out border-2 border-transparent rounded-full focus:outline-none focus:border-coolGray-300"
        id="user-menu"
        aria-label="User menu"
        aria-haspopup="true"
        onClick={() => toggleDropdownState()}
      >
        <img
          className="w-8 h-8 rounded-full"
          src={
            "https://pipeline-media.s3.amazonaws.com/avatars/" +
            data?.loggedInUser.id +
            ".128.png"
          }
          alt={
            data?.loggedInUser.firstName +
            " " +
            data?.loggedInUser.lastName +
            "profile image"
          }
        />
      </button>
      <AvatarDropdown dropdownOpen={dropdownOpen} />
    </div>
  );
}
