import {useHistory} from "react-router-dom";

import {gql, useMutation} from "@apollo/client";

import {GET_TODOS} from "../../GraphQL/Queries";

const TOGGLE_TODO_PROJECT_COMPLETION = gql`
  mutation toggleTodoProjectCompletion($id: String!) {
    toggleTodoProjectCompletion(id: $id) {
      todoprojects {
        id
        name
        incompleteLists {
          id
          name
          unclearedTodos {
            id
            created
            description
            url
            completed
            cleared
            hideuntil
            priority
          }
        }
      }
    }
  }
`;

type TodoList = {
  id: string;
  name: string;
};

type TodoProject = {
  id: string;
  name: string;
  incompleteLists: TodoList[];
};

type Props = {
  todoproject: TodoProject;
};

export function CompleteProject({todoproject}: Props) {
  let history = useHistory();

  const [toggleCompletion] = useMutation<{
    toggleCompletion: TodoProject[];
  }>(TOGGLE_TODO_PROJECT_COMPLETION, {
    onCompleted() {
      history.push("/todo");
    },
  });

  return (
    <button
      onClick={() => {
        toggleCompletion({
          variables: {
            id: todoproject.id,
          },
          refetchQueries: [{query: GET_TODOS}],
        });
      }}
      className="px-4 py-2 font-bold text-red-500 bg-white border border-red-500 rounded hover:bg-red-500 hover:text-white"
    >
      Delete Empty Project
    </button>
  );
}
