import * as React from "react";

import {
  //   BrowserRouter,
  // Switch,
  // Link,
  // Route,
  // RouteComponentProps,
  useParams,
} from "react-router-dom";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

import {SettingsAccount} from "../../FormComponents/SettingsAccount";

const GET_USER_SETTINGS = loader(
  "../../Fragments/Contacts/UserSettingsQuery.graphql"
);

export function Security() {
  const {loading, error, data} = useQuery(GET_USER_SETTINGS);

  React.useEffect(() => {
    document.title = `Account Settings`;
  });

  let {pageName} = useParams<Record<string, string | undefined>>();

  console.log(pageName);

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  return (
    <div style={{backgroundColor: `rgb(248, 248, 248)`}}>
      <SettingsAccount userSettings={data.userSettings} />;
    </div>
  );
}
