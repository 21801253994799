import * as React from "react";

type Props = {
  children: any;
};

export function Tabs({children}: Props) {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const selectTabIndex = (activeIndex: number) => {
    setActiveIndex(activeIndex);
  };

  const newChildren = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      activeIndex: activeIndex,
      onSelectTab: selectTabIndex,
    });
  });
  return <div className="Tabs">{newChildren}</div>;
}

type TabListProps = {
  children: any;
  activeIndex?: number;
  onSelectTab?: (newIndex: number) => void;
};

export function TabList({children, activeIndex, onSelectTab}: TabListProps) {
  if (onSelectTab) {
    const newChildren = React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        isActive: index === activeIndex,
        onSelect: () => onSelectTab(index),
      });
    });
    return <div>{newChildren}</div>;
  } else {
    return <div>{children}</div>;
  }
}

type TabProps = {
  children: any;
  isDisabled?: boolean;
  isActive?: boolean;
  onSelect?: () => void;
};

export function Tab({children, isDisabled, isActive, onSelect}: TabProps) {
  return (
    <button
      className={isDisabled ? "tab disabled" : isActive ? "tab active" : "tab"}
      onClick={isDisabled ? undefined : onSelect}
    >
      {children}
    </button>
  );
}

type TabPanelsProps = {
  children: any;
  activeIndex?: number;
};

export function TabPanels({children, activeIndex = 0}: TabPanelsProps) {
  return <div className="panels">{children[activeIndex]}</div>;
}

type TabPanelProps = {
  children: any;
};

export function TabPanel({children}: TabPanelProps) {
  return children;
}
