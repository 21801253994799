import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import Instance from "../../../Context/Instance";

import IdleTimer from "react-idle-timer";
import {DeletePriorCancellationForm} from "./DeletePriorCancellation";

// import {MyIntegerField} from "../../../FormComponents/MyIntegerField";
import {MySelect} from "../../../FormComponents/MySelect";
import {MyTextarea} from "../../../FormComponents/MyTextarea";
import {MyTextInputField} from "../../../FormComponents/MyTextInputField";
import {MyDateField} from "../../../FormComponents/MyDateField";

import {CircleIconSpinning} from "../../Icons/CircleIconSpinning";

// import sha1 from "sha1";

const UPDATE_PRIOR_CANCELLATION = loader(
  "../../../Fragments/Underwriting/PriorCancellationUpdate.graphql"
);

const CANCELLATION_TYPE_CHOICES = [
  {key: "Q", text: "Unknown", value: "Q"},
  {key: "N", text: "Nonrenewal", value: "N"},
  {
    key: "A",
    text: "Nonrenewal - Agent No Longer Represents Carrier",
    value: "A",
  },
  {key: "P", text: "Cancellation for Non Payment / Lapse", value: "P"},
  {key: "U", text: "Cancellation for Underwriting Issue", value: "U"},
];

const priorcancellationMachine = Machine({
  id: "priorcancellation",
  initial: "idle",
  states: {
    idle: {
      on: {
        SAVE: "saving",
        MAY_DELETE: "may_delete",
      },
    },
    saving: {
      on: {
        SAVED: "idle",
        GRAPHQL_ERROR: "idle",
      },
    },
    graphql_error: {
      on: {SAVE: "saving", DISCARD: "refetch", MAY_DELETE: "may_delete"},
    },
    refetch: {
      on: {
        DOG_LOADED: "idle",
        ERROR: "loading_error",
      },
    },
    loading_error: {
      //
    },
    may_delete: {
      on: {
        DONT_DELETE: "idle",
        CONFIRM_DELETE: "deleting",
      },
    },
    deleting: {
      //
    },
  },
});

type PriorCancellation = {
  id: string;
  date: string;
  insuranceCompany: string;
  cancellationType: string;
  cancellationNotes: string;
};

type Props = {
  priorcancellation: PriorCancellation;
  autoSave: boolean;
  locationId: string;
};

export function EditPriorCancellation({
  priorcancellation,
  autoSave,
  locationId,
}: Props) {
  const [state, send] = useMachine(priorcancellationMachine);

  const [date, setDate] = React.useState<string | null>(priorcancellation.date);
  const [insuranceCompany, setInsuranceCompany] = React.useState(
    priorcancellation.insuranceCompany
  );
  const [cancellationType, setCancellationType] = React.useState(
    priorcancellation.cancellationType
  );
  const [cancellationNotes, setCancellationNotes] = React.useState(
    priorcancellation.cancellationNotes
  );

  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (
      priorcancellation.date !== date ||
      priorcancellation.insuranceCompany !== insuranceCompany ||
      priorcancellation.cancellationType !== cancellationType ||
      priorcancellation.cancellationNotes !== cancellationNotes
    ) {
      if (!dirty) {
        setDirty(true);
      }
    } else {
      if (dirty) {
        setDirty(false);
      }
    }
  }, [
    dirty,
    priorcancellation.date,
    date,
    priorcancellation.insuranceCompany,
    insuranceCompany,
    priorcancellation.cancellationType,
    cancellationType,
    priorcancellation.cancellationNotes,
    cancellationNotes,
  ]);

  const [
    priorCancellationUpdate,
    {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    priorCancellationUpdate: PriorCancellation;
  }>(UPDATE_PRIOR_CANCELLATION, {
    variables: {
      id: priorcancellation.id,
      date: date,
      insuranceCompany: insuranceCompany,
      cancellationType: cancellationType,
      cancellationNotes: cancellationNotes,
    },
    onCompleted: () => {
      send("SAVED");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      send("GRAPHQL_ERROR");
    },
  });

  function handleFormSubmit() {
    if (dirty) {
      send("SAVE");
      priorCancellationUpdate();
    }
  }

  if (state.value === "may_delete") {
    return (
      <DeletePriorCancellationForm
        priorcancellation={priorcancellation}
        locationId={locationId}
        abortDeletion={() => send("DONT_DELETE")}
      />
    );
  }

  return (
    <Instance.Provider value={priorcancellation.id}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          priorCancellationUpdate();
        }}
      >
        {autoSave ? (
          <IdleTimer
            timeout={1000}
            startOnMount={true}
            onIdle={dirty ? () => priorCancellationUpdate() : () => {}}
          />
        ) : null}
        <div>
          <div>
            <div>
              <div className="flex">
                <div className="flex-1">
                  <MyDateField
                    label="Date"
                    value={date}
                    setValue={setDate}
                    initialValue={priorcancellation.date}
                  />
                </div>
                <div className="flex-1">
                  <MySelect
                    label="Cancellation Type"
                    value={cancellationType}
                    options={CANCELLATION_TYPE_CHOICES}
                    setValue={setCancellationType}
                    initialValue={priorcancellation.cancellationType}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyTextInputField
                    label="Insurance Company"
                    value={insuranceCompany}
                    setValue={setInsuranceCompany}
                    initialValue={priorcancellation.insuranceCompany}
                    characterLimit={64}
                  />
                </div>
                <div className="flex-1">
                  <MyTextarea
                    label="Notes"
                    value={cancellationNotes}
                    initialValue={priorcancellation.cancellationNotes}
                    setValue={setCancellationNotes}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            {dirty ? (
              <button
                type="button"
                onClick={() => handleFormSubmit()}
                className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
              >
                {autoSave ? (
                  <>
                    <CircleIconSpinning className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" />{" "}
                    Auto Saving
                  </>
                ) : (
                  "Save"
                )}
              </button>
            ) : (
              <button
                type="button"
                // onClick={() => handleFormSubmit()}
                className="px-4 py-2 font-bold text-white bg-blue-500 rounded opacity-50 cursor-not-allowed"
              >
                Saved
              </button>
            )}

            {mutationError && (
              <div className="text-3xl">
                {(() => {
                  switch (mutationError.message.split("GraphQL error: ")[1]) {
                    case "value too long for type character varying(64)\n":
                      return (
                        <span>
                          Changes cannot be saved
                          <br /> One or more fields has too many characters.
                        </span>
                      );
                    case "AM":
                      return <span>Additional Mailing</span>;
                    case "OT":
                      return <span>Other</span>;
                    default:
                      console.log("switch didn't have a match.");
                      console.log(mutationError.message);
                      return <span>Error: {mutationError.message}</span>;
                  }
                })()}
              </div>
            )}

            <button
              type="button"
              onClick={() => send("MAY_DELETE")}
              className="float-right px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </Instance.Provider>
  );
}
