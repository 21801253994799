import * as React from "react";

// import {SearchInput} from "./SearchInput";
// import {Offline} from "react-detect-offline";
// import {UserMenu} from "../Menus/UserMenu";

type Props = {
  pageTitle: string;
};

export function Header({pageTitle}: Props) {
  return (
    <>
      {/* commented 5/4/2021 due to issues with change to swapi url */}
      {/* <Offline>
        <div className="relative bg-orange-500">
          <div className="max-w-screen-xl px-3 py-3 mx-auto sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-medium text-white">
                <span className="md:hidden">You are Offline!</span>
                <span className="hidden md:inline">
                  Hey! It looks like your internet connection is offline.
                </span>
              </p>
            </div>
          </div>
        </div>
      </Offline> */}

      <div className="flex-shrink-0 sm:border-b-2 sm:border-coolGray-200">
        <header>
          <div className="px-4 sm:px-6">
            {/* <div className="flex items-center justify-between py-3 border-b border-coolGray-200 print:hidden"> */}
            {/* <div className="flex flex-1 min-w-0"> */}
            {/* <button
                  // @click="sidebarOpen = true"
                  className="text-coolGray-600 lg:hidden"
                >
                  <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
                    <path
                      d="M4 6h16M4 12h16M4 18h7"
                      strokeLinecap="round"
                      strokeWidth="2"
                      stroke="currentColor"
                    />
                  </svg>
                </button> */}
            {/* <div className="relative w-64 ml-3 flex-shrink-1 lg:ml-0">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                      className="w-6 h-6 text-coolGray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        strokeLinecap="round"
                        strokeWidth="2"
                        stroke="currentColor"
                      />
                    </svg>
                  </span>

                  {/* <SearchInput />
                </div> */}
            {/* </div> */}

            {/* <div className="flex items-center flex-shrink-0 ml-6"> */}
            {/* Alert Button */}
            {/* <button>
                <svg
                  className="w-6 h-6 text-coolGray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                  />
                </svg>
              </button> */}
            {/* <button className="ml-6">
                <img
                  alt="Your profile image"
                  className="object-cover rounded-full h-9 w-9"
                  width="40"
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=144&h=144&q=80"
                />
              </button> */}
            {/* <UserMenu /> */}
            {/* </div> */}
            {/* </div> */}
            <div className="flex items-center justify-between py-2">
              <div className="sm:flex sm:items-center">
                <h2 className="text-2xl font-medium leading-tight text-coolGray-900 print:ml-6 print:mt-8">
                  {pageTitle}
                </h2>
                {/* <div className="flex items-center mt-1 sm:mt-0 sm:ml-6 print:hidden">
                <span className="border-2 border-white rounded-full">
                  <img
                    alt=""
                    className="object-cover w-6 h-6 rounded-full"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                  />
                </span>
                <span className="-ml-2 border-2 border-white rounded-full">
                  <img
                    alt=""
                    className="object-cover w-6 h-6 rounded-full"
                    src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=144&h=144&q=80"
                  />
                </span>
                <span className="-ml-2 border-2 border-white rounded-full">
                  <img
                    alt=""
                    className="object-cover w-6 h-6 rounded-full"
                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=144&h=144&q=80"
                  />
                </span>
                <span className="-ml-2 border-2 border-white rounded-full">
                  <img
                    alt=""
                    className="object-cover w-6 h-6 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=144&h=144&q=80"
                  />
                </span>
              </div> */}
              </div>
              {/* <div className="flex print:hidden">
              <span className="hidden p-1 border rounded-lg bg-coolGray-200 sm:inline-flex">
                <button className="px-2 py-1 rounded">
                  <svg
                    className="w-6 h-6 text-coolGray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M4 6h16M4 10h16M4 14h16M4 18h16"
                      strokeLinecap="round"
                      strokeWidth="2"
                      stroke="currentColor"
                    />
                  </svg>
                </button>
                <button className="px-2 py-1 bg-white rounded shadow">
                  <svg
                    className="w-6 h-6 text-coolGray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"
                      strokeWidth="2"
                      stroke="currentColor"
                    />
                  </svg>
                </button>
              </span>
              <button className="flex items-center py-2 pl-2 pr-4 ml-5 text-sm font-medium text-white rounded-lg bg-coolGray-800 hover:bg-coolGray-700">
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
                  <path
                    d="M12 7v10m5-5H7"
                    strokeLinecap="round"
                    strokeWidth="2"
                    stroke="currentColor"
                  />
                </svg>
                <span className="ml-1">New Issue</span>
              </button>
            </div> */}
            </div>
          </div>
          {/* <div className="flex p-1 px-4 border-t border-b bg-coolGray-200 sm:hidden print:hidden">
          <button className="inline-flex items-center justify-center w-1/2 px-2 py-1 rounded">
            <svg
              className="w-6 h-6 text-coolGray-600"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                d="M4 6h16M4 10h16M4 14h16M4 18h16"
                strokeLinecap="round"
                strokeWidth="2"
                stroke="currentColor"
              />
            </svg>
            <span className="ml-2 text-sm font-medium leading-none text-coolGray-600">
              List
            </span>
          </button>
          <button className="inline-flex items-center justify-center w-1/2 px-2 py-1 bg-white rounded shadow">
            <svg
              className="w-6 h-6 text-coolGray-600"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"
                strokeWidth="2"
                stroke="currentColor"
              />
            </svg>
            <span className="ml-2 text-sm font-medium leading-none text-coolGray-900">
              Board
            </span>
          </button>
        </div> */}
        </header>
      </div>
    </>
  );
}
