import * as React from "react";

type Props = {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  button?: React.ReactNode;
  content: React.ReactNode;
};

export function FormPanel({heading, subheading, button, content}: Props) {
  return (
    <div className="shadow sm:rounded-md sm:overflow-hidden">
      <div className="px-4 py-6 space-y-6 bg-white sm:p-6">
        <div className="flex flex-wrap items-center justify-between -mt-4 -ml-4 sm:flex-nowrap">
          <div className="mt-4 ml-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {/* <h3 className="text-lg font-medium leading-6 text-blue-700"> */}
              {heading}
            </h3>
            <p className="mt-1 text-sm text-gray-500">{subheading}</p>
          </div>

          <div className="flex-shrink-0 mt-4 ml-4">{button}</div>
        </div>

        {content}
      </div>
      {/* <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div> */}
    </div>
  );
}
