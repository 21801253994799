import * as React from "react";

import {gql, useQuery} from "@apollo/client";
import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

import {Nonrenewal} from "../Components/Nonrenewal";
import {PendingCancellation} from "../Components/PendingCancellation";

const pendingCancellationQuery = gql`
  query CancellationListQuery {
    allPendingCancellations {
      id
      actionDate
      premium
      status
      quoteClosed
      description
      policy {
        id
        company {
          id
          name
          carrier {
            id
            name
          }
        }
        policyNumber
        client {
          id
          name
          attn
          csr {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

// type Status = {
//   id: string;
//   name: string;
// };

type Carrier = {
  id: string;
  name: string;
};

type User = {
  id: string;
  firstName: string;
  lastName: string;
};

type PendingCancellationType = {
  id: string;
  policy: {
    policyNumber: string;
    client: {
      id: string;
      name: string;
      attn: string;
      csr: User;
    };
    company: {
      carrier: Carrier;
    };
  };
  quoteClosed: boolean;
  actionDate: string;
  description: string;
  status: string;
};

type PendingCancellations = {
  allPendingCancellations: PendingCancellationType[];
};

export function Nonrenewals() {
  const {loading, error, data} = useQuery<PendingCancellations>(
    pendingCancellationQuery
  );

  React.useEffect(() => {
    document.title = `Nonrenewals`;
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  if (data) {
    const forUnderwriting = data.allPendingCancellations.filter(
      (cancellation) => cancellation.status === "A_1"
    );

    const nonRenewal = data.allPendingCancellations.filter(
      (cancellation) => cancellation.status === "A_2"
    );

    return (
      <div>
        <div className="flex h-screen" id="app">
          <Sidebar activeItem="nonrenewals" />

          <div className="flex flex-col flex-1 min-w-0 bg-white">
            <Header pageTitle="Nonrenewals" />

            <div className="flex-1 overflow-auto bg-coolGray-200">
              <main className="inline-flex h-full p-3">
                <div
                  className="flex flex-col flex-shrink-0 rounded-lg bg-coolGray-100"
                  style={{width: `30rem`}}
                >
                  <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                    For Underwriting ({forUnderwriting.length})
                  </h3>
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    <ul className="px-3 pt-1 pb-3">
                      {forUnderwriting.map((cancellation, index) => (
                        <PendingCancellation
                          cancellation={cancellation}
                          key={cancellation.id}
                          index={index}
                        />
                      ))}
                    </ul>
                  </div>
                </div>

                <div
                  className="flex flex-col flex-shrink-0 ml-3 rounded-lg bg-coolGray-100"
                  style={{width: `30rem`}}
                >
                  <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                    Nonrenewal ({nonRenewal.length})
                  </h3>
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    <ul className="px-3 pt-1 pb-3">
                      {nonRenewal.map((cancellation, index) => (
                        <Nonrenewal
                          cancellation={cancellation}
                          key={cancellation.id}
                          index={index}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <span>There was an error</span>;
}
