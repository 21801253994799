import * as React from "react";

import {Policy} from "./Policy";
import {AddPolicy} from "../FormComponents/AddPolicy";

type Attachment = {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
};

type Tag = {
  id: string;
  document: Attachment;
};

type ActivityNote = {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
};

type FlexiblePolicyActivity = {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNote[];
  documenttagSet: Tag[];
};

type Reminder = {
  id: string;
  reminder: string;
  remindOn: string;
  about: {
    name: string;
    attn: string;
  };
  completed: boolean;
};

type DownloadMessage = {
  id: string;
  acknowledged: boolean;
  transactionTypeCode: string;
  originalFile: {
    timestamp: string;
  };
};

type PolicySection = {
  id: string;
  downloadmessageSet: DownloadMessage[];
};

type PolicyTerm = {
  id: string;
  effectiveDate: string;
  expirationDate: string;
  premium: string;
  policysectionSet: PolicySection[];
};

type PolicyType = {
  id: string;
  isActive: boolean;
  agency: {
    agency: {
      name: string;
    };
    name: string;
  };
  company: {
    id: string;
    name: string;
    carrier: {
      id: string;
      name: string;
    };
  };
  description: string;
  policyType: string;
  policyNumber: string;
  effectiveDate: string;
  expirationDate: string;
  premium: number;
  firstWritten: string;
  daysSinceLastPolicyReview: string;

  policytermSet: PolicyTerm[];
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
};

type Client = {
  id: string;
  phonenumberSet: {
    id: string;
    number: string;
    label: string;
  };
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
  clientreminderSet: Reminder[];
  policySet: PolicyType[];
};

type Props = {
  client: Client;
  filter?: string;
};

const lifeHealthTypes = [
  "Life",
  "Dental (Individual)",
  "Disability",
  "Group Life",
  "Health Insurance",
  "Medicare Prescription Drug Plan",
  "Medicare Supplement",
  "Medicare Advantage",
];

export function PolicyListPane({client, filter = ""}: Props) {
  const [adding, setAdding] = React.useState(false);

  function toggleAdding() {
    setAdding(adding ? false : true);
  }

  let policies = client.policySet;

  // policies.map((policy) => console.log(policy.policyType));

  if (filter === "life-and-health") {
    policies = policies.filter((policy) =>
      lifeHealthTypes.includes(policy.policyType)
    );
  } else {
    policies = policies.filter(
      (policy) => !lifeHealthTypes.includes(policy.policyType)
    );
  }

  return (
    <div>
      <div className="flex p-3">
        <h3 className="flex-1 text-lg">Active Policies</h3>

        {adding ? null : (
          <button className="float-right bg-green-500" onClick={toggleAdding}>
            Add
          </button>
        )}
      </div>
      <div>
        <div className="flex flex-col">
          <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="inline-block min-w-full overflow-hidden align-middle border-b shadow border-coolGray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-coolGray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-coolGray-500 bg-coolGray-50">
                      Company
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-coolGray-500 bg-coolGray-50">
                      Type
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-coolGray-500 bg-coolGray-50">
                      Policy Number
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-coolGray-500 bg-coolGray-50">
                      Dates
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-coolGray-500 bg-coolGray-50">
                      Premium
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-coolGray-500 bg-coolGray-50"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-coolGray-200">
                  {policies.map((policy) =>
                    policy.isActive ? (
                      <Policy key={policy.id} policy={policy} />
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {adding ? (
          <AddPolicy clientId={client.id} toggleAdding={toggleAdding} />
        ) : null}
      </div>

      {policies.some((policy) => policy.isActive === false) ? (
        <div>
          <div className="flex p-3">
            <h3 className="flex-1 text-lg">Inactive Policies</h3>
          </div>
          <div className="flex flex-col">
            <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="inline-block min-w-full overflow-hidden align-middle border-b shadow border-coolGray-200 sm:rounded-lg">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50">
                        Company
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50">
                        Type
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50">
                        Policy Number
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50">
                        Dates
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50">
                        Premium
                      </th>

                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-coolGray-500 bg-coolGray-50"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-coolGray-200">
                    {policies.map((policy) =>
                      policy.isActive ? null : (
                        <Policy key={policy.id} policy={policy} />
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
