import {gql, useMutation} from "@apollo/client";

const ADD_DWELLING_MUTATION = gql`
  mutation AddDwelling($id: String!) {
    dwellingAdd(id: $id) {
      location {
        id
        created
        purchaseClosingDate
        purchaseClosingDateNotes
        purchasePrice
        purchasePriceNotes
        deededOwner
        deededOwnerNotes
        firstTimeHomebuyer
        hydrantsAvailable
        hydrantsAvailableNotes
        respondingFireDepartment
        respondingFireDepartmentNotes
        distanceToFireDepartment
        distanceToFireDepartmentNotes
        isoProtectionClass
        isoProtectionClassNotes
        acreage
        acreageNotes
        floodZone
        floodZoneNotes
        dwellingSet {
          id
          unitCount
          unitsNotes
          occupancy
          occupancyNotes
          constructionType
          constructionNotes
          yearBuilt
          squareFeet
          homeStyle
          homeStyleNotes
          bedrooms
          fullBaths
          halfBaths
          foundation
          foundationNotes
          piers
          piersNotes
          basement
          basementNotes
          walkoutBasement
          sumpPump
          attachedGarage
          attachedGarageNotes
          exterior
          exteriorNotes
          decksAndPorches
          specialFeatures
          roofYear
          roofMaterial
          roofStyle
          roofNotes
          heatingYear
          heatingStyle
          heatingType
          gasFireplaces
          woodFireplaces
          solidFuelBurner
          heatingNotes
          centralAir
          undergroundOilTank
          undergroundOilTankNotes
          plumbingYear
          newPipes
          plumbingYearNotes
          waterSource
          waterSourceNotes
          wastewater
          wastewaterNotes
          circuitBreakers
          electricYear
          electricYearNotes
          numberOfAmps
          smokeAndCODetectors
          generator
          solarPanels
          solarPanelsNotes
          fuses
          fusesNotes
          wiring
          nonsmokerDiscount
          fireExtinguisher
          deadboltLocks
          inHomeSprinklerSystem
          waterAlarm
          lowTemperatureAlarm
          burglarAlarm
          burglarAlarmNotes
          exteriorDamage
          exteriorDamageNotes
          everRented
          everRentedNotes
          missingHandrails
          missingHandrailsNotes
          homeBasedBusiness
          homeBasedBusinessNotes
          farming
          farmingNotes
          homeConcealedFromNeighbors
          homeConcealedFromNeighborsNotes
          priorBankruptcy
          priorBankruptcyNotes
          homeForSale
          homeForSaleNotes
          dayCare
          dayCareNotes
          underConstruction
          underConstructionNotes
          trampoline
          pool
          poolNotes
          fenced
          lockingGate
          divingBoard
          slide
          cancelledOrNonrenewed
          residenceEmployees
          residenceEmployeesNotes
          generalNotes
          privateNotes
        }
        claimSet {
          id
          date
          lossType
          payout
          claimNotes
        }
        dogSet {
          id
          dogName
          breedComposition
          anyPriorAggression
          priorAggressionNotes
        }
        outbuildingSet {
          id
          buildingDescription
          constructionYear
          constructionType
          value
          outbuildingNotes
          heat
          electric
          hayStorage
          usage
        }
        priorcancellationSet {
          id
          date
          insuranceCompany
          cancellationType
          cancellationNotes
        }
        mortgageSet {
          id
          name
          street
          street2
          city
          state
          zipcode
          loanNumber
          escrowBill
          position
        }
        address {
          street
          city
          state
          zipcode
          client {
            id
            name
            attn
          }
        }
      }
    }
  }
`;

type LocationProfile = {
  // TODO: Flesh this out.
};

type Props = {
  locationId: string;
};

export function AddDwellingButton({locationId}: Props) {
  const [dwellingAdd] = useMutation<{
    dwellingAdd: LocationProfile;
  }>(ADD_DWELLING_MUTATION, {
    variables: {
      id: locationId,
    },
  });

  return <button onClick={() => dwellingAdd()}>+</button>;
}
