import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {PrescriptionDelete} from "./PrescriptionDelete";

import IdleTimer from "react-idle-timer";

import {MyTextInputField} from "../../FormComponents/MyTextInputField";
import {MySelect} from "../../FormComponents/MySelect";

import Instance from "../../Context/Instance";

const UPDATE_PRESCRIPTION_MUTATION = loader(
  "../../Fragments/Medicare/PrescriptionUpdate.graphql"
);

const REFILL_FREQUENCY_CHOICES = [
  {key: "U", text: "Unknown", value: "U"},
  {key: "3", text: "30 Days", value: "A_3"},
  {key: "9", text: "90 Days", value: "A_9"},
  {key: "O", text: "Other", value: "O"},
];

type Prescription = {
  id: string;
  drugName: string;
  refillFrequency: string;
  otherFrequency: string;
  dosage: string;
  quantityPerFill: string;
  notes: string;
};

type Props = {
  autoSave: boolean;
  beneficiaryId: string;
  prescription: Prescription;
};

export function PrescriptionEdit({
  autoSave,
  beneficiaryId,
  prescription,
}: Props) {
  const [drugName, setDrugName] = React.useState(prescription.drugName);
  const [refillFrequency, setRefillFrequency] = React.useState(
    prescription.refillFrequency
  );
  const [otherFrequency, setOtherFrequency] = React.useState(
    prescription.otherFrequency
  );
  const [dosage, setDosage] = React.useState(prescription.dosage);
  const [quantityPerFill, setQuantityPerFill] = React.useState(
    prescription.quantityPerFill
  );
  const [notes, setNotes] = React.useState(prescription.notes);

  const [
    updatePrescription,
    // {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    updatePrescription: Prescription;
  }>(UPDATE_PRESCRIPTION_MUTATION, {
    variables: {
      id: prescription.id,
      drugName: drugName,
      dosage: dosage,
      quantityPerFill: quantityPerFill,
      refillFrequency: refillFrequency,
      otherFrequency: otherFrequency,
      notes: notes,
    },
    // onCompleted: () => {
    //   send("SAVED");
    // },
    // onError: () => {
    //   // alert(
    //   //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
    //   // );
    //   send("GRAPHQL_ERROR");
    // },
  });

  return (
    <Instance.Provider value={prescription.id}>
      <div className="grid grid-cols-3 gap-4 p-4 border border-gray-200 rounded-md">
        <MyTextInputField
          label="Drug Name"
          value={drugName}
          setValue={setDrugName}
          characterLimit={128}
          initialValue={prescription.drugName}
          autoFocusIfBlank
        />

        <MyTextInputField
          label="Dosage"
          value={dosage}
          setValue={setDosage}
          characterLimit={32}
          initialValue={prescription.dosage}
        />

        <MyTextInputField
          label="Quantity per Fill"
          value={quantityPerFill}
          setValue={setQuantityPerFill}
          characterLimit={32}
          initialValue={prescription.quantityPerFill}
        />

        <MySelect
          label="Refill Frequency"
          value={refillFrequency}
          options={REFILL_FREQUENCY_CHOICES}
          setValue={setRefillFrequency}
          initialValue={prescription.refillFrequency}
        />

        {refillFrequency === "O" ? (
          <MyTextInputField
            label="Refill Every"
            value={otherFrequency}
            setValue={setOtherFrequency}
            characterLimit={128}
            initialValue={prescription.otherFrequency}
          />
        ) : null}

        <MyTextInputField
          label="Notes"
          value={notes}
          setValue={setNotes}
          initialValue={prescription.notes}
          characterLimit={128}
        />

        <div>
          {drugName +
            refillFrequency +
            otherFrequency +
            dosage +
            quantityPerFill +
            notes ===
          "U" ? (
            <PrescriptionDelete
              beneficiaryId={beneficiaryId}
              prescription={prescription}
              immaculate={true}
            />
          ) : (
            <PrescriptionDelete
              beneficiaryId={beneficiaryId}
              prescription={prescription}
            />
          )}

          {drugName === prescription.drugName &&
          dosage === prescription.dosage &&
          refillFrequency === prescription.refillFrequency &&
          otherFrequency === prescription.otherFrequency &&
          quantityPerFill === prescription.quantityPerFill &&
          notes === prescription.notes ? null : (
            <span>
              {autoSave ? (
                <IdleTimer
                  timeout={1000}
                  startOnMount={true}
                  onIdle={() => updatePrescription()}
                />
              ) : (
                <button type="button" onClick={() => updatePrescription()}>
                  save
                </button>
              )}
            </span>
          )}
        </div>
      </div>
    </Instance.Provider>
  );
}
