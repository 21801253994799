import * as React from "react";

import {backendUrlHostname} from "../urls";

import {AttachmentIcon} from "./AttachmentIcon";
import {AttachmentLinkMessage} from "./AttachmentLinkMessage";

type attachment = {
  createdOn: number;
  extension: string;
  uploadedBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  url: string;
};

type Props = {
  attachment: attachment;
};

export function AttachmentImage({attachment}: Props) {
  const [loadImage, setLoadImage] = React.useState(false);
  const messageStyle = {
    marginTop: 0,
  };
  return (
    <div>
      {loadImage ? (
        <div>
          <img
            src={backendUrlHostname + attachment.url}
            alt={
              attachment.extension +
              " Uploaded by " +
              attachment.uploadedBy.firstName +
              " on " +
              attachment.createdOn
            }
          />
          <div style={messageStyle}>
            LOADING (img svg goes here)
            <div>
              <div>Just one second</div>
              We are fetching that file
            </div>
          </div>
        </div>
      ) : (
        <div>
          <a
            href={backendUrlHostname + attachment.url}
            onClick={() => setLoadImage(true)}
          >
            <div>
              <AttachmentIcon extension={attachment.extension} />
              <span>
                IMAGE Uploaded by {attachment.uploadedBy.firstName} on{" "}
                {attachment.createdOn}
              </span>
            </div>

            <AttachmentLinkMessage extension={attachment.extension} />
          </a>
        </div>
      )}
    </div>
  );
}
