type Props = {
  children: any;
};

export function ActivityWrapper({children}: Props) {
  return (
    <li className="py-5">
      <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
        {children}
      </div>
    </li>
  );
}
