import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {MySelect} from "../FormComponents/MySelect";

const GET_POLICY_TYPE_CHOICES = loader(
  "../Fragments/Policies/PolicyTypeChoices.graphql"
);

type PolicyType = {
  id: string;
  name: string;
};

type PolicyTypeChoices = {
  policyTypeChoices: PolicyType[];
};

type Props = {
  value: string;
  setValue: (newValue: string) => void;
  initialValue: string;
};

export const PolicyTypeChooser = ({value, setValue, initialValue}: Props) => {
  const {loading, error, data} = useQuery<PolicyTypeChoices>(
    GET_POLICY_TYPE_CHOICES
  );

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  if (data) {
    return (
      <MySelect
        label="Policy Type"
        graphqloptions={data.policyTypeChoices}
        value={value}
        setValue={setValue}
        initialValue={initialValue}
      />
    );
  }

  return <p>An error occurred</p>;
};
