import * as React from "react";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

// import {Link} from "react-router-dom";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

import {OpenQuote} from "../Components/OpenQuote";

const GET_OPEN_QUOTES = loader(
  "../Fragments/Activities/OpenQuotesQuery.graphql"
);

interface Quote {
  id: string;
  client: {
    id: string;
    name: string;
    attn: string;
  };
  product: [{name: string}];
  quoteType: string;
  quoteStatus: string;
  timestamp: string;
}

interface OpenQuotes {
  myOpenQuotes: Quote[];
}

export function Quotes() {
  const {loading, error, data} = useQuery<OpenQuotes>(GET_OPEN_QUOTES);

  React.useEffect(() => {
    document.title = `Quotes`;
  });

  if (loading) return <p>Loading...</p>;

  if (error) return <span>{`Error! ${error}`}</span>;

  if (data)
    return (
      <div>
        <div className="flex h-screen" id="app">
          <Sidebar activeItem="quotes" />

          <div className="flex flex-col flex-1 min-w-0 bg-white">
            <Header pageTitle="Quotes" />
            {/* <PageTitle>Quotes</PageTitle>
            View All <Link to="/reports/open-quotes">Open Quotes</Link> for the
            Agency */}
            {/* </Header> */}
            <div className="flex-1 overflow-auto bg-coolGray-200">
              <main className="inline-flex h-full p-3 overflow-hidden">
                <div className="flex flex-col flex-shrink-0 rounded-lg bg-coolGray-100 w-80">
                  <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                    Gathering Information
                  </h3>
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    <ul className="px-3 pt-1 pb-3">
                      {data.myOpenQuotes
                        .filter((quote) => quote.quoteStatus === "A_10")
                        .map((quote, index) => (
                          <OpenQuote
                            quote={quote}
                            key={quote.id}
                            index={index}
                          />
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col flex-shrink-0 ml-3 rounded-lg bg-coolGray-100 w-80">
                  <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                    Preparing Quotes
                  </h3>
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    <ul className="px-3 pt-1 pb-3">
                      {data.myOpenQuotes
                        .filter((quote) => quote.quoteStatus === "A_20")
                        .map((quote, index) => (
                          <OpenQuote
                            quote={quote}
                            key={quote.id}
                            index={index}
                          />
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col flex-shrink-0 ml-3 rounded-lg bg-coolGray-100 w-80">
                  <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                    Ready to Review
                  </h3>
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    <ul className="px-3 pt-1 pb-3">
                      {data.myOpenQuotes
                        .filter((quote) => quote.quoteStatus === "A_30")
                        .map((quote, index) => (
                          <OpenQuote
                            quote={quote}
                            key={quote.id}
                            index={index}
                          />
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col flex-shrink-0 ml-3 rounded-lg bg-coolGray-100 w-80">
                  <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                    Reviewed, Pending
                  </h3>
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    <ul className="px-3 pt-1 pb-3">
                      {data.myOpenQuotes
                        .filter((quote) => quote.quoteStatus === "A_40")
                        .map((quote, index) => (
                          <OpenQuote
                            quote={quote}
                            key={quote.id}
                            index={index}
                          />
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col flex-shrink-0 ml-3 rounded-lg bg-coolGray-100 w-80">
                  <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                    Sold
                  </h3>
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    <ul className="px-3 pt-1 pb-3">
                      {data.myOpenQuotes
                        .filter((quote) => quote.quoteStatus === "A_50")
                        .map((quote, index) => (
                          <OpenQuote
                            quote={quote}
                            key={quote.id}
                            index={index}
                          />
                        ))}
                    </ul>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  return <span>There was an error</span>;
}
