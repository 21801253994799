type Props = {
  className: string;
};

export function HashtagIcon({className}: Props) {
  return (
    <svg
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={className}
    >
      <path d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"></path>
    </svg>
  );
}
