import * as React from "react";

import {Link} from "react-router-dom";
import {backendUrlHostname} from "../urls";

type Props = {
  dropdownOpen: boolean;
};

/* <!--
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-200"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */

export function AvatarDropdown({dropdownOpen}: Props) {
  return (
    <div
      className={`${
        dropdownOpen ? "" : "hidden"
      } absolute right-0 w-48 mt-2 origin-top-right rounded-md shadow-lg`}
    >
      <div
        className="py-1 bg-white rounded-md ring-1 ring-black ring-opacity-5"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        <Link
          to="/my-account"
          className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-coolGray-700 hover:bg-coolGray-100 focus:outline-none focus:bg-coolGray-100"
          role="menuitem"
        >
          My Account
        </Link>
        <Link
          to="/agency"
          className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-coolGray-700 hover:bg-coolGray-100 focus:outline-none focus:bg-coolGray-100"
          role="menuitem"
        >
          Agency
        </Link>
        <Link
          to="/users"
          className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-coolGray-700 hover:bg-coolGray-100 focus:outline-none focus:bg-coolGray-100"
          role="menuitem"
        >
          Users
        </Link>
        <a
          href={backendUrlHostname + "/logout"}
          className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-coolGray-700 hover:bg-coolGray-100 focus:outline-none focus:bg-coolGray-100"
          role="menuitem"
        >
          Sign out
        </a>
      </div>
    </div>
  );
}
