import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {DeleteButton} from "../../Components/DeleteButton";
import {ConfirmDeleteObject} from "../../FormComponents/ConfirmDeleteObject";

const DELETE_PROVIDER_MUTATION = loader(
  "../../Fragments/Medicare/ProviderDelete.graphql"
);

const PROVIDER_SET_FRAGMENT = loader(
  "../../Fragments/Medicare/ProviderSet.graphql"
);

interface DeletedProvider {
  deleteProvider: {
    id: string;
  };
}

type Provider = {
  id: string;
  name: string;
  typeOfDoctor: string;
  notes: string;
};

type ProviderSet = {
  providerSet: Provider[];
};

type Props = {
  beneficiaryId: string;
  provider: Provider;
  immaculate?: boolean;
};

export function ProviderDelete({
  beneficiaryId,
  provider,
  immaculate = false,
}: Props) {
  const [startConfirm, setStartConfirm] = React.useState(false);

  const [deleteProvider] = useMutation<DeletedProvider>(
    DELETE_PROVIDER_MUTATION,
    {
      variables: {id: provider.id},
      update(cache, {data}) {
        if (!data || !data.deleteProvider || !data.deleteProvider.id) return;

        let providers = cache.readFragment<ProviderSet>({
          id: "BeneficiaryProfileObjectType:" + beneficiaryId,
          fragment: PROVIDER_SET_FRAGMENT,
          fragmentName: "ProviderSet",
        });

        let newproviders = {};
        if (providers) {
          newproviders = providers.providerSet.filter(
            (e) => e.id !== data.deleteProvider.id
          );

          cache.writeFragment({
            id: "BeneficiaryProfileObjectType:" + beneficiaryId,
            fragment: PROVIDER_SET_FRAGMENT,
            fragmentName: "ProviderSet",
            data: {
              providerSet: newproviders,
              __typename: "BeneficiaryProfileObjectType",
            },
          });
          // send("DELETED");
        }
      },
    }
  );

  if (immaculate) {
    return <DeleteButton action={() => deleteProvider()} />;
  }

  if (startConfirm) {
    return (
      <ConfirmDeleteObject
        objectType="Provider"
        objectDescription={
          <span>
            Name: <strong>{provider.name}</strong>
            <br />
            Type: {provider.typeOfDoctor}
          </span>
        }
        abortDeletion={() => setStartConfirm(false)}
        performDeletion={() => deleteProvider()}
      />
    );
  }

  return <DeleteButton action={() => setStartConfirm(true)} />;
}
