import * as React from "react";

import {Attachment} from "../Attachment";

import {ActivityHeader} from "./ActivityHeader";
import {ActivityWrapper} from "./ActivityWrapper";
import {ActivityNoteList} from "./ActivityNoteList";
import {AddActivityNote} from "./ActivityNoteAdd";

type AttachmentType = {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
};

type Tag = {
  id: string;
  document: AttachmentType;
};

type ActivityNote = {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
};

type FlexiblePolicyActivity = {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNote[];
  documenttagSet: Tag[];
};

type Props = {
  activity: FlexiblePolicyActivity;
};

export function MedicareAppointment({activity}: Props) {
  const [adding, setAdding] = React.useState(false);

  const [active, setActive] = React.useState(false);

  function toggleActive() {
    setActive(active ? false : true);
  }

  return (
    <ActivityWrapper>
      <ActivityHeader
        header={`Medicare Appointment`}
        toggle={() => toggleActive()}
      />

      <p className="mt-1 text-sm text-gray-600 line-clamp-2">
        {activity.description}
      </p>

      {active && (
        <div>
          <button type="button">Edit</button>

          <div>
            {adding ? (
              <AddActivityNote activityId={activity.id} setAdding={setAdding} />
            ) : (
              <button type="button" onClick={() => setAdding(true)}>
                Add Note
              </button>
            )}
            <br />
            <br />
            <ActivityNoteList notes={activity.activitynoteSet} />

            {activity.documenttagSet.map((doc) => (
              <Attachment attachment={doc.document} key={doc.document.id} />
            ))}
          </div>
        </div>
      )}
    </ActivityWrapper>
  );
}
