import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

const ADD_MORTGAGE = loader(
  "../../../Fragments/Underwriting/MortgageAdd.graphql"
);
const MORTGAGE_SET = loader(
  "../../../Fragments/Underwriting/MortgageSet.graphql"
);

type Mortgage = {
  id: string;
  name: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zipcode: string;
  loanNumber: string;
  escrowBill: string;
  position: string;
};

type MortgageSet = {
  mortgageSet: Mortgage[];
};

type Props = {
  id: string;
};

export function AddMortgageButton({id}: Props) {
  const [mortgageAdd] = useMutation<{
    mortgageAdd: Mortgage;
  }>(ADD_MORTGAGE, {
    variables: {
      id: id,
    },
    update(cache, {data}) {
      if (!data || !data.mortgageAdd) return;

      let mortgages = cache.readFragment<MortgageSet>({
        id: "LocationObjectType:" + id,
        fragment: MORTGAGE_SET,
        fragmentName: "MortgageSet",
      });

      let newmortgages = {};
      if (mortgages) {
        newmortgages = mortgages.mortgageSet.concat(data.mortgageAdd);
      } else {
        newmortgages = [data.mortgageAdd];
      }

      cache.writeFragment({
        id: "LocationObjectType:" + id,
        fragment: MORTGAGE_SET,
        fragmentName: "MortgageSet",
        data: {mortgageSet: newmortgages, __typename: "LocationObjectType"},
      });
    },
  });

  return (
    <button
      onClick={() => mortgageAdd()}
      className="px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700"
    >
      +
    </button>
  );
}
