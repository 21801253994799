import * as React from "react";

import {AddMortgageButton} from "./AddMortgage";
import {EditMortgage} from "./EditMortgage";

type Mortgage = {
  id: string;
  name: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zipcode: string;
  loanNumber: string;
  escrowBill: string;
  position: string;
};

type Props = {
  autoSave: boolean;
  mortgages: Mortgage[];
  locationId: string;
};

export default function Mortgages({locationId, mortgages, autoSave}: Props) {
  return (
    <div className="flex p-8 m-8 border-t border-coolGray-400">
      <div className="w-1/3">
        <h3 className="text-lg">
          Mortgages {Object.values(mortgages).length === 0 ? " (None)" : null}
        </h3>

        <AddMortgageButton id={locationId} />
      </div>

      {Object.values(mortgages).length > 0 && (
        <div className="w-2/3 p-8 bg-white rounded-lg shadow-lg">
          <div className="grid grid-cols-1 gap-4 divide-y divide-coolGray-300">
            {mortgages.map((mortgage) => (
              <EditMortgage
                key={mortgage.id}
                locationId={locationId}
                mortgage={mortgage}
                autoSave={autoSave}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
