import * as React from "react";

import {AddAddress} from "../FormComponents/AddAddress";
import {Address} from "./Address";

type Client = {
  id: string;
  name: string;
};

type Location = {
  id: string;
};

type ClientAddress = {
  id: string;
  validatedData: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  description: string;
  locationSet: Location[];
  distanceFromOffice: string;
  addressType: string;
  county: string;
  active: boolean;
  seeAlso: Client[];
};

type Props = {
  addresses: ClientAddress[];
  clientId: string;
};

export function AddressList({addresses, clientId}: Props) {
  const [adding, setAdding] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);

  const count = addresses.filter((address) => address.active === true).length;

  return (
    <div>
      <div>
        <h4>{count === 1 ? "Address" : "Addresses"}</h4>

        {adding ? null : (
          <button className="bg-green-500" onClick={() => setAdding(true)}>
            Add
          </button>
        )}
      </div>
      <div>
        {addresses
          .filter((address) => address.active === true)
          .map((address) => (
            <Address
              key={address.id}
              address={address}
              count={count}
              showAll={showAll}
            />
          ))}

        {showAll === false && count > 3 ? (
          <button onClick={() => setShowAll(true)}>
            Show All {count} addresses
          </button>
        ) : null}

        {adding ? (
          <AddAddress clientId={clientId} stopAdding={() => setAdding(false)} />
        ) : null}
      </div>
    </div>
  );
  // }
}
