import * as React from "react";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

import {ActivityList} from "../Components/Activities/ActivityList";

import {AddressList} from "../Components/AddressList";

import {PersonList} from "../Components/PersonList";

// import {ClientRemindersPane} from "../Components/ClientRemindersPane";
import {RemindersPane} from "../Components/RemindersPane";
// import {ClientContentPane} from "../Components/ClientContentPane";
import {PolicyListPane} from "../Components/PolicyListPane";

// import {ReferralsPane} from "../Components/ReferralsPane";
// import {QuotesPane} from "../Components/QuotesPane";
import {ScheduledPropertyPane} from "../Components/ScheduledPropertyPane";

import {
  Switch,
  Link,
  Route,
  // Redirect,
  // useParams,
  useRouteMatch,
  RouteComponentProps,
} from "react-router-dom";

// import {ActivitySummary} from "../Components/Activities/activitysummary";

const CLIENT_QUERY = loader("../Fragments/Clients/ClientQuery.graphql");

type TParams = {clientId: string};

interface BeneficiaryProfile {
  id: string;
}

interface PersonType {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  preferredName: string;
  gender: string;
  dob: string;
  age: string;
  social: string;
  maritalStatus: string;
  license: string;
  licenseDate: string;
  ddc: string;
  education: string;
  occupation: string;
  employer: string;
  email: string;
  cellPhone: string;
  workPhone: string;
  notes: string;
  beneficiaryprofileSet: BeneficiaryProfile[];
}

interface PersonAccountTag {
  person: PersonType;
}

interface Attachment {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
}

interface Tag {
  id: string;
  document: Attachment;
}

interface ActivityNote {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
}

interface FlexiblePolicyActivity {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNote[];
  documenttagSet: Tag[];
}

interface DownloadMessage {
  id: string;
  acknowledged: boolean;
  transactionTypeCode: string;
  originalFile: {
    timestamp: string;
  };
}

interface PolicySection {
  id: string;
  downloadmessageSet: DownloadMessage[];
}

interface PolicyTerm {
  id: string;
  effectiveDate: string;
  expirationDate: string;
  premium: string;
  policysectionSet: PolicySection[];
}

interface PolicyType {
  id: string;
  isActive: boolean;
  agency: {
    agency: {
      name: string;
    };
    name: string;
  };
  company: {
    id: string;
    name: string;
    carrier: {
      id: string;
      name: string;
    };
  };
  description: string;
  policyType: string;
  policyNumber: string;
  effectiveDate: string;
  expirationDate: string;
  premium: number;
  firstWritten: string;
  daysSinceLastPolicyReview: string;

  policytermSet: PolicyTerm[];
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
}

interface Reminder {
  id: string;
  completed: boolean;
  message: string;
  remindOn: string;
  client: {
    name: string;
    attn: string;
  };
}

interface ClientReminder {
  id: string;
  completed: boolean;
  reminder: string;
  remindOn: string;
  about: {
    name: string;
    attn: string;
  };
}

interface Location {
  id: string;
}

interface ClientAddress {
  id: string;
  validatedData: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  description: string;
  locationSet: Location[];
  distanceFromOffice: string;
  addressType: string;
  county: string;
  active: boolean;
  seeAlso: ClientType[];
}

interface ClientType {
  id: string;
  name: string;
  attn: string;
  active: boolean;
  clientType: {
    name: string;
  };
  notes: string;
  csr: {
    firstName: string;
    lastName: string;
  };
  producer: {
    firstName: string;
    lastName: string;
  };
  policySet: PolicyType[];
  phonenumberSet: {
    id: string;
    number: string;
    label: string;
  };
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
  clientaddressSet: ClientAddress[];
  personaccounttagSet: PersonAccountTag[];
  reminderSet: Reminder[];
  clientreminderSet: ClientReminder[];
}

interface ClientQueryResult {
  client: ClientType;
}

export function Client({match}: RouteComponentProps<TParams>) {
  let {path, url} = useRouteMatch();
  //   let {selectedPane} = useParams<Record<string, string | undefined>>();

  const [activityQuery, setActivityQuery] = React.useState("");

  const {loading, error, data} = useQuery<ClientQueryResult>(CLIENT_QUERY, {
    variables: {clientId: match.params.clientId},
  });

  React.useEffect(() => {
    document.title = data?.client?.name ?? "Loading";
  });

  if (loading) {
    return (
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div>Loading</div>
      </div>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (data) {
    let activities = data.client.flexiblepolicyactivitySet;

    data.client.policySet.map(
      (policy) =>
        (activities = activities.concat(policy.flexiblepolicyactivitySet))
    );

    //   TODO make the activities show up in the right order.

    // console.log(activities);
    //   let {path, url} = useRouteMatch();
    //   let {selectedPane} = useParams<Record<string, string | undefined>>();

    return (
      //       < !--
      //   This example requires Tailwind CSS v2.0+

      //   This example requires some changes to your config:

      //   ```
      //   // tailwind.config.js
      //   const colors = require('tailwindcss/colors')

      //   module.exports = {
      //     // ...
      //     theme: {
      //       extend: {
      //         colors: {
      //           'light-blue': colors.lightBlue,
      //           teal: colors.teal,
      //           cyan: colors.cyan,
      //           rose: colors.rose,
      //         }
      //       }
      //     },
      //     plugins: [
      //       // ...
      //       require('@tailwindcss/forms'),
      //       require('@tailwindcss/line-clamp'),
      //     ]
      //   }
      //   ```
      // -->
      <div className="min-h-screen bg-gray-100">
        <header className="pb-24 bg-gradient-to-r from-sky-800 to-cyan-600">
          <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:max-w-full lg:px-8">
            <div className="relative flex flex-wrap items-center justify-center lg:justify-between">
              {/* <!-- Logo --> */}
              {/* <div className="absolute left-0 flex-shrink-0 py-5 lg:static">
                <a href="#">
                  <span className="sr-only">Workflow</span> */}
              {/* <!-- https://tailwindui.com/img/logos/workflow-mark-cyan-200.svg --> */}
              {/* <svg
                    className="w-auto h-8"
                    fill="none"
                    viewBox="0 0 35 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#A5F3FC"
                      d="M15.258 26.865a4.043 4.043 0 01-1.133 2.917A4.006 4.006 0 0111.253 31a3.992 3.992 0 01-2.872-1.218 4.028 4.028 0 01-1.133-2.917c.009-.698.2-1.382.557-1.981.356-.6.863-1.094 1.47-1.433-.024.109.09-.055 0 0l1.86-1.652a8.495 8.495 0 002.304-5.793c0-2.926-1.711-5.901-4.17-7.457.094.055-.036-.094 0 0A3.952 3.952 0 017.8 7.116a3.975 3.975 0 01-.557-1.98 4.042 4.042 0 011.133-2.918A4.006 4.006 0 0111.247 1a3.99 3.99 0 012.872 1.218 4.025 4.025 0 011.133 2.917 8.521 8.521 0 002.347 5.832l.817.8c.326.285.668.551 1.024.798.621.33 1.142.826 1.504 1.431a3.902 3.902 0 01-1.504 5.442c.033-.067-.063.036 0 0a8.968 8.968 0 00-3.024 3.183 9.016 9.016 0 00-1.158 4.244zM19.741 5.123c0 .796.235 1.575.676 2.237a4.01 4.01 0 001.798 1.482 3.99 3.99 0 004.366-.873 4.042 4.042 0 00.869-4.386 4.02 4.02 0 00-1.476-1.806 3.994 3.994 0 00-5.058.501 4.038 4.038 0 00-1.175 2.845zM23.748 22.84c-.792 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.806 4.042 4.042 0 00.869 4.387 3.99 3.99 0 004.366.873A4.01 4.01 0 0027.08 29.1a4.039 4.039 0 00-.5-5.082 4 4 0 00-2.832-1.18zM34 15.994c0-.796-.235-1.574-.675-2.236a4.01 4.01 0 00-1.798-1.483 3.99 3.99 0 00-4.367.873 4.042 4.042 0 00-.869 4.387 4.02 4.02 0 001.476 1.806 3.993 3.993 0 002.226.678 4.003 4.003 0 002.832-1.18A4.04 4.04 0 0034 15.993z M5.007 11.969c-.793 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.807 4.042 4.042 0 00.869 4.386 4.001 4.001 0 004.366.873 4.011 4.011 0 001.798-1.483 4.038 4.038 0 00-.5-5.08 4.004 4.004 0 00-2.831-1.181z"
                    />
                  </svg>
                </a>
              </div> */}

              <div className="w-full py-5 lg:border-t lg:border-white lg:border-opacity-20">
                <div className="lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center">
                  {/* <!-- Left nav --> */}
                  <div className="hidden lg:block lg:col-span-2">
                    <nav className="flex space-x-4">
                      {/* <a
                        href="#"
                        className="px-3 py-2 text-sm font-medium text-white bg-white bg-opacity-0 rounded-md hover:bg-opacity-10"
                        aria-current="page"
                      >
                        Home
                      </a> */}
                      <Link
                        to={`${url}`}
                        className="px-3 py-2 text-sm font-medium text-white bg-white bg-opacity-0 rounded-md hover:bg-opacity-10"
                        // className={`${
                        //   selectedPane === "security"
                        //     ? "text-purple-600  border-purple-500"
                        //     : "text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700"
                        // } px-1 py-4 ml-8 text-sm font-medium border-b-2 whitespace-nowrap`}
                      >
                        Home
                      </Link>

                      <Link
                        to={`${url}/property-and-casualty`}
                        className="px-3 py-2 text-sm font-medium bg-white bg-opacity-0 rounded-md text-cyan-100 hover:bg-opacity-10"
                        // className={`${
                        //   selectedPane === "general"
                        //     ? "text-purple-600  border-purple-500"
                        //     : "text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700"
                        // } px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap`}
                      >
                        Property &amp; Casualty Policies
                      </Link>

                      {/* <a
                        href="#"
                        className="px-3 py-2 text-sm font-medium bg-white bg-opacity-0 rounded-md text-cyan-100 hover:bg-opacity-10"
                        aria-current="false"
                      >
                        Property &amp; Casualty
                      </a> */}

                      {/* <a
                        href="#"
                        className="px-3 py-2 text-sm font-medium bg-white bg-opacity-0 rounded-md text-cyan-100 hover:bg-opacity-10"
                        aria-current="false"
                      >
                        Life &amp; Health
                      </a> */}

                      <Link
                        to={`${url}/life-and-health`}
                        className="px-3 py-2 text-sm font-medium bg-white bg-opacity-0 rounded-md text-cyan-100 hover:bg-opacity-10"
                        // className={`${
                        //   selectedPane === "security"
                        //     ? "text-purple-600  border-purple-500"
                        //     : "text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700"
                        // } px-1 py-4 ml-8 text-sm font-medium border-b-2 whitespace-nowrap`}
                      >
                        Life &amp; Health
                      </Link>

                      <Link
                        to={`${url}/reminders`}
                        className="px-3 py-2 text-sm font-medium bg-white bg-opacity-0 rounded-md text-cyan-100 hover:bg-opacity-10"
                        // className={`${
                        //   selectedPane === "security"
                        //     ? "text-purple-600  border-purple-500"
                        //     : "text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700"
                        // } px-1 py-4 ml-8 text-sm font-medium border-b-2 whitespace-nowrap`}
                      >
                        Reminders
                      </Link>

                      {/* <a
                        href="#"
                        className="px-3 py-2 text-sm font-medium bg-white bg-opacity-0 rounded-md text-cyan-100 hover:bg-opacity-10"
                        aria-current="false"
                      >
                        Reminders
                      </a> */}

                      {/* <a
                        href="#"
                        className="px-3 py-2 text-sm font-medium bg-white bg-opacity-0 rounded-md text-cyan-100 hover:bg-opacity-10"
                        aria-current="false"
                      >
                        Openings
                      </a> */}
                    </nav>
                  </div>
                  <div className="px-12 lg:px-0">
                    {/* <!-- Search --> */}
                    <div className="w-full max-w-xs mx-auto lg:max-w-full">
                      <label htmlFor="search" className="sr-only">
                        Search Activities
                      </label>
                      <div className="relative text-white focus-within:text-gray-600">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          {/* <!-- Heroicon name: search --> */}
                          <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <input
                          id="search"
                          className="block w-full py-2 pl-10 pr-3 leading-5 text-white placeholder-white bg-white border border-transparent rounded-md bg-opacity-20 focus:text-gray-900 focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder="Search Activities"
                          type="search"
                          name="search"
                          value={activityQuery}
                          onChange={(event) =>
                            setActivityQuery(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Menu button --> */}
              <div className="absolute right-0 flex-shrink-0 lg:hidden">
                {/* <!-- Mobile menu button --> */}
                <button
                  className="inline-flex items-center justify-center p-2 bg-transparent rounded-md text-cyan-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {/* <!--
              Heroicon name: menu

              Menu open: "hidden", Menu closed: "block"
            --> */}
                  <svg
                    className="block w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  {/* <!--
              Heroicon name: x

              Menu open: "block", Menu closed: "hidden"
            --> */}
                  <svg
                    className="hidden w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {/* <!--
      Mobile menu overlay, show/hide based on mobile menu state.

      Entering: "duration-150 ease-out"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "duration-150 ease-in"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
          <div
            className="fixed inset-0 z-20 bg-black bg-opacity-25 lg:hidden"
            aria-hidden="true"
          ></div>

          {/* <!--
      Mobile menu, show/hide based on mobile menu state.

      Entering: "duration-150 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-150 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    --> */}
          <div className="absolute inset-x-0 top-0 z-30 w-full max-w-5xl p-2 mx-auto transition origin-top transform lg:hidden">
            <div className="bg-white divide-y divide-gray-200 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="pt-3 pb-2">
                <div className="flex items-center justify-between px-4">
                  <div>
                    <img
                      className="w-auto h-8"
                      src="https://tailwindui.com/img/logos/workflow-mark-cyan-600.svg"
                      alt="Workflow"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
                    >
                      <span className="sr-only">Close menu</span>
                      {/* <!-- Heroicon name: x --> */}
                      <svg
                        className="w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="px-2 mt-3 space-y-1">
                  <a
                    href="#home"
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                  >
                    Home
                  </a>
                  <a
                    href="#profile"
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                  >
                    Profile
                  </a>
                  <a
                    href="#preferences"
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                  >
                    Resources
                  </a>
                  <a
                    href="#directory"
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                  >
                    Company Directory
                  </a>
                  <a
                    href="#openings"
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                  >
                    Openings
                  </a>
                </div>
              </div>
              <div className="pt-4 pb-2">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="w-10 h-10 rounded-full"
                      src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                      alt=""
                    />
                  </div>
                  <div className="flex-1 min-w-0 ml-3">
                    <div className="text-base font-medium text-gray-800 truncate">
                      Rebecca Nicholas
                    </div>
                    <div className="text-sm font-medium text-gray-500 truncate">
                      rebecca.nicholas@example.com
                    </div>
                  </div>
                  <button className="flex-shrink-0 p-1 ml-auto text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                    <span className="sr-only">View notifications</span>
                    {/* <!-- Heroicon name: bell --> */}
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </button>
                </div>
                <div className="px-2 mt-3 space-y-1">
                  <a
                    href="#profile"
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                  >
                    Your Profile
                  </a>
                  <a
                    href="#settings"
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                  >
                    Settings
                  </a>
                  <a
                    href="#signout"
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                  >
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main className="pb-8 -mt-24">
          <div className="max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Profile</h1>
            {/* <!-- Main 3 column grid --> */}
            <div className="grid items-start grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
              {/* <!-- Left column --> */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                {/* <!-- Welcome panel --> */}
                <section aria-labelledby="profile-overview-title">
                  <div className="overflow-hidden bg-white rounded-lg shadow">
                    <h2 className="sr-only" id="profile-overview-title">
                      Profile Overview
                    </h2>
                    <div className="p-6 bg-white">
                      <div className="sm:flex sm:items-center sm:justify-between">
                        <div className="sm:flex sm:space-x-5">
                          {/* <div className="flex-shrink-0">
                            <img
                              className="w-20 h-20 mx-auto rounded-full"
                              src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                              alt=""
                            />
                          </div> */}
                          <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p className="text-sm font-medium text-gray-600">
                              {data.client.active ? null : <>Inactive </>}
                              {data.client.clientType.name}
                            </p>
                            <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                              {data.client.name}
                              {data.client.attn && (
                                <>
                                  <br />
                                  {data.client.attn}
                                </>
                              )}
                            </p>
                            <p className="text-sm font-medium text-gray-600">
                              {data.client.notes && (
                                <span>
                                  <h4>Notes</h4> {data.client.notes}
                                </span>
                              )}
                            </p>

                            <AddressList
                              addresses={data.client.clientaddressSet}
                              clientId={data.client.id}
                            />
                          </div>
                        </div>
                        <div className="flex justify-center mt-5 sm:mt-0">
                          {/* <a
                            href="#"
                            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                          >
                            Edit
                          </a> */}
                          <Link
                            to={`${url}/edit`}
                            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                            // className={`${
                            //   selectedPane === "security"
                            //     ? "text-purple-600  border-purple-500"
                            //     : "text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700"
                            // } px-1 py-4 ml-8 text-sm font-medium border-b-2 whitespace-nowrap`}
                          >
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 border-t border-gray-200 divide-y divide-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
                      <div className="px-6 py-5 text-sm font-medium text-center">
                        <span className="text-gray-600">Customer Service</span>
                        &nbsp;
                        <span className="text-gray-900">
                          {data.client.csr.firstName} {data.client.csr.lastName}
                        </span>
                      </div>

                      <div className="px-6 py-5 text-sm font-medium text-center">
                        <span className="text-gray-600">Producer</span>&nbsp;
                        <span className="text-gray-900">
                          {data.client.producer.firstName}{" "}
                          {data.client.producer.lastName}
                        </span>
                      </div>

                      <div className="px-6 py-5 text-sm font-medium text-center">
                        <span className="text-gray-900">2</span>&nbsp;
                        <span className="text-gray-600">
                          Personal days left
                        </span>
                      </div>
                    </div>
                  </div>
                </section>

                <Switch>
                  <Route exact path={path}>
                    {/* <Redirect to={`${path}/general`} /> */}
                    <PersonList people={data.client.personaccounttagSet} />
                  </Route>

                  <Route exact path={`${path}/property-and-casualty`}>
                    <PolicyListPane client={data.client} />
                  </Route>
                  <Route exact path={`${path}/life-and-health`}>
                    <PolicyListPane client={data.client} />
                  </Route>
                  <Route exact path={`${path}/reminders`}>
                    <RemindersPane
                      reminders={data.client.reminderSet}
                      clientId={data.client.id}
                    />
                    {/* <ClientRemindersPane
                      reminders={data.client.clientreminderSet}
                      clientId={data.client.id}
                    /> */}
                    {/* <AgencySettings />   */}
                  </Route>
                  {/* <Route exact path={`${path}/users`}>
                    <Users />
                  </Route> */}
                </Switch>

                {/* <section>
                  <ClientContentPane client={data.client} />
                  <QuotesPane client={data.client} />

                  <ReferralsPane client={data.client} />
                </section> */}

                {/* <!-- Actions panel --> */}
                <section aria-labelledby="quick-links-title">
                  <div className="overflow-hidden bg-gray-200 divide-y divide-gray-200 rounded-lg shadow sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                    <h2 className="sr-only" id="quick-links-title">
                      Quick links
                    </h2>

                    <div className="relative p-6 bg-white rounded-tl-lg group focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500">
                      <div>
                        <span className="inline-flex p-3 text-teal-700 rounded-lg bg-teal-50 ring-4 ring-white">
                          {/* <!-- Heroicon name: clock --> */}
                          <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a href="#request" className="focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            Request time off
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          Doloribus dolores nostrum quia qui natus officia quod
                          et dolorem. Sit repellendus qui ut at blanditiis et
                          quo et molestiae.
                        </p>
                      </div>
                      <span
                        className="absolute text-gray-300 top-6 right-6 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-6 h-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>

                    <div className="relative p-6 bg-white rounded-tr-lg group focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500">
                      <div>
                        <span className="inline-flex p-3 text-purple-700 rounded-lg bg-purple-50 ring-4 ring-white">
                          {/* <!-- Heroicon name: badge-check --> */}
                          <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a href="#benefits" className="focus:outline-none">
                            {/* <!-- Extend touch target to entire panel --> */}
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            Benefits
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          Doloribus dolores nostrum quia qui natus officia quod
                          et dolorem. Sit repellendus qui ut at blanditiis et
                          quo et molestiae.
                        </p>
                      </div>
                      <span
                        className="absolute text-gray-300 top-6 right-6 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-6 h-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>

                    <div className="relative p-6 bg-white group focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500">
                      <div>
                        <span className="inline-flex p-3 rounded-lg bg-sky-50 text-sky-700 ring-4 ring-white">
                          {/* <!-- Heroicon name: users --> */}
                          <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a href="#schedule" className="focus:outline-none">
                            {/* <!-- Extend touch target to entire panel --> */}
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            Schedule a one-on-one
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          Doloribus dolores nostrum quia qui natus officia quod
                          et dolorem. Sit repellendus qui ut at blanditiis et
                          quo et molestiae.
                        </p>
                      </div>
                      <span
                        className="absolute text-gray-300 top-6 right-6 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-6 h-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>

                    <div className="relative p-6 bg-white group focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500">
                      <div>
                        <span className="inline-flex p-3 text-yellow-700 rounded-lg bg-yellow-50 ring-4 ring-white">
                          {/* <!-- Heroicon name: cash --> */}
                          <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a href="#payroll" className="focus:outline-none">
                            {/* <!-- Extend touch target to entire panel --> */}
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            Payroll
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          Doloribus dolores nostrum quia qui natus officia quod
                          et dolorem. Sit repellendus qui ut at blanditiis et
                          quo et molestiae.
                        </p>
                      </div>
                      <span
                        className="absolute text-gray-300 top-6 right-6 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-6 h-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>

                    <div className="relative p-6 bg-white rounded-bl-lg group focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500">
                      <div>
                        <span className="inline-flex p-3 rounded-lg bg-rose-50 text-rose-700 ring-4 ring-white">
                          {/* <!-- Heroicon name: receipt-refund --> */}
                          <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a href="#expenses" className="focus:outline-none">
                            {/* <!-- Extend touch target to entire panel --> */}
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            Submit an expense
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          Doloribus dolores nostrum quia qui natus officia quod
                          et dolorem. Sit repellendus qui ut at blanditiis et
                          quo et molestiae.
                        </p>
                      </div>
                      <span
                        className="absolute text-gray-300 top-6 right-6 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-6 h-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>

                    <div className="relative p-6 bg-white rounded-br-lg group focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500">
                      <div>
                        <span className="inline-flex p-3 text-indigo-700 rounded-lg bg-indigo-50 ring-4 ring-white">
                          {/* <!-- Heroicon name: academic-cap --> */}
                          <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                            <path
                              fill="#fff"
                              d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a href="training" className="focus:outline-none">
                            {/* <!-- Extend touch target to entire panel --> */}
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            Training
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          Doloribus dolores nostrum quia qui natus officia quod
                          et dolorem. Sit repellendus qui ut at blanditiis et
                          quo et molestiae.
                        </p>
                      </div>
                      <span
                        className="absolute text-gray-300 top-6 right-6 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-6 h-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </section>
              </div>

              {/* <!-- Right column --> */}
              <div className="grid grid-cols-1 gap-4">
                {/* <!-- Announcements --> */}
                <section aria-labelledby="announcements-title">
                  <div className="overflow-hidden bg-white rounded-lg shadow">
                    <div className="p-6">
                      <h2
                        className="text-base font-medium text-gray-900"
                        id="announcements-title"
                      >
                        Happening
                      </h2>
                      <div className="flow-root mt-6">
                        <ul className="-my-5 divide-y divide-gray-200">
                          {/* <ActivitySummary /> */}
                          <li className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                              <h3 className="text-sm font-semibold text-gray-800">
                                <a
                                  href="#closed"
                                  className="hover:underline focus:outline-none"
                                >
                                  {/* <!-- Extend touch target to entire panel --> */}
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  ></span>
                                  Office closed on July 2nd
                                </a>
                              </h3>
                              <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                                Cum qui rem deleniti. Suscipit in dolor
                                veritatis sequi aut. Vero ut earum quis
                                deleniti. Ut a sunt eum cum ut repudiandae
                                possimus. Nihil ex tempora neque cum consectetur
                                dolores.
                              </p>
                            </div>
                          </li>

                          <li className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                              <h3 className="text-sm font-semibold text-gray-800">
                                <a
                                  href="#policy"
                                  className="hover:underline focus:outline-none"
                                >
                                  {/* <!-- Extend touch target to entire panel --> */}
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  ></span>
                                  New password policy
                                </a>
                              </h3>
                              <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                                Alias inventore ut autem optio voluptas et
                                repellendus. Facere totam quaerat quam quo
                                laudantium cumque eaque excepturi vel. Accusamus
                                maxime ipsam reprehenderit rerum id repellendus
                                rerum. Culpa cum vel natus. Est sit autem
                                mollitia.
                              </p>
                            </div>
                          </li>

                          <li className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                              <h3 className="text-sm font-semibold text-gray-800">
                                <a
                                  href="#closed"
                                  className="hover:underline focus:outline-none"
                                >
                                  {/* <!-- Extend touch target to entire panel --> */}
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  ></span>
                                  Office closed on July 2nd
                                </a>
                              </h3>
                              <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                                Tenetur libero voluptatem rerum occaecati qui
                                est molestiae exercitationem. Voluptate quisquam
                                iure assumenda consequatur ex et recusandae.
                                Alias consectetur voluptatibus. Accusamus a ab
                                dicta et. Consequatur quis dignissimos
                                voluptatem nisi.
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-6">
                        <a
                          href="#view-all"
                          className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                        >
                          View all
                        </a>
                      </div>
                    </div>
                  </div>
                </section>

                <ActivityList flexiblepolicyactivitySet={activities} />
                <ScheduledPropertyPane client={data.client} />
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className="max-w-3xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-full">
            <div className="py-8 text-sm text-center text-gray-500 border-t border-gray-200 sm:text-left">
              <span className="block sm:inline">
                &copy; 2021 Tailwind Labs Inc.
              </span>{" "}
              <span className="block sm:inline">All rights reserved.</span>
            </div>
          </div>
        </footer>
      </div>
    );
  }

  return <div>Query Error - No Data Returned</div>;
}
