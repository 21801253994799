import * as React from "react";

import {Link} from "react-router-dom";

import {AddressMenu} from "./AddressMenu";
import {EditAddress} from "../FormComponents/EditAddress";
import {GetStandardizedAddressButton} from "../Components/GetStandardizedAddressButton";

type Client = {
  id: string;
  name: string;
};

type Location = {
  id: string;
};

type ClientAddress = {
  id: string;
  validatedData: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  description: string;
  locationSet: Location[];
  distanceFromOffice: string;
  addressType: string;
  county: string;
  seeAlso: Client[];
};

type Props = {
  address: ClientAddress;
  count: number;
  showAll: boolean;
};

export function Address({address, count, showAll = false}: Props) {
  const [editing, setEditing] = React.useState(false);
  // const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  // const [showOptions, setShowOptions] = React.useState(count === 1 ? true : false);

  function toggleEditing() {
    setEditing(editing ? false : true);
  }

  // function toggleOptions() {
  //   setShowOptions(showOptions ? false : true);
  // }

  let distance = null;
  let duration = null;

  if (address.distanceFromOffice !== "{}") {
    distance = JSON.parse(address.distanceFromOffice).rows[0].elements[0]
      .distance.text;
    duration = JSON.parse(address.distanceFromOffice).rows[0].elements[0]
      .duration.text;
  }

  const description = address.description === null ? "" : address.description;
  var errorMessage = null;
  var needsLocation = null;
  var locationId = null;

  if (editing) {
    return <EditAddress address={address} toggleEditing={toggleEditing} />;
  }

  if (count === 1) {
    if (address.addressType === "PM") {
      errorMessage = "There is no physical address";
    }
    if (address.addressType === "PP") {
      errorMessage = "There is no mailing address";
    }
  }

  if (address.locationSet.length > 0) {
    needsLocation = false;
    locationId = address.locationSet[0].id;
  } else {
    needsLocation = true;
  }

  if (
    showAll === true ||
    (count > 3 &&
      (address.addressType === "MP" || address.addressType === "PM")) ||
    count < 4
  ) {
    return (
      <div key={address.id} className="p-6 m-6 bg-white rounded-lg shadow-lg">
        {/* {count > 1 ? <button onClick={toggleOptions}>...</button> : null} */}

        <div className="align-middle">
          <div className="text-l">
            {address.street}
            <br />
            {address.city}, {address.state} {address.zipcode}
            <span style={{color: `#999`}}>
              &nbsp; &nbsp;
              {address.county && address.county + " County "}
              {distance && " (" + distance + ", " + duration + " from office)"}
            </span>
          </div>{" "}
          {address.addressType === "MP" && count === 1
            ? null
            : (() => {
                switch (address.addressType) {
                  case "MP":
                    return <span>Mailing &amp; Physical</span>;
                  case "PM":
                    return <span>Mailing</span>;
                  case "PP":
                    return <span>Physical</span>;
                  case "AP":
                    return <span>Additional Physical</span>;
                  case "AM":
                    return <span>Additional Mailing</span>;
                  case "OT":
                    return <span>Other</span>;
                  default:
                    return <span>Other</span>;
                }
              })()}
          {description === "" ? null : " (" + address.description + ")"}
          {/* {showOptions ? ( */}
          <br />
          {address.seeAlso && <span>See Also: </span>}
          {address.seeAlso?.map((related) => (
            <Link
              key={related.id}
              to={"/client/" + related.id}
              className="mx-2 my-1 text-blue-500 underline hover:text-blue-700"
            >
              {related.name}
            </Link>
          ))}
          <>
            <AddressMenu
              address={address}
              toggleEditing={toggleEditing}
              needsLocation={needsLocation}
              locationId={locationId}
            />

            {address.validatedData === "{}" ||
            address.validatedData ===
              `{"LOOKUP_FAILED": "ADDRESS_NOT_FOUND"}` ? (
              <GetStandardizedAddressButton addressId={address.id} />
            ) : null}
          </>
          {/* ) : null} */}
          {errorMessage ? (
            <span className="bg-orange-500">Warning Icon !{errorMessage}</span>
          ) : null}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
