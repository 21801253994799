import * as React from "react";

import {useMutation} from "@apollo/client";

import {isValid} from "date-fns";

import {Prompt} from "react-router";

import {MyTextarea} from "../FormComponents/MyTextarea";
import {MyDateField} from "../FormComponents/MyDateField";

// Commented because no type definitions. Did not search for them yet.
// import TextareaAutosize from "react-textarea-autosize";

import {SAVE_REMINDER} from "../GraphQL/Queries";

type ReminderType = {
  id: string;
  completed: boolean;
  message: string;
  remindOn: string;
  client: {
    name: string;
    attn: string;
  };
};

type Props = {
  reminder: ReminderType;
  stopEditing: () => void;
};

export function ReminderEdit({reminder, stopEditing}: Props) {
  const [reminderContent, setReminderContent] = React.useState(
    reminder.message
  );
  const [remindOn, setRemindOn] = React.useState<string | null>(
    reminder.remindOn
  );
  const [modified, setModified] = React.useState(false);
  const [formIsValid, setFormIsValid] = React.useState(false);

  React.useEffect(() => {
    if (
      reminder.message !== reminderContent.trim() ||
      reminder.remindOn !== remindOn
    ) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [
    reminder.message,
    reminderContent,
    reminder.remindOn,
    remindOn,
    modified,
  ]);

  React.useEffect(() => {
    if (remindOn && remindOn.length === 10 && isValid(new Date(remindOn))) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [remindOn, formIsValid]);

  const [saveReminder] = useMutation<{
    toggleReminderCompletion: ReminderType;
  }>(SAVE_REMINDER, {
    variables: {
      reminderId: reminder.id,
      message: reminderContent.trim(),
      remindOn: remindOn,
    },
  });

  function handleDiscard() {
    let confirmDiscardMessage =
      "Are you sure you want to discard the changes?\n\nPress OK to discard or cancel to continue editing.";
    if (window.confirm(confirmDiscardMessage)) {
      setReminderContent(reminder.message);
      setRemindOn(reminder.remindOn);
      setModified(false);
    }
  }

  return (
    <div className="border border-coolGray-200" key={reminder.id}>
      <div className="p-2">
        <div>
          <form>
            <Prompt
              when={modified}
              message="There are unsaved changes. If you proceed the data will be lost forever."
            />
            <h4>Editing</h4>

            <MyTextarea
              label="Reminder Note"
              value={reminderContent}
              initialValue={reminder.message}
              setValue={setReminderContent}
              autoFocusIfBlank
            />

            <MyDateField
              label="Remind On Date YYYY-MM-DD"
              value={remindOn}
              setValue={setRemindOn}
              initialValue={reminder.remindOn}
            />

            {modified ? (
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={handleDiscard}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    disabled={!formIsValid}
                    onClick={(event) => {
                      event.preventDefault();

                      if (formIsValid) {
                        saveReminder();
                        stopEditing();
                      }
                    }}
                    className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <button onClick={stopEditing} type="button">
                Cancel
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
