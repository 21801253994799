import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import IdleTimer from "react-idle-timer";

import {MyTextInputField} from "../../../FormComponents/MyTextInputField";

const DELETE_DOG = loader("../../../Fragments/Underwriting/DogDelete.graphql");
const DOG_SET = loader("../../../Fragments/Underwriting/DogSet.graphql");

const dogDeleteMachine = Machine({
  id: "dogDelete",
  initial: "idle",
  states: {
    idle: {
      on: {
        DELETE: "deleting",
      },
    },
    deleting: {
      on: {
        DELETED: "deleted",
        GRAPHQL_ERROR: "idle",
      },
    },
    graphql_error: {
      on: {DELETE: "deleting", CANCEL: "idle"},
    },
    deleted: {
      type: "final",
    },
  },
});

interface DeletedDog {
  dogDelete: {
    id: string;
  };
}

type Dog = {
  id: string;
  dogName: string;
  breedComposition: string;
  anyPriorAggression: string;
  priorAggressionNotes: string;
};

type DogSet = {
  dogSet: Dog[];
};

type Props = {
  locationId: string;
  dog: Dog;
  abortDeletion: () => void;
};

export function DeleteDogForm({locationId, dog, abortDeletion}: Props) {
  const [state, send] = useMachine(dogDeleteMachine);

  const [confirmText, setConfirmText] = React.useState("");

  const [dogDelete] = useMutation<DeletedDog>(DELETE_DOG, {
    variables: {id: dog.id},
    update(cache, {data}) {
      if (!data || !data.dogDelete || !data.dogDelete.id) return;

      let dogs = cache.readFragment<DogSet>({
        id: "LocationObjectType:" + locationId,
        fragment: DOG_SET,
        fragmentName: "DogSet",
      });

      let newdogs = {};
      if (dogs) {
        newdogs = dogs.dogSet.filter((e) => e.id !== data.dogDelete.id);

        cache.writeFragment({
          id: "LocationObjectType:" + locationId,
          fragment: DOG_SET,
          fragmentName: "DogSet",
          data: {dogSet: newdogs, __typename: "LocationObjectType"},
        });
        send("DELETED");
      }
    },
  });

  function handleSubmit() {
    if (confirmText === "DELETE") {
      send("DELETE");
      dogDelete();
    }
  }

  if (
    state.value === "idle" &&
    dog.dogName === "" &&
    dog.breedComposition === "" &&
    dog.anyPriorAggression === "U" &&
    dog.priorAggressionNotes === ""
  ) {
    // send("DELETE");
    if (confirmText !== "DELETE") {
      setConfirmText("DELETE");
    }
    return (
      <span>
        <IdleTimer
          timeout={1000}
          startOnMount={true}
          onIdle={() => handleSubmit()}
        />
      </span>
    );
  }

  return (
    <div>
      <h2 className="text-3xl">Permanently delete this dog?</h2>
      <p className="mt-4">This action cannot be undone.</p>
      <p className="mt-4">
        {dog.dogName && (
          <>
            Name: {dog.dogName}
            <br />
          </>
        )}
        {dog.breedComposition && (
          <>
            Breed: {dog.breedComposition}
            <br />
          </>
        )}
        Bite History:
        {(() => {
          switch (dog.anyPriorAggression) {
            case "U":
              return <>Unknown</>;
            case "Y":
              return <>Yes</>;
            case "N":
              return <>No</>;
            default:
              return <>Unknown</>;
          }
        })()}
        <br />
        {dog.priorAggressionNotes && <>Notes: {dog.priorAggressionNotes}</>}
      </p>
      <div className="mt-6">
        <form onSubmit={() => handleSubmit()}>
          <div className="mb-6">
            <MyTextInputField
              label="Type DELETE to continue"
              value={confirmText}
              setValue={setConfirmText}
              characterLimit={6}
              autoFocus
            />
          </div>

          <button
            type="button"
            onClick={() => abortDeletion()}
            className="float-left px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Keep Dog
          </button>
          <button
            type="button"
            onClick={() => handleSubmit()}
            className={
              confirmText === "DELETE"
                ? "float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                : "float-right bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
            }
          >
            Delete Forever
          </button>
        </form>
      </div>
    </div>
  );
}
