import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

const ADD_CLAIM = loader("../../../Fragments/Underwriting/ClaimAdd.graphql");
const CLAIM_SET = loader("../../../Fragments/Underwriting/ClaimSet.graphql");

type Claim = {
  id: string;
  date: string;
  lossType: string;
  payout: number;
  claimNotes: string;
};

type ClaimSet = {
  claimSet: Claim[];
};

type Props = {
  id: string;
};

export function AddClaimButton({id}: Props) {
  const [claimAdd] = useMutation<{
    claimAdd: Claim;
  }>(ADD_CLAIM, {
    variables: {
      id: id,
    },
    update(cache, {data}) {
      if (!data || !data.claimAdd) return;

      let claims = cache.readFragment<ClaimSet>({
        id: "LocationObjectType:" + id,
        fragment: CLAIM_SET,
        fragmentName: "ClaimSet",
      });

      let newclaims = {};
      if (claims) {
        newclaims = claims.claimSet.concat(data.claimAdd);
      } else {
        newclaims = [data.claimAdd];
      }

      cache.writeFragment({
        id: "LocationObjectType:" + id,
        fragment: CLAIM_SET,
        fragmentName: "ClaimSet",
        data: {claimSet: newclaims, __typename: "LocationObjectType"},
      });
    },
  });

  return (
    <button
      onClick={() => claimAdd()}
      className="px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700"
    >
      +
    </button>
  );
}
