import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

const ADD_OUTBUILDING = loader(
  "../../../Fragments/Underwriting/OutbuildingAdd.graphql"
);
const OUTBUILDING_SET = loader(
  "../../../Fragments/Underwriting/OutbuildingSet.graphql"
);

type Outbuilding = {
  id: string;
  buildingDescription: string;
  constructionYear: number | null;
  constructionType: string;
  value: number;
  outbuildingNotes: string;
  heat: string;
  electric: string;
  hayStorage: string;
  usage: string;
  sha1: string;
};

type OutbuildingSet = {
  outbuildingSet: Outbuilding[];
};

type Props = {
  id: string;
};

export function AddOutbuildingButton({id}: Props) {
  const [outbuildingAdd] = useMutation<{
    outbuildingAdd: Outbuilding;
  }>(ADD_OUTBUILDING, {
    variables: {
      id: id,
    },
    update(cache, {data}) {
      if (!data || !data.outbuildingAdd) return;

      let outbuildings = cache.readFragment<OutbuildingSet>({
        id: "LocationObjectType:" + id,
        fragment: OUTBUILDING_SET,
        fragmentName: "OutbuildingSet",
      });

      let newoutbuildings = {};
      if (outbuildings) {
        newoutbuildings = outbuildings.outbuildingSet.concat(
          data.outbuildingAdd
        );
      } else {
        newoutbuildings = [data.outbuildingAdd];
      }

      cache.writeFragment({
        id: "LocationObjectType:" + id,
        fragment: OUTBUILDING_SET,
        fragmentName: "OutbuildingSet",
        data: {
          outbuildingSet: newoutbuildings,
          __typename: "LocationObjectType",
        },
      });
    },
  });

  return (
    <button
      onClick={() => outbuildingAdd()}
      className="px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700"
    >
      +
    </button>
  );
}
