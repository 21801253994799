import * as React from "react";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import Instance from "../../../Context/Instance";

import IdleTimer from "react-idle-timer";
import {DeleteMortgageForm} from "./DeleteMortgage";

import {MyNullBoolean} from "../../../FormComponents/MyNullBoolean";
// import {MyTextarea} from "../../../FormComponents/MyTextarea";
import {MyTextInputField} from "../../../FormComponents/MyTextInputField";

import {CircleIconSpinning} from "../../Icons/CircleIconSpinning";

// import sha1 from "sha1";

const UPDATE_MORTGAGE = loader(
  "../../../Fragments/Underwriting/MortgageUpdate.graphql"
);

const dogMachine = Machine({
  id: "dog",
  initial: "idle",
  states: {
    idle: {
      on: {
        SAVE: "saving",
        MAY_DELETE: "may_delete",
      },
    },
    saving: {
      on: {
        SAVED: "idle",
        GRAPHQL_ERROR: "idle",
        // MUTATION_ERROR: "error_saving"
      },
    },
    graphql_error: {
      //
      on: {SAVE: "saving", DISCARD: "refetch", MAY_DELETE: "may_delete"},
    },
    // error_saving: {
    //   // Need to do more work here as it is unable to save after a failed mutation
    //   on: {SAVE: "saving", DISCARD: "refetch", MAY_DELETE: "may_delete"}
    // },
    refetch: {
      on: {
        DOG_LOADED: "idle",
        ERROR: "loading_error",
      },
    },
    loading_error: {
      //
    },
    may_delete: {
      on: {
        DONT_DELETE: "idle",
        CONFIRM_DELETE: "deleting",
      },
    },
    deleting: {
      on: {
        DELETE_SUCCESSFUL: "redirect_after_delete",
        DELETE_FAILED: "error_deleting",
      },
    },
    redirect_after_delete: {
      type: "final",
    },
    error_deleting: {
      on: {
        RETRY_DELETE: "deleting",
        ABORT_DELETION: "idle",
      },
    },
  },
});

type Mortgage = {
  id: string;
  name: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zipcode: string;
  loanNumber: string;
  escrowBill: string;
  position: string;
};

type Props = {
  locationId: string;
  mortgage: Mortgage;
  autoSave: boolean;
};

export function EditMortgage({locationId, mortgage, autoSave}: Props) {
  const [status, send] = useMachine(dogMachine);

  const [name, setName] = React.useState(mortgage.name);
  const [street, setStreet] = React.useState(mortgage.street);
  const [street2, setStreet2] = React.useState(mortgage.street2);
  const [city, setCity] = React.useState(mortgage.city);
  const [state, setTheState] = React.useState(mortgage.state);
  const [zipcode, setZipcode] = React.useState(mortgage.zipcode);
  const [loanNumber, setLoanNumber] = React.useState(mortgage.loanNumber);
  const [escrowBill, setEscrowBill] = React.useState(mortgage.escrowBill);
  const [position, setPosition] = React.useState(mortgage.position);

  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (
      mortgage.name !== name ||
      mortgage.street !== street ||
      mortgage.street2 !== street2 ||
      mortgage.city !== city ||
      mortgage.state !== state ||
      mortgage.zipcode !== zipcode ||
      mortgage.loanNumber !== loanNumber ||
      mortgage.escrowBill !== escrowBill ||
      mortgage.position !== position
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [
    mortgage.name,
    name,
    mortgage.street,
    street,
    mortgage.street2,
    street2,
    mortgage.city,
    city,
    mortgage.state,
    state,
    mortgage.zipcode,
    zipcode,
    mortgage.loanNumber,
    loanNumber,
    mortgage.escrowBill,
    escrowBill,
    mortgage.position,
    position,
  ]);

  const [
    mortgageUpdate,
    // {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    mortgageUpdate: Mortgage;
  }>(UPDATE_MORTGAGE, {
    variables: {
      id: mortgage.id,
      name: name,
      street: street,
      street2: street2,
      city: city,
      state: state,
      zipcode: zipcode,
      loanNumber: loanNumber,
      escrowBill: escrowBill,
      position: position,
    },
    onCompleted: () => {
      send("SAVED");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      send("GRAPHQL_ERROR");
    },
  });

  function handleFormSubmit() {
    if (dirty) {
      send("SAVE");
      mortgageUpdate();
    }
  }

  // console.log(state.value);

  // if (mutationError) {
  //   send("MUTATION_ERROR");
  // }

  if (status.value === "may_delete") {
    return (
      <DeleteMortgageForm
        locationId={locationId}
        mortgage={mortgage}
        abortDeletion={() => send("DONT_DELETE")}
      />
    );
  }

  return (
    <Instance.Provider value={mortgage.id}>
      <form onSubmit={() => handleFormSubmit()}>
        <div>
          {/* <div className="text-3xl">state: {status.value}</div> */}

          {autoSave ? (
            <IdleTimer
              timeout={1000}
              startOnMount={true}
              onIdle={dirty ? () => handleFormSubmit() : () => {}}
            />
          ) : null}

          <div>
            <div>
              <div className="flex">
                <div className="flex-1">
                  <MyTextInputField
                    label="Name"
                    value={name}
                    setValue={setName}
                    initialValue={mortgage.name}
                    characterLimit={50}
                    autoFocusIfBlank
                  />
                </div>
                <div className="flex-1">
                  <MyTextInputField
                    label="Street"
                    value={street}
                    setValue={setStreet}
                    initialValue={mortgage.street}
                    characterLimit={50}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyTextInputField
                    label="(Street Continued)"
                    value={street2}
                    setValue={setStreet2}
                    initialValue={mortgage.street2}
                    characterLimit={50}
                  />
                </div>
                <div className="flex-1">
                  <MyTextInputField
                    label="City"
                    value={city}
                    setValue={setCity}
                    initialValue={mortgage.city}
                    characterLimit={50}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyTextInputField
                    label="State"
                    value={state}
                    setValue={setTheState}
                    initialValue={mortgage.state}
                    characterLimit={2}
                  />
                </div>
                <div className="flex-1">
                  <MyTextInputField
                    label="Zipcode"
                    value={zipcode}
                    setValue={setZipcode}
                    initialValue={mortgage.zipcode}
                    characterLimit={10}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyTextInputField
                    label="Loan Number"
                    value={loanNumber}
                    setValue={setLoanNumber}
                    initialValue={mortgage.loanNumber}
                    characterLimit={50}
                  />
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Escrow Bill"
                    value={escrowBill}
                    setValue={setEscrowBill}
                    initialValue={mortgage.escrowBill}
                  />
                </div>
              </div>
              <div>
                <MyTextInputField
                  label="Loan Position"
                  value={position}
                  setValue={setPosition}
                  initialValue={mortgage.position}
                  characterLimit={50}
                />
              </div>
            </div>
          </div>

          <div>
            {dirty ? (
              <button
                type="button"
                onClick={() => handleFormSubmit()}
                className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
              >
                {autoSave ? (
                  <>
                    <CircleIconSpinning className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" />{" "}
                    Auto Saving
                  </>
                ) : (
                  "Save"
                )}
              </button>
            ) : (
              <button
                type="button"
                // onClick={() => handleFormSubmit()}
                className="px-4 py-2 font-bold text-white bg-blue-500 rounded opacity-50 cursor-not-allowed"
              >
                Saved
              </button>
            )}

            {/* {mutationError && (
              <div className="text-3xl">
                {(() => {
                  switch (mutationError.message.split("GraphQL error: ")[1]) {
                    case "value too long for type character varying(64)\n":
                      return (
                        <span>
                          Changes cannot be saved
                          <br /> One or more fields has too many characters.
                        </span>
                      );
                    case "AM":
                      return <span>Additional Mailing</span>;
                    case "OT":
                      return <span>Other</span>;
                    default:
                      console.log("switch didn't have a match.");
                      console.log(mutationError.message);
                      return <span>Error: {mutationError.message}</span>;
                  }
                })()}
              </div>
            )} */}

            <button
              type="button"
              onClick={() => send("MAY_DELETE")}
              className="float-right px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </Instance.Provider>
  );
}
