import * as React from "react";

// import {Transition} from "@tailwindui/react";

import {MyTextInputField} from "./MyTextInputField";

type Props = {
  objectType: string;
  objectDescription: Object;
  abortDeletion: () => void;
  performDeletion: () => void;
};

export function ConfirmDeleteObject({
  objectType,
  objectDescription,
  abortDeletion,
  performDeletion,
}: Props) {
  const [deleteConfirmText, setDeleteConfirmText] = React.useState("");

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
        {/* <Transition
          show={true}
          enter="transition ease-out duration-3000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-2000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        > */}
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 opacity-75 bg-coolGray-500"></div>
        </div>
        {/* </Transition> */}
        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        {/* <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    --> */}
        {/* <Transition
          show={true}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        > */}
        <div
          className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:flex sm:items-start">
            <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
              <svg
                className="w-6 h-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg font-medium leading-6 text-coolGray-900"
                id="modal-headline"
              >
                Delete {objectType}?
              </h3>
              <div className="mt-2">
                <p className="text-sm leading-5 text-coolGray-500">
                  This action cannot be undone. Are you sure?
                </p>

                <div className="mt-4">{objectDescription}</div>

                <MyTextInputField
                  label={"Type DELETE to confirm."}
                  value={deleteConfirmText}
                  setValue={setDeleteConfirmText}
                  characterLimit={6}
                  informUnsavedChanges={false}
                  autoFocus
                />
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
            <span className="flex w-full rounded-md shadow-sm sm:w-auto">
              {deleteConfirmText === "DELETE" ? (
                <button
                  type="button"
                  onClick={() => performDeletion()}
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red sm:text-sm sm:leading-5"
                >
                  Delete
                </button>
              ) : (
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md shadow-sm opacity-50 cursor-not-allowed hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red sm:text-sm sm:leading-5"
                >
                  Delete
                </button>
              )}
            </span>
            <span className="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
              <button
                onClick={() => abortDeletion()}
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-white border rounded-md shadow-sm text-coolGray-700 border-coolGray-300 hover:text-coolGray-500 focus:outline-none focus:border-blue-300 focus:ring-blue sm:text-sm sm:leading-5"
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
        {/* </Transition> */}
      </div>
    </div>
  );
}
