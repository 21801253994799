import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

const ADD_MEDICARE_PROFILE = loader(
  "../Fragments/Contacts/MedicareProfileAdd.graphql"
);

type BeneficiaryProfile = {
  id: string;
};

type Person = {
  id: string;
  firstName: string;
  lastName: string;
  age: string;
  email: string;
  cellPhone: string;
  beneficiaryprofileSet: BeneficiaryProfile[];
};

type Props = {
  person: Person;
};

export function MedicareProfileAdd({person}: Props) {
  const [medicareProfileAdd] = useMutation<{
    medicareProfileAdd: Person[];
  }>(ADD_MEDICARE_PROFILE, {
    variables: {
      personId: person.id,
    },
  });

  return (
    <button type="button" onClick={() => medicareProfileAdd()}>
      ADD Health & Medicare Profile
    </button>
  );
}
