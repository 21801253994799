import * as React from "react";
import {backendUrlHostname} from "../urls";

import {SidebarItem} from "./SidebarItem";

type Props = {
  activeItem: string;
};

export function Sidebar({activeItem}: Props) {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  // const {activeItem} = props;
  return (
    <div
      className={
        sidebarOpen
          ? "fixed z-30 inset-y-0 left-0 w-64 px-8 py-4 bg-teal-600 border-r overflow-auto lg:static lg:inset-auto lg:translate-x-0 print:hidden translate-x-0 ease-out transition-medium"
          : "fixed z-30 inset-y-0 left-0 w-64 px-8 py-4 bg-teal-600 border-r overflow-auto lg:static lg:inset-auto lg:translate-x-0 print:hidden -translate-x-full ease-in transition-medium"
      }
    >
      <div className="flex items-center justify-between pl-3 pr-1 -mx-3">
        <button
          onClick={() => setSidebarOpen(false)}
          className="text-coolGray-700 lg:hidden"
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
            <path
              d="M6 18L18 6M6 6L18 18"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              stroke="currentColor"
            />
          </svg>
        </button>
      </div>
      <nav className="mt-8">
        {/* <h3 className="text-xs font-semibold tracking-wide uppercase text-coolGray-600">
          Issues
        </h3> */}
        <div className="mt-2 -mx-3">
          {/* <SidebarItem
            title="Home"
            active={activeItem === "home"}
            linkTo={"/"}
          />
          <SidebarItem
            title="Dashboard"
            active={activeItem === "dashboard"}
            linkTo={"/dashboard"}
          /> */}
          <SidebarItem
            title="To Do"
            active={activeItem === "todo"}
            linkTo={"/todo"}
          />
          <SidebarItem
            title="Work in Progress"
            active={activeItem === "workInProgress"}
            // linkTo={"/reports"}
            href={backendUrlHostname + "/work-in-progress"}
          />
          {/* <SidebarItem
            title="Work in Progress"
            active={activeItem === "workInProgress"}
            linkTo={"/work-in-progress"}
          /> */}
          <SidebarItem
            title="Reminders"
            active={activeItem === "reminders"}
            // linkTo={"/reports"}
            href={backendUrlHostname + "/reminders"}
          />

          {/* <SidebarItem
            title="Reminders"
            active={activeItem === "reminders"}
            linkTo={"/reminders"}
          /> */}

          <SidebarItem
            title="Reports"
            active={activeItem === "reports"}
            // linkTo={"/reports"}
            href={backendUrlHostname + "/reports/"}
          />
          <SidebarItem
            title="Activities"
            active={activeItem === "activities"}
            linkTo={"/activities"}
          />
          <SidebarItem
            title="Quotes"
            active={activeItem === "quotes"}
            linkTo={"/quotes"}
          />
          <SidebarItem
            title="Cancellations"
            active={activeItem === "cancellations"}
            href={backendUrlHostname + "/cancellations"}
          />
          <SidebarItem
            title="Nonpays"
            active={activeItem === "nonpays"}
            linkTo={"/nonpays"}
          />
          {/* <SidebarItem
            title="Nonrenewals"
            active={activeItem === "nonrenewals"}
            linkTo={"/nonrenewals"}
          /> */}
          <SidebarItem
            title="Birthdays"
            active={activeItem === "birthdays"}
            href={backendUrlHostname + "/birthdays"}
          />
          <SidebarItem
            title="Downloads"
            active={activeItem === "downloads"}
            linkTo={"/downloads"}
          />
          <SidebarItem
            title="Forms"
            active={activeItem === "forms"}
            linkTo={"/forms"}
          />
          {/* <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg bg-coolGray-200"
          >
            <span className="text-sm font-medium text-coolGray-900">All</span>
            <span className="text-xs font-semibold text-coolGray-700">36</span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">
              Assigned to me
            </span>
            <span className="text-xs font-semibold text-coolGray-700">2</span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">
              Created by me
            </span>
            <span className="text-xs font-semibold text-coolGray-700">1</span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">Archived</span>
          </a> */}
        </div>
        {/* <h3 className="mt-8 text-xs font-semibold tracking-wide uppercase text-coolGray-600">
          Tags
        </h3>
        <div className="mt-2 -mx-3">
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">Bug</span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">
              Feature Request
            </span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">Marketing</span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">v2.0</span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">
              Enhancement
            </span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">Design</span>
          </a>
        </div>
        <button className="flex items-center mt-2 -ml-1 text-sm font-medium text-coolGray-600">
          <svg
            className="w-5 h-5 text-coolGray-500"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 7v10m5-5H7"
              strokeLinecap="round"
              strokeWidth="2"
              stroke="currentColor"
            />
          </svg>
          <span className="ml-1">New Project</span>
        </button> */}
      </nav>
    </div>
  );
}
