import * as React from "react";
// import {UserConsumer} from "./UserContext";

import {RouteComponentProps} from "react-router-dom";

// import {ContentPage} from "../Components/ContentPage";
import {MainMenu} from "../Menus/MainMenu";
import {Client} from "../Components/Client";

type TParams = {clientId: string};

export function ClientPage({match}: RouteComponentProps<TParams>) {
  return (
    <>
      {/* <ContentPage activeItem="client" collapsed={true}> */}
      <MainMenu />

      <Client clientId={match.params.clientId} />

      {/* </ContentPage> */}
    </>
  );
}
