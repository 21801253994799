import * as React from "react";

import {gql, useQuery} from "@apollo/client";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

import {WorkItem} from "../Components/WorkItem";

const WIP_QUERY = gql`
  {
    allClientTabs {
      id
      client {
        id
        name
        attn
      }
    }
  }
`;

type WorkItems = {
  allClientTabs: [
    {
      id: string;
      client: {
        id: string;
        name: string;
        attn: string;
      };
    }
  ];
};

export function WorkInProgress() {
  const {loading, error, data} = useQuery<WorkItems>(WIP_QUERY);

  React.useEffect(() => {
    document.title = `Work in Progress`;
  });

  if (loading) return <p>Loading ...</p>;

  if (error) return <p>Error!</p>;
  //
  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="workInProgress" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle="Work In Progress" />

          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="grid grid-cols-1 gap-5 p-3 sm:grid-cols-2 xl:grid-cols-3">
              {data &&
                data.allClientTabs.map((clientTab) => (
                  <WorkItem clientTab={clientTab} key={clientTab.id} />
                ))}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
