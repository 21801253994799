import {gql, useMutation} from "@apollo/client";

const TOGGLE_REMINDER_COMPLETION = gql`
  mutation ToggleCompletion($reminderId: String!) {
    reminderToggleCompletion(reminderData: {reminderId: $reminderId}) {
      reminder {
        id
        client {
          id
          name
          attn
        }
        user {
          id
          firstName
          lastName
        }
        remindOn
        message
        completed
      }
    }
  }
`;

type Reminder = {
  id: string;
  completed: boolean;
};

type Props = {
  reminder: Reminder;
};

export function ToggleReminderCompletion({reminder}: Props) {
  const [toggleReminderCompletion] = useMutation<{
    toggleReminderCompletion: Reminder;
  }>(TOGGLE_REMINDER_COMPLETION, {
    variables: {
      reminderId: reminder.id,
    },
  });

  return (
    <button
      onClick={() => {
        toggleReminderCompletion();
      }}
      type="button"
      className="px-6 py-1 m-2 border rounded border-coolGray-500 text-coolGray-700"
    >
      {reminder.completed ? "Mark NOT Finished" : "Mark Finished"}
    </button>
  );
}
