import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {DeleteButton} from "../../Components/DeleteButton";
import {ConfirmDeleteObject} from "../../FormComponents/ConfirmDeleteObject";

const DELETE_APPOINTMENT_MUTATION = loader(
  "../../Fragments/Medicare/AppointmentDelete.graphql"
);

const APPOINTMENT_SET_FRAGMENT = loader(
  "../../Fragments/Medicare/AppointmentSet.graphql"
);

interface DeletedAppointment {
  deleteAppointment: {
    id: string;
  };
}

type Appointment = {
  id: string;
  timestamp: number;
  notes: string;
  createdBy: {
    firstName: string;
    lastName: string;
  };
};

type AppointmentSet = {
  beneficiaryappointmentSet: Appointment[];
};

type Props = {
  beneficiaryId: string;
  appointment: Appointment;
  immaculate?: boolean;
};

export function AppointmentDelete({
  beneficiaryId,
  appointment,
  immaculate = false,
}: Props) {
  const [startConfirm, setStartConfirm] = React.useState(false);

  const [deleteAppointment] = useMutation<DeletedAppointment>(
    DELETE_APPOINTMENT_MUTATION,
    {
      variables: {id: appointment.id},
      update(cache, {data}) {
        if (!data || !data.deleteAppointment || !data.deleteAppointment.id)
          return;

        let appointments = cache.readFragment<AppointmentSet>({
          id: "BeneficiaryProfileObjectType:" + beneficiaryId,
          fragment: APPOINTMENT_SET_FRAGMENT,
          fragmentName: "AppointmentSet",
        });

        let newappointments = {};
        if (appointments) {
          newappointments = appointments.beneficiaryappointmentSet.filter(
            (e) => e.id !== data.deleteAppointment.id
          );

          cache.writeFragment({
            id: "BeneficiaryProfileObjectType:" + beneficiaryId,
            fragment: APPOINTMENT_SET_FRAGMENT,
            fragmentName: "AppointmentSet",
            data: {
              beneficiaryappointmentSet: newappointments,
              __typename: "BeneficiaryProfileObjectType",
            },
          });
          // send("DELETED");
        }
      },
    }
  );

  if (immaculate) {
    return <DeleteButton action={() => deleteAppointment()} />;
  }

  if (startConfirm) {
    return (
      <ConfirmDeleteObject
        objectType="Appointment"
        objectDescription={
          <span>
            Recorded on: {appointment.timestamp}
            <br />
            Appointment Notes: <strong>{appointment.notes}</strong>
          </span>
        }
        abortDeletion={() => setStartConfirm(false)}
        performDeletion={() => deleteAppointment()}
      />
    );
  }

  return <DeleteButton action={() => setStartConfirm(true)} />;
}
