import * as React from "react";

import {useParams} from "react-router-dom";

import {gql} from "@apollo/client";
import {useQuery} from "@apollo/client";

import {Prelist} from "../../Components/Prelist";

const GET_PRELIST_FOR_ACCOUNT = gql`
  query depositPrelist($bankAccountId: String!) {
    depositPrelist(id: $bankAccountId) {
      id
      name
      bankName
      routing
      account
      undepositedPaymentCount
      undepositedPaymentSet {
        id
        actionDate
        paymentMethod
        premium
        policy {
          client {
            name
          }
        }
      }
    }
  }
`;

export function DepositPrelist() {
  let {bankAccountId} = useParams<Record<string, string | undefined>>();
  const {loading, error, data} = useQuery(GET_PRELIST_FOR_ACCOUNT, {
    variables: {bankAccountId: bankAccountId},
  });

  React.useEffect(() => {
    document.title = `Deposit Prelist`;
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  return <Prelist account={data.depositPrelist} />;
}
