import {gql, useMutation} from "@apollo/client";

const TOGGLE_CLIENT_REMINDER_COMPLETION = gql`
  mutation ToggleCompletion($clientReminderId: String!) {
    clientReminderToggleCompletion(
      reminderData: {clientReminderId: $clientReminderId}
    ) {
      clientReminder {
        id
        created
        updated
        about {
          id
          name
          attn
        }
        whoToRemind {
          id
          firstName
          lastName
        }
        remindOn
        reminder
        completed
      }
    }
  }
`;

type ClientReminder = {
  id: string;
  completed: boolean;
};

type Props = {
  reminder: ClientReminder;
};

export function ToggleCompletionButton({reminder}: Props) {
  const [toggleReminderCompletion] = useMutation<{
    toggleReminderCompletion: ClientReminder;
  }>(TOGGLE_CLIENT_REMINDER_COMPLETION, {
    variables: {
      clientReminderId: reminder.id,
    },
  });

  return (
    <button
      onClick={() => {
        toggleReminderCompletion();
      }}
      type="button"
      className="px-6 py-1 m-2 border rounded border-coolGray-500 text-coolGray-700"
    >
      {reminder.completed ? "Mark NOT Finished" : "Mark Finished"}
    </button>
  );
}
