import * as React from "react";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import Instance from "../../../Context/Instance";

import IdleTimer from "react-idle-timer";
import {DeleteDogForm} from "./DeleteDog";

import {MyNullBoolean} from "../../../FormComponents/MyNullBoolean";
import {MyTextarea} from "../../../FormComponents/MyTextarea";
import {MyTextInputField} from "../../../FormComponents/MyTextInputField";

import sha1 from "sha1";
import {CircleIconSpinning} from "../../Icons/CircleIconSpinning";

const UPDATE_DOG = loader("../../../Fragments/Underwriting/DogUpdate.graphql");

const dogMachine = Machine({
  id: "dog",
  initial: "idle",
  states: {
    idle: {
      on: {
        SAVE: "saving",
        MAY_DELETE: "may_delete",
      },
    },
    saving: {
      on: {
        SAVED: "idle",
        GRAPHQL_ERROR: "idle",
        // MUTATION_ERROR: "error_saving"
      },
    },
    graphql_error: {
      //
      on: {SAVE: "saving", DISCARD: "refetch", MAY_DELETE: "may_delete"},
    },
    // error_saving: {
    //   // Need to do more work here as it is unable to save after a failed mutation
    //   on: {SAVE: "saving", DISCARD: "refetch", MAY_DELETE: "may_delete"}
    // },
    refetch: {
      on: {
        DOG_LOADED: "idle",
        ERROR: "loading_error",
      },
    },
    loading_error: {
      //
    },
    may_delete: {
      on: {
        DONT_DELETE: "idle",
        CONFIRM_DELETE: "deleting",
      },
    },
    deleting: {
      on: {
        DELETE_SUCCESSFUL: "redirect_after_delete",
        DELETE_FAILED: "error_deleting",
      },
    },
    redirect_after_delete: {
      type: "final",
    },
    error_deleting: {
      on: {
        RETRY_DELETE: "deleting",
        ABORT_DELETION: "idle",
      },
    },
  },
});

type Dog = {
  id: string;
  dogName: string;
  breedComposition: string;
  anyPriorAggression: string;
  priorAggressionNotes: string;
  sha1: string;
};

type Props = {
  locationId: string;
  dog: Dog;
  autoSave: boolean;
};

export function EditDog({locationId, dog, autoSave}: Props) {
  const [state, send] = useMachine(dogMachine);

  const [dogName, setDogName] = React.useState(dog.dogName);
  const [breedComposition, setBreedComposition] = React.useState(
    dog.breedComposition
  );
  const [anyPriorAggression, setAnyPriorAggression] = React.useState(
    dog.anyPriorAggression
  );
  const [priorAggressionNotes, setPriorAggressionNotes] = React.useState(
    dog.priorAggressionNotes
  );

  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (
      dog.dogName !== dogName ||
      dog.breedComposition !== breedComposition ||
      dog.anyPriorAggression !== anyPriorAggression ||
      dog.priorAggressionNotes !== priorAggressionNotes
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [
    dog.dogName,
    dogName,
    dog.breedComposition,
    breedComposition,
    dog.anyPriorAggression,
    anyPriorAggression,
    dog.priorAggressionNotes,
    priorAggressionNotes,
  ]);

  const [
    dogUpdate,
    {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    dogUpdate: Dog;
  }>(UPDATE_DOG, {
    variables: {
      id: dog.id,
      dogName: dogName,
      breedComposition: breedComposition,
      anyPriorAggression: anyPriorAggression,
      priorAggressionNotes: priorAggressionNotes,
      sha1: dog.sha1,
      newSha1: sha1(
        dogName + breedComposition + anyPriorAggression + priorAggressionNotes
      ),
    },
    onCompleted: () => {
      send("SAVED");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      send("GRAPHQL_ERROR");
    },
  });

  function handleFormSubmit() {
    if (dirty) {
      send("SAVE");
      dogUpdate();
    }
  }

  // console.log(state.value);

  // if (mutationError) {
  //   send("MUTATION_ERROR");
  // }

  if (state.value === "may_delete") {
    return (
      <DeleteDogForm
        locationId={locationId}
        dog={dog}
        abortDeletion={() => send("DONT_DELETE")}
      />
    );
  }

  return (
    <Instance.Provider value={dog.id}>
      <form onSubmit={() => handleFormSubmit()}>
        <div>
          {/* <div className="text-3xl">state: {state.value}</div> */}

          {autoSave ? (
            <IdleTimer
              timeout={1000}
              startOnMount={true}
              onIdle={dirty ? () => handleFormSubmit() : () => {}}
            />
          ) : null}

          <div>
            <div className="flex">
              <div className="flex-1">
                <MyTextInputField
                  label="Breed Composition"
                  value={breedComposition}
                  setValue={setBreedComposition}
                  initialValue={dog.breedComposition}
                  characterLimit={64}
                  autoFocusIfBlank
                />
              </div>

              <div className="flex-1">
                <MyNullBoolean
                  label="Any Prior Aggression"
                  value={anyPriorAggression}
                  setValue={setAnyPriorAggression}
                  initialValue={dog.anyPriorAggression}
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex-1">
                <MyTextInputField
                  label="Dog Name"
                  value={dogName}
                  setValue={setDogName}
                  initialValue={dog.dogName}
                  characterLimit={64}
                />
              </div>
              <div className="flex-1">
                <MyTextarea
                  label="Notes"
                  value={priorAggressionNotes}
                  setValue={setPriorAggressionNotes}
                  initialValue={dog.priorAggressionNotes}
                />
              </div>
            </div>
          </div>

          <div>
            {dirty ? (
              <button
                type="button"
                onClick={() => handleFormSubmit()}
                className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 print:hidden"
              >
                {autoSave ? (
                  <>
                    <CircleIconSpinning className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" />{" "}
                    Auto Saving
                  </>
                ) : (
                  "Save"
                )}
              </button>
            ) : (
              <button
                type="button"
                className="px-4 py-2 font-bold text-white bg-blue-500 rounded opacity-50 cursor-not-allowed"
              >
                Saved
              </button>
            )}

            {mutationError && (
              <div className="text-3xl">
                {(() => {
                  switch (mutationError.message.split("GraphQL error: ")[1]) {
                    case "value too long for type character varying(64)\n":
                      return (
                        <span>
                          Changes cannot be saved
                          <br /> One or more fields has too many characters.
                        </span>
                      );
                    case "AM":
                      return <span>Additional Mailing</span>;
                    case "OT":
                      return <span>Other</span>;
                    default:
                      console.log("switch didn't have a match.");
                      console.log(mutationError.message);
                      return <span>Error: {mutationError.message}</span>;
                  }
                })()}
              </div>
            )}

            <button
              type="button"
              onClick={() => send("MAY_DELETE")}
              className="float-right px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 print:hidden"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </Instance.Provider>
  );
}
