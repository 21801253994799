import * as React from "react";

import {indexOf, without} from "lodash";

import {gql, useMutation} from "@apollo/client";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

import {PrelistItem} from "./PrelistItem";

const CREATE_DEPOSIT = gql`
  mutation CreateDeposit($payments: String!) {
    createDeposit(payments: $payments) {
      deposit {
        id
      }
    }
  }
`;

type Payment = {
  id: string;
  paymentMethod: string;
  premium: number;
  policy: {
    client: {
      name: string;
    };
  };
};

type Props = {
  account: {
    name: string;
    routing: string;
    account: string;
    bankName: string;
    undepositedPaymentSet: Payment[];
  };
};

export function Prelist({account}: Props) {
  // const [createDeposit, { data }] = useMutation(CREATE_DEPOSIT);
  const [createDeposit] = useMutation(CREATE_DEPOSIT);

  const [deposited, setDeposited] = React.useState(false);
  const [selectedPayments, setSelectedPayments] = React.useState<string[]>([]);
  const [totalSelectedChecks, setTotalSelectedChecks] = React.useState(0.0);
  const [totalSelectedCash, setTotalSelectedCash] = React.useState(0.0);

  const togglePaymentSelection = (paymentId: string) => {
    if (indexOf(selectedPayments, paymentId) !== -1) {
      setSelectedPayments(without(selectedPayments, paymentId));
    } else {
      setSelectedPayments([...selectedPayments, paymentId]);
    }
  };

  const today = new Date().toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="reports" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header
            pageTitle={
              deposited
                ? today + " Deposit "
                : "Available for Deposit: " + account.name
            }
          />

          <div className="flex-1 overflow-auto">
            <main className="h-full p-3">
              <form>
                {deposited ? (
                  <div className="flex">
                    <div className="flex-1 text-center">
                      <h3 className="text-xl text-coolGray-800 print:text-base">
                        Routing: {account.routing}
                        <br />
                        {account.bankName}
                      </h3>
                    </div>
                    <div className="flex-1 text-center">
                      <h3 className="text-xl text-coolGray-800 print:text-base">
                        {" "}
                        Account: {account.account}
                        <br />
                        {account.name}
                      </h3>
                    </div>
                  </div>
                ) : null}

                <div className="flex print:mt-6">
                  <div className="flex-1 m-6 print:m-4">
                    <h2 className="mb-4 text-xl text-center text-coolGray-800 print:text-xl">
                      Checks:
                    </h2>
                    <ul className="divide-y divide-coolGray-300">
                      {account.undepositedPaymentSet
                        .filter((payment) => payment.paymentMethod === "A_1")
                        .map((payment) => (
                          <PrelistItem
                            payment={payment}
                            key={payment.id}
                            totalSelected={totalSelectedChecks}
                            togglePaymentSelection={togglePaymentSelection}
                            toggleSelectionTypeSum={setTotalSelectedChecks}
                            deposited={deposited}
                          />
                        ))}{" "}
                    </ul>
                  </div>
                  <div className="flex-1 m-6 print:m-4">
                    <h2 className="mb-4 text-xl text-center text-coolGray-800 print:text-xl">
                      Cash:
                    </h2>
                    <ul className="divide-y divide-coolGray-300">
                      {account.undepositedPaymentSet
                        .filter((payment) => payment.paymentMethod === "A_0")
                        .map((payment) => (
                          <PrelistItem
                            payment={payment}
                            key={payment.id}
                            totalSelected={totalSelectedCash}
                            togglePaymentSelection={togglePaymentSelection}
                            toggleSelectionTypeSum={setTotalSelectedCash}
                            deposited={deposited}
                          />
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="flex pt-4 mt-4 border-t">
                  <div className="flex-1 text-center">
                    <h2 className="text-xl tabular-nums">
                      Check Total: $
                      {(totalSelectedChecks / 100).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </h2>
                  </div>
                  <div className="flex-1 text-center">
                    <h2 className="text-xl tabular-nums">
                      Cash Total: $
                      {(totalSelectedCash / 100).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </h2>
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  {deposited ? (
                    <button
                      type="button"
                      className="flex items-center px-4 py-2 text-2xl text-white bg-blue-500 rounded hover:bg-blue-700 print:hidden"
                      // className="flex items-center px-5 py-3 text-2xl bg-blue-300 border rounded-lg"
                      onClick={() => window.print()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 mr-2 text-white fill-current"
                        viewBox="0 0 24 24"
                      >
                        <path d="M18 18v2a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-2H4a2 2 0 0 1-2-2v-6c0-1.1.9-2 2-2h2V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v4h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-2zm0-2h2v-6H4v6h2v-2c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v2zm-2-8V4H8v4h8zm-8 6v6h8v-6H8z" />
                      </svg>
                      <span>Print</span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="flex items-center px-4 py-2 text-2xl text-white bg-blue-500 rounded hover:bg-blue-700 print:hidden"
                      onClick={() => {
                        if (selectedPayments.length === 0) {
                          alert("You didn't select any payments");
                        } else {
                          createDeposit({
                            variables: {payments: selectedPayments},
                          }).then(() => setDeposited(true));
                        }
                      }}
                    >
                      Next
                    </button>
                  )}
                </div>
              </form>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
