import * as React from "react";

import {MicrophoneIcon} from "@heroicons/react/outline";

import {DocumentIcon} from "../Components/Icons/DocumentIcon";
import {FileIcon} from "../Components/Icons/FileIcon";
import {FolderIcon} from "../Components/Icons/FolderIcon";
import {PhotoIcon} from "../Components/Icons/PhotoIcon";
import {SpreadsheetIcon} from "../Components/Icons/SpreadsheetIcon";
import {VideoIcon} from "../Components/Icons/VideoIcon";

type Props = {
  extension: string;
};

export const AttachmentIcon = ({extension}: Props) => {
  return (
    <>
      {(() => {
        switch (extension) {
          case ".pdf":
            return <DocumentIcon />;
          case ".doc":
            return <DocumentIcon />;
          case ".docx":
            return <DocumentIcon />;
          case ".jpg":
            return <PhotoIcon />;
          case ".jpeg":
            return <PhotoIcon />;
          case ".png":
            return <PhotoIcon />;
          case ".gif":
            return <PhotoIcon />;
          case ".mp3":
            return <MicrophoneIcon />;
          case ".wav":
            return <MicrophoneIcon />;
          case ".mp4":
            return <VideoIcon />;
          case ".txt":
            return <DocumentIcon />;
          case ".xls":
            return <SpreadsheetIcon />;
          case ".xlsx":
            return <SpreadsheetIcon />;
          case ".csv":
            return <SpreadsheetIcon />;
          case ".zip":
            return <FolderIcon />;
          default:
            return <FileIcon />;
        }
      })()}
    </>
  );
};
