/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import ReactDOM from "react-dom";
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import { GraphQLClient, gql } from "graphql-request";
// removed request to get this to build
// import {request, GraphQLClient, gql} from "graphql-request";


import Cookies from "js-cookie";

const csrfCookie = Cookies.get("csrftoken");

// const endpoint = "https://graphqlzero.almansi.me/api";
const endpoint = "http://localhost:8000/graphql";

const graphQLClient = new GraphQLClient(endpoint, {
    credentials: 'include',
  // mode: 'cors',
  headers: {
      "X-CSRFToken": csrfCookie
    }
  })

const queryClient = new QueryClient();

const queryMakes = gql`
   query getVehicleMakeChoices {
  vehicleMakeChoices {
    id
    name
  }
}
  `

export function QueryMakes() {
  const [makeId, setMakeId] = React.useState(-1);

  return (
    <QueryClientProvider client={queryClient}>
      <p>
        As you visit the VEHICLE MAKES below, you will notice them in a loading state
        the first time you load them. However, after you return to this list and
        click on any posts you have already visited again, you will see them
        load instantly and background refresh right before your eyes!{" "}
        <strong>
          (You may need to throttle your network speed to simulate longer
          loading sequences)
        </strong>
      </p>
      {makeId === -1 ? (
        <span>

          <button onClick={() => setMakeId("something else")}>click me for on</button>
        {/* <Make makeId={makeId} setMakeId={setMakeId} /> */}
        </span>
      ) : (
          <div>
<button onClick={() => setMakeId(-1)}>click me for off</button>
        <Makes setMakeId={setMakeId} />
          </div>
      )}
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}

function useMakes() {
  return useQuery("vehicleMakeChoices", async () => {
    const data = await graphQLClient.request(queryMakes)

    return data;
  });
}

function Makes({setMakeId}) {
  const queryClient = useQueryClient();
  const {status, data, error, isFetching} = useMakes();

  // console.log(status + " " + data)
  return (
    <div>
      <h1 className="text-3xl text-pink-400">VEHICLE MAKES</h1>
      <div>
        {status === "loading" ? (
          "Loading..."
        ) : status === "error" ? (
          <span>Error: {error.message}</span>
        ) : (
          <>
            <div>
              {data?.vehicleMakeChoices?.map((make) => (
                <p key={make.id}>
                  <a
                    onClick={() => setMakeId(make.id)}
                    href="#"
                    style={
                      // We can find the existing query data here to show bold links for
                      // ones that are cached
                      queryClient.getQueryData(["make", make.id])
                        ? {
                            fontWeight: "bold",
                            color: "green",
                          }
                        : {}
                    }
                  >
                    {make.name}
                  </a>
                </p>
              ))}
                </div>
            <div>{isFetching ? "Background Updating..." : " "}</div>
          </>
        )}
      </div>
    </div>
  );
}

// Commented to get this to build.

// function useMake(makeId) {
//   return useQuery(
//     ["make", makeId],
//     async () => {
//       const {make} = await request(
//         endpoint,
//         gql`
//         query {
//           make(id: ${makeId}) {
//             id
//             name
//           }
//         }
//         `
//       );

//       return make;
//     },
//     {
//       enabled: !!makeId,
//     }
//   );
// }

// Commented to get this to build
// function Make({makeId, setMakeId}) {
//   const {status, data, error, isFetching} = useMake(makeId);

//   return (
//     <div>
//       <div>
//         <a onClick={() => setMakeId(-1)} href="#">
//           Back
//         </a>
//       </div>
//       {!makeId || status === "loading" ? (
//         "Loading..."
//       ) : status === "error" ? (
//         <span>Error: {error.message}</span>
//       ) : (
//         <>
//           <h1>{data.title}</h1>
//           <div>
//             <p>{data.body}</p>
//           </div>
//           <div>{isFetching ? "Background Updating..." : " "}</div>
//         </>
//       )}
//     </div>
//   );
// }

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
