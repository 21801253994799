import * as React from "react";

import {backendUrlHostname} from "../urls";

// import {Link} from "react-router-dom";

import {ChangeQuoteStatusButton} from "../FormComponents/ChangeQuoteStatus";

type Product = {
  name: string;
};

type Quote = {
  id: string;
  client: {
    id: string;
    name: string;
    attn: string;
  };
  product: Product[];
  quoteType: string;
  timestamp: string;
};

type Props = {
  quote: Quote;
  index: number;
};

export const OpenQuote = ({quote, index}: Props) => {
  var labelColor = (() => {
    switch (quote.quoteType) {
      case "A_0":
        return "gray";
      case "A_1":
        return "teal";
      case "A_2":
        return "blue";
      case "A_3":
        return "purple";
      default:
        return "red";
    }
  })();

  const date = new Date(quote.timestamp).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <li key={quote.id} className={index > 0 ? "mt-3" : undefined}>
      <div className="block p-5 bg-white rounded-lg shadow">
        <div className="flex justify-between">
          <p className="text-sm font-medium leading-snug text-coolGray-900">
            {quote.client && (
              <a href={backendUrlHostname + "/clients/" + quote.client.id}>
                {/* <Link to={"/client/" + quote.client.id}> */}
                {quote.client.name}
                {quote.client.attn && (
                  <>
                    <br />
                    {quote.client.attn}
                  </>
                )}
                {/* </Link> */}
              </a>
            )}
            {/* {quote.quoteType} */}
            {/* {quote.id} */}
            {/* {quote.policy && (
              <Link to={"/client/" + quote.policy.client.id}>
                {quote.client.name}
                {quote.client.attn && (
                  <>
                    <br />
                    {quote.client.attn}
                  </>
                )}
              </Link>
            )} */}
          </p>
        </div>
        <div className="flex items-baseline justify-between mt-2">
          <div className="text-sm text-coolGray-600">{date}</div>
          <div>
            <span
              className={
                "px-2 py-1 leading-tight inline-flex items-center bg-" +
                labelColor +
                "-100 rounded"
              }
            >
              <svg
                className={"h-2 w-2 text-" + labelColor + "-500"}
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx="4" cy="4" r="3" />
              </svg>

              <span
                className={
                  "ml-2 text-sm font-medium text-" + labelColor + "-900"
                }
              >
                {quote.product[0].name}
              </span>
            </span>
          </div>
        </div>
        <div className="flex justify-between mt-3">
          <ChangeQuoteStatusButton quoteId={quote.id} direction="left" />

          <ChangeQuoteStatusButton quoteId={quote.id} direction="right" />
        </div>
      </div>
    </li>
    // <Card>
    //   <Card.Content>
    //     <Card.Header>
    //     <Card.Meta>
    //       Started {formatDistance(new Date(timestamp), new Date())} ago. (
    //       )
    //     </Card.Meta>
    //     <Card.Description>
    //       {/* {product.map(item => <span>{item}</span>)} */}
    //       <br />
    //       <br />
    //     </Card.Description>
    //     <Card.Content extra textAlign="center">

    //     </Card.Content>
    //   </Card.Content>
    // </Card>
  );
};
