import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";
import {PlusIcon} from "@heroicons/react/solid";

const ADD_APPOINTMENT = loader(
  "../../Fragments/Medicare/AppointmentAdd.graphql"
);
const APPOINTMENT_SET = loader(
  "../../Fragments/Medicare/AppointmentSet.graphql"
);

type Appointment = {
  id: string;
  timestamp: string;
  notes: string;
};

type AppointmentSet = {
  beneficiaryappointmentSet: Appointment[];
};

type Props = {
  id: string;
};

export function AppointmentAdd({id}: Props) {
  const [addAppointment] = useMutation<{
    addAppointment: Appointment;
  }>(ADD_APPOINTMENT, {
    variables: {
      id: id,
    },
    update(cache, {data}) {
      if (!data || !data.addAppointment) return;

      // Updating the cache is not working right now
      let appointments = cache.readFragment<AppointmentSet>({
        id: "BeneficiaryProfileObjectType:" + id,
        fragment: APPOINTMENT_SET,
        fragmentName: "AppointmentSet",
      });

      let newappointments = {};
      if (appointments) {
        newappointments = appointments.beneficiaryappointmentSet.concat(
          data.addAppointment
        );
      } else {
        newappointments = [data.addAppointment];
      }

      cache.writeFragment({
        id: "BeneficiaryProfileObjectType:" + id,
        fragment: APPOINTMENT_SET,
        fragmentName: "AppointmentSet",
        data: {
          beneficiaryappointmentSet: newappointments,
          __typename: "BeneficiaryProfileObjectType",
        },
      });
    },
  });

  return (
    <button
      type="button"
      onClick={() => addAppointment()}
      className="flex items-center p-1 -ml-1 bg-white rounded-md group focus:outline-none focus:ring-2 focus:ring-indigo-500"
    >
      <span className="flex items-center justify-center w-8 h-8 text-gray-400 border-2 border-gray-300 border-dashed rounded-full">
        <PlusIcon className="w-5 h-5" aria-hidden="true" />
      </span>
      <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
        Add Appointment
      </span>
    </button>
  );
}
