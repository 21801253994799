import * as React from "react";

import {gql} from "@apollo/client";
import {useMutation} from "@apollo/client";

import {MyTextInputField} from "../../FormComponents/MyTextInputField";

const CREATE_TODO_ITEM = gql`
  mutation createTodo($id: String!, $description: String!) {
    createTodoItem(id: $id, description: $description) {
      todolist {
        id
        name
        unclearedTodos {
          id
          created
          description
          url
          completed
          cleared
          hideuntil
          priority
        }
      }
    }
  }
`;

type TodoItem = {
  id: string;
  description: string;
  completed: boolean;
};

type Props = {
  todolistId: string;
};

export function AddTodoItem({todolistId}: Props) {
  const [description, setDescription] = React.useState("");

  const [
    createTodo,
    // {error: saveChangesError, data: saveChangesData}
  ] = useMutation<{
    createTodo: TodoItem;
  }>(CREATE_TODO_ITEM);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        if (description.trim() !== "") {
          createTodo({
            variables: {
              id: todolistId,
              description: description.trim(),
            },
          });
          setDescription("");
        }
      }}
    >
      <MyTextInputField
        placeholder="What needs to be done?"
        label=""
        value={description}
        setValue={setDescription}
        characterLimit={256}
        autoFocus={false}
      />
    </form>
  );
}
