import * as React from "react";

import {useMutation} from "@apollo/client";

import {CREATE_TODO_LIST} from "../../GraphQL/Queries";

import {MyTextInputField} from "../../FormComponents/MyTextInputField";

type TodoItem = {
  id: string;
  description: string;
  completed: boolean;
};

type TodoList = {
  id: string;
  name: string;
  unclearedTodos: TodoItem[];
};

type Props = {
  projectId: string;
};

export function AddTodoList({projectId}: Props) {
  const [adding, setAdding] = React.useState(false);
  const [name, setName] = React.useState("");

  const [createTodoList] = useMutation<{
    createTodoList: TodoList;
  }>(CREATE_TODO_LIST);

  function toggleAdding() {
    setAdding(adding === false ? true : false);
  }

  function cancel() {
    setName("");
    setAdding(false);
  }

  if (adding) {
    return (
      <div>
        <div>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              if (name.trim() !== "") {
                createTodoList({
                  variables: {
                    id: projectId,
                    name: name.trim(),
                  },
                });
                setName("");
                setAdding(false);
              }
            }}
          >
            <MyTextInputField
              label="To Do List Name"
              value={name}
              setValue={setName}
              placeholder="Enter List Name, then press Enter..."
              characterLimit={64}
              autoFocus
            />
          </form>
          <div>Or</div>
          <button
            className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="print:hidden">
          <button
            className="px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
            onClick={toggleAdding}
          >
            Add a List
          </button>
        </div>
      </div>
    );
  }
}
