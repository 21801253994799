import * as React from "react";

import {useParams} from "react-router-dom";

import {useQuery} from "@apollo/client";

import {loader} from "graphql.macro";

import {EditLocation} from "../FormComponents/EditLocation";

const GET_LOCATION = loader("../Fragments/Underwriting/LocationQuery.graphql");

type Dog = {
  id: string;
  dogName: string;
  breedComposition: string;
  anyPriorAggression: string;
  priorAggressionNotes: string;
  sha1: string;
};

type Outbuilding = {
  id: string;
  buildingDescription: string;
  constructionYear: string;
  constructionType: string;
  value: number;
  setValue: (newValue: string) => void;
  initialValue: number | null;
  outbuildingNotes: string;
  heat: string;
  electric: string;
  hayStorage: string;
  usage: string;
  sha1: string;
};

type PriorCancellation = {
  id: string;
  date: string;
  insuranceCompany: string;
  cancellationType: string;
  cancellationNotes: string;
};

type Dwelling = {
  id: string;
  unitCount: string;
  unitsNotes: string;
  occupancy: string;
  occupancyNotes: string;
  constructionType: string;
  constructionNotes: string;
  yearBuilt: number | null;
  squareFeet: number;
  homeStyle: string;
  homeStyleNotes: string;
  bedrooms: number;
  fullBaths: number;
  halfBaths: number;
  foundation: string;
  foundationNotes: string;
  piers: string;
  piersNotes: string;
  basement: string;
  basementNotes: string;
  walkoutBasement: string;
  sumpPump: string;
  attachedGarage: string;
  attachedGarageNotes: string;
  exterior: string;
  exteriorNotes: string;
  decksAndPorches: string;
  specialFeatures: string;
  roofYear: number | null;
  roofMaterial: string;
  roofStyle: string;
  roofNotes: string;
  heatingYear: number | null;
  heatingStyle: string;
  heatingType: string;
  gasFireplaces: number;
  woodFireplaces: number;
  solidFuelBurner: string;
  heatingNotes: string;
  centralAir: string;
  undergroundOilTank: string;
  undergroundOilTankNotes: string;
  plumbingYear: number | null;
  plumbingYearNotes: string;
  newPipes: string;
  waterSource: string;
  waterSourceNotes: string;
  wastewater: string;
  wastewaterNotes: string;
  circuitBreakers: string;
  electricYear: number | null;
  electricYearNotes: string;
  numberOfAmps: string;
  smokeAndCODetectors: string;
  generator: string;
  wiring: string;
  solarPanels: string;
  solarPanelsNotes: string;
  fuses: string;
  fusesNotes: string;
  nonsmokerDiscount: string;
  fireExtinguisher: string;
  deadboltLocks: string;
  inHomeSprinklerSystem: string;
  waterAlarm: string;
  lowTemperatureAlarm: string;
  burglarAlarm: string;
  burglarAlarmNotes: string;
  exteriorDamage: string;
  exteriorDamageNotes: string;
  everRented: string;
  everRentedNotes: string;
  missingHandrails: string;
  missingHandrailsNotes: string;
  homeBasedBusiness: string;
  homeBasedBusinessNotes: string;
  farming: string;
  farmingNotes: string;
  homeConcealedFromNeighbors: string;
  homeConcealedFromNeighborsNotes: string;
  priorBankruptcy: string;
  priorBankruptcyNotes: string;
  homeForSale: string;
  homeForSaleNotes: string;
  trampoline: string;
  pool: string;
  poolNotes: string;
  fenced: string;
  lockingGate: string;
  divingBoard: string;
  slide: string;
  cancelledOrNonrenewed: string;
  residenceEmployees: string;
  residenceEmployeesNotes: string;
  dayCare: string;
  dayCareNotes: string;
  underConstruction: string;
  underConstructionNotes: string;
  generalNotes: string;
  privateNotes: string;
};

type Claim = {
  id: string;
  date: string;
  lossType: string;
  payout: number;
  claimNotes: string;
};

type Mortgage = {
  id: string;
  name: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zipcode: string;
  loanNumber: string;
  escrowBill: string;
  position: string;
};

type LocationProfile = {
  id: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipcode: string;
    client: {
      id: string;
      name: string;
      attn: string;
    };
  };
  deededOwner: string;
  deededOwnerNotes: string;
  firstTimeHomebuyer: string;
  purchaseClosingDate: string;
  purchaseClosingDateNotes: string;
  purchasePrice: number;
  purchasePriceNotes: string;
  hydrantsAvailable: string;
  hydrantsAvailableNotes: string;
  respondingFireDepartment: string;
  respondingFireDepartmentNotes: string;
  distanceToFireDepartment: string;
  distanceToFireDepartmentNotes: string;
  isoProtectionClass: string;
  isoProtectionClassNotes: string;
  acreage: number;
  acreageNotes: string;
  acreageNotesVisibility: boolean;
  floodZone: string;
  floodZoneNotes: string;
  claimSet: Claim[];
  dogSet: Dog[];
  dwellingSet: Dwelling[];
  mortgageSet: Mortgage[];
  outbuildingSet: Outbuilding[];
  priorcancellationSet: PriorCancellation[];
};

type QueryResult = {
  location: LocationProfile;
};

export function LocationPage() {
  let {locationId} = useParams<Record<string, string | undefined>>();
  const {loading, error, data} = useQuery<QueryResult>(GET_LOCATION, {
    variables: {
      id: locationId,
    },
  });

  if (loading) return <p>Loading ...</p>;

  if (error) return <p>Error!</p>;

  if (data && data.location) return <EditLocation location={data.location} />;

  return <span>There was an error</span>;
}
