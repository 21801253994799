import * as React from "react";

import {backendUrlHostname} from "../../urls";

import {Transition} from "@headlessui/react";

import {Link} from "react-router-dom";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

import {SidebarItems} from "../../Components/SidebarItems";
import {FormList} from "../../Components/Forms/FormList";

import pipelinelogo from "../../Images/pipelinelogo.png";

import {CloseIcon} from "../../Components/Icons/CloseIcon";
import {MenuAltIcon} from "../../Components/Icons/MenuAltIcon";
import {SearchInput} from "../../Components/SearchInput";
import {BellIcon, ChevronDownIcon} from "@heroicons/react/outline";

const ALL_FORMS_QUERY = loader("../../Fragments/Forms/all_forms_query.graphql");

interface Forms {
  forms: [
    {
      id: string;
      number: string;
      name: string;
      edition: string;
      url: string;
      active: boolean;
      brand: {
        name: string;
        logoUrl: string;
      };
      formtagSet: [
        {
          id: string;
          name: string;
        }
      ];
    }
  ];
}

export function NewForms() {
  const {loading, error, data} = useQuery<Forms>(ALL_FORMS_QUERY);
  const [isOpen, setIsOpen] = React.useState(false);

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  React.useEffect(() => {
    document.title = `All Forms`;
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  return (
    <div className="flex h-screen overflow-hidden bg-coolGray-200">
      {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}
      <Transition show={isOpen}>
        <div className="lg:hidden">
          <div className="fixed inset-0 z-40 flex">
            {/* <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      --> */}
            <Transition.Child
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0">
                <div className="absolute inset-0 opacity-75 bg-coolGray-600"></div>
              </div>
            </Transition.Child>
            {/* <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      --> */}
            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-teal-600">
                <div className="absolute top-0 right-0 p-1 -mr-14">
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-coolGray-600"
                    aria-label="Close sidebar"
                  >
                    <CloseIcon className="w-6 h-6 text-white" />
                  </button>
                </div>
                <div className="flex items-center flex-shrink-0 px-4">
                  {/* <img
                    className="w-auto h-8"
                    src="https://tailwindui.com/img/logos/v1/easywire-logo-on-brand.svg"
                    alt="Easywire logo"
                  /> */}
                  <img
                    className="w-auto h-8"
                    src={pipelinelogo}
                    alt="Pipeline logo"
                  />
                </div>
                <div className="mt-5 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    <SidebarItems activeItem="forms" />
                    {/* <a
                      href="#"
                      className="flex items-center px-2 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-teal-700 rounded-md group focus:outline-none focus:bg-teal-500"
                    >
                      <!-- Heroicon name: home -->
                      <svg
                        className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                        />
                      </svg>
                      Home
                    </a>
          

                    <a
                      href="#"
                      className="flex items-center px-2 py-2 text-base font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                    >
                      <!-- Heroicon name: clock -->
                      <svg
                        className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      History
                    </a>

                    <a
                      href="#"
                      className="flex items-center px-2 py-2 text-base font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                    >
                      <!-- Heroicon name: scale -->
                      <svg
                        className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                        />
                      </svg>
                      Balances
                    </a>

                    <a
                      href="#"
                      className="flex items-center px-2 py-2 text-base font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                    >
                      <!-- Heroicon name: credit-card -->
                      <svg
                        className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                        />
                      </svg>
                      Cards
                    </a>

                    <a
                      href="#"
                      className="flex items-center px-2 py-2 text-base font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                    >
                      <!-- Heroicon name: user-group -->
                      <svg
                        className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                      Recipients
                    </a>

                    <a
                      href="#"
                      className="flex items-center px-2 py-2 text-base font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                    >
                      <!-- Heroicon name: document-report -->
                      <svg
                        className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      Reports
                    </a> */}
                  </nav>
                </div>
                {/* <hr className="h-px mt-6 bg-teal-700 border-none" />
                <div className="flex-1 h-0 mt-6 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    <a
                      href="#"
                      className="flex items-center px-2 py-2 text-base font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                    >
                      <!-- Heroicon name: cog -->
                      <svg
                        className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      Settings
                    </a>

                    <a
                      href="#"
                      className="flex items-center px-2 py-2 text-base font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                    >
                      <!-- Heroicon name: question-mark-circle -->
                      <svg
                        className="w-6 h-6 mr-4 text-teal-300 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Help
                    </a>

                    <a
                      href="#"
                      className="flex items-center px-2 py-2 text-base font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                    >
                      <!-- Heroicon name: shield-check -->
                      <svg
                        className="w-6 h-6 mr-4 text-teal-300 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                        />
                      </svg>
                      Privacy
                    </a>
                  </nav>
                </div> */}
              </div>
              <div className="flex-shrink-0 w-14">
                {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>

      {/* <!-- Static sidebar for desktop --> */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
          <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto bg-teal-600">
            <div className="flex items-center flex-shrink-0 px-4">
              {/* <img
                className="w-auto h-8"
                src="https://tailwindui.com/img/logos/v1/easywire-logo-on-brand.svg"
                alt="Easywire logo"
              /> */}
              <img
                className="w-auto h-8"
                src={pipelinelogo}
                alt="Pipeline logo"
              />
            </div>
            <div className="flex flex-col flex-1 mt-5 overflow-y-auto">
              <div className="overflow-y-auto">
                <nav className="px-2 space-y-1">
                  <SidebarItems activeItem="forms" />
                </nav>
              </div>
              {/* <hr className="h-px mt-6 bg-teal-700 border-none" />
              <div className="flex-1 h-0 mt-6 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  <a
                    href="#"
                    className="flex items-center px-2 py-2 text-sm font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                  >
                    <!-- Heroicon name: cog -->
                    <svg
                      className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    Settings
                  </a>

                  <a
                    href="#"
                    className="flex items-center px-2 py-2 text-sm font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                  >
                    <!-- Heroicon name: question-mark-circle -->
                    <svg
                      className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Help
                  </a>

                  <a
                    href="#"
                    className="flex items-center px-2 py-2 text-sm font-medium leading-6 text-teal-100 transition duration-150 ease-in-out rounded-md group hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500"
                  >
                    <!-- Heroicon name: shield-check -->
                    <svg
                      className="w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      />
                    </svg>
                    Privacy
                  </a>
                </nav>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex-1 overflow-auto focus:outline-none" tabindex="0"> */}
      <div className="flex-1 overflow-auto focus:outline-none">
        <div className="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-coolGray-200 lg:border-none">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="px-4 border-r text-coolGray-400 border-coolGray-200 focus:outline-none focus:bg-coolGray-100 focus:text-coolGray-600 lg:hidden"
            aria-label="Open sidebar"
          >
            <MenuAltIcon className="w-6 h-6 transition duration-150 ease-in-out" />
          </button>
          {/* <!-- Search bar --> */}
          <div className="flex justify-between flex-1 px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
            <SearchInput />
            {/* <div className="flex flex-1">
              <form className="flex w-full md:ml-0" action="#" method="GET">
                <label htmlFor="search_field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-coolGray-400 focus-within:text-coolGray-600">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      />
                    </svg>
                  </div>
                  <input
                    id="search_field"
                    className="block w-full h-full py-2 pl-8 pr-3 rounded-md text-coolGray-900 placeholder-coolGray-500 focus:outline-none focus:placeholder-coolGray-400 sm:text-sm"
                    placeholder="Search"
                    type="search"
                  />
                </div>
              </form>
            </div> */}
            <div className="flex items-center ml-4 md:ml-6">
              <button
                className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                aria-label="Notifications"
              >
                <BellIcon className="w-6 h-6" />
              </button>

              {/* <!-- Profile dropdown --> */}
              <div className="relative ml-3">
                <div>
                  <button
                    className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50"
                    id="user-menu"
                    aria-haspopup="true"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    <img
                      className="w-8 h-8 rounded-full"
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="hidden ml-3 text-sm font-medium text-gray-700 lg:block">
                      <span className="sr-only">Open user menu for </span>Emilia
                      Birch
                    </span>

                    <ChevronDownIcon className="flex-shrink-0 hidden w-5 h-5 ml-1 text-gray-400 lg:block" />
                  </button>
                </div>
                {/* <!--
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */}
                <Transition
                  show={dropdownOpen}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="absolute right-0 w-48 mt-2 origin-top-right rounded-md shadow-lg">
                    <div
                      className="py-1 bg-white rounded-md ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <Link
                        to="/my-account"
                        className="block px-4 py-2 text-sm transition duration-150 ease-in-out text-coolGray-700 hover:bg-coolGray-100"
                        role="menuitem"
                      >
                        My Account
                      </Link>
                      <Link
                        to="/agency"
                        className="block px-4 py-2 text-sm transition duration-150 ease-in-out text-coolGray-700 hover:bg-coolGray-100"
                        role="menuitem"
                      >
                        Agency
                      </Link>
                      <Link
                        to="/users"
                        className="block px-4 py-2 text-sm transition duration-150 ease-in-out text-coolGray-700 hover:bg-coolGray-100"
                        role="menuitem"
                      >
                        Users
                      </Link>
                      <a
                        href={backendUrlHostname + "/logout"}
                        className="block px-4 py-2 text-sm transition duration-150 ease-in-out text-coolGray-700 hover:bg-coolGray-100"
                        role="menuitem"
                      >
                        Sign out
                      </a>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>
        <main className="relative z-0 flex-1 overflow-y-auto">
          {data && (
            <FormList
              brandName="All"
              logoUrl="https://pipeline-media.s3.amazonaws.com/logos/all-forms.webp"
              forms={data.forms}
            />
          )}
        </main>
      </div>
    </div>
  );
}
