import * as React from "react";

import {gql, useMutation} from "@apollo/client";

import {MyTextInputField} from "../../FormComponents/MyTextInputField";

const TOGGLE_TODO_ITEM_COMPLETION = gql`
  mutation toggleTodoCompletion($id: String!) {
    toggleTodoItemCompletion(id: $id) {
      todoitem {
        id
        created
        description
        url
        completed
        cleared
        hideuntil
        priority
      }
    }
  }
`;

const UPDATE_TODO_ITEM = gql`
  mutation updateTodoItem($id: String!, $description: String!) {
    updateTodoItem(id: $id, description: $description) {
      todoitem {
        id
        created
        description
        url
        completed
        cleared
        hideuntil
        priority
      }
    }
  }
`;

type TodoItemType = {
  id: string;
  description: string;
  completed: boolean;
};

type Props = {
  todoitem: TodoItemType;
};

export function TodoItem({todoitem}: Props) {
  const [editing, setEditing] = React.useState(false);
  const [description, setDescription] = React.useState(todoitem.description);

  const [
    saveTodoChanges,
    // {error: saveChangesError, data: saveChangesData}
  ] = useMutation<{
    saveTodoChanges: TodoItemType;
  }>(UPDATE_TODO_ITEM);

  const [
    toggleTodoCompletion,
    // {error: completionError, data: completionData}
  ] = useMutation<{
    toggleTodoCompletion: TodoItemType;
  }>(TOGGLE_TODO_ITEM_COMPLETION);

  function toggleEditing() {
    setEditing(editing ? false : true);
  }

  if (editing) {
    return (
      <li>
        <form
          onSubmit={(event) => {
            event.preventDefault();

            saveTodoChanges({variables: {id: todoitem.id, description}});
            toggleEditing();
          }}
        >
          <MyTextInputField
            value={description}
            label=""
            placeholder=""
            setValue={setDescription}
            characterLimit={256}
            autoFocus
          />
        </form>
      </li>
    );
  } else {
    return (
      <li className="flex mt-4">
        <span className="my-auto">
          <button
            className="mt-1"
            onClick={() => {
              toggleTodoCompletion({
                variables: {
                  id: todoitem.id,
                },
              });
            }}
          >
            {todoitem.completed ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 text-green-500 fill-current print:h-6 print:w-6"
                viewBox="0 0 24 24"
              >
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 fill-current text-coolGray-800 print:h-6 print:w-6"
                viewBox="0 0 24 24"
              >
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z" />
              </svg>
            )}
          </button>
        </span>

        <div className="flex-1 my-auto ml-3 mr-2">{todoitem.description}</div>

        <div className="my-auto print:hidden">
          <button
            onClick={() => setEditing(true)}
            type="button"
            className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Edit
          </button>
        </div>
      </li>
    );
  }
}
