import * as React from "react";

import {HomeIcon} from "@heroicons/react/outline";

type Props = {
  children?: React.ReactNode;
};

export function BreadcrumbNavigation({children}: Props) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex px-6 space-x-4 bg-white rounded-md shadow">
        <li className="flex">
          <div className="flex items-center">
            <a href="/" className="text-coolGray-400 hover:text-coolGray-500">
              <HomeIcon className="flex-shrink-0 w-5 h-5 transition duration-150 ease-in-out" />
            </a>
            <span className="sr-only">Home</span>
          </div>
        </li>
        {children}
      </ol>
    </nav>
  );
}
