import * as React from "react";
import DirtyIndicator from "../Components/DirtyIndicator";
import FormLabel from "../Components/FormLabel";
import Instance from "../Context/Instance";
import {fieldName} from "../UtilityComponents/FieldName";

type Props = {
  label: string;
  value: string;
  initialValue: string;
  setValue: (newValue: string) => void;
  buttonIfBlank?: boolean;
  autoFocus?: boolean;
  autoFocusIfBlank?: boolean;
  informUnsavedChanges?: boolean;
  differentiator?: string;
};

export const MyTextarea = React.memo(function MyTextarea({
  label,
  value,
  initialValue,
  setValue,
  buttonIfBlank,
  autoFocus,
  autoFocusIfBlank,
  informUnsavedChanges = true,
  differentiator = "",
}: Props) {
  const [showAnyway, setShowAnyway] = React.useState(false);

  const instanceId = React.useContext(Instance);

  if (buttonIfBlank && value === "" && !showAnyway) {
    return <button onClick={() => setShowAnyway(true)}>Add Notes</button>;
  }

  function handleBlur() {
    if (value !== value.trim()) {
      setValue(value.trim());
    }
  }

  const inputId = `${instanceId}${differentiator}-${fieldName(label)}`;

  return (
    <div className="w-full p-3">
      <div className="flex justify-between">
        <FormLabel label={label} htmlFor={inputId} />
        {informUnsavedChanges && value !== initialValue ? (
          <DirtyIndicator />
        ) : null}
      </div>
      <textarea
        id={inputId}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={handleBlur}
        className="block w-full px-4 py-3 leading-tight border rounded appearance-none text-coolGray-700 bg-coolGray-100 border-coolGray-200 focus:outline-none focus:bg-white focus:ring-indigo-500 focus:border-indigo-500"
        autoFocus={autoFocus || (autoFocusIfBlank && value === "")}
      />

      {/* autoFocus: {autoFocus}
      initialValue: {initialValue} */}
      {/* <Form.Field
        size="huge"
        control={TextareaAutosize}
        style={
          value !== initialValue
            ? {borderColor: "#6435c9", color: "#6435c9"}
            : null
        }
        autoFocus={autoFocus || (buttonIfBlank && value === "")}
      /> */}
    </div>
  );
});
