import * as React from "react";

// import {useMachine} from "@xstate/react";
// import {Machine} from "xstate";

import {loader} from "graphql.macro";

import {useMutation} from "@apollo/client";

import {StateVerified} from "./StateVerified";
import {StateVerificationNeeded} from "./StateVerificationNeeded";

const ENABLE_TWO_FACTOR_AUTHENTICATION = loader(
  "../../../Fragments/Contacts/TwoFactorAuthenticationEnable.graphql"
);

// const REGENERATE_TWO_FACTOR_AUTHENTICATION_SECRET = loader(
//   "../../../Fragments/Contacts/TwoFactorAuthenticationRegenerateSecret.graphql"
// );

// const VERIFY_TWO_FACTOR_AUTHENTICATION = loader(
//   "../../../Fragments/Contacts/TwoFactorAuthenticationVerify.graphql"
// );

type Group = {
  id: string;
  name: string;
};

type UserSettings = {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  profile: {
    id: string;
    mobile: string;
    authyId: string;
    passwordChanged: string | null;
    timezone: {id: string; name: string};
    language: string;
    otpSecret: string;
    otpVerified: string;
  };
  groups: Group[];
};

type Props = {
  userSettings: UserSettings;
};

// const tfaMachine = Machine({
//   id: "two-factor-authentication",
//   initial: "idle",
//   states: {
//     idle: {
//       on: {
//         ENABLE: "enabling",
//         MAY_DELETE: "may_delete",
//       },
//     },
//     enabling: {
//       on: {
//         ENABLED: "verification_needed",
//         GRAPHQL_ERROR: "idle",
//         // MUTATION_ERROR: "error_saving"
//       },
//     },
//     graphql_error: {
//       //
//       on: {SAVE: "saving"},
//     },
//     saving: {
//       on: {OK: "idle"},
//     },
//     verification_needed: {
//       on: {
//         ATTEMPT_VERIFY: "idle",
//         ERROR: "loading_error",
//       },
//     },
//     verifying: {
//       on: {
//         VERIFIED: "idle",
//         ERROR: "verification_needed",
//       },
//     },
//     loading_error: {
//       //
//     },
//     may_delete: {
//       on: {
//         DONT_DELETE: "idle",
//         CONFIRM_DELETE: "deleting",
//       },
//     },
//     deleting: {
//       on: {
//         DELETE_SUCCESSFUL: "redirect_after_delete",
//         DELETE_FAILED: "error_deleting",
//       },
//     },
//     redirect_after_delete: {
//       type: "final",
//     },
//     error_deleting: {
//       on: {
//         RETRY_DELETE: "deleting",
//         ABORT_DELETION: "idle",
//       },
//     },
//   },
// });

export function TwoFactorAuthenticationSetting({userSettings}: Props) {
  // const [state, send] = useMachine(tfaMachine);
  const [status, setStatus] = React.useState(
    userSettings.profile.otpSecret === ""
      ? "idle"
      : userSettings.profile.otpVerified == null
      ? "verification_needed"
      : "verified"
  );
  const [verificationInput, setVerificationInput] = React.useState("");

  React.useEffect(() => {
    if (
      userSettings.profile.otpVerified != null &&
      status === "verification_needed"
    ) {
      setStatus("verified");
      setVerificationInput("");
    }
    if (userSettings.profile.otpVerified === null && status === "verified") {
      setStatus("verification_needed");
    }
  }, [status, userSettings]);

  //   if (state.value === "enabling") {
  //     if (status === "idle") {

  //     }
  //     if (userSettings.profile.otpSecret !== "") {
  //       () => send("ENABLED")

  //       //   console.log("secret is " + userSettings.profile.otpSecret);
  //     } else {
  //       console.log("waiting for mutation");
  //     }
  //   }
  //   if (state.value === "verification_needed") {
  //     if (userSettings.profile.otpVerified === null) {
  //       console.log("verification is null");
  //     } else {
  //       console.log(userSettings.profile.otpVerified);
  //     }
  //   }
  const [
    enableTwoFactorAuthentication,
    {error: enableMutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    enableTwoFactorAuthentication: UserSettings;
  }>(ENABLE_TWO_FACTOR_AUTHENTICATION, {
    onCompleted: () => {
      // send("SAVED");
      console.log("saved");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      // send("GRAPHQL_ERROR");
      console.log("error" + enableMutationError);
    },
  });

  // const [
  //   regenerateTwoFactorAuthenticationSecret,
  //   {error: regenerateMutationError},
  //   // {loading: mutationLoading, error: mutationError}
  // ] = useMutation<{
  //   regenerateTwoFactorAuthenticationSecret: UserSettings;
  // }>(REGENERATE_TWO_FACTOR_AUTHENTICATION_SECRET, {
  //   variables: {
  //     otp: verificationInput,
  //   },
  //   onCompleted: () => {
  //     // send("SAVED");
  //     console.log("saved");
  //   },
  //   onError: () => {
  //     // alert(
  //     //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
  //     // );
  //     // send("GRAPHQL_ERROR");
  //     console.log("error" + regenerateMutationError);
  //   },
  // });

  // const [
  //   verifyTwoFactorAuthentication,
  //   {error: verifyMutationError},
  //   // {loading: mutationLoading, error: mutationError}
  // ] = useMutation<{
  //   verifyTwoFactorAuthentication: UserSettings;
  // }>(VERIFY_TWO_FACTOR_AUTHENTICATION, {
  //   variables: {
  //     otp: verificationInput,
  //   },
  //   onCompleted: () => {
  //     // send("SAVED");
  //     console.log("saved");
  //   },
  //   onError: () => {
  //     // alert(
  //     //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
  //     // );
  //     // send("GRAPHQL_ERROR");
  //     console.log("error" + verifyMutationError);
  //   },
  // });

  if (status === "idle") {
    return (
      <div className="bg-indigo-300">
        This is where I will have the button to turn on 2fa.
        <br />
        <br />
        <div className="text-3xl">state: {status}</div>
        {status === "idle" ? (
          <div>
            <button
              type="button"
              onClick={() => enableTwoFactorAuthentication()}
            >
              turn on 2fa
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  if (status === "verification_needed") {
    return (
      <StateVerificationNeeded
        userSettings={userSettings}
        verificationInput={verificationInput}
        setVerificationInput={setVerificationInput}
      />
    );
  }

  if (status === "verified") {
    return <StateVerified userSettings={userSettings} />;
  }

  return <div>this shouldn't happen</div>;

  //   return (
  //     <div className="bg-indigo-300">
  //       This is where I will have the button to turn on 2fa.
  //       <br />
  //       <br />
  //       {userSettings.profile.otpVerified}
  //       <br />
  //       <div className="text-3xl">state: {status}</div>
  //       {status === "idle" ? (
  //         <div>
  //           <button type="button" onClick={() => enableTwoFactorAuthentication()}>
  //             turn on 2fa
  //           </button>
  //         </div>
  //       ) : null}
  //       {status === "verified" ? (
  //         <div>It is verified. Do you want to turn it off?</div>
  //       ) : null}
  //       <br />
  //       <p>
  //         Scan this code with your authenticator app. Don't share it with anyone
  //         else.
  //       </p>
  //       <QRCode
  //         bgColor="#FFFFFF"
  //         fgColor="#000000"
  //         level="Q"
  //         style={{width: 256}}
  //         value={`otpauth://totp/AgencyPipeline:${userSettings.username}?secret=${userSettings.profile.otpSecret}&issuer=AgencyPipeline`}
  //       />
  //       <p>
  //         After scanning it, please enter the One-time password here:
  //         <input
  //           type="text"
  //           onChange={(event) => setVerificationInput(event.target.value)}
  //           value={verificationInput}
  //         />
  //         {verificationInput.length === 6 ? (
  //           <button type="button" onClick={() => verifyTwoFactorAuthentication()}>
  //             Submit
  //           </button>
  //         ) : null}
  //       </p>
  //     </div>
  //   );
}
