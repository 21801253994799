import * as React from "react";

import Cleave from "cleave.js/react";

import Instance from "../Context/Instance";
import DirtyIndicator from "../Components/DirtyIndicator";
import {fieldName} from "../UtilityComponents/FieldName";
import FormLabel from "../Components/FormLabel";

type Props = {
  label: string;
  value: number | null;
  setValue: (newValue: number | null) => void;
  initialValue: number | null;
  toggleMore?: () => void;
  // placeholder?: number;
  autoFocus?: string;
};

export const MyYearField = React.memo(function MyYearField({
  label,
  toggleMore,
  value,
  setValue,
  initialValue,
  // placeholder,
  autoFocus,
}: Props) {
  const [status, setStatus] = React.useState(value === 1999 ? "ERROR" : "OK");

  React.useEffect(() => {
    if (value && value > 2100) {
      setStatus("ERROR");
    } else {
      setStatus("OK");
    }
  }, [value]);

  const instanceId = React.useContext(Instance);

  return (
    <div className="w-full p-3 mb-6 md:mb-0">
      <div className="flex justify-between">
        <FormLabel
          label={label}
          htmlFor={`${instanceId}-${fieldName(label)}`}
        />

        {value === initialValue ? null : <DirtyIndicator />}
      </div>
      <Cleave
        id={`${instanceId}-${fieldName(label)}`}
        options={{
          numeral: true,
          numeralDecimalScale: 0,
          numeralThousandsGroupStyle: "none",
          numeralIntegerScale: 4,
        }}
        onChange={(event) => setValue(parseInt(event.target.rawValue))}
        data-lpignore="true"
        // placeholder={placeholder}
        value={value === null ? "" : value}
        className={`
          ${
            status === "ERROR"
              ? "border-red-500 mb-3"
              : "border-coolGray-200 focus:border-coolGray-500"
          }
            appearance-none block w-full bg-coolGray-100 text-coolGray-700 border  rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white
        `}
        //   style={
        //     parseInt(value) !== parseInt(initialValue)
        //       ? {borderColor: "#6435c9", color: "#6435c9"}
        //       : null
        //   }
      />
      {toggleMore ? (
        <button type="button" onClick={() => toggleMore()}>
          ...
        </button>
      ) : null}
      {/* </input> */}
      {/* {value.length > 4 ? (
        <div>
          <div>Error! Unable to Save</div>
          <p>
            This year field is limited to 4 characters. You entered{" "}
            {value.length} characters.
          </p>
        </div>
      ) : null} */}
    </div>
  );
});
