import * as React from "react";

import {motion, AnimatePresence} from "framer-motion";
// import {Link} from "react-router-dom";

import {DeleteClientTab} from "../Components/DeleteClientTab";
import {backendUrlHostname} from "../urls";

type Props = {
  clientTab: {
    id: string;
    client: {
      id: string;
      name: string;
      attn: string;
    };
  };
};

export function WorkItem({clientTab}: Props) {
  return (
    <AnimatePresence>
      <motion.div initial={{opacity: 1}} exit={{opacity: 0}}>
        <div className={"block p-5 bg-white rounded-lg shadow mb-4"}>
          <div className="flex justify-between">
            <p className="text-lg font-medium leading-snug text-coolGray-800">
              {/* <Link to={"/client/" + clientTab.client.id}> */}
              <a href={backendUrlHostname + "/clients/" + clientTab.client.id}>
                {clientTab.client.name}
                {clientTab.client.attn && (
                  <>
                    <br />
                    {clientTab.client.attn}
                  </>
                )}
              </a>
              {/* </Link> */}
            </p>
            <div>
              <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{
                  duration: 2,
                }}
                whileHover={{scale: 1.2}}
              >
                <DeleteClientTab id={clientTab.id} />
              </motion.div>
            </div>
          </div>
          {/* </motion.div> */}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
