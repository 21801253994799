import * as React from "react";

// import {ActivityNote} from "./ActivityNote";
// import {Attachment} from "../Attachment";

import {Application} from "./Application";
import {Audit} from "./Audit";
import {BillingInquiry} from "./BillingInquiry";
import {BillingNotice} from "./BillingNotice";
import {Claim} from "./Claim";
import {CollectionNotice} from "./CollectionNotice";
import {Communication} from "./Communication";
import {ConditionalRenewal} from "./ConditionalRenewal";
import {CoverageInquiry} from "./CoverageInquiry";
import {Crop} from "./Crop";
import {Correspondence} from "./Correspondence";
import {DMVNotice} from "./DMVNotice";
import {Endorsement} from "./Endorsement";
import {EndorsementQuote} from "./EndorsementQuote";
import {GenericPolicyActivity} from "./GenericPolicyActivity";
import {LegacyActivity} from "./LegacyActivity";
import {LegacyAttachment} from "./LegacyAttachment";
import {MedicareAppointment} from "./MedicareAppointment";
import {Nonrenewal} from "./Nonrenewal";
import {NoticeOfLoss} from "./NoticeOfLoss";
import {Payment} from "./Payment";
import {PendingCancellation} from "./PendingCancellation";
import {PhotoInspectionCompleted} from "./PhotoInspectionCompleted";
import {PhotoInspectionNeeded} from "./PhotoInspectionNeeded";
import {PhysicalFilesScannedIn} from "./PhysicalFilesScannedIn";
import {ProofOfInsurance} from "./ProofOfInsurance";
import {PolicyReview} from "./PolicyReview";
import {Quote} from "./Quote";
import {Questionnaire} from "./Questionnaire";
import {Remarket} from "./Remarket";
import {ReturnedMail} from "./ReturnedMail";
import {RiskMap} from "./RiskMap";
import {Renewal} from "./Renewal";
import {ScheduleOfDrivers} from "./ScheduleOfDrivers";
import {ScheduleOfEquipment} from "./ScheduleOfEquipment";
import {ScheduleOfLocations} from "./ScheduleOfLocations";
import {StartupFile} from "./StartupFile";
import {ScheduleOfPersonalProperty} from "./ScheduleOfPersonalProperty";
import {Termination} from "./Termination";
import {UnderwritingInquiry} from "./UnderwritingInquiry";

interface AttachmentType {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
}

interface Tag {
  id: string;
  document: AttachmentType;
}

interface ActivityNoteType {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
}

interface FlexiblePolicyActivity {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNoteType[];
  documenttagSet: Tag[];
}

interface Props {
  activity: FlexiblePolicyActivity;
}

export function Activity({activity}: Props) {
  switch (activity.activityType) {
    case "APPLICATION":
      return <Application activity={activity} />;
    case "AUDIT":
      return <Audit activity={activity} />;
    case "BILLING_INQUIRY":
      return <BillingInquiry activity={activity} />;
    case "BILLING_NOTICE":
      return <BillingNotice activity={activity} />;
    case "CLAIM":
      return <Claim activity={activity} />;
    case "COLLECTION_NOTICE":
      return <CollectionNotice activity={activity} />;
    case "COMMUNICATION":
      return <Communication activity={activity} />;
    case "CONDITIONAL_RENEWAL":
      return <ConditionalRenewal activity={activity} />;
    case "CORRESPONDENCE":
      return <Correspondence activity={activity} />;
    case "COVERAGE_INQUIRY":
      return <CoverageInquiry activity={activity} />;
    case "CROP":
      return <Crop activity={activity} />;
    case "DMV_NOTICE":
      return <DMVNotice activity={activity} />;
    case "ENDORSEMENT":
      return <Endorsement activity={activity} />;
    case "ENDORSEMENT_QUOTE":
      return <EndorsementQuote activity={activity} />;
    case "EQUIPMENT_SCHEDULE":
      return <ScheduleOfEquipment activity={activity} />;
    case "RENEWAL":
      return <Renewal activity={activity} />;
    case "GENERIC_POLICY_ACTIVITY":
      return <GenericPolicyActivity activity={activity} />;
    case "LEGACY_ACTIVITY":
      return <LegacyActivity activity={activity} />;
    case "LEGACY_ATTACHMENT":
      return <LegacyAttachment activity={activity} />;
    case "MEDICARE_APPOINTMENT":
      return <MedicareAppointment activity={activity} />;
    case "NONRENEWAL":
      return <Nonrenewal activity={activity} />;
    case "NOTICE_OF_LOSS":
      return <NoticeOfLoss activity={activity} />;
    case "PAYMENT":
      return <Payment activity={activity} />;
    case "PENDING_CANCELLATION":
      return <PendingCancellation activity={activity} />;
    case "PHOTO_INSPECTION_COMPLETED":
      return <PhotoInspectionCompleted activity={activity} />;
    case "PHOTO_INSPECTION_NEEDED":
      return <PhotoInspectionNeeded activity={activity} />;
    case "PHYSICAL_FILES_SCANNED_IN":
      return <PhysicalFilesScannedIn activity={activity} />;
    case "POLICY_REVIEW":
      return <PolicyReview activity={activity} />;
    case "PROOF_OF_INSURANCE":
      return <ProofOfInsurance activity={activity} />;
    case "QUESTIONNAIRE":
      return <Questionnaire activity={activity} />;
    case "QUOTE":
      return <Quote activity={activity} />;
    case "REMARKET":
      return <Remarket activity={activity} />;
    case "RETURNED_MAIL":
      return <ReturnedMail activity={activity} />;
    case "RISK_MAP":
      return <RiskMap activity={activity} />;
    case "SCHEDULE_OF_DRIVERS":
      return <ScheduleOfDrivers activity={activity} />;
    case "SCHEDULE_OF_EQUIPMENT":
      return <ScheduleOfEquipment activity={activity} />;
    case "SCHEDULE_OF_LOCATIONS":
      return <ScheduleOfLocations activity={activity} />;
    case "SCHEDULE_OF_PERSONAL_PROPERTY":
      return <ScheduleOfPersonalProperty activity={activity} />;
    case "STARTUP_FILE":
      return <StartupFile activity={activity} />;
    case "TERMINATION":
      return <Termination activity={activity} />;
    case "UNDERWRITING_INQUIRY":
      return <UnderwritingInquiry activity={activity} />;

    default:
      return <span>Missing Component: {activity.activityType}</span>;
  }
}
