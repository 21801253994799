import * as React from "react";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {MySelect} from "../FormComponents/MySelect";

const GET_AGENCY_CHOICES = loader(
  "../Fragments/Policies/AgencyChoices.graphql"
);

type AgencyChoice = {
  id: string;
  name: string;
};

type AgencyChoices = {
  agencyChoices: AgencyChoice[];
};

type Props = {
  defaultAgencyName: string;
  value: string;
  setValue: (newValue: string) => void;
  initialValue: string;
};

export const AgencyChooser = ({
  value,
  setValue,
  initialValue,
  defaultAgencyName,
}: Props) => {
  const {loading, error, data} = useQuery<AgencyChoices>(GET_AGENCY_CHOICES);

  // This side effect will default the selected agency to the user's agency
  React.useEffect(() => {
    if (value === "") {
      const userAgency = data?.agencyChoices.filter(
        (agency) => agency.name === defaultAgencyName
      );

      if (userAgency && userAgency.length === 1) {
        setValue(userAgency[0].id);
      }
    }
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  if (data) {
    return (
      <MySelect
        label="Agency"
        graphqloptions={data.agencyChoices}
        value={value}
        setValue={setValue}
        initialValue={initialValue}
      />
    );
  }

  return <p>An error occurred</p>;
};
