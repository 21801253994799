import * as React from "react";

import {gql, useQuery} from "@apollo/client";

import {BrandCard} from "../../Components/Forms/BrandCard";

const FORM_BRANDS = gql`
  query FormBrandQuery {
    formBrands {
      id
      name
      slug
      logoUrl
      pdfformSet {
        id
        active
      }
    }
  }
`;

const allFormsBrand = {
  id: "1",
  slug: "all",
  logoUrl: "https://pipeline-media.s3.amazonaws.com/logos/all-forms.webp",
  name: "All Forms",
  pdfformSet: [],
};

interface FormTag {
  id: string;
  name: string;
}

interface Form {
  id: string;
  number: string;
  name: string;
  edition: string;
  url: string;
  active: boolean;
  brand: Brand;
  formtagSet: FormTag[];
}

interface Brand {
  id: string;
  name: string;
  slug: string;
  logoUrl: string;
  pdfformSet: Form[];
}

interface BrandList {
  formBrands: Brand[];
}

export function FormBrands() {
  const {loading, error, data} = useQuery<BrandList>(FORM_BRANDS);

  React.useEffect(() => {
    document.title = `Forms`;
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  return (
    <div className="max-w-full p-4 mx-auto lg:p-8">
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
        <BrandCard brand={allFormsBrand} />

        {data &&
          data.formBrands.map((brand) => (
            <BrandCard key={brand.id} brand={brand} />
          ))}
      </div>
    </div>
  );
}
