import * as React from "react";

import Cleave from "cleave.js/react";

import {fieldName} from "../UtilityComponents/FieldName";
import Instance from "../Context/Instance";
import DirtyIndicator from "../Components/DirtyIndicator";
import FormLabel from "../Components/FormLabel";

type Props = {
  label: string;
  value: number;
  setValue: (newValue: number) => void;
  initialValue: number;
  digits: number;
  toggleMore?: () => void;
};

export const MyNumberField = React.memo(function MyNumberField({
  label,
  value,
  setValue,
  initialValue,
  digits,
  toggleMore,
}: Props) {
  const [status, setStatus] = React.useState("OK");

  React.useEffect(() => {
    // Do Error Checking Here
    if (value && value === 999999999) {
      setStatus("ERROR");
    } else {
      setStatus("OK");
    }
  }, [value]);

  const instanceId = React.useContext(Instance);

  return (
    <div className="w-full p-3 mb-6 md:mb-0">
      <div className="flex justify-between">
        <FormLabel
          label={label}
          htmlFor={`${instanceId}-${fieldName(label)}`}
        />

        {value === initialValue ? null : <DirtyIndicator />}
      </div>
      <Cleave
        id={`${instanceId}-${fieldName(label)}`}
        options={{
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralIntegerScale: digits,
        }}
        // onChange={(event) => console.log(parseFloat(event.target.rawValue))}
        onChange={(event) => {
          if (event.target.value === "" || event.target.value === ".") {
            setValue(0.0);
          } else {
            setValue(parseFloat(event.target.rawValue));
          }
        }}
        data-lpignore="true"
        value={value}
        className={`
          ${
            status === "ERROR"
              ? "border-red-500 mb-3"
              : "border-coolGray-200 focus:border-coolGray-500"
          }
          appearance-none block w-full bg-coolGray-100 text-coolGray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white
        `}
      />

      {toggleMore && (
        <button type="submit" onClick={() => toggleMore()}>
          ...
        </button>
      )}

      {/* {value.length > characterLimit ? (
        <div>
          <div>Error! Unable to Save</div>
          <p>
            This field is limited to {characterLimit} characters. You entered{" "}
            {value.length} characters.
          </p>
        </div>
      ) : null} */}
    </div>
  );
});
