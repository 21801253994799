import * as React from "react";

import {Redirect} from "react-router-dom";

// import {SearchIcon} from "./Icons/SearchIcon";
import {SearchIcon} from "@heroicons/react/solid";

export function SearchInput() {
  const [query, setQuery] = React.useState("");
  const [doSearch, setDoSearch] = React.useState(false);

  if (doSearch) {
    return <Redirect to={`/search/${query}`} />;
  }

  return (
    // <div className="flex flex-1">
    //   <form
    //     className="flex w-full md:ml-0"
    //     onSubmit={(event) => {
    //       event.preventDefault();
    //       setDoSearch(true);
    //     }}
    //   >
    //     <label htmlFor="search_field" className="sr-only">
    //       Search Clients
    //     </label>
    //     <div className="relative w-full text-coolGray-400 focus-within:text-coolGray-600">
    //       <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
    //         <SearchIcon className="w-5 h-5" />
    //       </div>
    //       <input
    //         className="block w-full h-full py-2 pl-8 pr-3 border-0 border-transparent rounded-md text-coolGray-900 placeholder-coolGray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-coolGray-400 sm:text-sm"
    //         placeholder="Search Clients"
    //         type="search"
    //         value={query}
    //         onChange={(event) => setQuery(event.target.value)}
    //       />
    //     </div>
    //   </form>
    // </div>

    <div className="flex-1 min-w-0 md:px-8 lg:px-0 xl:col-span-6">
      <div className="flex items-center px-6 py-2 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
        <div className="w-full">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              setDoSearch(true);
            }}
          >
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <SearchIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                id="search"
                name="search"
                className="block w-full py-2 pl-10 pr-3 text-sm placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                placeholder="Search"
                type="search"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
