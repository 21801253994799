import * as React from "react";

import {format, isAfter, subDays} from "date-fns";

import {PostponeReminder} from "./Reminders/PostponeReminder";
import {ToggleReminderCompletion} from "./Reminders/ToggleReminderCompletion";
import {ReminderEdit} from "./ReminderEdit";

type ReminderType = {
  id: string;
  completed: boolean;
  message: string;
  remindOn: string;
  client: {
    name: string;
    attn: string;
  };
};

type Props = {
  reminder: ReminderType;
};

export function Reminder({reminder}: Props) {
  const [editing, setEditing] = React.useState(false);

  if (editing) {
    return (
      <ReminderEdit reminder={reminder} stopEditing={() => setEditing(false)} />
    );
  }

  return (
    <div className="border border-coolGray-200" key={reminder.id}>
      <div className="p-2">
        <h2 className="mt-2 text-xl font-semibold leading-9 tracking-normal text-indigo-600">
          {reminder.message}
        </h2>

        {reminder.completed ? null : (
          <>
            {isAfter(
              subDays(new Date(), 1),
              new Date(reminder.remindOn.replace(/-/g, "/"))
            ) ? (
              <div className="text-3xl font-bold text-red-300">OVERDUE</div>
            ) : null}
            {reminder.completed ? null : (
              <PostponeReminder reminder={reminder} />
            )}
          </>
        )}

        <div className="mt-6">
          {format(
            new Date(reminder.remindOn.replace(/-/g, "/")),
            "MMMM do yyyy"
          )}
        </div>

        <div>
          <button
            className="px-6 py-1 m-2 border rounded text-coolGray-700 border-coolGray-500"
            onClick={() => setEditing(true)}
          >
            Edit
          </button>
          <ToggleReminderCompletion reminder={reminder} />
        </div>
      </div>
    </div>
  );
}
