// import * as React from "react";

import {loader} from "graphql.macro";

import {useMutation} from "@apollo/client";

const DISABLE_TWO_FACTOR_AUTHENTICATION = loader(
  "../../../Fragments/Contacts/TwoFactorAuthenticationDisable.graphql"
);

type UserSettings = {
  profile: {
    otpSecret: string;
    otpVerified: string;
  };
};

type Props = {
  userSettings: UserSettings;
};

export function DisableButton({userSettings}: Props) {
  //   const [verificationInput, setVerificationInput] = React.useState("");

  const [
    disableTwoFactorAuthentication,
    {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    disableTwoFactorAuthentication: UserSettings;
  }>(DISABLE_TWO_FACTOR_AUTHENTICATION, {
    onCompleted: () => {
      console.log("saved");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      // send("GRAPHQL_ERROR");
      console.log("error" + mutationError);
    },
  });

  return (
    <button type="button" onClick={() => disableTwoFactorAuthentication()}>
      Submit
    </button>
  );
}
