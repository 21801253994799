import * as React from "react";

import {Link} from "react-router-dom";
import {TodoSidebarItem} from "../../Components/Todo/TodoSidebarItem";

type TodoList = {
  id: string;
  name: string;
};

type TodoProject = {
  id: string;
  name: string;
  incompleteLists: TodoList[];
};

type Props = {
  selectedProjectId: string;
  todoprojects: TodoProject[];
  setSelectedProjectId: (newSelection: string) => void;
  sidebarShowAnyway: boolean;
  setSidebarShowAnyway: (newValue: boolean) => void;
};

export function TodoSidebar({
  selectedProjectId,
  todoprojects,
  setSelectedProjectId,
  sidebarShowAnyway,
  setSidebarShowAnyway,
}: Props) {
  // const [sidebarOpen, setSidebarOpen] = React.useState(false);
  return (
    <div
      // :className="sidebarOpen ? 'translate-x-0 ease-out transition-medium' : '-translate-x-full ease-in transition-medium'"
      className={
        sidebarShowAnyway
          ? "fixed z-30 inset-y-0 left-0 w-64 px-8 py-4 bg-coolGray-100 border-r overflow-auto print:hidden lg:static lg:inset-auto lg:translate-x-0"
          : "hidden"
      }
    >
      <div className="flex items-center justify-between pl-3 pr-1 -mx-3">
        <button
          onClick={() => setSidebarShowAnyway(false)}
          className="text-coolGray-700 lg:hidden"
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
            <path
              d="M6 18L18 6M6 6L18 18"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              stroke="currentColor"
            />
          </svg>
        </button>
      </div>
      <nav className="mt-4">
        <h3 className="text-xs font-semibold tracking-wide uppercase text-coolGray-600">
          Projects
          {/* {sidebarOpen ? "Open" : "Closed"} */}
        </h3>
        <div className="mt-2 -mx-3">
          {/* <SidebarItem
            title="Home"
            active={activeItem === "home"}
            linkTo={"/"}
          />
          <SidebarItem
            title="Dashboard"
            active={activeItem === "dashboard"}
            linkTo={"/dashboard"}
          /> */}
          {todoprojects &&
            todoprojects.map((todoproject) => (
              <TodoSidebarItem
                key={todoproject.id}
                project={todoproject}
                // name={todoproject.name}
                active={selectedProjectId === todoproject.id}
                setSelectedProjectId={setSelectedProjectId}
                // count={Object.keys(todoproject.incompleteLists).length}
              />
            ))}

          {/* <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg bg-coolGray-200"
          >
            <span className="text-sm font-medium text-coolGray-900">All</span>
            <span className="text-xs font-semibold text-coolGray-700">36</span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">
              Assigned to me
            </span>
            <span className="text-xs font-semibold text-coolGray-700">2</span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">
              Created by me
            </span>
            <span className="text-xs font-semibold text-coolGray-700">1</span>
          </a>
          <a
            href="#"
            className="flex items-center justify-between px-3 py-2 rounded-lg"
          >
            <span className="text-sm font-medium text-coolGray-700">Archived</span>
          </a> */}
        </div>

        <Link to={"/todo/project/add"}>
          <button className="flex items-center mt-2 -ml-1 text-sm font-medium text-coolGray-600">
            <svg
              className="w-5 h-5 text-coolGray-500"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 7v10m5-5H7"
                strokeLinecap="round"
                strokeWidth="2"
                stroke="currentColor"
              />
            </svg>
            <span className="ml-1">New Project</span>
          </button>
        </Link>
      </nav>
    </div>
  );
}
