interface Props {
  label: string;
  htmlFor: string;
}

export default function FormLabel({label, htmlFor}: Props) {
  return (
    <label
      className="block mb-2 text-sm font-medium tracking-wide text-gray-700"
      htmlFor={htmlFor}
    >
      {label}
    </label>
  );
}
