import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";

const CLEAR_COMPLETED_TODOS = gql`
  mutation clearCompletedTodos {
    clearCompletedTodos {
      todoprojects {
        id
        name
        incompleteLists {
          id
          name
          unclearedTodos {
            id
            created
            description
            url
            completed
            cleared
            hideuntil
            priority
          }
        }
      }
    }
  }
`;

type TodoList = {
  id: string;
  name: string;
};

type TodoProject = {
  id: string;
  name: string;
  incompleteLists: TodoList[];
};

export function ClearCompletedTodos() {
  const [clearCompletedTodos] = useMutation<{
    clearCompletedTodos: TodoProject[];
  }>(CLEAR_COMPLETED_TODOS);

  return (
    <button
      type="button"
      onClick={() => {
        clearCompletedTodos();
      }}
    >
      Clear Completed
    </button>
  );
}
