import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {AppointmentDelete} from "./AppointmentDelete";

import IdleTimer from "react-idle-timer";

import {MyTextarea} from "../../FormComponents/MyTextarea";

const SAVE_APPOINTMENT_MUTATION = loader(
  "../../Fragments/Medicare/AppointmentUpdate.graphql"
);

type Appointment = {
  id: string;
  timestamp: number;
  notes: string;
  createdBy: {
    firstName: string;
    lastName: string;
  };
};

type Props = {
  appointment: Appointment;
  autoSave: boolean;
  beneficiaryId: string;
};

export function AppointmentEdit({appointment, autoSave, beneficiaryId}: Props) {
  const [notes, setNotes] = React.useState(appointment.notes);

  // const [deleting, setDeleting] = React.useState(false);
  // function toggleDeleting() {
  //   setDeleting(deleting === false ? true : false);
  // }

  const [saveAppointment] = useMutation<{
    saveAppointment: Appointment;
  }>(SAVE_APPOINTMENT_MUTATION, {
    variables: {
      id: appointment.id,
      notes: notes,
    },
  });

  return (
    <div>
      <div>
        <p>
          Recorded: {appointment.timestamp} by {appointment.createdBy.firstName}{" "}
          {appointment.createdBy.lastName}
        </p>
        <MyTextarea
          label="Notes"
          value={notes}
          setValue={setNotes}
          initialValue={appointment.notes}
          autoFocusIfBlank
        />

        {notes === "" ? (
          <AppointmentDelete
            beneficiaryId={beneficiaryId}
            appointment={appointment}
            immaculate={true}
          />
        ) : (
          <AppointmentDelete
            beneficiaryId={beneficiaryId}
            appointment={appointment}
          />
        )}
      </div>
      <div>
        {notes === appointment.notes ? null : (
          <span>
            {autoSave ? (
              <IdleTimer
                timeout={1000}
                startOnMount={true}
                onIdle={() => saveAppointment()}
              />
            ) : (
              <button type="button" onClick={() => saveAppointment()}>
                save
              </button>
            )}
          </span>
        )}
      </div>
    </div>
  );
}
