import {gql, useMutation} from "@apollo/client";

const ADD_LOCATION = gql`
  mutation AddLocation($id: String!) {
    locationAdd(id: $id) {
      address {
        id
        active
        addressType
        description
        street
        city
        state
        zipcode
        validatedData
        distanceFromOffice
        locationSet {
          id
          created
          purchaseClosingDate
          purchaseClosingDateNotes
          purchasePrice
          purchasePriceNotes
          deededOwner
          deededOwnerNotes
          firstTimeHomebuyer
          hydrantsAvailable
          hydrantsAvailableNotes
          respondingFireDepartment
          distanceToFireDepartment
          isoProtectionClass
          isoProtectionClassNotes
        }
      }
    }
  }
`;

type Location = {
  id: string;
};

type ClientAddress = {
  id: string;
  validatedData: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  description: string;
  locationSet: Location[];
  distanceFromOffice: string;
  addressType: string;
};

type Props = {
  addressId: string;
};

export function AddUnderwritingProfile({addressId}: Props) {
  const [locationAdd] = useMutation<{
    locationAdd: ClientAddress;
  }>(ADD_LOCATION, {
    variables: {
      id: addressId,
    },
  });

  return (
    <button
      onClick={() => {
        locationAdd();
      }}
      className="px-2 py-1 text-blue-500 underline hover:text-blue-700"
    >
      ADD Underwriting Profile
    </button>
  );
}
