import * as React from "react";

import {MainMenu} from "../Menus/MainMenu";

// import {HomeMenu} from "../Menus/HomeMenu";

import {Sidebar} from "./Sidebar";

type Props = {
  children: any;
  activeItem: string;
};

export function ContentPage({children, activeItem}: Props) {
  // const [collapsed, setCollapsed] = React.useState(props.collapsed ? true : false);

  // function toggleCollapsed() {
  //   setCollapsed(collapsed === false ? true : false);
  // }

  return (
    <>
      <MainMenu />
      <Sidebar activeItem={activeItem} />

      {children}
      {/*       
      {collapsed === true ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "56px 1fr",
          }}
        >
          <div>
            <HomeMenu
              activeItem={props.activeItem}
              collapsed={collapsed}
              handleToggleCollapsed={toggleCollapsed}
            />
          </div>

          <div style={{padding: "1em"}}></div>
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "210px 1fr",
          }}
        >
          <div>
            <HomeMenu
              activeItem={props.activeItem}
              collapsed={collapsed}
              handleToggleCollapsed={toggleCollapsed}
            />
          </div>

          <div style={{padding: "1em"}}>{props.children}</div>
        </div>
      )} */}
    </>
  );
}
