import * as React from "react";

import {Link} from "react-router-dom";
// import {ExternalIcon} from "./Icons/ExternalIcon";

type Props = {
  name?: string;
  icon?: string;
  color?: string;
  href?: string;
  linkTo?: string;
  title: string;
  active: boolean;
  collapsed?: boolean;
};

export function SidebarItem({
  name,
  icon,
  color,
  href,
  linkTo,
  title,
  active,
  collapsed,
}: Props) {
  if (name === "test") {
    // added this to get rid of unused variable alerts
    return (
      <div>
        {icon}
        {color}
        {collapsed}
      </div>
    );
  }

  if (href) {
    return (
      <a
        href={href}
        className={`${
          active
            ? "text-white bg-teal-700"
            : "text-teal-100 hover:bg-teal-500 hover:text-white"
        } flex items-center px-2 py-2 text-sm font-medium leading-6  transition duration-150 ease-in-out  rounded-md group focus:outline-none focus:bg-teal-500`}
      >
        {/* <!-- Heroicon name: document-report --> */}
        {/* {title == "Reports" && (
          <svg
            className="w-6 h-6 mr-4 text-teal-300 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
        )} */}
        {title}
        {/* <ExternalIcon className="w-5 h-5" /> */}
      </a>
    );
  }

  if (linkTo) {
    return (
      <Link
        to={linkTo}
        className={`${
          active
            ? "text-white bg-teal-700"
            : "text-teal-100 hover:bg-teal-500 hover:text-white"
        } flex items-center px-2 py-2 text-sm font-medium leading-6  transition duration-150 ease-in-out  rounded-md group focus:outline-none focus:bg-teal-500`}
      >
        {/* <span
          className={`${
            active ? "text-coolGray-900" : "text-coolGray-600"
          } text-sm font-medium`}
        > */}
        {title}
        {/* </span> */}
        {/* <span className="text-xs font-semibold text-coolGray-700">36</span> */}
      </Link>
    );
  }

  return <span>Item missing href or linkTo attributes</span>;
}
