import * as React from "react";
import _ from "lodash";

import {Link} from "react-router-dom";

import {Form} from "./Form";

import {useUser} from "../../Context/UserContext";

import Toggle from "../../Components/Toggle";

import {BreadcrumbNavigation} from "../BreadcrumbNavigation";
import {BreadcrumbItem} from "../BreadcrumbItem";
import {SearchIcon} from "../Icons/SearchIcon";

interface SortOptions {
  column: string;
  direction: "asc" | "desc";
}

type FormTag = {
  id: string;
  name: string;
};

type Brand = {
  name: string;
  logoUrl: string;
};

type FormType = {
  id: string;
  number: string;
  name: string;
  edition: string;
  url: string;
  active: boolean;
  brand: Brand;
  formtagSet: FormTag[];
};

type Props = {
  brandName?: string;
  forms: FormType[];
  logoUrl: string;
  extraFormUrl?: string;
};

export const FormList = ({brandName, forms, logoUrl, extraFormUrl}: Props) => {
  const [sortSelection, setSortSelection] = React.useState<SortOptions>({
    column: "numberStringIndex",
    direction: "asc",
  });

  const [editing, setEditing] = React.useState(false);

  const user = useUser();

  function toggleSortDirection() {
    setSortSelection({
      ...sortSelection,
      direction: sortSelection.direction === "desc" ? "asc" : "desc",
    });
  }

  const handleColumnClick = (nameOfClickedColumn: string) => {
    sortSelection.column === nameOfClickedColumn
      ? toggleSortDirection()
      : setSortSelection({
          column: nameOfClickedColumn,
          direction: "asc",
        });
  };

  const [searchTerm, setSearchTerm] = React.useState("");

  //TODO: Hide form number if all in the set are blank
  //TODO: Hide edition if all in the set are blank
  //TODO: Make columns show an indicator when sorted by that column.

  return (
    <div className="p-4 lg:p-8">
      <div
        style={{
          display: "grid",
          gridGap: "1em",
          gridTemplateColumns: "auto 1fr",
          // margin: ".5em",
        }}
      >
        <BreadcrumbNavigation>
          <BreadcrumbItem>
            <Link
              to="/forms/brands"
              className="text-sm font-medium leading-5 transition duration-150 ease-in-out text-coolGray-500 hover:text-coolGray-700"
            >
              Forms
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <span
              aria-current="page"
              className="text-sm font-medium leading-5 transition duration-150 ease-in-out text-coolGray-500 hover:text-coolGray-700"
            >
              {brandName}
            </span>
          </BreadcrumbItem>
        </BreadcrumbNavigation>
        <div
          style={{
            display: "grid",
            gridGap: ".5em",
            gridTemplateColumns: "1fr auto",
          }}
        >
          <div className="flex flex-1">
            <form
              className="flex w-full md:ml-0"
              action="#"
              method="GET"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <label htmlFor="search_field" className="sr-only">
                Search
              </label>
              <div className="relative w-full text-coolGray-400 focus-within:text-coolGray-600">
                <div
                  className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                  aria-hidden="true"
                >
                  <SearchIcon className="w-5 h-5" />
                </div>
                <input
                  className="block w-full h-full py-2 pl-8 pr-3 border-0 border-transparent text-coolGray-900 placeholder-coolGray-500 bg-coolGray-200 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-coolGray-400 sm:text-md"
                  placeholder="Filter Forms"
                  type="text"
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event?.target.value)}
                  autoFocus
                />
              </div>
            </form>
          </div>

          {user.firstName === "Mathieu" ? (
            <div className="flex items-center px-6 py-3 ml-auto space-x-4 text-sm font-medium leading-5 bg-white rounded-md shadow text-coolGray-500">
              Edit Mode &nbsp;
              <Toggle setValue={setEditing} value={editing} />
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-8 overflow-hidden bg-white rounded-t-lg shadow">
        <div className="p-2">
          <div className="flex items-center">
            <div className="flex-shrink-0 p-3 rounded-md">
              <img src={logoUrl} alt={brandName + " logo"} className="w-32" />
            </div>
            <div className="flex-1 w-0 ml-5">
              {/* <div className="text-xl">
                <MyTextInputField
                  label="Filter"
                  value={searchTerm}
                  setValue={setSearchTerm}
                  characterLimit={128}
                  informUnsavedChanges={false}
                  autoFocus
                />
              </div> */}
            </div>
          </div>
          {extraFormUrl && (
            <div>
              Find more forms here:{" "}
              <a
                href={extraFormUrl}
                className="text-blue-600 underline"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {extraFormUrl}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b shadow border-coolGray-200 sm:rounded-b-lg">
            <table className="min-w-full border-t divide-y border-coolGray-200 divide-coolGray-200">
              <thead>
                <tr>
                  {brandName === "All" ? (
                    <th
                      className="px-6 py-3 text-sm font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                      onClick={() => handleColumnClick("brand.name")}
                    >
                      Type
                    </th>
                  ) : null}

                  <th
                    className="px-6 py-3 text-sm font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                    onClick={() => handleColumnClick("numberStringIndex")}
                  >
                    Number
                  </th>
                  <th
                    className="px-6 py-3 text-sm font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                    onClick={() => handleColumnClick("name")}
                  >
                    Name
                  </th>
                  <th
                    className="px-6 py-3 text-sm font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                    onClick={() => handleColumnClick("edition")}
                  >
                    Edition
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-coolGray-200">
                {_.orderBy(forms, sortSelection.column, sortSelection.direction)
                  .filter((form) => form.active)
                  .filter(function (form) {
                    for (const tag of form.formtagSet) {
                      if (
                        tag.name
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase()) !== -1
                      ) {
                        return true;
                      }
                    }
                    return false;
                  })
                  .map((form) => (
                    <Form
                      key={form.id}
                      form={form}
                      brandFilter={brandName}
                      editing={editing}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
