type Props = {
  className: string;
};

export function CloseIcon({className}: Props) {
  return (
    <svg
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={className}
    >
      <path d="M6 18L18 6M6 6l12 12"></path>
    </svg>
  );
}
