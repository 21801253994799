import * as React from "react";

import {DisableButton} from "./DisableButton";

type UserSettings = {
  profile: {
    otpSecret: string;
    otpVerified: string;
  };
};

type Props = {
  userSettings: UserSettings;
};

export function StateVerified({userSettings}: Props) {
  return (
    <div>
      <div>It is verified. Do you want to turn it off?</div>

      <DisableButton userSettings={userSettings} />
    </div>
  );
}
