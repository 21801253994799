import * as React from "react";

// import {Redirect} from "react-router";

import {gql, useQuery} from "@apollo/client";

import _ from "lodash";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

import {RouteComponentProps} from "react-router-dom";

import {SearchResult} from "../Components/SearchResult";
import {backendUrlHostname} from "../urls";

const CLIENT_SEARCH = gql`
  query ClientQuery($query: String!) {
    matchingClients(searchTerm: $query) {
      id
      name
      attn
      inactive
      clientType {
        name
      }
      mailingAddress {
        street
        city
        state
        zipcode
      }
    }
  }
`;

type Results = {
  matchingClients: [
    {
      id: string;
      name: string;
      attn: string;
      inactive: boolean;
      clientType: {
        name: string;
      };
      mailingAddress: {
        street: string;
        city: string;
        state: string;
        zipcode: string;
      };
    }
  ];
};

type TParams = {query: string};

export function SearchResults({match}: RouteComponentProps<TParams>) {
  const {loading, error, data} = useQuery<Results>(CLIENT_SEARCH, {
    variables: {
      query: match.params.query,
    },
  });

  if (loading) return <p>Loading ...</p>;

  if (error) return <p>Error!</p>;

  if (data && data.matchingClients.length === 1) {
    window.location.replace(
      backendUrlHostname + "/clients/" + data.matchingClients[0].id
    );
    return <span>Redirecting</span>;
    // return <Redirect to={"/client/" + data.matchingClients[0].id} />;
  }

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="todo" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle={"Search Results"} />

          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="h-full p-3">
              {data && data.matchingClients.length.toString() === "0" ? (
                <div>
                  <h1>No Matches</h1>
                </div>
              ) : null}

              <div>
                {data && data.matchingClients.length} Results:
                <br />
                <br />
                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                  <ul>
                    {data &&
                      _.orderBy(
                        data.matchingClients,
                        "inactive",
                        "asc"
                      ).map((client, index) => (
                        <SearchResult
                          client={client}
                          key={client.id}
                          index={index}
                        />
                      ))}
                  </ul>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
