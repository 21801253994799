import {gql} from "@apollo/client";
import {useMutation} from "@apollo/client";

const CHANGE_QUOTE_STATUS = gql`
  mutation ChangeQuoteStatus($quoteId: String!, $direction: String!) {
    changeQuoteStatus(quoteData: {quoteId: $quoteId, direction: $direction}) {
      flexiblePolicyActivity {
        id
        actionDate
        activityType
        premium
        quoteClosed
        quoteStatus
        quoteType
        client {
          id
          name
          attn
        }
        product {
          name
        }
      }
    }
  }
`;

type Quote = {
  id: string;
  quoteStatus: string;
};

type Props = {
  quoteId: string;
  direction: string;
};

export const ChangeQuoteStatusButton = ({quoteId, direction}: Props) => {
  const [changeStatus] = useMutation<{
    changeStatus: Quote;
  }>(CHANGE_QUOTE_STATUS);

  return (
    <span
      onClick={() => {
        changeStatus({
          variables: {
            quoteId: quoteId,
            direction: direction,
          },
        });
      }}
    >
      {direction === "right" ? <span>&rarr;</span> : <span>&larr;</span>}
    </span>
  );
};
