import * as React from "react";

import {ContentPage} from "../Components/ContentPage";

export function Home() {
  return (
    <ContentPage activeItem="home">
      <h1>Home</h1>
    </ContentPage>
  );
}
