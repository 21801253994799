import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import Instance from "../../../Context/Instance";

import IdleTimer from "react-idle-timer";
import {DeleteOutbuildingForm} from "./DeleteOutbuilding";

import {MyIntegerField} from "../../../FormComponents/MyIntegerField";
import {MyNullBoolean} from "../../../FormComponents/MyNullBoolean";
import {MyTextarea} from "../../../FormComponents/MyTextarea";
import {MyTextInputField} from "../../../FormComponents/MyTextInputField";
// import {MyYearField} from "../../../FormComponents/MyYearField";

import {CircleIconSpinning} from "../../Icons/CircleIconSpinning";

import sha1 from "sha1";

const UPDATE_OUTBUILDING = loader(
  "../../../Fragments/Underwriting/OutbuildingUpdate.graphql"
);

const outbuildingMachine = Machine({
  id: "outbuilding",
  initial: "idle",
  states: {
    idle: {
      on: {
        SAVE: "saving",
        MAY_DELETE: "may_delete",
      },
    },
    saving: {
      on: {
        SAVED: "idle",
        GRAPHQL_ERROR: "idle",
      },
    },
    graphql_error: {
      on: {SAVE: "saving", DISCARD: "refetch", MAY_DELETE: "may_delete"},
    },
    refetch: {
      on: {
        DOG_LOADED: "idle",
        ERROR: "loading_error",
      },
    },
    loading_error: {
      //
    },
    may_delete: {
      on: {
        DONT_DELETE: "idle",
        CONFIRM_DELETE: "deleting",
      },
    },
    deleting: {
      //
    },
  },
});

type Outbuilding = {
  id: string;
  buildingDescription: string;
  constructionYear: string;
  constructionType: string;
  value: number | null;
  outbuildingNotes: string;
  heat: string;
  electric: string;
  hayStorage: string;
  usage: string;
  sha1: string;
};

type Props = {
  outbuilding: Outbuilding;
  autoSave: boolean;
  locationId: string;
};

export function EditOutbuilding({outbuilding, autoSave, locationId}: Props) {
  const [state, send] = useMachine(outbuildingMachine);

  const [buildingDescription, setBuildingDescription] = React.useState(
    outbuilding.buildingDescription
  );
  const [constructionYear, setConstructionYear] = React.useState(
    outbuilding.constructionYear
  );
  const [constructionType, setConstructionType] = React.useState(
    outbuilding.constructionType
  );
  const [value, setValue] = React.useState(outbuilding.value);
  const [outbuildingNotes, setOutbuildingNotes] = React.useState(
    outbuilding.outbuildingNotes
  );
  const [heat, setHeat] = React.useState(outbuilding.heat);
  const [electric, setElectric] = React.useState(outbuilding.electric);
  const [hayStorage, setHayStorage] = React.useState(outbuilding.hayStorage);
  const [usage, setUsage] = React.useState(outbuilding.usage);

  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (
      outbuilding.buildingDescription !== buildingDescription ||
      outbuilding.constructionYear !== constructionYear ||
      outbuilding.constructionType !== constructionType ||
      outbuilding.value !== value ||
      outbuilding.outbuildingNotes !== outbuildingNotes ||
      outbuilding.heat !== heat ||
      outbuilding.electric !== electric ||
      outbuilding.hayStorage !== hayStorage ||
      outbuilding.usage !== usage
    ) {
      if (!dirty) {
        setDirty(true);
      }
    } else {
      if (dirty) {
        setDirty(false);
      }
    }
  }, [
    dirty,
    outbuilding.buildingDescription,
    buildingDescription,
    outbuilding.constructionYear,
    constructionYear,
    outbuilding.constructionType,
    constructionType,
    outbuilding.value,
    value,
    outbuilding.outbuildingNotes,
    outbuildingNotes,
    outbuilding.heat,
    heat,
    outbuilding.electric,
    electric,
    outbuilding.hayStorage,
    hayStorage,
    outbuilding.usage,
    usage,
  ]);

  const [
    outbuildingUpdate,
    {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    outbuildingUpdate: Outbuilding;
  }>(UPDATE_OUTBUILDING, {
    variables: {
      id: outbuilding.id,
      buildingDescription: buildingDescription,
      constructionYear: constructionYear,
      constructionType: constructionType,
      value: value,
      outbuildingNotes: outbuildingNotes,
      heat: heat,
      electric: electric,
      hayStorage: hayStorage,
      usage: usage,
      sha1: outbuilding.sha1,
      newSha1: sha1(
        buildingDescription +
          constructionYear +
          constructionType +
          value +
          outbuildingNotes +
          heat +
          electric +
          hayStorage +
          usage
      ),
    },
    onCompleted: () => {
      send("SAVED");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      send("GRAPHQL_ERROR");
    },
  });

  function handleFormSubmit() {
    if (dirty) {
      send("SAVE");
      outbuildingUpdate();
    }
  }

  if (state.value === "may_delete") {
    return (
      <DeleteOutbuildingForm
        outbuilding={outbuilding}
        locationId={locationId}
        abortDeletion={() => send("DONT_DELETE")}
      />
    );
  }

  return (
    <Instance.Provider value={outbuilding.id}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          outbuildingUpdate();
        }}
      >
        {autoSave ? (
          <IdleTimer
            timeout={1000}
            startOnMount={true}
            onIdle={dirty ? () => outbuildingUpdate() : () => {}}
          />
        ) : null}
        <div>
          {/* <span>{state.value}</span> */}
          <div>
            <div>
              <div className="flex">
                <div className="flex-1">
                  <MyTextInputField
                    label="Building Description"
                    value={buildingDescription}
                    setValue={setBuildingDescription}
                    initialValue={outbuilding.buildingDescription}
                    characterLimit={64}
                    autoFocusIfBlank
                  />
                </div>
                <div className="flex-1">
                  <MyTextInputField
                    label="Construction Year"
                    value={constructionYear}
                    setValue={setConstructionYear}
                    initialValue={outbuilding.constructionYear}
                    characterLimit={64}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyTextInputField
                    label="Construction Type"
                    value={constructionType}
                    setValue={setConstructionType}
                    initialValue={outbuilding.constructionType}
                    characterLimit={64}
                  />
                </div>
                <div className="flex-1">
                  <MyIntegerField
                    label="Value"
                    value={value}
                    setValue={setValue}
                    initialValue={outbuilding.value}
                    characterLimit={8}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Heat"
                    value={heat}
                    setValue={setHeat}
                    initialValue={outbuilding.heat}
                  />

                  <MyNullBoolean
                    label="Electric"
                    value={electric}
                    setValue={setElectric}
                    initialValue={outbuilding.electric}
                  />

                  <MyNullBoolean
                    label="Hay Storage"
                    value={hayStorage}
                    setValue={setHayStorage}
                    initialValue={outbuilding.hayStorage}
                  />
                </div>
                <div className="flex-1">
                  <MyTextarea
                    label="Usage"
                    value={usage}
                    initialValue={outbuilding.usage}
                    setValue={setUsage}
                  />

                  <MyTextarea
                    label="Notes"
                    value={outbuildingNotes}
                    initialValue={outbuilding.outbuildingNotes}
                    setValue={setOutbuildingNotes}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            {dirty ? (
              <button
                type="button"
                onClick={() => handleFormSubmit()}
                className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
              >
                {autoSave ? (
                  <>
                    <CircleIconSpinning className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" />{" "}
                    Auto Saving
                  </>
                ) : (
                  "Save"
                )}
              </button>
            ) : (
              <button
                type="button"
                // onClick={() => handleFormSubmit()}
                className="px-4 py-2 font-bold text-white bg-blue-500 rounded opacity-50 cursor-not-allowed"
              >
                Saved
              </button>
            )}

            {mutationError && (
              <div className="text-3xl">
                {(() => {
                  switch (mutationError.message.split("GraphQL error: ")[1]) {
                    case "value too long for type character varying(64)\n":
                      return (
                        <span>
                          Changes cannot be saved
                          <br /> One or more fields has too many characters.
                        </span>
                      );
                    case "AM":
                      return <span>Additional Mailing</span>;
                    case "OT":
                      return <span>Other</span>;
                    default:
                      console.log("switch didn't have a match.");
                      console.log(mutationError.message);
                      return <span>Error: {mutationError.message}</span>;
                  }
                })()}
              </div>
            )}

            <button
              type="button"
              onClick={() => send("MAY_DELETE")}
              className="float-right px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </Instance.Provider>
  );
}
