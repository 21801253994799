import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {DeleteButton} from "../../Components/DeleteButton";
import {ConfirmDeleteObject} from "../../FormComponents/ConfirmDeleteObject";

const DELETE_FORM_TAG_MUTATION = loader(
  "../../Fragments/Forms/FormTagDelete.graphql"
);

const FORM_TAG_SET_FRAGMENT = loader(
  "../../Fragments/Forms/FormTagSet.graphql"
);

interface DeletedFormTag {
  deleteFormTag: {
    id: string;
  };
}

type FormTag = {
  id: string;
  name: string;
};

type FormTagSet = {
  formtagSet: FormTag[];
};

type Props = {
  formId: string;
  formtag: FormTag;
  immaculate?: boolean;
};

export function FormTagDelete({formId, formtag, immaculate = false}: Props) {
  const [startConfirm, setStartConfirm] = React.useState(false);

  const [deleteFormTag] = useMutation<DeletedFormTag>(
    DELETE_FORM_TAG_MUTATION,
    {
      variables: {id: formtag.id},
      update(cache, {data}) {
        if (!data || !data.deleteFormTag || !data.deleteFormTag.id) return;

        //   Read from the cache
        let formtags = cache.readFragment<FormTagSet>({
          id: "PDFFormObjectType:" + formId,
          fragment: FORM_TAG_SET_FRAGMENT,
          fragmentName: "FormTagSet",
        });

        //   Update the cache, removing the deleted one
        let newformtags = {};
        if (formtags) {
          newformtags = formtags.formtagSet.filter(
            (e) => e.id !== data.deleteFormTag.id
          );

          cache.writeFragment({
            id: "PDFFormObjectType:" + formId,
            fragment: FORM_TAG_SET_FRAGMENT,
            fragmentName: "FormTagSet",
            data: {
              formtagSet: newformtags,
              __typename: "PDFFormObjectType",
            },
          });
          // send("DELETED");
        }
      },
    }
  );

  if (immaculate) {
    return <DeleteButton action={() => deleteFormTag()} />;
  }

  if (startConfirm) {
    return (
      <ConfirmDeleteObject
        objectType="Form Search Term"
        objectDescription={
          <span>
            Search Term: <strong>{formtag.name}</strong>
          </span>
        }
        abortDeletion={() => setStartConfirm(false)}
        performDeletion={() => deleteFormTag()}
      />
    );
  }

  return <DeleteButton action={() => setStartConfirm(true)} />;
}
