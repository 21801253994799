// import {Document, Page} from "react-pdf";
// import throttle from "lodash.throttle";

// import {AttachmentIcon} from "./AttachmentIcon";
// import {AttachmentLinkMessage} from "./AttachmentLinkMessage";

// class PdfComponent extends PureComponent {
//   render() {
//     const {file, onDocumentLoad, pageNumber, rotation} = this.props;
//     return (
//       <Document
//         file={file}
//         loading={
//           <Message icon size="massive">
//             <Icon loading name="file pdf outline" color="red" />
//             <Message.Content>
//               <Message.Header>Just one second</Message.Header>
//               We are fetching that PDF
//             </Message.Content>
//           </Message>
//         }
//         onLoadSuccess={onDocumentLoad}
//       >
//         <Page pageNumber={pageNumber} rotate={rotation} width={500} />
//       </Document>
//     );
//   }
// }

type Attachment = {
  id: string;
  extension: string;
  createdOn: number;
  url: string;
  uploadedBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

type Props = {
  attachment: Attachment;
};

export function AttachmentPDF({attachment}: Props) {
  return <div>PDF would go here.</div>;
}

// export class AttachmentPDF extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       numPages: null,
//       pageNumber: 1,
//       pageRotation: 0,
//       showPDF: false,
//       width: null
//     };
//   }

//   componentDidMount() {
//     this.setDivSize();
//     window.addEventListener("resize", throttle(this.setDivSize, 1500));
//   }

//   componentWillUnmount() {
//     window.removeEventListener("resize", throttle(this.setDivSize, 1500));
//   }

//   setDivSize = () => {
//     this.setState({width: this.pdfWrapper.getBoundingClientRect().width});
//   };

//   handleShowPDF = event => {
//     event.preventDefault();
//     this.setState({
//       numPages: null,
//       showPDF: true
//     });
//   };

//   handleClosePDF = event => {
//     this.setState({
//       showPDF: false
//     });
//   };

//   handleAnteClockwisePageRotation = event => {
//     if (this.state.pageRotation > 0) {
//       this.setState({
//         pageRotation: this.state.pageRotation - 90
//       });
//     } else {
//       this.setState({
//         pageRotation: 270
//       });
//     }
//   };

//   handleClockwisePageRotation = event => {
//     if (this.state.pageRotation < 270) {
//       this.setState({
//         pageRotation: this.state.pageRotation + 90
//       });
//     } else {
//       this.setState({
//         pageRotation: 0
//       });
//     }
//   };

//   handlePageChange = (event, {activePage}) =>
//     this.setState({pageNumber: activePage, pageRotation: 0});

//   onDocumentLoad = ({numPages}) => {
//     this.setState({numPages});
//   };

//   render() {
//     const {createdOn, extension, uploadedBy, url} = this.props.attachment;
//     const {pageRotation, pageNumber, numPages, showPDF, width} = this.state;
//     return (
//       <Segment>
//         <div ref={ref => (this.pdfWrapper = ref)}>
//           {showPDF ? (
//             <div>
//               <PdfComponent
//                 file={{
//                   url: "https://agencypipeline.com" + url,
//                   withCredentials: true
//                 }}
//                 onDocumentLoad={this.onDocumentLoad.bind(this)}
//                 pageNumber={pageNumber}
//                 pageWidth={width}
//                 rotation={pageRotation}
//               />

//               {numPages && (
//                 <div>
//                   <Grid columns="equal">
//                     <Grid.Row>
//                       <Grid.Column width={3}>
//                         <Button.Group>
//                           <Button
//                             icon
//                             onClick={() =>
//                               window.open(
//                                 "https://agencypipeline.com" + url,
//                                 "_blank"
//                               )
//                             }
//                           >
//                             <Icon name="download" />
//                           </Button>
//                           <Button icon onClick={this.handleClosePDF}>
//                             <Icon name="compress" />
//                           </Button>
//                         </Button.Group>
//                       </Grid.Column>
//                       <Grid.Column textAlign="center">
//                         {numPages > 1 && (
//                           <Pagination
//                             activePage={pageNumber}
//                             boundaryRange={0}
//                             ellipsisItem={null}
//                             firstItem={null}
//                             lastItem={null}
//                             prevItem={{
//                               content: <Icon name="arrow left" />,
//                               icon: true
//                             }}
//                             nextItem={{
//                               content: <Icon name="arrow right" />,
//                               icon: true
//                             }}
//                             onPageChange={this.handlePageChange}
//                             totalPages={numPages}
//                           />
//                         )}
//                       </Grid.Column>
//                       <Grid.Column width={3}>
//                         <Button.Group>
//                           <Button
//                             icon
//                             onClick={this.handleAnteClockwisePageRotation}
//                           >
//                             <Icon name="share" flipped="horizontally" />
//                           </Button>
//                           <Button
//                             icon
//                             onClick={this.handleClockwisePageRotation}
//                           >
//                             <Icon name="share" />
//                           </Button>
//                         </Button.Group>
//                       </Grid.Column>
//                     </Grid.Row>
//                   </Grid>
//                 </div>
//               )}
//             </div>
//           ) : (
//             <div>
//               <a
//                 href={"https://agencypipeline.com" + url}
//                 onClick={this.handleShowPDF}
//               >
//                 <Popup
//                   trigger={<AttachmentIcon extension={extension} />}
//                   content={
//                     "Uploaded by " + uploadedBy.firstName + " on " + createdOn
//                   }
//                 />
//                 <AttachmentLinkMessage attachment={this.props.attachment} />
//               </a>
//             </div>
//           )}
//         </div>
//       </Segment>
//     );
//   }
// }
