import * as React from "react";

import {formatDistance, format} from "date-fns";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

const GET_USER_SETTINGS = loader(
  "../../Fragments/Contacts/UserSettingsQuery.graphql"
);

interface Group {
  id: string;
  name: string;
}

interface Profile {
  id: string;
  user: {
    isActive: boolean;
    firstName: string;
    lastName: string;
    groups: Group[];
    lastLogin: string;
  };
}

interface Settings {
  userSettings: {
    profile: {
      agency: {
        profileSet: Profile[];
      };
    };
  };
}

export function Users() {
  const {loading, error, data} = useQuery<Settings>(GET_USER_SETTINGS);

  React.useEffect(() => {
    document.title = `Agency Users `;
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  return (
    <div style={{backgroundColor: `rgb(248, 248, 248)`}}>
      <br />

      <div>
        <div>
          <div>
            <h1>Active Users</h1>
            <table>
              <tbody>
                {data?.userSettings.profile.agency.profileSet
                  .filter((profile) => profile.user.isActive)
                  .map((profile) => (
                    <tr key={profile.id}>
                      <td>
                        {profile.user.firstName} {profile.user.lastName}
                      </td>
                      <td>
                        <span className="date">
                          Last Login: {}
                          {formatDistance(
                            new Date(profile.user.lastLogin),
                            new Date()
                          )}{" "}
                          ago.{" "}
                        </span>
                      </td>
                      <td>
                        {profile.user.isActive ? (
                          <div color="violet">Active</div>
                        ) : (
                          <div color="grey">Inactive</div>
                        )}
                      </td>
                      <td>
                        Groups:
                        <br />
                        <div className="prose">
                          <ul>
                            {profile.user.groups.map((group) => (
                              <li key={group.id}>{group.name}</li>
                            ))}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <br />
            <br />
            Inactive users
            <br />
            <div>
              {data &&
                data.userSettings.profile.agency.profileSet
                  .filter((profile) => profile.user.isActive === false)
                  .map((profile) => (
                    <div key={profile.id}>
                      <div>
                        <div className="text-lg font-bold">
                          {profile.user.firstName} {profile.user.lastName}
                        </div>
                        <div>
                          Last Login: {}
                          {formatDistance(
                            new Date(profile.user.lastLogin),
                            new Date()
                          )}{" "}
                          ago.{" "}
                          {format(
                            new Date(profile.user.lastLogin),
                            "MMMM do yyyy, h:mm:ss a"
                          )}
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
