import * as React from "react";

import {fieldName} from "../UtilityComponents/FieldName";
import DirtyIndicator from "../Components/DirtyIndicator";
import FormLabel from "../Components/FormLabel";

type Props = {
  label: string;
  value: string;
  initialValue?: string;
  setValue: (newValue: string) => void;
};

export const MyNullBoolean = React.memo(function MyNullBoolean({
  label,
  value,
  initialValue,
  setValue,
}: Props) {
  return (
    <div className="w-full p-3">
      <div className="flex justify-between">
        <FormLabel label={label} htmlFor={fieldName(label)} />

        {value === initialValue ? null : <DirtyIndicator />}
      </div>
      <div className="flex mt-1">
        <button
          className={`${
            value === "U"
              ? "bg-coolGray-400 print:border print:border-coolGray-400 print:rounded-lg"
              : "bg-coolGray-200 hover:bg-coolGray-400"
          } flex-1 text-coolGray-800 py-2 px-4 rounded-l`}
          type="button"
          onClick={() => setValue("U")}
        >
          ?
        </button>
        <button
          className={`${
            value === "Y"
              ? "bg-coolGray-400 print:border print:border-coolGray-400 print:rounded-lg"
              : "bg-coolGray-200 hover:bg-coolGray-400"
          } flex-1 text-coolGray-800 py-2 px-4`}
          type="button"
          onClick={() => setValue("Y")}
        >
          Yes
        </button>
        <button
          className={`${
            value === "N"
              ? "bg-coolGray-400 print:border print:border-coolGray-400 print:rounded-lg"
              : "bg-coolGray-200 hover:bg-coolGray-400"
          } flex-1 text-coolGray-800 py-2 px-4 rounded-r`}
          type="button"
          onClick={() => setValue("N")}
        >
          No
        </button>
      </div>
    </div>
  );
});
