import * as React from "react";

import "../App.css";

import {useMutation, useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

import IdleTimer from "react-idle-timer";

import {RouteComponentProps} from "react-router-dom";

import {MyTextInputField} from "../FormComponents/MyTextInputField";
import {MySelect} from "../FormComponents/MySelect";
import {MyNullBoolean} from "../FormComponents/MyNullBoolean";
import {MyTextarea} from "../FormComponents/MyTextarea";

// import {Appointment} from "../Components/Medicare/Appointment";
import {AppointmentAdd} from "../Components/Medicare/AppointmentAdd";
import {AppointmentEdit} from "../Components/Medicare/AppointmentEdit";
// import {Prescription} from "../Components/Medicare/Prescription";
import {PrescriptionAdd} from "../Components/Medicare/PrescriptionAdd";
import {PrescriptionEdit} from "../Components/Medicare/PrescriptionEdit";
// import {Provider} from "../Components/Medicare/Provider";
import {ProviderAdd} from "../Components/Medicare/ProviderAdd";
import {ProviderEdit} from "../Components/Medicare/ProviderEdit";
// import {update} from "xstate/lib/actionTypes";

import {FormPanel} from "../Components/FormPanel";

const BENEFICIARY_QUERY = loader(
  "../Fragments/Medicare/BeneficiaryQuery.graphql"
);

const BENEFICIARY_UPDATE = loader(
  "../Fragments/Medicare/BeneficiaryUpdate.graphql"
);

const CLIENT_STATUS_CHOICES = [
  {key: "U", text: "Unknown", value: "U"},
  {key: "C", text: "Client", value: "C"},
  {key: "P", text: "Prospect", value: "P"},
  {key: "N", text: "Not Interested", value: "N"},
  {key: "X", text: "No Opportunity", value: "X"},
];

type BeneficiaryAppointment = {
  id: string;
  timestamp: number;
  notes: string;
  createdBy: {firstName: string; lastName: string};
};

type PrescriptionType = {
  id: string;
  drugName: string;
  dosage: string;
  quantityPerFill: string;
  refillFrequency: string;
  otherFrequency: string;
  notes: string;
};

type ProviderType = {
  id: string;
  name: string;
  typeOfDoctor: string;
  notes: string;
};

type Person = {
  firstName: string;
  lastName: string;
};

type BeneficiaryProfile = {
  id: string;
  individualMedicareConsumer: string;
  individualMedicareConsumerNotes: string;
  medicareClientStatus: string;
  clientStatusNotes: string;
  medicareNumber: string;
  partAEffective: string;
  partBEffective: string;
  anyPowerOfAttorney: string;
  powerOfAttorney: string;
  emergencyContactName: string;
  emergencyContactRelationship: string;
  emergencyContactPhone: string;
  spapEligible: string;
  spapNotes: string;
  extraHelpEligible: string;
  extraHelpNotes: string;
  anyOtherCoverage: string;
  otherCoverageNotes: string;
  priorPlans: string;
  preferredPharmacy: string;
  drugListId: string;
  primaryCareProvider: string;
  preferredHospital: string;
  notes: string;
  person: Person;
  prescriptionSet: PrescriptionType[];
  providerSet: ProviderType[];
  beneficiaryappointmentSet: BeneficiaryAppointment[];
};

type Props = {
  profile: BeneficiaryProfile;
};

function EditBeneficiary({profile}: Props) {
  const [individualMedicareConsumer, setIndividualMedicareConsumer] =
    React.useState(profile.individualMedicareConsumer);

  const [individualMedicareConsumerNotes, setIndividualMedicareConsumerNotes] =
    React.useState(profile.individualMedicareConsumerNotes);

  const [medicareClientStatus, setMedicareClientStatus] = React.useState(
    profile.medicareClientStatus
  );

  const [clientStatusNotes, setClientStatusNotes] = React.useState(
    profile.clientStatusNotes
  );

  const [medicareNumber, setMedicareNumber] = React.useState(
    profile.medicareNumber
  );

  const [partAEffective, setPartAEffective] = React.useState(
    profile.partAEffective
  );

  const [partBEffective, setPartBEffective] = React.useState(
    profile.partBEffective
  );

  const [anyPowerOfAttorney, setAnyPowerOfAttorney] = React.useState(
    profile.anyPowerOfAttorney
  );

  const [powerOfAttorney, setPowerOfAttorney] = React.useState(
    profile.powerOfAttorney
  );

  const [emergencyContactName, setEmergencyContactName] = React.useState(
    profile.emergencyContactName
  );

  const [emergencyContactRelationship, setEmergencyContactRelationship] =
    React.useState(profile.emergencyContactRelationship);

  const [emergencyContactPhone, setEmergencyContactPhone] = React.useState(
    profile.emergencyContactPhone
  );

  const [spapEligible, setSpapEligible] = React.useState(profile.spapEligible);

  const [spapNotes, setSpapNotes] = React.useState(profile.spapNotes);

  const [extraHelpEligible, setExtraHelpEligible] = React.useState(
    profile.extraHelpEligible
  );

  const [extraHelpNotes, setExtraHelpNotes] = React.useState(
    profile.extraHelpNotes
  );

  const [anyOtherCoverage, setAnyOtherCoverage] = React.useState(
    profile.anyOtherCoverage
  );

  const [otherCoverageNotes, setOtherCoverageNotes] = React.useState(
    profile.otherCoverageNotes
  );

  const [priorPlans, setPriorPlans] = React.useState(profile.priorPlans);

  const [preferredPharmacy, setPreferredPharmacy] = React.useState(
    profile.preferredPharmacy
  );

  const [drugListId] = React.useState(profile.drugListId);

  const [primaryCareProvider, setPrimaryCareProvider] = React.useState(
    profile.primaryCareProvider
  );

  const [preferredHospital, setPreferredHospital] = React.useState(
    profile.preferredHospital
  );

  const [notes, setNotes] = React.useState(profile.notes);

  const autoSave = true;
  // const [autoSave, setAutoSave] = React.useState(true);

  // function toggleAutoSave() {
  //   setAutoSave(autoSave === false ? true : false);
  // }

  const [
    updateBeneficiary,
    // {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    updateBeneficiary: BeneficiaryProfile[];
  }>(BENEFICIARY_UPDATE, {
    variables: {
      beneficiaryId: profile.id,
      individualMedicareConsumer: individualMedicareConsumer,
      individualMedicareConsumerNotes: individualMedicareConsumerNotes,
      medicareClientStatus: medicareClientStatus,
      clientStatusNotes: clientStatusNotes,
      medicareNumber: medicareNumber,
      partAEffective: partAEffective,
      partBEffective: partBEffective,
      anyPowerOfAttorney: anyPowerOfAttorney,
      powerOfAttorney: powerOfAttorney,
      emergencyContactName: emergencyContactName,
      emergencyContactRelationship: emergencyContactRelationship,
      emergencyContactPhone: emergencyContactPhone,
      spapEligible: spapEligible,
      spapNotes: spapNotes,
      extraHelpEligible: extraHelpEligible,
      extraHelpNotes: extraHelpNotes,
      anyOtherCoverage: anyOtherCoverage,
      otherCoverageNotes: otherCoverageNotes,
      priorPlans: priorPlans,
      preferredPharmacy: preferredPharmacy,
      drugListId: drugListId,
      primaryCareProvider: primaryCareProvider,
      preferredHospital: preferredHospital,
      notes: notes,
    },
    // onCompleted: () => {
    //   send("SAVED");
    // },
    // onError: () => {
    //   // alert(
    //   //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
    //   // );
    //   send("GRAPHQL_ERROR");
    // },
  });

  return (
    <main className="bg-gray-100">
      <div className="max-w-6xl p-8 mx-auto space-y-6">
        <FormPanel
          heading="Status"
          content={
            <div className="grid grid-cols-2 gap-6">
              <MyNullBoolean
                label={
                  "Is " +
                  profile.person.firstName +
                  " " +
                  profile.person.lastName +
                  " in Individual Medicare?"
                }
                value={individualMedicareConsumer}
                setValue={setIndividualMedicareConsumer}
                initialValue={profile.individualMedicareConsumer}
              />

              <MyTextarea
                label="Individual Medicare Consumer Notes"
                value={individualMedicareConsumerNotes}
                setValue={setIndividualMedicareConsumerNotes}
                // buttonIfBlank
                initialValue={profile.individualMedicareConsumerNotes}
              />

              <MySelect
                label="Medicare Client Status"
                value={medicareClientStatus}
                options={CLIENT_STATUS_CHOICES}
                setValue={setMedicareClientStatus}
                initialValue={profile.medicareClientStatus}
              />

              <MyTextarea
                label="Client Status Notes"
                value={clientStatusNotes}
                setValue={setClientStatusNotes}
                // buttonIfBlank
                initialValue={profile.clientStatusNotes}
              />
            </div>
          }
        />

        <FormPanel
          heading="Medicare Card"
          content={
            <div className="flex">
              <MyTextInputField
                label="Medicare Number"
                value={medicareNumber}
                setValue={setMedicareNumber}
                characterLimit={32}
                initialValue={profile.medicareNumber}
              />

              <MyTextInputField
                label="Part A Effective"
                value={partAEffective}
                setValue={setPartAEffective}
                characterLimit={12}
                initialValue={profile.partAEffective}
              />

              <MyTextInputField
                label="Part B Effective"
                value={partBEffective}
                setValue={setPartBEffective}
                characterLimit={12}
                initialValue={profile.partBEffective}
              />
            </div>
          }
        />

        <FormPanel
          heading="Contacts"
          content={
            <div className="grid grid-cols-3 gap-6">
              <MyTextInputField
                label="Emergency Contact"
                value={emergencyContactName}
                setValue={setEmergencyContactName}
                characterLimit={64}
                initialValue={profile.emergencyContactName}
              />

              <MyTextInputField
                label="Relationship"
                value={emergencyContactRelationship}
                setValue={setEmergencyContactRelationship}
                characterLimit={32}
                initialValue={profile.emergencyContactRelationship}
              />

              <MyTextInputField
                label="Phone"
                value={emergencyContactPhone}
                setValue={setEmergencyContactPhone}
                characterLimit={32}
                initialValue={profile.emergencyContactPhone}
              />

              <MyNullBoolean
                label="Does Anyone Hold a Power Of Attorney"
                value={anyPowerOfAttorney}
                setValue={setAnyPowerOfAttorney}
                initialValue={profile.anyPowerOfAttorney}
              />

              {anyPowerOfAttorney === "Y" ? (
                <MyTextInputField
                  label="Power of Attorney"
                  value={powerOfAttorney}
                  setValue={setPowerOfAttorney}
                  characterLimit={64}
                  initialValue={profile.powerOfAttorney}
                />
              ) : null}
            </div>
          }
        />

        <FormPanel
          heading="Providers"
          button={<ProviderAdd id={profile.id} />}
          content={
            <>
              <div className="grid grid-cols-3 gap-6">
                <MyTextInputField
                  label="Preferred Hospital"
                  value={preferredHospital}
                  setValue={setPreferredHospital}
                  characterLimit={128}
                  initialValue={profile.preferredHospital}
                />

                <MyTextInputField
                  label="Primary Care Provider"
                  value={primaryCareProvider}
                  setValue={setPrimaryCareProvider}
                  initialValue={profile.primaryCareProvider}
                  characterLimit={128}
                />

                {/* <span>notes will go here</span> */}
                <span />
              </div>
              <div className="grid grid-cols-1 gap-6">
                {profile.providerSet.map((provider) => (
                  <ProviderEdit
                    key={provider.id}
                    provider={provider}
                    beneficiaryId={profile.id}
                    autoSave={autoSave}
                  />
                ))}
              </div>
            </>
          }
        />

        <FormPanel
          heading="Prescriptions"
          button={<PrescriptionAdd id={profile.id} />}
          content={
            <>
              {" "}
              <div className="grid grid-cols-3 gap-6">
                <MyTextInputField
                  label="Preferred Pharmacy"
                  value={preferredPharmacy}
                  setValue={setPreferredPharmacy}
                  initialValue={profile.preferredPharmacy}
                  characterLimit={64}
                />
                <MyNullBoolean
                  label="EPIC Eligible"
                  value={spapEligible}
                  setValue={setSpapEligible}
                  initialValue={profile.spapEligible}
                />

                <MyTextInputField
                  label="EPIC Notes"
                  value={spapNotes}
                  setValue={setSpapNotes}
                  characterLimit={128}
                  initialValue={profile.spapNotes}
                />
              </div>
              <div className="grid grid-cols-1 gap-6">
                {profile.prescriptionSet.map((prescription) => (
                  <PrescriptionEdit
                    key={prescription.id}
                    beneficiaryId={profile.id}
                    prescription={prescription}
                    autoSave={autoSave}
                  />
                ))}
              </div>
            </>
          }
        />

        <FormPanel
          heading="Other"
          content={
            <div className="grid grid-cols-2 gap-6">
              <MyNullBoolean
                label="Eligible For Extra Help?"
                value={extraHelpEligible}
                setValue={setExtraHelpEligible}
                initialValue={profile.extraHelpEligible}
              />
              <MyTextInputField
                label="Extra Help Notes"
                value={extraHelpNotes}
                setValue={setExtraHelpNotes}
                characterLimit={128}
                initialValue={profile.extraHelpNotes}
              />

              <MyNullBoolean
                label="Any Other Coverage"
                value={anyOtherCoverage}
                setValue={setAnyOtherCoverage}
                initialValue={profile.anyOtherCoverage}
              />
              <MyTextInputField
                label="Other Coverage Notes"
                value={otherCoverageNotes}
                setValue={setOtherCoverageNotes}
                characterLimit={128}
                initialValue={profile.otherCoverageNotes}
              />

              <MyTextarea
                label="Prior Plans"
                value={priorPlans}
                setValue={setPriorPlans}
                initialValue={profile.priorPlans}
              />
            </div>
          }
        />

        <FormPanel
          heading="Notes"
          content={
            <MyTextarea
              label="Notes"
              value={notes}
              setValue={setNotes}
              initialValue={profile.notes}
            />
          }
        />

        <FormPanel
          heading="Appointments"
          button={<AppointmentAdd id={profile.id} />}
          content={
            <div className="px-4 py-5 bg-white sm:p-6">
              {profile.beneficiaryappointmentSet.map((appointment) => (
                <AppointmentEdit
                  key={appointment.id}
                  appointment={appointment}
                  autoSave={autoSave}
                  beneficiaryId={profile.id}
                />
              ))}
            </div>
          }
        />
      </div>

      <br />
      <form>
        <h2>
          {profile.person.firstName} {profile.person.lastName} - Medicare
          Profile
        </h2>

        <span className="print:hidden">
          {individualMedicareConsumer === profile.individualMedicareConsumer &&
          individualMedicareConsumerNotes ===
            profile.individualMedicareConsumerNotes &&
          medicareClientStatus === profile.medicareClientStatus &&
          clientStatusNotes === profile.clientStatusNotes &&
          medicareNumber === profile.medicareNumber &&
          partAEffective === profile.partAEffective &&
          partBEffective === profile.partBEffective &&
          anyPowerOfAttorney === profile.anyPowerOfAttorney &&
          powerOfAttorney === profile.powerOfAttorney &&
          emergencyContactName === profile.emergencyContactName &&
          emergencyContactRelationship ===
            profile.emergencyContactRelationship &&
          emergencyContactPhone === profile.emergencyContactPhone &&
          spapEligible === profile.spapEligible &&
          spapNotes === profile.spapNotes &&
          extraHelpEligible === profile.extraHelpEligible &&
          extraHelpNotes === profile.extraHelpNotes &&
          anyOtherCoverage === profile.anyOtherCoverage &&
          otherCoverageNotes === profile.otherCoverageNotes &&
          priorPlans === profile.priorPlans &&
          preferredPharmacy === profile.preferredPharmacy &&
          drugListId === profile.drugListId &&
          primaryCareProvider === profile.primaryCareProvider &&
          preferredHospital === profile.preferredHospital &&
          notes === profile.notes ? null : (
            <span>
              {autoSave ? (
                <IdleTimer
                  timeout={1000}
                  startOnMount={true}
                  onIdle={() => updateBeneficiary()}
                />
              ) : (
                <button type="button" onClick={() => updateBeneficiary()}>
                  Save Changes
                </button>
              )}
            </span>
          )}

          {/* Need to make these functions work */}
          {/* <button onClick={() => props.toggleEditing()} type="button">
            Stop Editing
          </button>
          <button onClick={() => props.printPage()} type="button">
            PRINT
          </button> */}
        </span>
      </form>
    </main>
  );
}

type BeneficiaryQueryResult = {
  beneficiaryProfile: BeneficiaryProfile;
};

type TParams = {beneficiaryId: string};

export function MedicareBeneficiary({match}: RouteComponentProps<TParams>) {
  React.useEffect(() => {
    document.title = `Medicare Beneficiary Profile`;
  });

  const {loading, error, data} = useQuery<BeneficiaryQueryResult>(
    BENEFICIARY_QUERY,
    {
      variables: {
        beneficiaryId: match.params.beneficiaryId,
      },
    }
  );

  if (loading) return <p>Loading ...</p>;

  if (error) return <p>Error!</p>;

  if (data) return <EditBeneficiary profile={data.beneficiaryProfile} />;

  return <p>No Data returned</p>;
}
