import * as React from "react";

// import TextareaAutosize from "react-textarea-autosize";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {isValid} from "date-fns";

import {MyTextarea} from "../FormComponents/MyTextarea";
import {MyDateField} from "../FormComponents/MyDateField";

const ADD_REMINDER = loader("../Fragments/reminders/ReminderAdd.graphql");

const REMINDER_SET = loader("../Fragments/reminders/ReminderSet.graphql");

type Reminder = {
  id: string;
  message: string;
  remindOn: string;
};

type ReminderSet = {
  reminderSet: Reminder[];
};

type Props = {
  clientId: string;
  stopAdding: () => void;
};

export function AddReminder({clientId, stopAdding}: Props) {
  const [reminderContent, setReminderContent] = React.useState("");
  const [remindOn, setRemindOn] = React.useState<string | null>("");
  const [formIsValid, setFormIsValid] = React.useState(false);

  React.useEffect(() => {
    if (remindOn && remindOn.length === 10 && isValid(new Date(remindOn))) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [remindOn, formIsValid]);

  function handleDiscard() {
    if (reminderContent === "") {
      stopAdding();
    } else {
      let confirmDiscardMessage =
        "Are you sure you want to discard the reminder?\n\nPress OK to discard or cancel to continue editing.";
      if (window.confirm(confirmDiscardMessage)) {
        setReminderContent("");

        stopAdding();
      }
    }
  }

  const [reminderAdd] = useMutation<{
    reminderAdd: Reminder;
  }>(ADD_REMINDER, {
    variables: {
      clientId: clientId,
      message: reminderContent.trim(),
      remindOn: remindOn,
    },
    update(cache, {data}) {
      if (!data || !data.reminderAdd) return;

      let reminders = cache.readFragment<ReminderSet>({
        id: "ClientObjectType:" + clientId,
        fragment: REMINDER_SET,
        fragmentName: "ReminderSet",
      });

      let newreminders = {};
      if (reminders) {
        newreminders = reminders.reminderSet.concat(data.reminderAdd);
      } else {
        newreminders = [data.reminderAdd];
      }

      cache.writeFragment({
        id: "ClientObjectType:" + clientId,
        fragment: REMINDER_SET,
        fragmentName: "ReminderSet",
        data: {reminderSet: newreminders, __typename: "ClientObjectType"},
      });
    },
  });

  return (
    <div className="my-3 bg-white rounded-lg shadow">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
          <div className="mt-2 ml-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Reminders
            </h3>
          </div>
          <div className="flex-shrink-0 mt-2 ml-4">
            <button
              type="button"
              disabled
              className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm opacity-50 bg-coolGray-600"
            >
              Create a reminder
            </button>
          </div>
        </div>
      </div>
      <div className="p-4 bg-white rounded shadow">
        <div>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              if (formIsValid) {
                reminderAdd().then(() => {
                  setReminderContent("");
                  setRemindOn("");

                  stopAdding();
                });
              }
            }}
          >
            <MyTextarea
              label="Reminder Message (Make this descriptive so future you knows what should happen)"
              value={reminderContent}
              initialValue={""}
              setValue={setReminderContent}
              autoFocusIfBlank
              informUnsavedChanges={false}
            />

            <MyDateField
              label="Date to Remind (YYYY-MM-DD)"
              value={remindOn}
              setValue={setRemindOn}
              initialValue={"2021-01-01"}
              informUnsavedChanges={false}
            />

            {formIsValid ? (
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={handleDiscard}
                    className="px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <button type="button" onClick={handleDiscard}>
                Cancel
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
