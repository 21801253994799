import * as React from "react";

type User = {
  id: string;
  firstName: string;
  lastName: string;
};

type Props = {
  label: string;
  users: User[];
  value: {
    id: string;
    firstName: string;
    lastName: string;
  };
  setValue: (newValue: {
    id: string;
    firstName: string;
    lastName: string;
  }) => void;
};

export function UserSelector({label, users, value, setValue}: Props) {
  const [selectorState, setSelectorState] = React.useState("closed");

  return (
    <div className="space-y-1">
      <label
        id="listbox-label"
        className="block text-sm font-medium leading-5 text-coolGray-700"
      >
        {label}
      </label>
      <div className="relative">
        <span className="inline-block w-full rounded-md shadow-sm">
          <button
            type="button"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            onClick={() => setSelectorState("open")}
            className="relative w-full py-2 pl-3 pr-10 text-left transition duration-150 ease-in-out bg-white border rounded-md cursor-default border-coolGray-300 focus:outline-none focus:ring-blue focus:border-blue-300 sm:text-sm sm:leading-5"
          >
            <span className="block truncate">
              {value.firstName} {value.lastName}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <svg
                className="w-5 h-5 text-coolGray-400"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
              >
                <path
                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </span>

        {/* <!-- Select popover, show/hide based on select state. --> */}
        {selectorState === "open" && (
          <div className="absolute w-full mt-1 bg-white rounded-md shadow-lg">
            <ul
              tabIndex={1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant={`listbox-item-${value.id}`}
              className="py-1 overflow-auto text-base leading-6 rounded-md ring-1 ring-black ring-opacity-5 max-h-60 focus:outline-none sm:text-sm sm:leading-5"
            >
              {/* <!--
          Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

          Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-coolGray-900"
        --> */}
              {users.map((user) => (
                <li
                  key={user.id}
                  id={`listbox-item-${user.id}`}
                  role="option"
                  aria-selected={value.id === user.id}
                  className="relative py-2 pl-8 pr-4 cursor-default select-none text-coolGray-900"
                  onClick={() => {
                    setValue(user);
                    setSelectorState("closed");
                  }}
                >
                  {/* <!-- Selected: "font-semibold", Not Selected: "font-normal" --> */}
                  <span
                    className={`${
                      value.id === user.id ? "font-semibold" : "font-normal"
                    } block truncate`}
                  >
                    {user.firstName} {user.lastName}
                  </span>

                  {/* <!--
            Checkmark, only display for selected option.

            Highlighted: "text-white", Not Highlighted: "text-indigo-600"
          --> */}
                  {value.id === user.id && (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
                      <svg
                        className="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                </li>
              ))}

              {/* <li
              id="listbox-item-0"
              role="option"
              className="relative py-2 pl-8 pr-4 cursor-default select-none text-coolGray-900"
            >
              <span className="block font-normal truncate">Wade Cooper</span>

              <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </li> */}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
