import * as React from "react";

import {AddClaimButton} from "./AddClaim";
import {EditClaim} from "./EditClaim";

type Claim = {
  id: string;
  date: string;
  lossType: string;
  payout: number;
  claimNotes: string;
};

type Props = {
  autoSave: boolean;
  claims: Claim[];
  locationId: string;
};

export default function Claims({locationId, claims, autoSave}: Props) {
  return (
    <div className="flex p-8 m-8 border-t border-coolGray-400">
      <div className="w-1/3">
        <h3 className="text-lg">
          Claims {Object.values(claims).length === 0 ? " (None)" : null}
        </h3>

        <AddClaimButton id={locationId} />
      </div>

      {Object.values(claims).length > 0 && (
        <div className="w-2/3 p-8 bg-white rounded-lg shadow-lg">
          <div className="grid grid-cols-1 gap-4 divide-y divide-coolGray-300">
            {claims.map((claim) => (
              <EditClaim
                key={claim.id}
                locationId={locationId}
                claim={claim}
                autoSave={autoSave}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
