import * as React from "react";

import {Link} from "react-router-dom";

import {isFuture} from "date-fns";

type PendingCancellation = {
  id: string;
  policy: {
    policyNumber: string;
    client: {
      id: string;
      name: string;
      attn: string;
    };
    company: {
      carrier: {
        name: string;
      };
    };
  };
  quoteClosed: boolean;
  actionDate: string;
  description: string;
};

type Props = {
  index: number;
  cancellation: PendingCancellation;
};

export function Nonrenewal({index, cancellation}: Props) {
  return (
    <li className={index > 0 ? "mt-3" : undefined}>
      <div className="block p-5 bg-white rounded-lg shadow">
        <div className="flex justify-between">
          <div className="text-sm font-medium leading-snug text-coolGray-900">
            Coverage{" "}
            {isFuture(new Date(cancellation.actionDate)) ? (
              "will expire"
            ) : (
              <strong>Expired</strong>
            )}{" "}
            on {cancellation.actionDate}.<br />
            <br />
            {cancellation.description && "Reason: " + cancellation.description}
            <br />
            to prevent the {cancellation.policy.company.carrier.name} policy (#
            {cancellation.policy.policyNumber}) for{" "}
            <Link to={"/client/" + cancellation.policy.client.id}>
              {cancellation.policy.client.name}
            </Link>{" "}
            from lapsing. <br />
            <br />
            {/* {cancellation.quoteClosed ? (
                <ToggleReminderContactedButton
                  flexiblepolicyactivity={cancellation}
                >
                  <Icon name="left arrow" />
                  CLOSE
                </ToggleReminderContactedButton>
              ) : (
                <ToggleReminderContactedButton
                  flexiblepolicyactivity={cancellation}
                >
                  mark as contacted
                  <Icon name="right arrow" />
                </ToggleReminderContactedButton>
              )} */}
          </div>
        </div>
      </div>
    </li>
  );
}
