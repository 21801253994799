type Props = {
  extension: string;
};

export const AttachmentLinkMessage = ({extension}: Props) => {
  return (
    <>
      {(() => {
        switch (extension) {
          case ".pdf":
            return "View PDF";
          case ".doc":
            return "Download Word Document";
          case ".docx":
            return "Download Word Document";
          case ".jpg":
            return "View Photograph";
          case ".jpeg":
            return "View Photograph";
          case ".png":
            return "View Image";
          case ".gif":
            return "View Image";
          case ".mp3":
            return "Play Audio";
          case ".wav":
            return "Play Audio";
          case ".mp4":
            return "Play Video";
          case ".txt":
            return "Show Text Document";
          case ".xls":
            return "Download Spreadsheet";
          case ".xlsx":
            return "Download Spreadsheet";
          case ".csv":
            return "Download Spreadsheet";
          case ".zip":
            return "Download ZIP folder";
          default:
            return "Unknown File Type";
        }
      })()}
    </>
  );
};
