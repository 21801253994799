import * as React from "react";
import {backendUrlHostname} from "../urls";

import {SidebarItem} from "./SidebarItem";

type Props = {
  activeItem: string;
};

export function SidebarItems({activeItem}: Props) {
  return (
    <>
      <SidebarItem
        title="To Do"
        active={activeItem === "todo"}
        linkTo={"/todo"}
      />
      <SidebarItem
        title="Work in Progress"
        active={activeItem === "workInProgress"}
        // linkTo={"/reports"}
        href={backendUrlHostname + "/work-in-progress"}
      />
      <SidebarItem
        title="Reminders"
        active={activeItem === "reminders"}
        // linkTo={"/reports"}
        href={backendUrlHostname + "/reminders"}
      />

      <SidebarItem
        title="Reports"
        active={activeItem === "reports"}
        // linkTo={"/reports"}
        href={backendUrlHostname + "/reports/"}
      />
      <SidebarItem
        title="Activities"
        active={activeItem === "activities"}
        linkTo={"/activities"}
      />
      <SidebarItem
        title="Quotes"
        active={activeItem === "quotes"}
        linkTo={"/quotes"}
      />
      <SidebarItem
        title="Cancellations"
        active={activeItem === "cancellations"}
        href={backendUrlHostname + "/cancellations"}
      />
      <SidebarItem
        title="Nonpays"
        active={activeItem === "nonpays"}
        linkTo={"/nonpays"}
      />
      <SidebarItem
        title="Birthdays"
        active={activeItem === "birthdays"}
        href={backendUrlHostname + "/birthdays"}
      />
      <SidebarItem
        title="Downloads"
        active={activeItem === "downloads"}
        linkTo={"/downloads"}
      />
      <SidebarItem
        title="Forms"
        active={activeItem === "forms"}
        linkTo={"/forms"}
      />
    </>
  );
}
