import * as React from "react";

import {TodoItem} from "../Todo/TodoItem";

import {AddTodoItem} from "../Todo/TodoItemAdd";
import {TodoListEdit} from "../Todo/TodoListEdit";
import {CompleteList} from "../Todo/CompleteList";

type TodoItemType = {
  id: string;
  description: string;
  completed: boolean;
};

type TodoListType = {
  id: string;
  name: string;
  unclearedTodos: TodoItemType[];
};

type Props = {
  todolist: TodoListType;
};

export function TodoList({todolist}: Props) {
  const [editing, setEditing] = React.useState(false);

  function toggleEditing() {
    setEditing(editing === false ? true : false);
  }

  return (
    <div
      key={todolist.id}
      className="max-w-lg p-8 mb-6 bg-white rounded-lg shadow-lg print:pt-6 print:shadow-none"
    >
      {editing ? (
        <TodoListEdit todolist={todolist} setEditing={setEditing} />
      ) : (
        <div className="flex">
          <div className="flex-1 text-2xl">{todolist.name}</div>

          <div className="print:hidden">
            <button
              onClick={toggleEditing}
              type="button"
              className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Edit
            </button>
          </div>
        </div>
      )}

      <ul className="print:pl-4">
        {todolist.unclearedTodos.map((todoitem) => (
          <TodoItem key={todoitem.id} todoitem={todoitem} />
        ))}
      </ul>

      <div className="mt-6 print:hidden">
        <AddTodoItem todolistId={todolist.id} />

        {Object.keys(todolist.unclearedTodos).length === 0 ? (
          <div>
            <div className="my-4 text-center">Or</div>
            <CompleteList todolistId={todolist.id} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
