import * as React from "react";

import {loader} from "graphql.macro";

import {useMutation} from "@apollo/client";

import {MyTextInputField} from "./MyTextInputField";

const UPDATE_AGENCY_SETTINGS = loader(
  "../Fragments/Contacts/AgencySettingsUpdate.graphql"
);

type Agency = {
  id: string;
  name: string;
  address: string;
  address1: string;
  address2: string;
  phone: string;
  fax: string;
};

type Props = {
  agency: Agency;
};

export function SettingsAgency({agency}: Props) {
  const [agencyName, setAgencyName] = React.useState(agency.name);
  const [agencyAddress, setAgencyAddress] = React.useState(agency.address);
  const [agencyAddress1, setAgencyAddress1] = React.useState(agency.address1);
  const [agencyAddress2, setAgencyAddress2] = React.useState(agency.address2);
  const [agencyPhone, setAgencyPhone] = React.useState(agency.phone);
  const [agencyFax, setAgencyFax] = React.useState(agency.fax);

  const [
    agencySettingsUpdate,
    {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    agencySettingsUpdate: Agency;
  }>(UPDATE_AGENCY_SETTINGS, {
    variables: {
      // userId: userSettings.id,

      agencyName: agencyName,
      agencyAddress: agencyAddress,
    },
    onCompleted: () => {
      // send("SAVED");
      console.log("saved");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      // send("GRAPHQL_ERROR");
      console.log("error" + mutationError);
    },
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        agencySettingsUpdate();
      }}
      style={{fontSize: `150%`}}
    >
      <h1>Agency</h1>
      <hr />
      <h2>
        Be really cautious if making changes here. It will likely break things
        if the name changes.
      </h2>
      <div>
        <div>
          <div>
            <MyTextInputField
              label="Agency Name"
              value={agencyName}
              setValue={setAgencyName}
              initialValue={agency.name}
              characterLimit={32}
            />

            <MyTextInputField
              label="Agency Address (On one line)"
              value={agencyAddress}
              setValue={setAgencyAddress}
              initialValue={agency.address}
              characterLimit={50}
            />

            <MyTextInputField
              label="Agency Address - Line One"
              value={agencyAddress1}
              setValue={setAgencyAddress1}
              initialValue={agency.address1}
              characterLimit={50}
            />

            <MyTextInputField
              label="Agency Address - Line 2"
              value={agencyAddress2}
              setValue={setAgencyAddress2}
              initialValue={agency.address2}
              characterLimit={50}
            />

            <MyTextInputField
              label="Phone"
              value={agencyPhone}
              setValue={setAgencyPhone}
              initialValue={agency.phone}
              characterLimit={32}
            />

            <MyTextInputField
              label="Fax"
              value={agencyFax}
              setValue={setAgencyFax}
              initialValue={agency.fax}
              characterLimit={32}
            />

            {/* AWS Bucket:{" "}
                {agency.awsDocumentsBucketName} */}
          </div>
        </div>
      </div>

      <button type="submit">Save</button>
    </form>
  );
}
