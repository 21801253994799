import * as React from "react";

import {gql, useMutation} from "@apollo/client";

import {MyTextInputField} from "../../FormComponents/MyTextInputField";

import {FormTagDelete} from "./FormTagDelete";

const UPDATE_FORM_TAG = gql`
  mutation updateFormTag($id: String!, $name: String!) {
    updateFormTag(id: $id, name: $name) {
      formtag {
        id
        name
      }
    }
  }
`;

type FormTagType = {
  id: string;
  name: string;
};

type Props = {
  formtag: FormTagType;
  formId: string;
};

export function FormTag({formtag, formId}: Props) {
  const [name, setName] = React.useState(formtag.name);

  const [saveFormTagChanges] = useMutation<{
    saveFormTagChanges: FormTagType;
  }>(UPDATE_FORM_TAG);

  return (
    <li>
      <div className="flex">
        <div className="flex-1">
          <form
            onSubmit={(event) => {
              saveFormTagChanges({variables: {id: formtag.id, name}});
              event.preventDefault();
            }}
          >
            <MyTextInputField
              value={name}
              label=""
              placeholder=""
              setValue={setName}
              characterLimit={128}
              initialValue={formtag.name}
              autoFocus={formtag.name === ""}
            />
          </form>
        </div>

        {name === "" ? (
          <FormTagDelete formId={formId} formtag={formtag} immaculate={true} />
        ) : (
          <FormTagDelete formId={formId} formtag={formtag} />
        )}
      </div>
    </li>
  );
}
