import * as React from "react";
import {FormTag} from "./FormTag";
import {FormTagAddButton} from "./FormTagAddButton";

type Brand = {
  name: string;
  logoUrl: string;
};

type FormTagType = {
  id: string;
  name: string;
};

type FormType = {
  id: string;
  number: string;
  name: string;
  edition: string;
  url: string;
  brand: Brand;
  formtagSet: FormTagType[];
};

type Props = {
  form: FormType;
  brandFilter?: string;
  editing?: boolean;
};

export const Form = ({form, brandFilter, editing = false}: Props) => {
  return (
    <>
      <tr className={"bg-white"}>
        {brandFilter === "All" ? (
          <td className="w-40 px-3 py-2">
            <img
              src={form.brand.logoUrl}
              alt="form.brand.name"
              className="w-32"
            />
          </td>
        ) : null}
        <td className="px-6 py-2 leading-5 whitespace-no-wrap text-coolGray-700">
          <span className="text-base font-semibold text-center">
            {form.number}
          </span>
        </td>
        <td className="px-6 py-2 leading-5">
          <a
            href={form.url}
            target="_blank"
            rel="noopener noreferrer"
            className="p-1 text-base text-blue-600 underline"
          >
            {form.name}
          </a>
        </td>
        <td className="px-6 py-2 text-base leading-5 whitespace-no-wrap text-coolGray-700">
          {form.edition}
        </td>
      </tr>
      {editing && (
        <tr>
          <td colSpan={3} className="px-6 py-2">
            Search Terms:
            <ul className="mb-16 divide-y divide-coolGray-300 divide-dashed">
              {form.formtagSet.map((tag) => (
                <FormTag formtag={tag} key={tag.id} formId={form.id} />
              ))}
              <li key="add">
                <FormTagAddButton id={form.id} />
              </li>
            </ul>
          </td>
        </tr>
      )}
    </>
  );
};
