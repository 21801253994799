import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import IdleTimer from "react-idle-timer";

import Instance from "../../../Context/Instance";

import {MyIntegerField} from "../../../FormComponents/MyIntegerField";
import {MyNullBoolean} from "../../../FormComponents/MyNullBoolean";
import {MySelect} from "../../../FormComponents/MySelect";
import {MyTextarea} from "../../../FormComponents/MyTextarea";
import {MyTextInputField} from "../../../FormComponents/MyTextInputField";
import {MyYearField} from "../../../FormComponents/MyYearField";

const UPDATE_DWELLING = loader(
  "../../../Fragments/Underwriting/DwellingUpdate.graphql"
);

type SaveDwellingProps = {
  dirty: boolean;
  autoSave: boolean;
};

function SaveDwellingButton({autoSave, dirty}: SaveDwellingProps) {
  if (autoSave && dirty) {
    return <span>Saving Changes</span>;
  }

  if (dirty) {
    return <button type="submit">Save Changes</button>;
  }

  if (!autoSave) {
    return <button disabled>No Changes to Save</button>;
  }

  return null;
}

const UNIT_COUNT_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "L", text: "Single-Family", value: "L"},
  {key: "N", text: "Two-Family", value: "N"},
  {key: "M", text: "Three-Family", value: "M"},
  {key: "R", text: "Four-Family", value: "R"},
];

const OCCUPANCY_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "O", text: "Owner-Occupied Primary", value: "O"},
  {key: "S", text: "Owner-Occupied Secondary / Seasonal", value: "S"},
  {key: "T", text: "Tenant-Occupied", value: "T"},
  {key: "V", text: "Vacant", value: "V"},
];

const CONSTRUCTION_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "A", text: "Apartment", value: "A"},
  {key: "C", text: "Concrete", value: "C"},
  {key: "N", text: "Condominium", value: "N"},
  {key: "F", text: "Frame", value: "F"},
  {key: "L", text: "Log Home", value: "L"},
  {key: "M", text: "Masonry or Brick", value: "M"},
  {key: "D", text: "Mobilehome (Doublewide)", value: "D"},
  {key: "S", text: "Mobilehome (Singlewide)", value: "S"},
  {key: "T", text: "Townhouse", value: "T"},
  {key: "O", text: "Other", value: "O"},
];

const HOME_STYLE_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "A", text: "1 Story", value: "A"},
  {key: "B", text: "1.5 Story", value: "B"},
  {key: "C", text: "2 Story", value: "C"},
  {key: "D", text: "2.5 Story", value: "D"},
  {key: "E", text: "3 Story", value: "E"},
  {key: "F", text: "Other", value: "F"},
];

const FOUNDATION_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "B", text: "Block", value: "B"},
  {key: "C", text: "Concrete", value: "C"},
  {key: "S", text: "Stone", value: "S"},
  {key: "O", text: "Other", value: "O"},
];

const BASEMENT_CHOICES = [
  {key: "X", text: "", value: "X"},
  {key: "N", text: "None", value: "N"},
  {key: "P", text: "Partial Basement", value: "P"},
  {key: "U", text: "Unfinished Basement", value: "U"},
  {key: "S", text: "Partially Finished Basement", value: "S"},
  {key: "F", text: "Finished Basement", value: "F"},
  {key: "O", text: "Other", value: "O"},
];

const EXTERIOR_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "V", text: "Vinyl", value: "V"},
  {key: "W", text: "Wood", value: "W"},
  {key: "B", text: "Brick", value: "B"},
  {key: "L", text: "Logs", value: "L"},
  {key: "S", text: "Stucco", value: "S"},
  {key: "A", text: "Aluminum", value: "A"},
  {key: "E", text: "Asbestos", value: "E"},
  {key: "O", text: "Other", value: "O"},
];

const GARAGE_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "N", text: "None", value: "N"},
  {key: "A", text: "1 Car", value: "A"},
  {key: "B", text: "1.5 Car", value: "B"},
  {key: "C", text: "2 Car", value: "C"},
  {key: "D", text: "2.5 Car", value: "D"},
  {key: "E", text: "3 Car", value: "E"},
  {key: "O", text: "Other", value: "O"},
];

const GENERATOR_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "N", text: "None", value: "N"},
  {key: "P", text: "Portable", value: "P"},
  {key: "H", text: "Hardwired", value: "H"},
];

const WIRING_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "C", text: "Copper", value: "C"},
  {key: "A", text: "Aluminum", value: "A"},
  {key: "K", text: "Knob & Tube", value: "K"},
  {key: "O", text: "Other", value: "O"},
];

const SOLID_FUEL_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "N", text: "None", value: "N"},
  {key: "W", text: "Wood", value: "W"},
  {key: "C", text: "Coal", value: "C"},
  {key: "P", text: "Pellet", value: "P"},
  {key: "O", text: "Other", value: "O"},
];

const ROOF_MATERIAL_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "S", text: "Asphalt Shingle", value: "S"},
  {key: "R", text: "Architectural Shingle", value: "R"},
  {key: "M", text: "Metal", value: "M"},
  {key: "O", text: "Other", value: "O"},
];

const ROOF_STYLE_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "G", text: "Gable", value: "G"},
  {key: "H", text: "Hip", value: "H"},
  {key: "F", text: "Flat", value: "F"},
  {key: "O", text: "Other", value: "O"},
];

const HEATING_STYLE_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "F", text: "Forced Air", value: "F"},
  {key: "R", text: "Radiant or Radiator", value: "R"},
  {key: "O", text: "Other", value: "O"},
];

const HEATING_TYPE_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "N", text: "Natural Gas", value: "N"},
  {key: "P", text: "Propane", value: "P"},
  {key: "E", text: "Electric", value: "E"},
  {key: "F", text: "Fuel Oil", value: "F"},
  {key: "G", text: "Geothermal", value: "G"},
  {key: "O", text: "Other", value: "O"},
];

const WATER_SOURCE_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "P", text: "Public Water", value: "P"},
  {key: "W", text: "Well", value: "W"},
  {key: "N", text: "No Running Water", value: "N"},
  {key: "O", text: "Other", value: "O"},
];

const WASTEWATER_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "P", text: "Public Sewer", value: "P"},
  {key: "S", text: "Septic", value: "S"},
  {key: "N", text: "No Indoor Plumbing", value: "N"},
  {key: "O", text: "Other", value: "O"},
];

const BURGLAR_ALARM_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "N", text: "None", value: "N"},
  {key: "L", text: "Local Alarm", value: "L"},
  {key: "C", text: "Central Station Alarm", value: "C"},
];

const TRAMPOLINE_CHOICES = [
  {key: "X", text: "", value: "X"},
  {key: "N", text: "None", value: "N"},
  {key: "Y", text: "Yes, With Net", value: "Y"},
  {key: "U", text: "Yes, No Net", value: "U"},
];

const POOL_CHOICES = [
  {key: "U", text: "", value: "U"},
  {key: "N", text: "None", value: "N"},
  {key: "A", text: "Above Ground", value: "A"},
  {key: "I", text: "In Ground", value: "I"},
];

type Dwelling = {
  id: string;
  unitCount: string;
  unitsNotes: string;
  occupancy: string;
  occupancyNotes: string;
  constructionType: string;
  constructionNotes: string;
  yearBuilt: number | null;
  squareFeet: number | null;
  homeStyle: string;
  homeStyleNotes: string;
  bedrooms: number | null;
  fullBaths: number | null;
  halfBaths: number | null;
  foundation: string;
  foundationNotes: string;
  piers: string;
  piersNotes: string;
  basement: string;
  basementNotes: string;
  walkoutBasement: string;
  sumpPump: string;
  attachedGarage: string;
  attachedGarageNotes: string;
  exterior: string;
  exteriorNotes: string;
  decksAndPorches: string;
  specialFeatures: string;
  roofYear: number | null;
  roofMaterial: string;
  roofStyle: string;
  roofNotes: string;
  heatingYear: number | null;
  heatingStyle: string;
  heatingType: string;
  gasFireplaces: number | null;
  woodFireplaces: number | null;
  solidFuelBurner: string;
  heatingNotes: string;
  centralAir: string;
  undergroundOilTank: string;
  undergroundOilTankNotes: string;
  plumbingYear: number | null;
  plumbingYearNotes: string;
  newPipes: string;
  waterSource: string;
  waterSourceNotes: string;
  wastewater: string;
  wastewaterNotes: string;
  circuitBreakers: string;
  electricYear: number | null;
  electricYearNotes: string;
  numberOfAmps: string;
  smokeAndCODetectors: string;
  generator: string;
  wiring: string;
  solarPanels: string;
  solarPanelsNotes: string;
  fuses: string;
  fusesNotes: string;
  nonsmokerDiscount: string;
  fireExtinguisher: string;
  deadboltLocks: string;
  inHomeSprinklerSystem: string;
  waterAlarm: string;
  lowTemperatureAlarm: string;
  burglarAlarm: string;
  burglarAlarmNotes: string;
  exteriorDamage: string;
  exteriorDamageNotes: string;
  everRented: string;
  everRentedNotes: string;
  missingHandrails: string;
  missingHandrailsNotes: string;
  homeBasedBusiness: string;
  homeBasedBusinessNotes: string;
  farming: string;
  farmingNotes: string;
  homeConcealedFromNeighbors: string;
  homeConcealedFromNeighborsNotes: string;
  priorBankruptcy: string;
  priorBankruptcyNotes: string;
  homeForSale: string;
  homeForSaleNotes: string;
  trampoline: string;
  pool: string;
  poolNotes: string;
  fenced: string;
  lockingGate: string;
  divingBoard: string;
  slide: string;
  cancelledOrNonrenewed: string;
  residenceEmployees: string;
  residenceEmployeesNotes: string;
  dayCare: string;
  dayCareNotes: string;
  underConstruction: string;
  underConstructionNotes: string;
  generalNotes: string;
  privateNotes: string;
};

type Props = {
  dwelling: Dwelling;
  autoSave: boolean;
};

export function EditDwelling({dwelling, autoSave}: Props) {
  const [unitCount, setUnitCount] = React.useState(dwelling.unitCount);
  const [unitsNotes, setUnitsNotes] = React.useState(dwelling.unitsNotes);
  const [unitsNotesVisibility, setUnitsNotesVisibility] = React.useState(
    dwelling.unitsNotes === "" ? false : true
  );
  const [occupancy, setOccupancy] = React.useState(dwelling.occupancy);
  const [occupancyNotes, setOccupancyNotes] = React.useState(
    dwelling.occupancyNotes
  );
  const [
    occupancyNotesVisibility,
    setOccupancyNotesVisibility,
  ] = React.useState(dwelling.occupancyNotes === "" ? false : true);
  const [constructionType, setConstructionType] = React.useState(
    dwelling.constructionType
  );
  const [constructionNotes, setConstructionNotes] = React.useState(
    dwelling.constructionNotes
  );
  const [
    constructionNotesVisibility,
    setConstructionNotesVisibility,
  ] = React.useState(dwelling.constructionNotes === "" ? false : true);
  const [yearBuilt, setYearBuilt] = React.useState(dwelling.yearBuilt);
  const [squareFeet, setSquareFeet] = React.useState(dwelling.squareFeet);
  const [homeStyle, setHomeStyle] = React.useState(dwelling.homeStyle);
  const [homeStyleNotes, setHomeStyleNotes] = React.useState(
    dwelling.homeStyleNotes
  );
  const [
    homeStyleNotesVisibility,
    setHomeStyleNotesVisibility,
  ] = React.useState(dwelling.homeStyleNotes === "" ? false : true);
  const [bedrooms, setBedrooms] = React.useState(dwelling.bedrooms);
  const [fullBaths, setFullBaths] = React.useState(dwelling.fullBaths);
  const [halfBaths, setHalfBaths] = React.useState(dwelling.halfBaths);
  const [foundation, setFoundation] = React.useState(dwelling.foundation);
  const [foundationNotes, setFoundationNotes] = React.useState(
    dwelling.foundationNotes
  );
  const [
    foundationNotesVisibility,
    setFoundationNotesVisibility,
  ] = React.useState(dwelling.foundationNotes === "" ? false : true);
  const [piers, setPiers] = React.useState(dwelling.piers);
  const [piersNotes, setPiersNotes] = React.useState(dwelling.piersNotes);
  const [basement, setBasement] = React.useState(dwelling.basement);
  const [basementNotes, setBasementNotes] = React.useState(
    dwelling.basementNotes
  );
  const [walkoutBasement, setWalkoutBasement] = React.useState(
    dwelling.walkoutBasement
  );
  const [sumpPump, setSumpPump] = React.useState(dwelling.sumpPump);
  const [attachedGarage, setAttachedGarage] = React.useState(
    dwelling.attachedGarage
  );
  const [attachedGarageNotes, setAttachedGarageNotes] = React.useState(
    dwelling.attachedGarageNotes
  );
  const [
    attachedGarageNotesVisibility,
    setAttachedGarageNotesVisibility,
  ] = React.useState(dwelling.attachedGarageNotes === "" ? false : true);
  const [exterior, setExterior] = React.useState(dwelling.exterior);
  const [exteriorNotes, setExteriorNotes] = React.useState(
    dwelling.exteriorNotes
  );
  const [exteriorNotesVisibility, setExteriorNotesVisibility] = React.useState(
    dwelling.exteriorNotes === "" ? false : true
  );
  const [decksAndPorches, setDecksAndPorches] = React.useState(
    dwelling.decksAndPorches
  );
  const [specialFeatures, setSpecialFeatures] = React.useState(
    dwelling.specialFeatures
  );
  const [roofYear, setRoofYear] = React.useState(dwelling.roofYear);
  const [roofMaterial, setRoofMaterial] = React.useState(dwelling.roofMaterial);
  const [roofStyle, setRoofStyle] = React.useState(dwelling.roofStyle);
  const [roofNotes, setRoofNotes] = React.useState(dwelling.roofNotes);
  const [heatingYear, setHeatingYear] = React.useState(dwelling.heatingYear);
  const [heatingStyle, setHeatingStyle] = React.useState(dwelling.heatingStyle);
  const [heatingType, setHeatingType] = React.useState(dwelling.heatingType);
  const [gasFireplaces, setGasFireplaces] = React.useState(
    dwelling.gasFireplaces
  );
  const [woodFireplaces, setWoodFireplaces] = React.useState(
    dwelling.woodFireplaces
  );
  const [solidFuelBurner, setSolidFuelBurner] = React.useState(
    dwelling.solidFuelBurner
  );
  const [heatingNotes, setHeatingNotes] = React.useState(dwelling.heatingNotes);
  const [centralAir, setCentralAir] = React.useState(dwelling.centralAir);
  const [undergroundOilTank, setUndergroundOilTank] = React.useState(
    dwelling.undergroundOilTank
  );
  const [undergroundOilTankNotes, setUndergroundOilTankNotes] = React.useState(
    dwelling.undergroundOilTankNotes
  );
  const [plumbingYear, setPlumbingYear] = React.useState(dwelling.plumbingYear);
  const [plumbingYearNotes, setPlumbingYearNotes] = React.useState(
    dwelling.plumbingYearNotes
  );
  const [newPipes, setNewPipes] = React.useState(dwelling.newPipes);
  const [
    plumbingYearNotesVisibility,
    setPlumbingYearNotesVisibility,
  ] = React.useState(dwelling.plumbingYearNotes === "" ? false : true);
  const [waterSource, setWaterSource] = React.useState(dwelling.waterSource);
  const [waterSourceNotes, setWaterSourceNotes] = React.useState(
    dwelling.waterSourceNotes
  );
  const [
    waterSourceNotesVisibility,
    setWaterSourceNotesVisibility,
  ] = React.useState(dwelling.waterSourceNotes === "" ? false : true);
  const [wastewater, setWastewater] = React.useState(dwelling.wastewater);
  const [wastewaterNotes, setWastewaterNotes] = React.useState(
    dwelling.wastewaterNotes
  );
  const [
    wastewaterNotesVisibility,
    setWastewaterNotesVisibility,
  ] = React.useState(dwelling.wastewaterNotes === "" ? false : true);
  const [circuitBreakers, setCircuitBreakers] = React.useState(
    dwelling.circuitBreakers
  );
  const [electricYear, setElectricYear] = React.useState(dwelling.electricYear);
  const [electricYearNotes, setElectricYearNotes] = React.useState(
    dwelling.electricYearNotes
  );
  const [
    electricYearNotesVisibility,
    setElectricYearNotesVisibility,
  ] = React.useState(dwelling.electricYearNotes === "" ? false : true);
  const [numberOfAmps, setNumberOfAmps] = React.useState(dwelling.numberOfAmps);
  const [smokeAndCODetectors, setSmokeAndCODetectors] = React.useState(
    dwelling.smokeAndCODetectors
  );
  const [generator, setGenerator] = React.useState(dwelling.generator);
  const [wiring, setWiring] = React.useState(dwelling.wiring);
  const [solarPanels, setSolarPanels] = React.useState(dwelling.solarPanels);
  const [solarPanelsNotes, setSolarPanelsNotes] = React.useState(
    dwelling.solarPanelsNotes
  );
  const [fuses, setFuses] = React.useState(dwelling.fuses);
  const [fusesNotes, setFusesNotes] = React.useState(dwelling.fusesNotes);
  const [nonsmokerDiscount, setNonsmokerDiscount] = React.useState(
    dwelling.nonsmokerDiscount
  );
  const [fireExtinguisher, setFireExtinguisher] = React.useState(
    dwelling.fireExtinguisher
  );
  const [deadboltLocks, setDeadboltLocks] = React.useState(
    dwelling.deadboltLocks
  );
  const [inHomeSprinklerSystem, setInHomeSprinklerSystem] = React.useState(
    dwelling.inHomeSprinklerSystem
  );
  const [waterAlarm, setWaterAlarm] = React.useState(dwelling.waterAlarm);
  const [lowTemperatureAlarm, setLowTemperatureAlarm] = React.useState(
    dwelling.lowTemperatureAlarm
  );
  const [burglarAlarm, setBurglarAlarm] = React.useState(dwelling.burglarAlarm);
  const [burglarAlarmNotes, setBurglarAlarmNotes] = React.useState(
    dwelling.burglarAlarmNotes
  );
  const [exteriorDamage, setExteriorDamage] = React.useState(
    dwelling.exteriorDamage
  );
  const [exteriorDamageNotes, setExteriorDamageNotes] = React.useState(
    dwelling.exteriorDamageNotes
  );

  const [everRented, setEverRented] = React.useState(dwelling.everRented);
  const [everRentedNotes, setEverRentedNotes] = React.useState(
    dwelling.everRentedNotes
  );
  const [missingHandrails, setMissingHandrails] = React.useState(
    dwelling.missingHandrails
  );
  const [missingHandrailsNotes, setMissingHandrailsNotes] = React.useState(
    dwelling.missingHandrailsNotes
  );

  const [homeBasedBusiness, setHomeBasedBusiness] = React.useState(
    dwelling.homeBasedBusiness
  );
  const [homeBasedBusinessNotes, setHomeBasedBusinessNotes] = React.useState(
    dwelling.homeBasedBusinessNotes
  );

  const [farming, setFarming] = React.useState(dwelling.farming);
  const [farmingNotes, setFarmingNotes] = React.useState(dwelling.farmingNotes);

  const [
    homeConcealedFromNeighbors,
    setHomeConcealedFromNeighbors,
  ] = React.useState(dwelling.homeConcealedFromNeighbors);
  const [
    homeConcealedFromNeighborsNotes,
    setHomeConcealedFromNeighborsNotes,
  ] = React.useState(dwelling.homeConcealedFromNeighborsNotes);
  const [priorBankruptcy, setPriorBankruptcy] = React.useState(
    dwelling.priorBankruptcy
  );
  const [priorBankruptcyNotes, setPriorBankruptcyNotes] = React.useState(
    dwelling.priorBankruptcyNotes
  );
  const [homeForSale, setHomeForSale] = React.useState(dwelling.homeForSale);
  const [homeForSaleNotes, setHomeForSaleNotes] = React.useState(
    dwelling.homeForSaleNotes
  );

  const [trampoline, setTrampoline] = React.useState(dwelling.trampoline);
  const [pool, setPool] = React.useState(dwelling.pool);
  const [poolNotes, setPoolNotes] = React.useState(dwelling.poolNotes);
  const [fenced, setFenced] = React.useState(dwelling.fenced);
  const [lockingGate, setLockingGate] = React.useState(dwelling.lockingGate);
  const [divingBoard, setDivingBoard] = React.useState(dwelling.divingBoard);
  const [slide, setSlide] = React.useState(dwelling.slide);
  const [cancelledOrNonrenewed, setCancelledOrNonrenewed] = React.useState(
    dwelling.cancelledOrNonrenewed
  );
  const [residenceEmployees, setResidenceEmployees] = React.useState(
    dwelling.residenceEmployees
  );
  const [residenceEmployeesNotes, setResidenceEmployeesNotes] = React.useState(
    dwelling.residenceEmployeesNotes
  );
  const [dayCare, setDayCare] = React.useState(dwelling.dayCare);
  const [dayCareNotes, setDayCareNotes] = React.useState(dwelling.dayCareNotes);
  const [underConstruction, setUnderConstruction] = React.useState(
    dwelling.underConstruction
  );
  const [underConstructionNotes, setUnderConstructionNotes] = React.useState(
    dwelling.underConstructionNotes
  );
  const [generalNotes, setGeneralNotes] = React.useState(dwelling.generalNotes);
  const [privateNotes, setPrivateNotes] = React.useState(dwelling.privateNotes);

  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (
      dwelling.unitCount !== unitCount ||
      dwelling.unitsNotes !== unitsNotes ||
      dwelling.occupancy !== occupancy ||
      dwelling.occupancyNotes !== occupancyNotes ||
      dwelling.constructionType !== constructionType ||
      dwelling.constructionNotes !== constructionNotes ||
      dwelling.yearBuilt !== yearBuilt ||
      dwelling.squareFeet !== squareFeet ||
      dwelling.homeStyle !== homeStyle ||
      dwelling.homeStyleNotes !== homeStyleNotes ||
      dwelling.bedrooms !== bedrooms ||
      dwelling.fullBaths !== fullBaths ||
      dwelling.halfBaths !== halfBaths ||
      dwelling.foundation !== foundation ||
      dwelling.foundationNotes !== foundationNotes ||
      dwelling.piers !== piers ||
      dwelling.piersNotes !== piersNotes ||
      dwelling.basement !== basement ||
      dwelling.basementNotes !== basementNotes ||
      dwelling.walkoutBasement !== walkoutBasement ||
      dwelling.sumpPump !== sumpPump ||
      dwelling.attachedGarage !== attachedGarage ||
      dwelling.attachedGarageNotes !== attachedGarageNotes ||
      dwelling.exterior !== exterior ||
      dwelling.exteriorNotes !== exteriorNotes ||
      dwelling.decksAndPorches !== decksAndPorches ||
      dwelling.specialFeatures !== specialFeatures ||
      dwelling.roofYear !== roofYear ||
      dwelling.roofMaterial !== roofMaterial ||
      dwelling.roofStyle !== roofStyle ||
      dwelling.roofNotes !== roofNotes ||
      dwelling.heatingYear !== heatingYear ||
      dwelling.heatingStyle !== heatingStyle ||
      dwelling.heatingType !== heatingType ||
      dwelling.gasFireplaces !== gasFireplaces ||
      dwelling.woodFireplaces !== woodFireplaces ||
      dwelling.solidFuelBurner !== solidFuelBurner ||
      dwelling.heatingNotes !== heatingNotes ||
      dwelling.centralAir !== centralAir ||
      dwelling.undergroundOilTank !== undergroundOilTank ||
      dwelling.undergroundOilTankNotes !== undergroundOilTankNotes ||
      dwelling.plumbingYear !== plumbingYear ||
      dwelling.plumbingYearNotes !== plumbingYearNotes ||
      dwelling.newPipes !== newPipes ||
      dwelling.waterSource !== waterSource ||
      dwelling.waterSourceNotes !== waterSourceNotes ||
      dwelling.wastewater !== wastewater ||
      dwelling.wastewaterNotes !== wastewaterNotes ||
      dwelling.circuitBreakers !== circuitBreakers ||
      dwelling.electricYear !== electricYear ||
      dwelling.electricYearNotes !== electricYearNotes ||
      dwelling.numberOfAmps !== numberOfAmps ||
      dwelling.smokeAndCODetectors !== smokeAndCODetectors ||
      dwelling.generator !== generator ||
      dwelling.wiring !== wiring ||
      dwelling.solarPanels !== solarPanels ||
      dwelling.solarPanelsNotes !== solarPanelsNotes ||
      dwelling.fuses !== fuses ||
      dwelling.fusesNotes !== fusesNotes ||
      dwelling.nonsmokerDiscount !== nonsmokerDiscount ||
      dwelling.fireExtinguisher !== fireExtinguisher ||
      dwelling.deadboltLocks !== deadboltLocks ||
      dwelling.inHomeSprinklerSystem !== inHomeSprinklerSystem ||
      dwelling.waterAlarm !== waterAlarm ||
      dwelling.lowTemperatureAlarm !== lowTemperatureAlarm ||
      dwelling.burglarAlarm !== burglarAlarm ||
      dwelling.burglarAlarmNotes !== burglarAlarmNotes ||
      dwelling.exteriorDamage !== exteriorDamage ||
      dwelling.exteriorDamageNotes !== exteriorDamageNotes ||
      dwelling.everRented !== everRented ||
      dwelling.everRentedNotes !== everRentedNotes ||
      dwelling.missingHandrails !== missingHandrails ||
      dwelling.missingHandrailsNotes !== missingHandrailsNotes ||
      dwelling.homeBasedBusiness !== homeBasedBusiness ||
      dwelling.homeBasedBusinessNotes !== homeBasedBusinessNotes ||
      dwelling.farming !== farming ||
      dwelling.farmingNotes !== farmingNotes ||
      dwelling.homeConcealedFromNeighbors !== homeConcealedFromNeighbors ||
      dwelling.homeConcealedFromNeighborsNotes !==
        homeConcealedFromNeighborsNotes ||
      dwelling.priorBankruptcy !== priorBankruptcy ||
      dwelling.priorBankruptcyNotes !== priorBankruptcyNotes ||
      dwelling.homeForSale !== homeForSale ||
      dwelling.homeForSaleNotes !== homeForSaleNotes ||
      dwelling.trampoline !== trampoline ||
      dwelling.pool !== pool ||
      dwelling.poolNotes !== poolNotes ||
      dwelling.fenced !== fenced ||
      dwelling.lockingGate !== lockingGate ||
      dwelling.divingBoard !== divingBoard ||
      dwelling.slide !== slide ||
      dwelling.cancelledOrNonrenewed !== cancelledOrNonrenewed ||
      dwelling.residenceEmployees !== residenceEmployees ||
      dwelling.residenceEmployeesNotes !== residenceEmployeesNotes ||
      dwelling.dayCare !== dayCare ||
      dwelling.dayCareNotes !== dayCareNotes ||
      dwelling.underConstruction !== underConstruction ||
      dwelling.underConstructionNotes !== underConstructionNotes ||
      dwelling.generalNotes !== generalNotes ||
      dwelling.privateNotes !== privateNotes
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [
    dwelling.unitCount,
    unitCount,
    dwelling.unitsNotes,
    unitsNotes,
    dwelling.occupancy,
    occupancy,
    dwelling.occupancyNotes,
    occupancyNotes,
    dwelling.constructionType,
    constructionType,
    dwelling.constructionNotes,
    constructionNotes,
    dwelling.yearBuilt,
    yearBuilt,
    dwelling.squareFeet,
    squareFeet,
    dwelling.homeStyle,
    homeStyle,
    dwelling.homeStyleNotes,
    homeStyleNotes,
    dwelling.bedrooms,
    bedrooms,
    dwelling.fullBaths,
    fullBaths,
    dwelling.halfBaths,
    halfBaths,
    dwelling.foundation,
    foundation,
    dwelling.foundationNotes,
    foundationNotes,
    dwelling.piers,
    piers,
    dwelling.piersNotes,
    piersNotes,
    dwelling.basement,
    basement,
    dwelling.basementNotes,
    basementNotes,
    dwelling.walkoutBasement,
    walkoutBasement,
    dwelling.sumpPump,
    sumpPump,
    dwelling.attachedGarage,
    attachedGarage,
    dwelling.attachedGarageNotes,
    attachedGarageNotes,
    dwelling.exterior,
    exterior,
    dwelling.exteriorNotes,
    exteriorNotes,
    dwelling.decksAndPorches,
    decksAndPorches,
    dwelling.specialFeatures,
    specialFeatures,
    dwelling.roofYear,
    roofYear,
    dwelling.roofMaterial,
    roofMaterial,
    dwelling.roofStyle,
    roofStyle,
    dwelling.roofNotes,
    roofNotes,
    dwelling.heatingYear,
    heatingYear,
    dwelling.heatingStyle,
    heatingStyle,
    dwelling.heatingType,
    heatingType,
    dwelling.gasFireplaces,
    gasFireplaces,
    dwelling.woodFireplaces,
    woodFireplaces,
    dwelling.solidFuelBurner,
    solidFuelBurner,
    dwelling.heatingNotes,
    heatingNotes,
    dwelling.centralAir,
    centralAir,
    dwelling.undergroundOilTank,
    undergroundOilTank,
    dwelling.undergroundOilTankNotes,
    undergroundOilTankNotes,
    dwelling.plumbingYear,
    plumbingYear,
    dwelling.plumbingYearNotes,
    plumbingYearNotes,
    dwelling.newPipes,
    newPipes,
    dwelling.waterSource,
    waterSource,
    dwelling.waterSourceNotes,
    waterSourceNotes,
    dwelling.wastewater,
    wastewater,
    dwelling.wastewaterNotes,
    wastewaterNotes,
    dwelling.circuitBreakers,
    circuitBreakers,
    dwelling.electricYear,
    electricYear,
    dwelling.electricYearNotes,
    electricYearNotes,
    dwelling.numberOfAmps,
    numberOfAmps,
    dwelling.smokeAndCODetectors,
    smokeAndCODetectors,
    dwelling.generator,
    generator,
    dwelling.wiring,
    wiring,
    dwelling.solarPanels,
    solarPanels,
    dwelling.solarPanelsNotes,
    solarPanelsNotes,
    dwelling.fuses,
    fuses,
    dwelling.fusesNotes,
    fusesNotes,
    dwelling.nonsmokerDiscount,
    nonsmokerDiscount,
    dwelling.fireExtinguisher,
    fireExtinguisher,
    dwelling.deadboltLocks,
    deadboltLocks,
    dwelling.inHomeSprinklerSystem,
    inHomeSprinklerSystem,
    dwelling.waterAlarm,
    waterAlarm,
    dwelling.lowTemperatureAlarm,
    lowTemperatureAlarm,
    dwelling.burglarAlarm,
    burglarAlarm,
    dwelling.burglarAlarmNotes,
    burglarAlarmNotes,
    dwelling.exteriorDamage,
    exteriorDamage,
    dwelling.exteriorDamageNotes,
    exteriorDamageNotes,
    dwelling.everRented,
    everRented,
    dwelling.everRentedNotes,
    everRentedNotes,
    dwelling.missingHandrails,
    missingHandrails,
    dwelling.missingHandrailsNotes,
    missingHandrailsNotes,
    dwelling.homeBasedBusiness,
    homeBasedBusiness,
    dwelling.homeBasedBusinessNotes,
    homeBasedBusinessNotes,
    dwelling.farming,
    farming,
    dwelling.farmingNotes,
    farmingNotes,
    dwelling.homeConcealedFromNeighbors,
    homeConcealedFromNeighbors,
    dwelling.homeConcealedFromNeighborsNotes,
    homeConcealedFromNeighborsNotes,
    dwelling.priorBankruptcy,
    priorBankruptcy,
    dwelling.priorBankruptcyNotes,
    priorBankruptcyNotes,
    dwelling.homeForSale,
    homeForSale,
    dwelling.homeForSaleNotes,
    homeForSaleNotes,
    dwelling.trampoline,
    trampoline,
    dwelling.pool,
    pool,
    dwelling.poolNotes,
    poolNotes,
    dwelling.fenced,
    fenced,
    dwelling.lockingGate,
    lockingGate,
    dwelling.divingBoard,
    divingBoard,
    dwelling.slide,
    slide,
    dwelling.cancelledOrNonrenewed,
    cancelledOrNonrenewed,
    dwelling.residenceEmployees,
    residenceEmployees,
    dwelling.residenceEmployeesNotes,
    residenceEmployeesNotes,
    dwelling.dayCare,
    dayCare,
    dwelling.dayCareNotes,
    dayCareNotes,
    dwelling.underConstruction,
    underConstruction,
    dwelling.underConstructionNotes,
    underConstructionNotes,
    dwelling.generalNotes,
    generalNotes,
    dwelling.privateNotes,
    privateNotes,
  ]);

  const [dwellingUpdate] = useMutation<{
    dwellingUpdate: Dwelling;
  }>(UPDATE_DWELLING, {
    variables: {
      id: dwelling.id,
      unitCount: unitCount.trim(),
      unitsNotes: unitsNotes.trim(),
      occupancy: occupancy.trim(),
      occupancyNotes: occupancyNotes.trim(),
      constructionType: constructionType.trim(),
      constructionNotes: constructionNotes.trim(),
      yearBuilt: yearBuilt,
      squareFeet: squareFeet,
      homeStyle: homeStyle.trim(),
      homeStyleNotes: homeStyleNotes.trim(),
      bedrooms: bedrooms,
      fullBaths: fullBaths,
      halfBaths: halfBaths,
      foundation: foundation.trim(),
      foundationNotes: foundationNotes.trim(),
      piers: piers,
      piersNotes: piersNotes.trim(),
      basement: basement.trim(),
      basementNotes: basementNotes.trim(),
      walkoutBasement: walkoutBasement,
      sumpPump: sumpPump,
      attachedGarage: attachedGarage.trim(),
      attachedGarageNotes: attachedGarageNotes.trim(),
      exterior: exterior,
      exteriorNotes: exteriorNotes.trim(),
      decksAndPorches: decksAndPorches.trim(),
      specialFeatures: specialFeatures.trim(),
      roofYear: roofYear,
      roofMaterial: roofMaterial.trim(),
      roofStyle: roofStyle.trim(),
      roofNotes: roofNotes.trim(),
      heatingYear: heatingYear,
      heatingStyle: heatingStyle.trim(),
      heatingType: heatingType.trim(),
      gasFireplaces: gasFireplaces,
      woodFireplaces: woodFireplaces,
      solidFuelBurner: solidFuelBurner,
      heatingNotes: heatingNotes.trim(),
      centralAir: centralAir,
      undergroundOilTank: undergroundOilTank,
      undergroundOilTankNotes: undergroundOilTankNotes.trim(),
      plumbingYear: plumbingYear,
      newPipes: newPipes,
      plumbingYearNotes: plumbingYearNotes.trim(),
      waterSource: waterSource,
      waterSourceNotes: waterSourceNotes.trim(),
      wastewater: wastewater,
      wastewaterNotes: wastewaterNotes.trim(),
      circuitBreakers: circuitBreakers,
      electricYear: electricYear,
      electricYearNotes: electricYearNotes.trim(),
      numberOfAmps: numberOfAmps.trim(),
      smokeAndCODetectors: smokeAndCODetectors,
      generator: generator.trim(),
      wiring: wiring,
      solarPanels: solarPanels,
      solarPanelsNotes: solarPanelsNotes,
      fuses: fuses,
      fusesNotes: fusesNotes.trim(),
      nonsmokerDiscount: nonsmokerDiscount,
      fireExtinguisher: fireExtinguisher,
      deadboltLocks: deadboltLocks,
      inHomeSprinklerSystem: inHomeSprinklerSystem,
      waterAlarm: waterAlarm,
      lowTemperatureAlarm: lowTemperatureAlarm,
      burglarAlarm: burglarAlarm,
      burglarAlarmNotes: burglarAlarmNotes.trim(),
      exteriorDamage: exteriorDamage,
      exteriorDamageNotes: exteriorDamageNotes.trim(),
      everRented: everRented,
      everRentedNotes: everRentedNotes.trim(),
      missingHandrails: missingHandrails,
      missingHandrailsNotes: missingHandrailsNotes.trim(),
      homeBasedBusiness: homeBasedBusiness,
      homeBasedBusinessNotes: homeBasedBusinessNotes.trim(),
      farming: farming,
      farmingNotes: farmingNotes.trim(),
      homeConcealedFromNeighbors: homeConcealedFromNeighbors,
      homeConcealedFromNeighborsNotes: homeConcealedFromNeighborsNotes,
      priorBankruptcy: priorBankruptcy,
      priorBankruptcyNotes: priorBankruptcyNotes.trim(),
      homeForSale: homeForSale,
      homeForSaleNotes: homeForSaleNotes.trim(),
      trampoline: trampoline,
      pool: pool,
      poolNotes: poolNotes,
      fenced: fenced,
      lockingGate: lockingGate,
      divingBoard: divingBoard,
      slide: slide,
      cancelledOrNonrenewed: cancelledOrNonrenewed,
      residenceEmployees: residenceEmployees,
      residenceEmployeesNotes: residenceEmployeesNotes,
      dayCare: dayCare,
      dayCareNotes: dayCareNotes.trim(),
      underConstruction: underConstruction,
      underConstructionNotes: underConstructionNotes.trim(),
      generalNotes: generalNotes.trim(),
      privateNotes: privateNotes.trim(),
    },
  });

  function handleFormSubmit() {
    if (dirty) {
      // send("SAVE");
      dwellingUpdate();
    }
  }

  return (
    <Instance.Provider value={dwelling.id}>
      <div className="p-8">
        <form onSubmit={() => handleFormSubmit()}>
          {autoSave ? (
            <IdleTimer
              timeout={1000}
              startOnMount={true}
              onIdle={dirty ? () => handleFormSubmit() : () => {}}
            />
          ) : null}

          <h2>Dwelling</h2>

          <hr />

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Usage</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <MySelect
                label="Occupancy"
                options={OCCUPANCY_CHOICES}
                value={occupancy}
                setValue={setOccupancy}
                initialValue={dwelling.occupancy}
              />
              {occupancyNotesVisibility ? (
                <MyTextarea
                  label="Notes"
                  value={occupancyNotes}
                  setValue={setOccupancyNotes}
                  initialValue={dwelling.occupancyNotes}
                  autoFocusIfBlank
                  differentiator="occupancy"
                />
              ) : (
                <button
                  type="submit"
                  onClick={() => setOccupancyNotesVisibility(true)}
                >
                  ...
                </button>
              )}
            </div>
          </div>
          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Construction</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MySelect
                    label="Unit Count"
                    value={unitCount}
                    options={UNIT_COUNT_CHOICES}
                    setValue={setUnitCount}
                    initialValue={dwelling.unitCount}
                  />

                  {unitsNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={unitsNotes}
                      initialValue={dwelling.unitsNotes}
                      setValue={setUnitsNotes}
                      autoFocusIfBlank
                      differentiator="units"
                    />
                  ) : (
                    <button
                      type="submit"
                      onClick={() => setUnitsNotesVisibility(true)}
                    >
                      ...
                    </button>
                  )}
                </div>

                <div className="flex-1">
                  <MySelect
                    label="Construction Type"
                    value={constructionType}
                    options={CONSTRUCTION_CHOICES}
                    setValue={setConstructionType}
                    initialValue={dwelling.constructionType}
                  />

                  {constructionNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={constructionNotes}
                      initialValue={dwelling.constructionNotes}
                      setValue={setConstructionNotes}
                      autoFocusIfBlank
                      differentiator="construction"
                    />
                  ) : (
                    <button
                      type="submit"
                      onClick={() => setConstructionNotesVisibility(true)}
                    >
                      ...
                    </button>
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyYearField
                    label="Year Built"
                    value={yearBuilt}
                    setValue={setYearBuilt}
                    initialValue={dwelling.yearBuilt}
                  />
                </div>

                <div className="flex-1">
                  <MyIntegerField
                    label="Square Feet"
                    value={squareFeet}
                    setValue={setSquareFeet}
                    initialValue={dwelling.squareFeet}
                    characterLimit={5}
                  />
                </div>
              </div>

              <div className="flex px-8 py-4">
                <div className="flex-1">
                  <MySelect
                    label="Home Style"
                    value={homeStyle}
                    options={HOME_STYLE_CHOICES}
                    setValue={setHomeStyle}
                    initialValue={dwelling.homeStyle}
                  />

                  {homeStyleNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={homeStyleNotes}
                      initialValue={dwelling.homeStyleNotes}
                      setValue={setHomeStyleNotes}
                      autoFocusIfBlank
                      differentiator="homeStyle"
                    />
                  ) : (
                    <button
                      type="submit"
                      onClick={() => setHomeStyleNotesVisibility(true)}
                    >
                      ...
                    </button>
                  )}
                </div>

                <div className="flex-1">
                  <MySelect
                    label="Attached Garage"
                    value={attachedGarage}
                    options={GARAGE_CHOICES}
                    setValue={setAttachedGarage}
                    initialValue={dwelling.attachedGarage}
                  />

                  {attachedGarageNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={attachedGarageNotes}
                      initialValue={dwelling.attachedGarageNotes}
                      setValue={setAttachedGarageNotes}
                      autoFocusIfBlank
                      differentiator="attachedGarage"
                    />
                  ) : (
                    <button
                      type="submit"
                      onClick={() => setAttachedGarageNotesVisibility(true)}
                    >
                      ...
                    </button>
                  )}
                </div>
              </div>

              <div className="flex px-8 py-4">
                <div className="flex-1">
                  <MySelect
                    label="Foundation"
                    value={foundation}
                    options={FOUNDATION_CHOICES}
                    setValue={setFoundation}
                    initialValue={dwelling.foundation}
                  />

                  {foundationNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={foundationNotes}
                      initialValue={dwelling.foundationNotes}
                      setValue={setFoundationNotes}
                      autoFocusIfBlank
                      differentiator="foundation"
                    />
                  ) : (
                    <button
                      type="submit"
                      onClick={() => setFoundationNotesVisibility(true)}
                    >
                      ...
                    </button>
                  )}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Piers"
                    value={piers}
                    setValue={setPiers}
                    initialValue={dwelling.piers}
                  />

                  {piers === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={piersNotes}
                      setValue={setPiersNotes}
                      initialValue={dwelling.piersNotes}
                      autoFocusIfBlank
                      differentiator="piers"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Exterior</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MySelect
                    label="Siding"
                    value={exterior}
                    options={EXTERIOR_CHOICES}
                    setValue={setExterior}
                    initialValue={dwelling.exterior}
                  />

                  {exteriorNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={exteriorNotes}
                      initialValue={dwelling.exteriorNotes}
                      setValue={setExteriorNotes}
                      autoFocusIfBlank
                      differentiator="exterior"
                    />
                  ) : (
                    <button
                      type="submit"
                      onClick={() => setExteriorNotesVisibility(true)}
                    >
                      ...
                    </button>
                  )}
                </div>

                <div className="flex-1">
                  <MyTextarea
                    label="Decks and Porches"
                    value={decksAndPorches}
                    setValue={setDecksAndPorches}
                    initialValue={dwelling.decksAndPorches}
                    differentiator="decksAndPorches"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Interior</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MyIntegerField
                    label="Bedrooms"
                    value={bedrooms}
                    setValue={setBedrooms}
                    initialValue={dwelling.bedrooms}
                    characterLimit={2}
                  />
                </div>
                <div className="flex-1">
                  <MyIntegerField
                    label="Full Baths"
                    value={fullBaths}
                    setValue={setFullBaths}
                    initialValue={dwelling.fullBaths}
                    characterLimit={2}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyIntegerField
                    label="Half Baths"
                    value={halfBaths}
                    setValue={setHalfBaths}
                    initialValue={dwelling.halfBaths}
                    characterLimit={2}
                  />
                </div>

                <div className="flex-1">
                  <MyTextarea
                    label="Describe Special Features"
                    value={specialFeatures}
                    setValue={setSpecialFeatures}
                    initialValue={dwelling.specialFeatures}
                    differentiator="specialFeatures"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Basement</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MySelect
                    label="Basement"
                    value={basement}
                    options={BASEMENT_CHOICES}
                    setValue={setBasement}
                    initialValue={dwelling.basement}
                  />
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Sump Pump"
                    value={sumpPump}
                    setValue={setSumpPump}
                    initialValue={dwelling.sumpPump}
                  />
                </div>
              </div>

              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Walkout Basement"
                    value={walkoutBasement}
                    setValue={setWalkoutBasement}
                    initialValue={dwelling.walkoutBasement}
                  />
                </div>

                <div className="flex-1">
                  <MyTextarea
                    label="Notes"
                    value={basementNotes}
                    initialValue={dwelling.basementNotes}
                    setValue={setBasementNotes}
                    differentiator="basement"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Roof</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MyYearField
                    label="Roof Year"
                    value={roofYear}
                    setValue={setRoofYear}
                    initialValue={dwelling.roofYear}
                  />
                </div>
                <div className="flex-1">
                  <MySelect
                    label="Material"
                    value={roofMaterial}
                    options={ROOF_MATERIAL_CHOICES}
                    setValue={setRoofMaterial}
                    initialValue={dwelling.roofMaterial}
                  />

                  {/* {roofMaterialNotesVisibility ? (
                          <MyTextarea
                            label="Notes"
                            value={roofMaterialNotes}
                            initialValue={
                              dwelling.roofMaterialNotes
                            }
                            setValue={setRoofMaterialNotes}
                            autoFocusIfBlank
                          />
                        ) : (
                          <button
                            type="submit"
                            onClick={e =>
                              this.toggleExtraContentVisibility(
                                "roofMaterialNotesVisibility",
                                e
                              )
                            }
                          >...</button>
                        )} */}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MySelect
                    label="Style"
                    value={roofStyle}
                    options={ROOF_STYLE_CHOICES}
                    setValue={setRoofStyle}
                    initialValue={dwelling.roofStyle}
                  />

                  {/* {roofStyleNotesVisibility ? (
                          <MyTextarea
                            label="Notes"
                            value={roofStyleNotes}
                            initialValue={
                              dwelling.roofStyleNotes
                            }
                            setValue={setRoofStyleNotes}
                            autoFocusIfBlank
                          />
                        ) : (
                          <button
                            type="submit"
                            onClick={e =>
                              this.toggleExtraContentVisibility(
                                "roofStyleNotesVisibility",
                                e
                              )
                            }
                          >...</button>
                        )} */}
                </div>
                <div className="flex-1">
                  <MyTextarea
                    label="Notes"
                    value={roofNotes}
                    initialValue={dwelling.roofNotes}
                    setValue={setRoofNotes}
                    differentiator="roof"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Heating</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MyYearField
                    label="Year"
                    value={heatingYear}
                    setValue={setHeatingYear}
                    initialValue={dwelling.heatingYear}
                  />
                </div>
                <div className="flex-1">
                  <MySelect
                    label="Heating Style"
                    value={heatingStyle}
                    options={HEATING_STYLE_CHOICES}
                    setValue={setHeatingStyle}
                    initialValue={dwelling.heatingStyle}
                  />

                  {/* {heatingStyleNotesVisibility ? (
                          <MyTextarea
                            label="Notes"
                            value={heatingStyleNotes}
                            initialValue={
                              dwelling.heatingStyleNotes
                            }
                            setValue={setHeatingStyleNotes}
                            autoFocusIfBlank
                          />
                        ) : (
                          <button
                            type="submit"
                            onClick={e =>
                              this.toggleExtraContentVisibility(
                                "heatingStyleNotesVisibility",
                                e
                              )
                            }
                          >...</button>
                        )} */}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MySelect
                    label="Heating Type"
                    value={heatingType}
                    options={HEATING_TYPE_CHOICES}
                    setValue={setHeatingType}
                    initialValue={dwelling.heatingType}
                  />

                  {/* {heatingTypeNotesVisibility ? (
                          <MyTextarea
                            label="Notes"
                            value={heatingTypeNotes}
                            initialValue={
                              dwelling.heatingTypeNotes
                            }
                            setValue={setHeatingTypeNotes}
                            autoFocusIfBlank
                          />
                        ) : (
                          <button
                            type="submit"
                            onClick={e =>
                              this.toggleExtraContentVisibility(
                                "heatingTypeNotesVisibility",
                                e
                              )
                            }
                          >...</button>
                        )} */}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Central Air"
                    value={centralAir}
                    setValue={setCentralAir}
                    initialValue={dwelling.centralAir}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyIntegerField
                    label="Gas Fireplaces"
                    value={gasFireplaces}
                    setValue={setGasFireplaces}
                    initialValue={dwelling.gasFireplaces}
                    characterLimit={2}
                  />
                </div>
                <div className="flex-1">
                  <MyIntegerField
                    label="Wood Fireplaces"
                    value={woodFireplaces}
                    setValue={setWoodFireplaces}
                    initialValue={dwelling.woodFireplaces}
                    characterLimit={2}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MySelect
                    label="Solid Fuel Burner"
                    value={solidFuelBurner}
                    options={SOLID_FUEL_CHOICES}
                    setValue={setSolidFuelBurner}
                    initialValue={dwelling.solidFuelBurner}
                  />

                  {/* {solidFuelBurnerNotesVisibility ? (
                          <MyTextarea
                            label="Notes"
                            value={solidFuelBurnerNotes}
                            initialValue={
                              dwelling.solidFuelBurnerNotes
                            }
                            setValue={setSolidFuelBurnerNotes}
                            autoFocusIfBlank
                          />
                        ) : (
                          <button
                            type="submit"
                            onClick={e =>
                              this.toggleExtraContentVisibility(
                                "solidFuelBurnerNotesVisibility",
                                e
                              )
                            }
                          >...</button>
                        )} */}
                </div>
                <div className="flex-1">
                  <MyTextarea
                    label="Notes"
                    value={heatingNotes}
                    initialValue={dwelling.heatingNotes}
                    setValue={setHeatingNotes}
                    differentiator="heating"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Plumbing</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MyYearField
                    label="Plumbing Year"
                    value={plumbingYear}
                    setValue={setPlumbingYear}
                    initialValue={dwelling.plumbingYear}
                    toggleMore={
                      plumbingYearNotesVisibility
                        ? undefined
                        : () => setPlumbingYearNotesVisibility(true)
                    }
                  />
                  {plumbingYearNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={plumbingYearNotes}
                      initialValue={dwelling.plumbingYearNotes}
                      setValue={setPlumbingYearNotes}
                      autoFocusIfBlank
                      differentiator="plumbing"
                    />
                  ) : null}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="All Pipes are Copper/PVC/Pex"
                    value={newPipes}
                    setValue={setNewPipes}
                    initialValue={dwelling.newPipes}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MySelect
                    label="Water Source"
                    value={waterSource}
                    options={WATER_SOURCE_CHOICES}
                    setValue={setWaterSource}
                    initialValue={dwelling.waterSource}
                  />

                  {waterSourceNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={waterSourceNotes}
                      initialValue={dwelling.waterSourceNotes}
                      setValue={setWaterSourceNotes}
                      autoFocusIfBlank
                      differentiator="waterSource"
                    />
                  ) : (
                    <button
                      type="submit"
                      onClick={() => setWaterSourceNotesVisibility(true)}
                    >
                      ...
                    </button>
                  )}
                </div>
                <div className="flex-1">
                  <MySelect
                    label="Wastewater System"
                    options={WASTEWATER_CHOICES}
                    value={wastewater}
                    setValue={setWastewater}
                    initialValue={dwelling.wastewater}
                  />

                  {wastewaterNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={wastewaterNotes}
                      setValue={setWastewaterNotes}
                      initialValue={dwelling.wastewaterNotes}
                      autoFocusIfBlank
                      differentiator="wastewater"
                    />
                  ) : (
                    <button
                      type="submit"
                      onClick={() => setWastewaterNotesVisibility(true)}
                    >
                      ...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Electric</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MyYearField
                    label="Update Year"
                    value={electricYear}
                    setValue={setElectricYear}
                    initialValue={dwelling.electricYear}
                    toggleMore={
                      electricYearNotesVisibility
                        ? undefined
                        : () => setElectricYearNotesVisibility(true)
                    }
                  />
                  {electricYearNotesVisibility ? (
                    <MyTextarea
                      label="Notes"
                      value={electricYearNotes}
                      initialValue={dwelling.electricYearNotes}
                      setValue={setElectricYearNotes}
                      autoFocusIfBlank
                      differentiator="electricYear"
                    />
                  ) : null}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Circuit Breakers Only"
                    value={circuitBreakers}
                    setValue={setCircuitBreakers}
                    initialValue={dwelling.circuitBreakers}
                  />

                  {circuitBreakers === "N" ? (
                    <MyNullBoolean
                      label="Fuses"
                      value={fuses}
                      setValue={setFuses}
                      initialValue={dwelling.fuses}
                    />
                  ) : null}

                  {fuses === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={fusesNotes}
                      initialValue={dwelling.fusesNotes}
                      setValue={setFusesNotes}
                      autoFocusIfBlank
                      differentiator="fuses"
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyTextInputField
                    label="Number of Amps"
                    value={numberOfAmps}
                    setValue={setNumberOfAmps}
                    initialValue={dwelling.numberOfAmps}
                    characterLimit={32}
                  />
                </div>
                <div className="flex-1">
                  <MySelect
                    label="Generator"
                    value={generator}
                    options={GENERATOR_CHOICES}
                    setValue={setGenerator}
                    initialValue={dwelling.generator}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Solar Panels"
                    value={solarPanels}
                    setValue={setSolarPanels}
                    initialValue={dwelling.solarPanels}
                  />

                  {solarPanels === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={solarPanelsNotes}
                      initialValue={dwelling.solarPanelsNotes}
                      setValue={setSolarPanelsNotes}
                      autoFocusIfBlank
                      differentiator="solarPanels"
                    />
                  ) : null}
                </div>
                <div className="flex-1">
                  <MySelect
                    label="Wiring"
                    value={wiring}
                    options={WIRING_CHOICES}
                    setValue={setWiring}
                    initialValue={dwelling.wiring}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Discounts</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Smoke and C.O. Detectors"
                    value={smokeAndCODetectors}
                    setValue={setSmokeAndCODetectors}
                    initialValue={dwelling.smokeAndCODetectors}
                  />
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Nonsmoker Discount"
                    value={nonsmokerDiscount}
                    setValue={setNonsmokerDiscount}
                    initialValue={dwelling.nonsmokerDiscount}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Fire Extinguisher"
                    value={fireExtinguisher}
                    setValue={setFireExtinguisher}
                    initialValue={dwelling.fireExtinguisher}
                  />
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Deadbolt Locks"
                    value={deadboltLocks}
                    setValue={setDeadboltLocks}
                    initialValue={dwelling.deadboltLocks}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="In-Home Sprinkler System"
                    value={inHomeSprinklerSystem}
                    setValue={setInHomeSprinklerSystem}
                    initialValue={dwelling.inHomeSprinklerSystem}
                  />
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Water Alarm"
                    value={waterAlarm}
                    setValue={setWaterAlarm}
                    initialValue={dwelling.waterAlarm}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Low Temperature Alarm"
                    value={lowTemperatureAlarm}
                    setValue={setLowTemperatureAlarm}
                    initialValue={dwelling.lowTemperatureAlarm}
                  />
                </div>
                <div className="flex-1">
                  <MySelect
                    label="Burglar Alarm"
                    value={burglarAlarm}
                    options={BURGLAR_ALARM_CHOICES}
                    setValue={setBurglarAlarm}
                    initialValue={dwelling.burglarAlarm}
                  />

                  {burglarAlarm === "L" || burglarAlarm === "C" ? (
                    <MyTextarea
                      label="Notes"
                      value={burglarAlarmNotes}
                      initialValue={dwelling.burglarAlarmNotes}
                      setValue={setBurglarAlarmNotes}
                      differentiator="burglarAlarm"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Hazards</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Exterior Damage"
                    value={exteriorDamage}
                    setValue={setExteriorDamage}
                    initialValue={dwelling.exteriorDamage}
                  />

                  {exteriorDamage === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={exteriorDamageNotes}
                      initialValue={dwelling.exteriorDamageNotes}
                      setValue={setExteriorDamageNotes}
                      autoFocusIfBlank
                      differentiator="exteriorDamage"
                    />
                  ) : null}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Missing Handrails"
                    value={missingHandrails}
                    setValue={setMissingHandrails}
                    initialValue={dwelling.missingHandrails}
                  />

                  {missingHandrails === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={missingHandrailsNotes}
                      initialValue={dwelling.missingHandrailsNotes}
                      setValue={setMissingHandrailsNotes}
                      autoFocusIfBlank
                      differentiator="missingHandrails"
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Prior Bankruptcy or Foreclosure"
                    value={priorBankruptcy}
                    setValue={setPriorBankruptcy}
                    initialValue={dwelling.priorBankruptcy}
                  />

                  {priorBankruptcy === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={priorBankruptcyNotes}
                      initialValue={dwelling.priorBankruptcyNotes}
                      setValue={setPriorBankruptcyNotes}
                      autoFocusIfBlank
                      differentiator="priorBankruptcy"
                    />
                  ) : null}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Home for Sale"
                    value={homeForSale}
                    setValue={setHomeForSale}
                    initialValue={dwelling.homeForSale}
                  />

                  {homeForSale === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={homeForSaleNotes}
                      initialValue={dwelling.homeForSaleNotes}
                      setValue={setHomeForSaleNotes}
                      autoFocusIfBlank
                      differentiator="homeForSale"
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Ever Rented"
                    value={everRented}
                    setValue={setEverRented}
                    initialValue={dwelling.everRented}
                  />

                  {everRented === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={everRentedNotes}
                      initialValue={dwelling.everRentedNotes}
                      setValue={setEverRentedNotes}
                      autoFocusIfBlank
                      differentiator="everRented"
                    />
                  ) : null}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Under Construction / Renovation"
                    value={underConstruction}
                    setValue={setUnderConstruction}
                    initialValue={dwelling.underConstruction}
                  />

                  {underConstruction === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={underConstructionNotes}
                      initialValue={dwelling.underConstructionNotes}
                      setValue={setUnderConstructionNotes}
                      autoFocusIfBlank
                      differentiator="underConstruction"
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Underground Oil Tank"
                    value={undergroundOilTank}
                    setValue={setUndergroundOilTank}
                    initialValue={dwelling.undergroundOilTank}
                  />

                  {undergroundOilTank === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={undergroundOilTankNotes}
                      initialValue={dwelling.undergroundOilTankNotes}
                      setValue={setUndergroundOilTankNotes}
                      autoFocusIfBlank
                      differentiator="undergroundOilTank"
                    />
                  ) : null}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Home Concealed From Neighbors"
                    value={homeConcealedFromNeighbors}
                    setValue={setHomeConcealedFromNeighbors}
                    initialValue={dwelling.homeConcealedFromNeighbors}
                  />

                  {homeConcealedFromNeighbors === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={homeConcealedFromNeighborsNotes}
                      initialValue={dwelling.homeConcealedFromNeighborsNotes}
                      setValue={setHomeConcealedFromNeighborsNotes}
                      autoFocusIfBlank
                      differentiator="homeConcealedFromNeighbors"
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Home-Based Business"
                    value={homeBasedBusiness}
                    setValue={setHomeBasedBusiness}
                    initialValue={dwelling.homeBasedBusiness}
                  />

                  {homeBasedBusiness === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={homeBasedBusinessNotes}
                      initialValue={dwelling.homeBasedBusinessNotes}
                      setValue={setHomeBasedBusinessNotes}
                      autoFocusIfBlank
                      differentiator="homeBasedBusiness"
                    />
                  ) : null}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Farming"
                    value={farming}
                    setValue={setFarming}
                    initialValue={dwelling.farming}
                  />

                  {farming === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={farmingNotes}
                      initialValue={dwelling.farmingNotes}
                      setValue={setFarmingNotes}
                      autoFocusIfBlank
                      differentiator="farming"
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Day Care"
                    value={dayCare}
                    setValue={setDayCare}
                    initialValue={dwelling.dayCare}
                  />

                  {dayCare === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={dayCareNotes}
                      initialValue={dwelling.dayCareNotes}
                      setValue={setDayCareNotes}
                      autoFocusIfBlank
                      differentiator="dayCare"
                    />
                  ) : null}
                </div>
                <div className="flex-1">
                  <MyNullBoolean
                    label="Residence Employees"
                    value={residenceEmployees}
                    setValue={setResidenceEmployees}
                    initialValue={dwelling.residenceEmployees}
                  />

                  {residenceEmployees === "Y" ? (
                    <MyTextarea
                      label="Notes"
                      value={residenceEmployeesNotes}
                      initialValue={dwelling.residenceEmployeesNotes}
                      setValue={setResidenceEmployeesNotes}
                      autoFocusIfBlank
                      differentiator="residenceEmployees"
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <MyNullBoolean
                    label="Cancelled or Nonrenewed"
                    value={cancelledOrNonrenewed}
                    setValue={setCancelledOrNonrenewed}
                    initialValue={dwelling.cancelledOrNonrenewed}
                  />
                </div>
                <div className="flex-1">
                  <MySelect
                    label="Trampoline"
                    value={trampoline}
                    options={TRAMPOLINE_CHOICES}
                    setValue={setTrampoline}
                    initialValue={dwelling.trampoline}
                  />
                </div>
              </div>
              <div>
                <MySelect
                  label="Pool"
                  value={pool}
                  options={POOL_CHOICES}
                  setValue={setPool}
                  initialValue={dwelling.pool}
                />

                {pool === "A" || pool === "I" ? (
                  <div>
                    <div className="flex">
                      <div className="flex-1">
                        <MyNullBoolean
                          label="Fenced"
                          value={fenced}
                          setValue={setFenced}
                          initialValue={dwelling.fenced}
                        />
                      </div>
                      <div className="flex-1">
                        <MyNullBoolean
                          label="Locking Gate"
                          value={lockingGate}
                          setValue={setLockingGate}
                          initialValue={dwelling.lockingGate}
                        />
                      </div>
                      <div className="flex-1">
                        <MyNullBoolean
                          label="Diving Board"
                          value={divingBoard}
                          setValue={setDivingBoard}
                          initialValue={dwelling.divingBoard}
                        />
                      </div>
                      <div className="flex-1">
                        <MyNullBoolean
                          label="Slide"
                          value={slide}
                          setValue={setSlide}
                          initialValue={dwelling.slide}
                        />
                      </div>
                    </div>
                    <div>
                      <MyTextarea
                        label="Notes"
                        value={poolNotes}
                        initialValue={dwelling.poolNotes}
                        setValue={setPoolNotes}
                        differentiator="pool"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="flex px-8 py-4">
            <div className="w-1/5">
              <h3 className="text-lg">Other</h3>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <MyTextarea
                    label="General Notes"
                    value={generalNotes}
                    initialValue={dwelling.generalNotes}
                    setValue={setGeneralNotes}
                    differentiator="general"
                  />
                </div>
                <div className="flex-1">
                  <MyTextarea
                    label="Private (Agency-Only) Notes"
                    value={privateNotes}
                    initialValue={dwelling.privateNotes}
                    setValue={setPrivateNotes}
                    differentiator="private"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <SaveDwellingButton dirty={dirty} autoSave={autoSave} />
          </div>
        </form>
      </div>
    </Instance.Provider>
  );
}
