import * as React from "react";

// import {Redirect} from "react-router-dom";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

// import {Offline, Online} from "react-detect-offline";
import IdleTimer from "react-idle-timer";

import Toggle from "../Components/Toggle";

import PriorCancellations from "../Components/Underwriting/PriorCancellations/PriorCancellations";
import Claims from "../Components/Underwriting/Claims/Claims";
import Dogs from "../Components/Underwriting/Dogs/Dogs";
import Outbuildings from "../Components/Underwriting/Outbuildings/Outbuildings";
import Mortgages from "../Components/Underwriting/Mortgages/Mortgages";

import {AddDwellingButton} from "./AddDwelling";
import {EditDwelling} from "../Components/Underwriting/Dwellings/EditDwelling";

import {MyIntegerField} from "./MyIntegerField";
import {MyNullBoolean} from "./MyNullBoolean";
import {MyTextarea} from "./MyTextarea";
import {MyTextInputField} from "./MyTextInputField";
import {MyDateField} from "./MyDateField";
import {MyNumberField} from "./MyNumberField";

import AutoSave from "../Context/AutoSave";
import Instance from "../Context/Instance";

const UPDATE_LOCATION = loader(
  "../Fragments/Underwriting/LocationUpdate.graphql"
);

type Dog = {
  id: string;
  dogName: string;
  breedComposition: string;
  anyPriorAggression: string;
  priorAggressionNotes: string;
  sha1: string;
};

type Outbuilding = {
  id: string;
  buildingDescription: string;
  constructionYear: string;
  constructionType: string;
  value: number;
  setValue: (newValue: string) => void;
  initialValue: number | null;
  outbuildingNotes: string;
  heat: string;
  electric: string;
  hayStorage: string;
  usage: string;
  sha1: string;
};

type PriorCancellation = {
  id: string;
  date: string;
  insuranceCompany: string;
  cancellationType: string;
  cancellationNotes: string;
};

type Dwelling = {
  id: string;
  unitCount: string;
  unitsNotes: string;
  occupancy: string;
  occupancyNotes: string;
  constructionType: string;
  constructionNotes: string;
  yearBuilt: number | null;
  squareFeet: number;
  homeStyle: string;
  homeStyleNotes: string;
  bedrooms: number | null;
  fullBaths: number | null;
  halfBaths: number | null;
  foundation: string;
  foundationNotes: string;
  piers: string;
  piersNotes: string;
  basement: string;
  basementNotes: string;
  walkoutBasement: string;
  sumpPump: string;
  attachedGarage: string;
  attachedGarageNotes: string;
  exterior: string;
  exteriorNotes: string;
  decksAndPorches: string;
  specialFeatures: string;
  roofYear: number | null;
  roofMaterial: string;
  roofStyle: string;
  roofNotes: string;
  heatingYear: number | null;
  heatingStyle: string;
  heatingType: string;
  gasFireplaces: number | null;
  woodFireplaces: number | null;
  solidFuelBurner: string;
  heatingNotes: string;
  centralAir: string;
  undergroundOilTank: string;
  undergroundOilTankNotes: string;
  plumbingYear: number | null;
  plumbingYearNotes: string;
  newPipes: string;
  waterSource: string;
  waterSourceNotes: string;
  wastewater: string;
  wastewaterNotes: string;
  circuitBreakers: string;
  electricYear: number | null;
  electricYearNotes: string;
  numberOfAmps: string;
  smokeAndCODetectors: string;
  generator: string;
  wiring: string;
  solarPanels: string;
  solarPanelsNotes: string;
  fuses: string;
  fusesNotes: string;
  nonsmokerDiscount: string;
  fireExtinguisher: string;
  deadboltLocks: string;
  inHomeSprinklerSystem: string;
  waterAlarm: string;
  lowTemperatureAlarm: string;
  burglarAlarm: string;
  burglarAlarmNotes: string;
  exteriorDamage: string;
  exteriorDamageNotes: string;
  everRented: string;
  everRentedNotes: string;
  missingHandrails: string;
  missingHandrailsNotes: string;
  homeBasedBusiness: string;
  homeBasedBusinessNotes: string;
  farming: string;
  farmingNotes: string;
  homeConcealedFromNeighbors: string;
  homeConcealedFromNeighborsNotes: string;
  priorBankruptcy: string;
  priorBankruptcyNotes: string;
  homeForSale: string;
  homeForSaleNotes: string;
  trampoline: string;
  pool: string;
  poolNotes: string;
  fenced: string;
  lockingGate: string;
  divingBoard: string;
  slide: string;
  cancelledOrNonrenewed: string;
  residenceEmployees: string;
  residenceEmployeesNotes: string;
  dayCare: string;
  dayCareNotes: string;
  underConstruction: string;
  underConstructionNotes: string;
  generalNotes: string;
  privateNotes: string;
};

type Claim = {
  id: string;
  date: string;
  lossType: string;
  payout: number;
  claimNotes: string;
};

type Mortgage = {
  id: string;
  name: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zipcode: string;
  loanNumber: string;
  escrowBill: string;
  position: string;
};

type LocationProfile = {
  id: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipcode: string;
    client: {
      id: string;
      name: string;
      attn: string;
    };
  };
  deededOwner: string;
  deededOwnerNotes: string;
  firstTimeHomebuyer: string;
  purchaseClosingDate: string;
  purchaseClosingDateNotes: string;
  purchasePrice: number | null;
  purchasePriceNotes: string;
  hydrantsAvailable: string;
  hydrantsAvailableNotes: string;
  respondingFireDepartment: string;
  respondingFireDepartmentNotes: string;
  distanceToFireDepartment: string;
  distanceToFireDepartmentNotes: string;
  isoProtectionClass: string;
  isoProtectionClassNotes: string;
  acreage: number;
  acreageNotes: string;
  acreageNotesVisibility: boolean;
  floodZone: string;
  floodZoneNotes: string;
  claimSet: Claim[];
  dogSet: Dog[];
  dwellingSet: Dwelling[];
  mortgageSet: Mortgage[];
  outbuildingSet: Outbuilding[];
  priorcancellationSet: PriorCancellation[];
};

type Props = {
  location: LocationProfile;
};

export function EditLocation({location}: Props) {
  // Need to pull this value from LocalStorage and cache it there.
  const [autoSave, setAutoSave] = React.useState(true);

  const [deededOwner, setDeededOwner] = React.useState(location.deededOwner);
  const [deededOwnerNotes, setDeededOwnerNotes] = React.useState(
    location.deededOwnerNotes
  );
  const [
    deededOwnerNotesVisibility,
    setDeededOwnerNotesVisibility,
  ] = React.useState(location.deededOwnerNotes === "" ? false : true);
  const [firstTimeHomebuyer, setFirstTimeHomebuyer] = React.useState(
    location.firstTimeHomebuyer
  );
  const [purchaseClosingDate, setPurchaseClosingDate] = React.useState<
    string | null
  >(location.purchaseClosingDate);
  const [
    purchaseClosingDateNotes,
    setPurchaseClosingDateNotes,
  ] = React.useState(location.purchaseClosingDateNotes);
  const [
    purchaseClosingDateNotesVisibility,
    setPurchaseClosingDateNotesVisibility,
  ] = React.useState(location.purchaseClosingDateNotes === "" ? false : true);
  const [purchasePrice, setPurchasePrice] = React.useState(
    location.purchasePrice
  );
  const [purchasePriceNotes, setPurchasePriceNotes] = React.useState(
    location.purchasePriceNotes
  );
  const [
    purchasePriceNotesVisibility,
    setPurchasePriceNotesVisibility,
  ] = React.useState(location.purchasePriceNotes === "" ? false : true);
  const [hydrantsAvailable, setHydrantsAvailable] = React.useState(
    location.hydrantsAvailable
  );
  const [hydrantsAvailableNotes, setHydrantsAvailableNotes] = React.useState(
    location.hydrantsAvailableNotes
  );
  const [
    respondingFireDepartment,
    setRespondingFireDepartment,
  ] = React.useState(location.respondingFireDepartment);
  const [
    respondingFireDepartmentNotes,
    setRespondingFireDepartmentNotes,
  ] = React.useState(location.respondingFireDepartmentNotes);
  const [
    respondingFireDepartmentNotesVisibility,
    setRespondingFireDepartmentNotesVisibility,
  ] = React.useState(
    location.respondingFireDepartmentNotes === "" ? false : true
  );
  const [
    distanceToFireDepartment,
    setDistanceToFireDepartment,
  ] = React.useState(location.distanceToFireDepartment);
  const [
    distanceToFireDepartmentNotes,
    setDistanceToFireDepartmentNotes,
  ] = React.useState(location.distanceToFireDepartmentNotes);
  const [
    distanceToFireDepartmentNotesVisibility,
    setDistanceToFireDepartmentNotesVisibility,
  ] = React.useState(
    location.distanceToFireDepartmentNotes === "" ? false : true
  );
  const [isoProtectionClass, setIsoProtectionClass] = React.useState(
    location.isoProtectionClass
  );
  const [isoProtectionClassNotes, setIsoProtectionClassNotes] = React.useState(
    location.isoProtectionClassNotes
  );
  const [
    isoProtectionClassNotesVisibility,
    setIsoProtectionClassNotesVisibility,
  ] = React.useState(location.isoProtectionClassNotes === "" ? false : true);
  const [acreage, setAcreage] = React.useState(location.acreage);
  const [acreageNotes, setAcreageNotes] = React.useState(location.acreageNotes);
  const [acreageNotesVisibility, setAcreageNotesVisibility] = React.useState(
    location.acreageNotes === "" ? false : true
  );
  const [floodZone, setfloodZone] = React.useState(location.floodZone);
  const [floodZoneNotes, setfloodZoneNotes] = React.useState(
    location.floodZoneNotes
  );
  const [
    floodZoneNotesVisibility,
    setFloodZoneNotesVisibility,
  ] = React.useState(location.floodZoneNotes === "" ? false : true);
  // const [redirectToClient, setRedirectToClient] = React.useState(false);

  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (
      location.deededOwner !== deededOwner.trim() ||
      location.deededOwnerNotes !== deededOwnerNotes ||
      location.firstTimeHomebuyer !== firstTimeHomebuyer ||
      (location.purchaseClosingDate == null &&
        purchaseClosingDate !== "" &&
        purchaseClosingDate !== null) ||
      // (location.purchaseClosingDate !== null &&
      location.purchaseClosingDate !== purchaseClosingDate ||
      location.purchaseClosingDateNotes !== purchaseClosingDateNotes.trim() ||
      location.purchasePrice !== purchasePrice ||
      location.purchasePriceNotes !== purchasePriceNotes.trim() ||
      location.hydrantsAvailable !== hydrantsAvailable ||
      location.hydrantsAvailableNotes !== hydrantsAvailableNotes.trim() ||
      location.respondingFireDepartment !== respondingFireDepartment.trim() ||
      location.respondingFireDepartmentNotes !==
        respondingFireDepartmentNotes.trim() ||
      location.distanceToFireDepartment !== distanceToFireDepartment.trim() ||
      location.distanceToFireDepartmentNotes !==
        distanceToFireDepartmentNotes.trim() ||
      location.isoProtectionClass !== isoProtectionClass.trim() ||
      location.isoProtectionClassNotes !== isoProtectionClassNotes.trim() ||
      location.acreage !== acreage ||
      location.acreageNotes !== acreageNotes.trim() ||
      location.floodZone !== floodZone.trim() ||
      location.floodZoneNotes !== floodZoneNotes.trim()
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [
    location.deededOwner,
    deededOwner,
    location.deededOwnerNotes,
    deededOwnerNotes,
    location.firstTimeHomebuyer,
    firstTimeHomebuyer,
    location.purchaseClosingDate,
    purchaseClosingDate,
    location.purchaseClosingDateNotes,
    purchaseClosingDateNotes,
    location.purchasePrice,
    purchasePrice,
    location.purchasePriceNotes,
    purchasePriceNotes,
    location.hydrantsAvailable,
    hydrantsAvailable,
    location.hydrantsAvailableNotes,
    hydrantsAvailableNotes,
    location.respondingFireDepartment,
    respondingFireDepartment,
    location.respondingFireDepartmentNotes,
    respondingFireDepartmentNotes,
    location.distanceToFireDepartment,
    distanceToFireDepartment,
    location.distanceToFireDepartmentNotes,
    distanceToFireDepartmentNotes,
    location.isoProtectionClass,
    isoProtectionClass,
    location.isoProtectionClassNotes,
    isoProtectionClassNotes,
    location.acreage,
    acreage,
    location.acreageNotes,
    acreageNotes,
    location.floodZone,
    floodZone,
    location.floodZoneNotes,
    floodZoneNotes,
  ]);

  React.useEffect(() => {
    document.title = `Underwriting Profile`;
  });

  const [locationUpdate] = useMutation<{
    locationUpdate: LocationProfile;
  }>(UPDATE_LOCATION, {
    variables: {
      id: location.id,
      purchaseClosingDate: purchaseClosingDate,
      purchaseClosingDateNotes: purchaseClosingDateNotes.trim(),
      purchasePrice: purchasePrice,
      purchasePriceNotes: purchasePriceNotes.trim(),
      deededOwner: deededOwner.trim(),
      deededOwnerNotes: deededOwnerNotes.trim(),
      firstTimeHomebuyer: firstTimeHomebuyer,
      hydrantsAvailable: hydrantsAvailable,
      hydrantsAvailableNotes: hydrantsAvailableNotes.trim(),
      respondingFireDepartment: respondingFireDepartment.trim(),
      respondingFireDepartmentNotes: respondingFireDepartmentNotes.trim(),
      distanceToFireDepartment: distanceToFireDepartment.trim(),
      distanceToFireDepartmentNotes: distanceToFireDepartmentNotes.trim(),
      isoProtectionClass: isoProtectionClass.trim(),
      isoProtectionClassNotes: isoProtectionClassNotes.trim(),
      acreage: acreage,
      acreageNotes: acreageNotes.trim(),
      floodZone: floodZone.trim(),
      floodZoneNotes: floodZoneNotes.trim(),
    },
  });

  // if (redirectToClient) {
  // return (
  //   <Confirm
  //     header="There are unsaved changes."
  //     content="Are you sure you want to discard the changes?"
  //     open
  //     cancelButton="Stay on Page"
  //     confirmButton="Discard Changes"
  //     onCancel={this.toggleRedirect}
  //     onConfirm={this.toggleModified}
  //     size="large"
  //   />
  // );

  // return <Redirect to={"/client/" + location.address.client.id} />;
  // }

  function handleFormSubmit() {
    if (
      // Skip submission if date is in invalid state
      purchaseClosingDate === null ||
      purchaseClosingDate === "" ||
      purchaseClosingDate?.length === 10
    ) {
      if (dirty) {
        // send("SAVE");
        locationUpdate();
      }
    }
  }

  return (
    <Instance.Provider value={location.id}>
      <AutoSave.Provider value={autoSave}>
        <div style={{backgroundColor: `#f8f8f8`}}>
          <br />

          <div>
            <div className="flex">
              <div className="flex-1">
                {/* <button
                  onClick={() => {
                    if (dirty && autoSave) {
                      handleFormSubmit();
                    }
                    setRedirectToClient(true);
                  }}
                >
                  &larr; */}
                <h2>
                  {location.address.client.name}
                  {location.address.client.attn === "" ? null : <br />}
                  {location.address.client.attn}
                </h2>
                {/* </button> */}
              </div>
              <div className="flex-1">
                <h2>
                  {location.address.street}
                  <br />
                  {location.address.city}, {location.address.state}{" "}
                  {location.address.zipcode}
                </h2>
              </div>
              <div className="flex-1">
                {/* <Online> */}
                {autoSave && (
                  <IdleTimer
                    timeout={1000}
                    startOnMount={false}
                    onIdle={dirty ? () => handleFormSubmit() : () => {}}
                  />
                )}
                <br />
                <br />
                Auto Save
                <span className="mx-3">
                  <Toggle value={autoSave} setValue={setAutoSave} />
                </span>
                {/* </Online> */}
                {/* <Offline>You are Offline</Offline> */}
              </div>
            </div>

            <form onSubmit={() => handleFormSubmit()}>
              <br />
              <h2>Location</h2>

              <hr />

              <div className="flex p-8">
                <div className="w-1/5">
                  <h3 className="text-lg">Owner Info</h3>
                </div>
                <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
                  <div className="flex">
                    <div className="flex-1">
                      <MyTextInputField
                        label="Deeded Owner"
                        value={deededOwner}
                        setValue={setDeededOwner}
                        initialValue={location.deededOwner}
                        characterLimit={64}
                        toggleMore={
                          deededOwnerNotesVisibility
                            ? undefined
                            : () => setDeededOwnerNotesVisibility(true)
                        }
                      />

                      {deededOwnerNotesVisibility && (
                        <MyTextarea
                          label="Notes"
                          value={deededOwnerNotes}
                          initialValue={location.deededOwnerNotes}
                          setValue={setDeededOwnerNotes}
                          autoFocusIfBlank
                        />
                      )}
                    </div>
                    <div className="flex-1">
                      <MyDateField
                        label="Purchase Closing Date YYYY-MM-DD"
                        value={purchaseClosingDate}
                        setValue={setPurchaseClosingDate}
                        initialValue={location.purchaseClosingDate}
                        toggleMore={
                          purchaseClosingDateNotesVisibility
                            ? undefined
                            : () => setPurchaseClosingDateNotesVisibility(true)
                        }
                      />
                      {purchaseClosingDateNotesVisibility ? (
                        <MyTextarea
                          label="Notes"
                          value={purchaseClosingDateNotes}
                          initialValue={location.purchaseClosingDateNotes}
                          setValue={setPurchaseClosingDateNotes}
                          autoFocusIfBlank
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex-1">
                      <MyIntegerField
                        label="Purchase Price"
                        value={purchasePrice}
                        setValue={setPurchasePrice}
                        initialValue={location.purchasePrice}
                        characterLimit={10}
                        toggleMore={
                          purchasePriceNotesVisibility
                            ? undefined
                            : () => setPurchasePriceNotesVisibility(true)
                        }
                      />

                      {purchasePriceNotesVisibility ? (
                        <MyTextarea
                          label="Notes"
                          value={purchasePriceNotes}
                          initialValue={location.purchasePriceNotes}
                          setValue={setPurchasePriceNotes}
                          autoFocusIfBlank
                        />
                      ) : null}
                    </div>

                    <div className="flex-1">
                      <MyNullBoolean
                        label="First Time Buyer"
                        value={firstTimeHomebuyer}
                        setValue={setFirstTimeHomebuyer}
                        initialValue={location.firstTimeHomebuyer}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex px-8">
                <div className="w-1/5">
                  <h3 className="text-lg">Fire Protection</h3>
                </div>
                <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
                  <div>
                    <div className="flex">
                      <div className="flex-1">
                        <MyTextInputField
                          label="Responding Fire Department"
                          value={respondingFireDepartment}
                          setValue={setRespondingFireDepartment}
                          initialValue={location.respondingFireDepartment}
                          characterLimit={64}
                          toggleMore={
                            respondingFireDepartmentNotesVisibility
                              ? undefined
                              : () =>
                                  setRespondingFireDepartmentNotesVisibility(
                                    true
                                  )
                          }
                        />

                        {respondingFireDepartmentNotesVisibility ? (
                          <MyTextarea
                            label="Notes"
                            value={respondingFireDepartmentNotes}
                            initialValue={
                              location.respondingFireDepartmentNotes
                            }
                            setValue={setRespondingFireDepartmentNotes}
                            autoFocusIfBlank
                          />
                        ) : null}

                        <MyTextInputField
                          label="Distance To Fire Department"
                          value={distanceToFireDepartment}
                          setValue={setDistanceToFireDepartment}
                          initialValue={location.distanceToFireDepartment}
                          characterLimit={64}
                          toggleMore={
                            distanceToFireDepartmentNotesVisibility
                              ? undefined
                              : () =>
                                  setDistanceToFireDepartmentNotesVisibility(
                                    true
                                  )
                          }
                        />

                        {distanceToFireDepartmentNotesVisibility && (
                          <MyTextarea
                            label="Notes"
                            value={distanceToFireDepartmentNotes}
                            initialValue={
                              location.distanceToFireDepartmentNotes
                            }
                            setValue={setDistanceToFireDepartmentNotes}
                            autoFocusIfBlank
                          />
                        )}

                        <MyTextInputField
                          label="Protection Class"
                          value={isoProtectionClass}
                          setValue={setIsoProtectionClass}
                          initialValue={location.isoProtectionClass}
                          toggleMore={
                            isoProtectionClassNotesVisibility
                              ? undefined
                              : () => setIsoProtectionClassNotesVisibility(true)
                          }
                          characterLimit={2}
                        />

                        {isoProtectionClassNotesVisibility ? (
                          <MyTextarea
                            label="Notes"
                            value={isoProtectionClassNotes}
                            initialValue={location.isoProtectionClassNotes}
                            setValue={setIsoProtectionClassNotes}
                            autoFocusIfBlank
                          />
                        ) : null}
                      </div>
                      <div className="flex-1">
                        <MyNullBoolean
                          label="Fire Hydrants Available"
                          value={hydrantsAvailable}
                          setValue={setHydrantsAvailable}
                          initialValue={location.hydrantsAvailable}
                        />

                        <MyTextarea
                          label="Notes"
                          value={hydrantsAvailableNotes}
                          initialValue={location.hydrantsAvailableNotes}
                          setValue={setHydrantsAvailableNotes}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex p-8">
                <div className="w-1/5">
                  <h3 className="text-lg">Location Details</h3>
                </div>
                <div className="w-4/5 p-8 bg-white rounded-lg shadow-lg">
                  <div>
                    <div className="flex">
                      <div className="flex-1">
                        <MyNumberField
                          label="Acreage"
                          value={acreage}
                          setValue={setAcreage}
                          initialValue={location.acreage}
                          digits={4}
                          toggleMore={
                            acreageNotesVisibility
                              ? undefined
                              : () => setAcreageNotesVisibility(true)
                          }
                        />

                        {acreageNotesVisibility ? (
                          <MyTextarea
                            label="Notes"
                            value={acreageNotes}
                            initialValue={location.acreageNotes}
                            setValue={setAcreageNotes}
                            autoFocusIfBlank
                          />
                        ) : null}
                      </div>
                      <div className="flex-1">
                        <MyTextInputField
                          label="Flood Zone"
                          value={floodZone}
                          setValue={setfloodZone}
                          initialValue={location.floodZone}
                          toggleMore={
                            floodZoneNotesVisibility
                              ? undefined
                              : () => setFloodZoneNotesVisibility(true)
                          }
                          characterLimit={64}
                        />

                        {floodZoneNotesVisibility ? (
                          <MyTextarea
                            label="Notes"
                            value={floodZoneNotes}
                            initialValue={location.floodZoneNotes}
                            setValue={setfloodZoneNotes}
                            autoFocusIfBlank
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {Object.values(location.dwellingSet).length === 0 ? (
              <AddDwellingButton locationId={location.id} />
            ) : null}

            {location.dwellingSet.map((dwelling) => (
              <EditDwelling
                key={dwelling.id}
                dwelling={dwelling}
                autoSave={autoSave}
              />
            ))}

            <hr />

            <PriorCancellations
              locationId={location.id}
              priorcancellations={location.priorcancellationSet}
              autoSave={autoSave}
            />

            <Claims
              locationId={location.id}
              claims={location.claimSet}
              autoSave={autoSave}
            />

            <Dogs
              locationId={location.id}
              dogs={location.dogSet}
              autoSave={autoSave}
            />

            <Outbuildings
              locationId={location.id}
              outbuildings={location.outbuildingSet}
              autoSave={autoSave}
            />

            <Mortgages
              locationId={location.id}
              mortgages={location.mortgageSet}
              autoSave={autoSave}
            />
          </div>
        </div>
      </AutoSave.Provider>
    </Instance.Provider>
  );
}
