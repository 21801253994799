import * as React from "react";
import {AuthProvider} from "./AuthContext";
import {UserProvider} from "./UserContext";

import {ApolloProvider} from "@apollo/client";

import {CLIENT} from "../UtilityComponents/MyApolloClient";

function AppProviders({children}) {
  return (
    <ApolloProvider client={CLIENT}>
      <AuthProvider>
        <UserProvider>{children}</UserProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default AppProviders;
