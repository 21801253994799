import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import IdleTimer from "react-idle-timer";

import {MyTextInputField} from "../../../FormComponents/MyTextInputField";

const DELETE_MORTGAGE = loader(
  "../../../Fragments/Underwriting/MortgageDelete.graphql"
);

const MORTGAGE_SET = loader(
  "../../../Fragments/Underwriting/MortgageSet.graphql"
);

const mortgageDeleteMachine = Machine({
  id: "mortgageDelete",
  initial: "idle",
  states: {
    idle: {
      on: {
        DELETE: "deleting",
      },
    },
    deleting: {
      on: {
        DELETED: "deleted",
        GRAPHQL_ERROR: "idle",
      },
    },
    graphql_error: {
      on: {DELETE: "deleting", CANCEL: "idle"},
    },
    deleted: {
      type: "final",
    },
  },
});

interface DeletedMortgage {
  mortgageDelete: {
    id: string;
  };
}

type Mortgage = {
  id: string;
  name: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zipcode: string;
  loanNumber: string;
  escrowBill: string;
  position: string;
};

type MortgageSet = {
  mortgageSet: Mortgage[];
};

type Props = {
  locationId: string;
  mortgage: Mortgage;
  abortDeletion: () => void;
};

export function DeleteMortgageForm({
  locationId,
  mortgage,
  abortDeletion,
}: Props) {
  const [state, send] = useMachine(mortgageDeleteMachine);

  const [confirmText, setConfirmText] = React.useState("");

  const [mortgageDelete] = useMutation<DeletedMortgage>(DELETE_MORTGAGE, {
    variables: {id: mortgage.id},
    update(cache, {data}) {
      if (!data || !data.mortgageDelete || !data.mortgageDelete.id) return;

      let mortgages = cache.readFragment<MortgageSet>({
        id: "LocationObjectType:" + locationId,
        fragment: MORTGAGE_SET,
        fragmentName: "MortgageSet",
      });

      let newmortgages = {};
      if (mortgages) {
        newmortgages = mortgages.mortgageSet.filter(
          (e) => e.id !== data.mortgageDelete.id
        );

        cache.writeFragment({
          id: "LocationObjectType:" + locationId,
          fragment: MORTGAGE_SET,
          fragmentName: "MortgageSet",
          data: {mortgageSet: newmortgages, __typename: "LocationObjectType"},
        });
        send("DELETED");
      }
    },
  });

  function handleSubmit() {
    if (confirmText === "DELETE") {
      send("DELETE");
      mortgageDelete();
    }
  }

  if (
    state.value === "idle" &&
    mortgage.name === "" &&
    mortgage.street === "" &&
    mortgage.street2 === "" &&
    mortgage.city === "" &&
    mortgage.state === "" &&
    mortgage.zipcode === "" &&
    mortgage.loanNumber === "" &&
    mortgage.escrowBill === "U" &&
    mortgage.position === ""
  ) {
    // send("DELETE");
    if (confirmText !== "DELETE") {
      setConfirmText("DELETE");
    }
    return (
      <span>
        <IdleTimer
          timeout={1000}
          startOnMount={true}
          onIdle={() => handleSubmit()}
        />
      </span>
    );
  }

  return (
    <div>
      <h2 className="text-3xl">Permanently delete this mortgage?</h2>
      <p className="mt-4">This action cannot be undone.</p>
      <p className="mt-4">{mortgage.name}</p>
      <div className="mt-6">
        <form onSubmit={() => handleSubmit()}>
          <div className="mb-6">
            <MyTextInputField
              label="Type DELETE to continue"
              value={confirmText}
              setValue={setConfirmText}
              characterLimit={6}
              informUnsavedChanges={false}
              autoFocus
            />
          </div>

          <button
            type="button"
            onClick={() => abortDeletion()}
            className="float-left px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Keep Mortgage
          </button>
          <button
            type="button"
            onClick={() => handleSubmit()}
            className={
              confirmText === "DELETE"
                ? "float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                : "float-right bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
            }
          >
            Delete Forever
          </button>
        </form>
      </div>
    </div>
  );
}
