import * as React from "react";

import Instance from "../Context/Instance";
import DirtyIndicator from "../Components/DirtyIndicator";
import {fieldName} from "../UtilityComponents/FieldName";
import FormLabel from "../Components/FormLabel";

type Props = {
  label: string;
  value: string;
  initialValue?: string;
  setValue: (newValue: string) => void;
  autoFocus?: boolean;
  autoFocusIfBlank?: boolean;
  placeholder?: string | undefined;
  toggleMore?: () => void;
  characterLimit: number;
  informUnsavedChanges?: boolean;
};

export const MyTextInputField = React.memo(function MyInputField({
  label,
  placeholder = "",
  value,
  initialValue,
  setValue,
  autoFocus = false,
  toggleMore,
  characterLimit,
  autoFocusIfBlank = false,
  informUnsavedChanges = true,
}: Props) {
  const instanceId = React.useContext(Instance);

  const [dirty, setDirty] = React.useState(false);

  const hasError = value?.length > characterLimit ? true : false;

  React.useEffect(() => {
    if (value === initialValue || (initialValue === null && value === "")) {
      setDirty(false);
    } else {
      setDirty(true);
    }
  }, [value, initialValue]);

  function handleBlur() {
    if (value !== value.trim()) {
      setValue(value.trim());
    }
  }

  return (
    <div className="w-full p-3 mb-6 md:mb-0">
      <div className="flex justify-between">
        <FormLabel
          label={label}
          htmlFor={`${instanceId}-${fieldName(label)}`}
        />

        {informUnsavedChanges && dirty ? <DirtyIndicator /> : null}
      </div>

      <div className="flex mt-1">
        <div className="relative flex-grow focus-within:z-10">
          <input
            className={`${
              toggleMore ? "rounded-l" : "rounded"
            } appearance-none block w-full bg-coolGray-100 text-coolGray-700 border border-coolGray-200 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-indigo-500 focus:border-indigo-500`}
            id={`${instanceId}-${fieldName(label)}`}
            type="text"
            placeholder={placeholder}
            value={value}
            data-lpignore="true"
            onChange={(event) => setValue(event.target.value)}
            onBlur={handleBlur}
            autoFocus={autoFocus || (autoFocusIfBlank && value === "")}
          />
        </div>

        {toggleMore && (
          <button
            onClick={() => toggleMore()}
            className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium leading-5 transition duration-150 ease-in-out text-coolGray-800 bg-coolGray-200 rounded-r-md hover:text-coolGray-500 hover:bg-coolGray-100 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-coolGray-100 active:text-coolGray-700"
          >
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              stroke="currentColor"
              viewBox="0 0 24 24"
              className="w-5 h-5 text-coolGray-400"
            >
              <path d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path>
            </svg>
          </button>
        )}
      </div>

      {hasError ? (
        <p className="text-sm italic text-red-500">
          This field is limited to {characterLimit} characters. You entered{" "}
          {value.length} characters.
        </p>
      ) : null}
    </div>
  );
});
