import * as React from "react";

// import {ContentPage} from "../Components/ContentPage";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

export function Dashboard() {
  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="dashboard" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle="Dashboard" />

          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="h-full p-3">Dashboard goes here.</main>
          </div>
        </div>
      </div>
    </div>
  );
}
