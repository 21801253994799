import * as React from "react";

import Cleave from "cleave.js/react";

import Instance from "../Context/Instance";
import DirtyIndicator from "../Components/DirtyIndicator";
import FormLabel from "../Components/FormLabel";

type Props = {
  label: string;
  autoFocus?: string;
  value: number | null;
  setValue: (newValue: number | null) => void;
  initialValue: number | null;
  characterLimit: number;
  toggleMore?: () => void;
};

export const MyIntegerField = React.memo(function MyIntegerField({
  label,
  autoFocus,
  value,
  setValue,
  initialValue,
  characterLimit,
  toggleMore,
}: Props) {
  const [status, setStatus] = React.useState("OK");

  React.useEffect(() => {
    // Do Error Checking Here
    if (value && value === 999999999) {
      setStatus("ERROR");
    }
  }, [value]);

  const instanceId = React.useContext(Instance);

  const fieldId =
    instanceId +
    "-" +
    label
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "") +
    "-field";

  return (
    <div className="w-full p-3 mb-6 md:mb-0">
      <div className="flex justify-between">
        <FormLabel label={label} htmlFor={fieldId} />
        {value === initialValue ? null : <DirtyIndicator />}
      </div>
      <Cleave
        id={fieldId}
        options={{
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
        }}
        onChange={(event) =>
          setValue(
            event.target.rawValue === ""
              ? null
              : parseInt(event.target.rawValue)
          )
        }
        data-lpignore="true"
        value={value === null ? undefined : value}
        className={`${
          status === "ERROR"
            ? "border-red-500 mb-3"
            : "border-coolGray-200 focus:border-coolGray-500"
        }
            appearance-none block w-full bg-coolGray-100 text-coolGray-700 border  rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white`}
      />

      {toggleMore && (
        <button type="submit" onClick={() => toggleMore()}>
          ...
        </button>
      )}

      {/* {value.length > characterLimit ? (
        <div>
          <div>Error! Unable to Save</div>
          <p>
            This field is limited to {characterLimit} characters. You entered{" "}
            {value.length} characters.
          </p>
        </div>
      ) : null} */}
    </div>
  );
});
