import * as React from "react";

import {Link} from "react-router-dom";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

import {Report} from "../Components/Report";

// function Tabs(props) {
//   const [activeIndex, setActiveIndex] = React.useState(1);

//   const children = React.Children.map(props.children, (child, index) => {
//     return React.cloneElement(child, {
//       activeIndex: activeIndex,
//       onSelectTab: setActiveIndex
//     });
//   });
//   return <div className="Tabs"> {children} </div>;
// }

// function TabList(props) {
//   const children = React.Children.map(props.children, (child, index) => {
//     return React.cloneElement(child, {
//       isActive: index === props.activeIndex,
//       onSelect: () => props.onSelectTab(index)
//     });
//   });
//   return <Menu tabular> {children} </Menu>;
// }

// function Tab(props) {
//   return (
//     <Menu.Item
//       className={
//         props.isDisabled
//           ? "tab disabled"
//           : props.isActive
//           ? "tab active"
//           : "tab"
//       }
//       onClick={props.isDisabled ? null : props.onSelect}
//     >
//       {props.children}
//     </Menu.Item>
//   );
// }

// function TabPanels(props) {
//   return <div className="panels"> {props.children[props.activeIndex]} </div>;
// }

// function TabPanel(props) {
//   return props.children;
// }

export function Reports() {
  React.useEffect(() => {
    document.title = `Reports`;
  });

  var today = new Date();

  var mmdd =
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + today.getDate()).slice(-2);
  var yyyymmdd = today.getFullYear() + "/" + mmdd;

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="reports" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle="Reports" />

          <div className="flex-1 overflow-auto">
            <main className="h-full p-3">
              <h1> Reports </h1>
              {/* <Tabs>
                <TabList>
                  <Tab>(blue line chart icon) &nbsp; Management</Tab>
                  <Tab>
                    <Icon name="money" color="green" /> &nbsp; Accounting
                  </Tab>
                  <Tab>
                    <Icon name="users" color="violet" /> &nbsp; Customer Service
                  </Tab>
                  <Tab>
                    <Icon name="flag outline" color="red" /> &nbsp; Data
                    Accuracy
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel> */}
              <div>
                <Report
                  csv
                  url="/reports/master_list"
                  name="List of All Clients (spreadsheet)"
                  description="This report outputs a spreadsheet listing all of the clients of
      the agency.It includes former clients as well as prospects.
      "
                />

                <Report
                  csv
                  url="/reports/client_name_and_notes"
                  name="Client Name and Notes (spreadsheet)"
                  description="This report outputs a spreadsheet listing all of the active
      clients of the agency who have data in the Notes Section.It includes the Name on the Account, and the Notes field "
                />

                <Report
                  url="/reports/book_stats"
                  name="Book Statistics"
                  description="Use this report to see a list of all carriers with whom the agency
      has an active policy.By clicking on a carrier 's name, you will
      see all policies
      for that carrier.
      "
                />

                <Report
                  url="/reports/book_of_business"
                  name="Book of Business"
                  description="Exports a spreadsheet report showing one row per active policy."
                />

                <Report
                  url="/reports/client_segment_list/all"
                  name="Client Segment List"
                  description="This report displays the agency-defined client account types. When
      clicking an account type, you will be taken to a list of all clients categorized within that client segment.
      "
                />

                <Report
                  csv
                  url="/reports/mailing_list"
                  name="Newsletter Mailing List (spreadsheet)"
                  description='This report will generate a spreadsheet for your convenience. It
      lists each client account that has the "receives newsletter" setting activated.
      '
                />

                <Report
                  csv
                  url="/reports/not_mailing_list"
                  name="NOT ON Newsletter Mailing List (spreadsheet)"
                  description="This report will generate a spreadsheet for your convenience. It
      lists each client account that has the & quot; receives newsletter & quot; setting TURNED OFF.
      "
                />

                <Report
                  csv
                  url="/reports/special_marketing_list"
                  name="Special Marketing List (spreadsheet)"
                  description="This report will generate a spreadsheet for your convenience. It
      lists each client account with the types of policies carried.
      "
                />

                <Report
                  url="/reports/quote_outcomes"
                  name="Quote Outcomes"
                  description="Displays each user account for your agency and the outcome of all
      quotes perfomed by that user.By clicking on an outcome, you will be shown a list of all clients that match the quote outcome
      for that user.This is helpful in following up on desireable accounts that were not written at the first attempt.
      "
                />

                <Report
                  csv
                  url="/reports/unsold_quotes"
                  name="Unsold Quotes (spreadsheet)"
                  description="This report will generate a spreadsheet listing all quotes that
      were not sold.
      "
                />

                <Report
                  csv
                  url="/reports/contact_info_unconfirmed"
                  name="Contact Info Not Confirmed (spreadsheet)"
                  description="This report shows a suggested list of client accounts whose the
      contact information may be out of date.
      "
                />
                <Report
                  csv
                  url="/reports/referrals"
                  name="Referrals (spreadsheet)"
                  description="This report generates a spreadhseet with the referrers who
      generated a new prospect.
      "
                />
              </div>
              {/* </TabPanel>
                  <TabPanel> */}
              <div>
                <div>
                  <div>Live Prelist</div>
                  <span>Prelist Live</span>

                  <Link to="/accounting/deposit-prelist">Prelist</Link>
                </div>

                <Report
                  url="/utilities/deposit"
                  name="Deposit Prelist"
                  description="This report shows payments made to the office by cash or check
      that have not yet been deposited.
      "
                />
                <Report
                  url="/utilities/reconciliation"
                  name="Payment Reconciliation Tool"
                  description="This report shows payments made to the office by cash or check
      that have not yet been marked as cleared.It allows payments to be marked as cleared.
      "
                />
                <Report
                  url="#"
                  name="Bank Reconciliation Report"
                  description="This report shows payments that came out of the bank account and
      should match the bank statement completely
      if the Payment Reconciliation has been completed
      for the given period.
      "
                />
              </div>
              {/* </TabPanel>
                  <TabPanel> */}
              <div>
                <Report
                  url={"/reports/renewals/" + yyyymmdd}
                  name="Renewal Report"
                  description="This report shows all policies renewing for a particular date.
      Helpful when you want to pick a future date and see all renewals
      for that date.
      "
                />

                <Report
                  url={"/reports/birthdays/" + mmdd}
                  name="Birthday Report"
                  description="This shows a list of birthdays for a given date and allows the
      viewer to advance forward and backward to view different dates at will.
      "
                />

                <Report
                  csv
                  url="/reports/no_phone_numbers"
                  name="Client accounts with no contact information (spreadsheet)"
                  description="This shows a list of client accounts with no phone number
      associated with the client 's account."
                />

                <Report
                  url="/reports/cancellations"
                  name="All Policies Pending Cancellation for Nonpayment (spreadsheet)"
                  description="This report will generate a spreadsheet that lists all of the
      agency 's clients that have an overdue payment that have a policy
      pending cancellation.
      "
                />

                <Report
                  url="/activity/open"
                  name="Open Activities"
                  description="This report shows the multi-step activities that have not been
      seen to full completion.
      "
                />

                <Report
                  csv
                  url="/reports/claims/all"
                  name="Claims (All)"
                  description="Shows a list of all claims for the agency."
                />

                <Report
                  csv
                  url="/reports/claims/open"
                  name="Claims (Open)"
                  description="Shows a list of claims for the agency that have not yet been
      closed.
      "
                />

                <Report
                  csv
                  url="/reports/claims/closed"
                  name="Claims (Closed)"
                  description="Shows a list of claims for the agency that have been closed."
                />

                <Report
                  csv
                  url="/reports/terminated_policies"
                  name="Terminated Policies"
                  description="This report will show a list of policies that have been
      terminated.
      "
                />

                <Report
                  url="/reports/cancellation_triage"
                  name="Current Pending Cancellations"
                  description="This report shows the policies that are pending cancellation."
                />

                <Report
                  url="/reports/object_count_report"
                  name="Object Count Report"
                  description="This report shows a count of objects in the database."
                />
              </div>
              {/* </TabPanel>
                  <TabPanel> */}
              <div>
                <Report
                  url="/reports/policies_failing_validation"
                  name="Policies Failing Validation"
                  description="The policies that show up on this list may have missing
      information, such as a policy type.
      "
                />

                <Report
                  url="/reports/zero_premium"
                  name="Policies with zero premium"
                  description="These policies show a premium of $0."
                />

                <Report
                  url="/reports/overdue_renewals"
                  name="Policies missing a renewal"
                  description="These policies may be expired or terminated, or there may be a
      step needed to update the agency 's records to list the current
      policy term.
      "
                />

                <Report
                  url="/reports/multiple_primary_mailing_addresses"
                  name="Multiple Primary Mailing Addresses"
                  description="This report detects client accounts that have multiple mailing
      addresses with two or more addresses classified as the Primary mailing address.
      "
                />
              </div>
              {/* </TabPanel>
                </TabPanels>
              </Tabs> */}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
