import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

const ADD_DOG = loader("../../../Fragments/Underwriting/DogAdd.graphql");
const DOG_SET = loader("../../../Fragments/Underwriting/DogSet.graphql");

type Dog = {
  id: string;
  dogName: string;
  breedComposition: string;
  anyPriorAggression: string;
  priorAggressionNotes: string;
  sha1: string;
};

type DogSet = {
  dogSet: Dog[];
};

type Props = {
  id: string;
};

export function AddDogButton({id}: Props) {
  const [dogAdd] = useMutation<{
    dogAdd: Dog;
  }>(ADD_DOG, {
    variables: {
      id: id,
    },
    update(cache, {data}) {
      if (!data || !data.dogAdd) return;

      let dogs = cache.readFragment<DogSet>({
        id: "LocationObjectType:" + id,
        fragment: DOG_SET,
        fragmentName: "DogSet",
      });

      let newdogs = {};
      if (dogs) {
        newdogs = dogs.dogSet.concat(data.dogAdd);
      } else {
        newdogs = [data.dogAdd];
      }

      cache.writeFragment({
        id: "LocationObjectType:" + id,
        fragment: DOG_SET,
        fragmentName: "DogSet",
        data: {dogSet: newdogs, __typename: "LocationObjectType"},
      });
    },
  });

  return (
    <button
      onClick={() => dogAdd()}
      className="px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700"
    >
      +
    </button>
  );
}
