import * as React from "react";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import {PasswordIncorrect} from "../Components/PasswordIncorrect";
import {CheckedShieldIcon} from "../Components/Icons/CheckedShieldIcon";
import {backendUrlHostname} from "../urls";

const loginMachine = Machine({
  id: "login",
  initial: "idle",
  states: {
    idle: {
      on: {LOGIN: "logging_in"},
    },
    logging_in: {
      entry: ["logIn"],
      on: {
        ACCOUNT_DISABLED: "disabled",
        INVALID_COMBO: "wrong_credentials",
        SUCCESS: "success",
        TWO_FACTOR_NEEDED: "request_2fa",
        TWO_FACTOR_TOKEN_ERROR: "wrong_token",
      },
    },
    request_2fa: {
      entry: ["requireToken"],
      on: {LOGIN: "logging_in"},
    },
    success: {
      entry: ["redirect"],
    },
    wrong_credentials: {
      on: {DISMISS: "idle"},
    },
    wrong_token: {
      on: {DISMISS: "idle"},
    },
    disabled: {
      type: "final",
    },
  },
});

export default function LoginPage() {
  const [state, send] = useMachine(loginMachine, {
    actions: {
      requireToken: () => setUseToken(true),
      redirect: () => window.location.reload(),
      logIn: () => doLogin(),
    },
  });

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [useToken, setUseToken] = React.useState(false);
  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    document.title = `Please Log In`;
  });

  async function doLogin() {
    let _csrfToken: string | null;

    _csrfToken = null;
    const response = await fetch(`${backendUrlHostname}/utilities/csrf`, {
      credentials: "include",
    });
    const data = await response.json();
    _csrfToken = data.csrfToken;

    let formData = new FormData();

    formData.append("username", username);
    formData.append("password", password);
    if (useToken) formData.append("token", token);

    if (_csrfToken) formData.append("csrfmiddlewaretoken", _csrfToken);
    if (_csrfToken) {
      const loginResponse = await fetch(`${backendUrlHostname}/log-me-in`, {
        body: formData,
        credentials: "include",
        method: "POST",
      });

      let _loginStatus: string | null;
      _loginStatus = null;
      const loginData = await loginResponse.json();
      _loginStatus = loginData.status;

      if (_loginStatus) send(_loginStatus);
    }
  }

  return (
    <div className="flex flex-col justify-center min-h-screen py-6 bg-coolGray-200 sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 transform -skew-y-6 shadow-lg bg-gradient-to-r from-cyan-400 to-lightBlue-500 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              {/* <svg
                // height="234"
                viewBox="0 0 300 234"
                // width="300"
                xmlns="http://www.w3.org/2000/svg"
                className="w-auto h-16 mx-auto text-coolGray-700"
              >
                <path
                  d="m22.5 80.3c5.4 3.1 11.4 4.7 17.4 4.7 3 0 6.1-.4 9.1-1.2l25.2-6.7c12.3-3.3 20.8-14.4 20.8-27.1s-8.5-23.8-20.8-27.1l-24.4-6.5c-6.1-1.8-12.6-1.9-18.9-.2-9 2.4-16.6 8.2-21.3 16.3s-5.8 17.5-3.4 26.6c2.4 9 8.2 16.5 16.3 21.2zm50.4-52.5c10.1 2.7 17.1 11.8 17.1 22.2s-7 19.5-17.1 22.2l-7.7 2.1c1.9-2 3.7-4.3 5.1-6.8 4.7-8.1 5.9-17.5 3.5-26.6-1.6-5.8-4.5-11-8.6-15.2zm-58.9 7.2c4-6.9 10.5-11.9 18.2-14 5.2-1.4 10.5-1.4 15.5 0 .2.1.5.1.7.2 2.3.7 4.5 1.6 6.6 2.8 6.9 4 11.9 10.5 14 18.2s1 15.8-3 22.8c-3.9 6.7-10.1 11.6-17.6 13.8-.2.1-.4.1-.6.2-7.8 2.1-15.9 1-22.8-3s-11.9-10.5-14-18.2c-2.1-7.8-1-15.9 3-22.8z"
                  transform="matrix(3.2856 0 0 3.2856 -13.663088 -46.995944)"
                  fill="currentColor"
                />
                <path
                  d="m40 75c2.9 0 5.7-.5 8.4-1.5 1.6-.6 3.1-1.3 4.5-2.1 7.6-4.5 12.1-12.6 12.1-21.4s-4.5-16.9-12.1-21.4c0 0-.1 0-.1-.1-1.4-.8-2.8-1.5-4.3-2-2.7-1-5.5-1.5-8.4-1.5-13.8 0-25 11.2-25 25s11.1 25 24.9 25zm11.8-41.1c5.2 3.7 8.2 9.6 8.2 16.1s-3 12.4-8.2 16.1c-1.3-1.3-2.5-2.7-3.4-4.2-2.2-3.5-3.4-7.6-3.4-11.9s1.2-8.4 3.4-11.9c1-1.5 2.1-2.9 3.4-4.2zm-11.8-3.9c2.5 0 5 .5 7.3 1.4-4.7 5-7.3 11.6-7.3 18.6s2.6 13.6 7.3 18.6c-2.3.9-4.8 1.4-7.3 1.4-11 0-20-9-20-20s9-20 20-20z"
                  transform="matrix(3.2856 0 0 3.2856 -13.65628 -46.9696)"
                  fill="currentColor"
                />
              </svg> */}
              {/* <img src="/img/logo.svg" classsName="h-7 sm:h-8" /> */}
            </div>
            <div className="divide-y divide-gray-200">
              <div className="space-y-4 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7">
                {state.value === "wrong_credentials" ? (
                  <PasswordIncorrect dismiss={() => send("DISMISS")} />
                ) : null}
                {state.value === "wrong_token" ? (
                  <PasswordIncorrect
                    dismiss={() => send("DISMISS")}
                    problem="two-factor authentication token"
                  />
                ) : null}
                <h2 className="mb-6 text-3xl font-extrabold leading-9 text-center text-coolGray-900">
                  Sign in to your account
                </h2>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    send("LOGIN");
                  }}
                >
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-lg font-medium leading-5 text-coolGray-700"
                    >
                      Username
                    </label>
                    <div className="mt-2 rounded-md shadow-sm">
                      <input
                        id="username"
                        type="text"
                        required
                        className="block w-full px-3 py-2 transition duration-150 ease-in-out border rounded-md appearance-none placeholder-coolGray-400 border-coolGray-300 focus:outline-none focus:ring-teal focus:border-teal-300 sm:text-2xl sm:leading-5"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="password"
                      className="block text-lg font-medium leading-5 text-coolGray-700"
                    >
                      Password
                    </label>
                    <div className="mt-2 rounded-md shadow-sm">
                      <input
                        id="password"
                        type="password"
                        required
                        className="block w-full px-3 py-2 transition duration-150 ease-in-out border rounded-md appearance-none placeholder-coolGray-400 border-coolGray-300 focus:outline-none focus:ring-teal focus:border-teal-300 sm:text-2xl sm:leading-5"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  {useToken && (
                    <div className="mt-6">
                      <label
                        htmlFor="token"
                        className="block text-lg font-medium leading-5 text-coolGray-700"
                      >
                        Two Factor Token
                      </label>
                      <div className="mt-2 rounded-md shadow-sm">
                        <input
                          id="token"
                          type="text"
                          required
                          className="block w-full px-3 py-2 transition duration-150 ease-in-out border rounded-md appearance-none placeholder-coolGray-400 border-coolGray-300 focus:outline-none focus:ring-teal focus:border-teal-300 sm:text-2xl sm:leading-5"
                          value={token}
                          onChange={(e) => setToken(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <div className="mt-12">
                    <span className="block w-full rounded-md shadow-sm">
                      <button
                        type="submit"
                        className="flex justify-center w-full px-4 py-2 text-xl font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-lightBlue-500 hover:bg-cyan-400 focus:outline-none focus:border-cyan-700 focus:ring-teal active:bg-cyan-500"
                      >
                        <CheckedShieldIcon className="w-7" /> &nbsp; Secure Sign
                        in
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
