import * as React from "react";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import Instance from "../../../Context/Instance";

import IdleTimer from "react-idle-timer";
import {DeleteClaimForm} from "./DeleteClaim";

import {MyDateField} from "../../../FormComponents/MyDateField";
import {MyIntegerField} from "../../../FormComponents/MyIntegerField";
import {MyTextarea} from "../../../FormComponents/MyTextarea";
import {MyTextInputField} from "../../../FormComponents/MyTextInputField";

import {CircleIconSpinning} from "../../../Components/Icons/CircleIconSpinning";

// import sha1 from "sha1";

const UPDATE_CLAIM = loader(
  "../../../Fragments/Underwriting/ClaimUpdate.graphql"
);

const dogMachine = Machine({
  id: "dog",
  initial: "idle",
  states: {
    idle: {
      on: {
        SAVE: "saving",
        MAY_DELETE: "may_delete",
      },
    },
    saving: {
      on: {
        SAVED: "idle",
        GRAPHQL_ERROR: "idle",
        // MUTATION_ERROR: "error_saving"
      },
    },
    graphql_error: {
      //
      on: {SAVE: "saving", DISCARD: "refetch", MAY_DELETE: "may_delete"},
    },
    // error_saving: {
    //   // Need to do more work here as it is unable to save after a failed mutation
    //   on: {SAVE: "saving", DISCARD: "refetch", MAY_DELETE: "may_delete"}
    // },
    refetch: {
      on: {
        DOG_LOADED: "idle",
        ERROR: "loading_error",
      },
    },
    loading_error: {
      //
    },
    may_delete: {
      on: {
        DONT_DELETE: "idle",
        CONFIRM_DELETE: "deleting",
      },
    },
    deleting: {
      on: {
        DELETE_SUCCESSFUL: "redirect_after_delete",
        DELETE_FAILED: "error_deleting",
      },
    },
    redirect_after_delete: {
      type: "final",
    },
    error_deleting: {
      on: {
        RETRY_DELETE: "deleting",
        ABORT_DELETION: "idle",
      },
    },
  },
});

type Claim = {
  id: string;
  date: string;
  lossType: string;
  payout: number | null;
  claimNotes: string;
};

type Props = {
  locationId: string;
  claim: Claim;
  autoSave: boolean;
};

export function EditClaim({locationId, claim, autoSave}: Props) {
  const [status, send] = useMachine(dogMachine);

  const [date, setDate] = React.useState<string | null>(claim.date);
  const [lossType, setLossType] = React.useState(claim.lossType);
  const [payout, setPayout] = React.useState(claim.payout);
  const [claimNotes, setClaimNotes] = React.useState(claim.claimNotes);

  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (
      claim.date !== date ||
      claim.lossType !== lossType ||
      claim.payout !== payout ||
      claim.claimNotes !== claimNotes
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [
    claim.date,
    date,
    claim.lossType,
    lossType,
    claim.payout,
    payout,
    claim.claimNotes,
    claimNotes,
  ]);

  const [
    claimUpdate,
    // {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    claimUpdate: Claim;
  }>(UPDATE_CLAIM, {
    variables: {
      id: claim.id,
      date: date,
      lossType: lossType,
      payout: payout,
      claimNotes: claimNotes,
    },
    onCompleted: () => {
      send("SAVED");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      send("GRAPHQL_ERROR");
    },
  });

  function handleFormSubmit() {
    if (dirty) {
      send("SAVE");
      claimUpdate();
    }
  }

  // console.log(state.value);

  // if (mutationError) {
  //   send("MUTATION_ERROR");
  // }

  if (status.value === "may_delete") {
    return (
      <DeleteClaimForm
        locationId={locationId}
        claim={claim}
        abortDeletion={() => send("DONT_DELETE")}
      />
    );
  }

  return (
    <Instance.Provider value={claim.id}>
      <form onSubmit={() => handleFormSubmit()}>
        <div>
          {/* <div className="text-3xl">state: {status.value}</div> */}

          {autoSave ? (
            <IdleTimer
              timeout={1000}
              startOnMount={true}
              onIdle={dirty ? () => handleFormSubmit() : () => {}}
            />
          ) : null}

          <div>
            <div>
              <div className="flex">
                <div className="flex-1">
                  <MyDateField
                    label="Date of Loss"
                    value={date}
                    setValue={setDate}
                    initialValue={claim.date}
                  />
                </div>
                <div className="flex-1">
                  <MyTextInputField
                    label="Loss Type"
                    value={lossType}
                    setValue={setLossType}
                    initialValue={claim.lossType}
                    characterLimit={64}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  {/* // TODO: Need to fix this so it doesn't blow up when blank. */}
                  <MyIntegerField
                    label="Payout"
                    value={payout}
                    setValue={setPayout}
                    initialValue={claim.payout}
                    characterLimit={8}
                  />
                </div>
                <div className="flex-1">
                  <MyTextarea
                    label="Notes"
                    value={claimNotes}
                    initialValue={claim.claimNotes}
                    setValue={setClaimNotes}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            {dirty ? (
              <button
                type="button"
                onClick={() => handleFormSubmit()}
                className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
              >
                {autoSave ? (
                  <>
                    <CircleIconSpinning className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" />{" "}
                    Auto Saving
                  </>
                ) : (
                  "Save"
                )}
              </button>
            ) : (
              <button
                type="button"
                // onClick={() => handleFormSubmit()}
                className="px-4 py-2 font-bold text-white bg-blue-500 rounded opacity-50 cursor-not-allowed"
              >
                Saved
              </button>
            )}

            {/* {mutationError && (
              <div className="text-3xl">
                {(() => {
                  switch (mutationError.message.split("GraphQL error: ")[1]) {
                    case "value too long for type character varying(64)\n":
                      return (
                        <span>
                          Changes cannot be saved
                          <br /> One or more fields has too many characters.
                        </span>
                      );
                    case "AM":
                      return <span>Additional Mailing</span>;
                    case "OT":
                      return <span>Other</span>;
                    default:
                      console.log("switch didn't have a match.");
                      console.log(mutationError.message);
                      return <span>Error: {mutationError.message}</span>;
                  }
                })()}
              </div>
            )} */}

            <button
              type="button"
              onClick={() => send("MAY_DELETE")}
              className="float-right px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </Instance.Provider>
  );
}
