import * as React from "react";

import {gql} from "@apollo/client";
import {useQuery} from "@apollo/client";

import {Header} from "../../Components/Header";
import {Sidebar} from "../../Components/Sidebar";

import {BankAccount} from "../../Components/BankAccount";

const GET_BANK_ACCOUNT_LIST = gql`
  query BankAccountList {
    bankAccounts {
      id
      name
      bankName
      routing
      account
      undepositedPaymentCount
    }
  }
`;

type Account = {
  id: string;
  name: string;
  account: string;
  bankName: string;
  routing: string;
  undepositedPaymentCount: number;
};

type AccountList = {
  bankAccounts: Account[];
};

export function BankAccountList() {
  const {loading, error, data} = useQuery<AccountList>(GET_BANK_ACCOUNT_LIST);

  React.useEffect(() => {
    document.title = `Deposit`;
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="downloads" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle="Deposit Prelist" />

          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="h-full p-3">
              <h2 className="mt-4 ml-6 text-xl font-bold text-coolGray-800">
                Choose a Bank Account for Deposit:
              </h2>

              <div className="flex flex-wrap items-start">
                {data &&
                  data.bankAccounts.map((account) => (
                    <BankAccount account={account} key={account.id} />
                  ))}
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
