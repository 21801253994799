import * as React from "react";

import {fieldName} from "../UtilityComponents/FieldName";
import Cleave from "cleave.js/react";
import DirtyIndicator from "../Components/DirtyIndicator";
import FormLabel from "../Components/FormLabel";

type Props = {
  label: string;
  value: string | null;
  setValue: (newValue: string | null) => void;
  initialValue: string;
  toggleMore?: () => void;
  placeholder?: string;
  autoFocus?: boolean;
  informUnsavedChanges?: boolean;
};

export const MyDateField = React.memo(function MyDateField({
  label,
  value,
  setValue,
  initialValue,
  toggleMore,
  placeholder,
  autoFocus,
  informUnsavedChanges = true,
}: Props) {
  const [status, setStatus] = React.useState("OK");
  const [unsaved, setUnsaved] = React.useState(false);

  React.useEffect(() => {
    // Do Error Checking Here
    if (value && value === "2020-04-19") {
      setStatus("ERROR");
    }
  }, [value]);

  React.useEffect(() => {
    if (value === initialValue || (initialValue === null && value === "")) {
      setUnsaved(false);
    } else {
      setUnsaved(true);
    }
  }, [value, initialValue]);

  function handleBlur() {
    // If field loses focus and it isn't ten digits, Set it to empty to allow it to save without error
    if (value?.length !== 10) {
      setValue(null);
      return false;
    }
  }

  return (
    <div className="w-full p-3 mb-6 md:mb-0">
      <div className="flex justify-between">
        <FormLabel label={label} htmlFor={fieldName(label)} />

        {informUnsavedChanges && unsaved ? <DirtyIndicator /> : null}
      </div>

      <Cleave
        options={{date: true, datePattern: ["Y", "m", "d"], delimiter: "-"}}
        onChange={(event) => setValue(event.target.value)}
        onBlur={handleBlur}
        data-lpignore="true"
        value={value?.toString()}
        className={
          status === "ERROR"
            ? "appearance-none block w-full bg-coolGray-200 text-coolGray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            : "appearance-none block w-full bg-coolGray-200 text-coolGray-700 border border-coolGray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-coolGray-500"
        }
      />

      {toggleMore ? (
        <button type="submit" onClick={() => toggleMore()}>
          ...
        </button>
      ) : null}
    </div>
  );
});
