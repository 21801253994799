import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import IdleTimer from "react-idle-timer";

import {MyTextInputField} from "../../../FormComponents/MyTextInputField";

const DELETE_CLAIM = loader(
  "../../../Fragments/Underwriting/ClaimDelete.graphql"
);

const CLAIM_SET_FRAGMENT = loader(
  "../../../Fragments/Underwriting/ClaimSet.graphql"
);

const claimDeleteMachine = Machine({
  id: "claimDelete",
  initial: "idle",
  states: {
    idle: {
      on: {
        DELETE: "deleting",
      },
    },
    deleting: {
      on: {
        DELETED: "deleted",
        GRAPHQL_ERROR: "idle",
      },
    },
    graphql_error: {
      on: {DELETE: "deleting", CANCEL: "idle"},
    },
    deleted: {
      type: "final",
    },
  },
});

interface DeletedClaim {
  claimDelete: {
    id: string;
  };
}

type Claim = {
  id: string;
  date: string;
  lossType: string;
  payout: number | null;
  claimNotes: string;
};

type ClaimSet = {
  claimSet: Claim[];
};

type Props = {
  locationId: string;
  claim: Claim;
  abortDeletion: () => void;
};

export function DeleteClaimForm({locationId, claim, abortDeletion}: Props) {
  const [state, send] = useMachine(claimDeleteMachine);

  const [confirmText, setConfirmText] = React.useState("");

  const [claimDelete] = useMutation<DeletedClaim>(DELETE_CLAIM, {
    variables: {id: claim.id},
    update(cache, {data}) {
      if (!data || !data.claimDelete || !data.claimDelete.id) return;

      let claims = cache.readFragment<ClaimSet>({
        id: "LocationObjectType:" + locationId,
        fragment: CLAIM_SET_FRAGMENT,
        fragmentName: "ClaimSet",
      });

      let newclaims = {};
      if (claims) {
        newclaims = claims.claimSet.filter((e) => e.id !== data.claimDelete.id);

        cache.writeFragment({
          id: "LocationObjectType:" + locationId,
          fragment: CLAIM_SET_FRAGMENT,
          fragmentName: "ClaimSet",
          data: {claimSet: newclaims, __typename: "LocationObjectType"},
        });
        send("DELETED");
      }
    },
  });

  function handleSubmit() {
    if (confirmText === "DELETE") {
      send("DELETE");
      claimDelete();
    }
  }

  if (
    state.value === "idle" &&
    claim.lossType === "" &&
    claim.payout === 0 &&
    claim.claimNotes === ""
  ) {
    // send("DELETE");
    if (confirmText !== "DELETE") {
      setConfirmText("DELETE");
    }
    return (
      <span>
        <IdleTimer
          timeout={1000}
          startOnMount={true}
          onIdle={() => handleSubmit()}
        />
      </span>
    );
  }

  return (
    <div>
      <h2 className="text-3xl">Permanently delete this claim?</h2>
      <p className="mt-4">This action cannot be undone.</p>
      <p className="mt-4">
        {claim.date} {claim.lossType} {claim.payout} {claim.claimNotes}
      </p>
      <div className="mt-6">
        <form onSubmit={() => handleSubmit()}>
          <div className="mb-6">
            <MyTextInputField
              label="Type DELETE to continue"
              value={confirmText}
              setValue={setConfirmText}
              characterLimit={6}
              informUnsavedChanges={false}
              autoFocus
            />
          </div>

          <button
            type="button"
            onClick={() => abortDeletion()}
            className="float-left px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Keep Claim
          </button>
          <button
            type="button"
            onClick={() => handleSubmit()}
            className={
              confirmText === "DELETE"
                ? "float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                : "float-right bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
            }
          >
            Delete Forever
          </button>
        </form>
      </div>
    </div>
  );
}
