import * as React from "react";

import {format, isAfter, subDays} from "date-fns";

import {PostponeButton} from "./Reminders/PostponeButton";
import {ToggleCompletionButton} from "./Reminders/ToggleCompletionButton";
import {ClientReminderEdit} from "./ClientReminderEdit";

type ClientReminderType = {
  id: string;
  completed: boolean;
  reminder: string;
  remindOn: string;
  about: {
    name: string;
    attn: string;
  };
};

type Props = {
  clientreminder: ClientReminderType;
};

export function ClientReminder({clientreminder}: Props) {
  const [editing, setEditing] = React.useState(false);

  if (editing) {
    return (
      <ClientReminderEdit
        clientreminder={clientreminder}
        stopEditing={() => setEditing(false)}
      />
    );
  }

  return (
    <div className="border border-coolGray-200" key={clientreminder.id}>
      <div className="p-2">
        <h2 className="mt-2 text-xl font-semibold leading-9 tracking-normal text-indigo-600">
          {clientreminder.reminder}
        </h2>

        {clientreminder.completed ? null : (
          <>
            {isAfter(
              subDays(new Date(), 1),
              new Date(clientreminder.remindOn.replace(/-/g, "/"))
            ) ? (
              <div className="text-3xl font-bold text-red-300">OVERDUE</div>
            ) : null}
            {clientreminder.completed ? null : (
              <PostponeButton reminder={clientreminder} />
            )}
          </>
        )}

        <div className="mt-6">
          {format(
            new Date(clientreminder.remindOn.replace(/-/g, "/")),
            "MMMM do yyyy"
          )}
        </div>

        <div>
          <button
            className="px-6 py-1 m-2 border rounded text-coolGray-700 border-coolGray-500"
            onClick={() => setEditing(true)}
          >
            Edit
          </button>
          <ToggleCompletionButton reminder={clientreminder} />
        </div>
      </div>
    </div>
  );
}
