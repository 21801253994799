import * as React from "react";

import {Link} from "react-router-dom";

type Person = {
  id: string;
  firstName: string;
  lastName: string;
  age: number;
  email: string;
};

type Props = {
  person: Person;
  index: number;
};

export const Birthday = ({person, index}: Props) => {
  return (
    <li className={index === 0 ? "" : "border-t border-coolGray-200"}>
      <Link
        to={"/person/" + person.id}
        className="block transition duration-150 ease-in-out hover:bg-coolGray-50 focus:outline-none focus:bg-coolGray-50"
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex items-center flex-1 min-w-0">
            <div className="flex-shrink-0">
              {/* <img
                className="w-12 h-12 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              /> */}
            </div>
            <div className="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <div className="text-sm font-medium leading-5 text-indigo-600 truncate">
                  {person.firstName}&nbsp;{person.lastName}
                </div>
                <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                  {person.email && (
                    <>
                      <svg
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-coolGray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="truncate">{person.email}</span>
                    </>
                  )}
                </div>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="text-sm leading-5 text-coolGray-900">
                    Age: {person.age}
                  </div>
                  {/* <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                    <svg
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Completed phone screening
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div>
            <svg
              className="w-5 h-5 text-coolGray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </Link>
    </li>
  );
};
