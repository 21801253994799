import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {Prompt} from "react-router";

import {MySelect} from "./MySelect";

import {MyTextInputField} from "./MyTextInputField";

const ADD_CLIENTADDRESS = loader(
  "../Fragments/Clients/ClientAddressAdd.graphql"
);

const CLIENTADDRESS_SET = loader(
  "../Fragments/Clients/ClientAddressSet.graphql"
);

const ADDRESS_TYPE_OPTIONS = [
  {key: "MP", text: "Primary Mailing & Physical", value: "MP"},
  {key: "PM", text: "Primary Mailing", value: "PM"},
  {key: "PP", text: "Primary Physical", value: "PP"},
  {key: "AP", text: "Additional Physical", value: "AP"},
  {key: "AM", text: "Additional Mailing", value: "AM"},
  {key: "OT", text: "Other", value: "OT"},
];

type Client = {
  id: string;
  name: string;
};

type Location = {
  id: string;
};

type ClientAddress = {
  id: string;
  validatedData: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  description: string;
  locationSet: Location[];
  distanceFromOffice: string;
  addressType: string;
  county: string;
  seeAlso: Client[];
};

type ClientAddressSet = {
  clientaddressSet: ClientAddress[];
};

type Props = {
  clientId: string;
  stopAdding: () => void;
};

export function AddAddress({clientId, stopAdding}: Props) {
  const [addressType, setAddressType] = React.useState("MP");
  const [description, setDescription] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setTheState] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");

  function handleDiscard() {
    if (
      addressType === "MP" &&
      description === "" &&
      street === "" &&
      city === "" &&
      state === "" &&
      zipcode === ""
    ) {
      stopAdding();
    } else {
      let confirmDiscardMessage =
        "Are you sure you want to discard the address?\n\nPress OK to discard or cancel to continue entering info.";
      if (window.confirm(confirmDiscardMessage)) {
        setAddressType("MP");
        setDescription("");
        setStreet("");
        setCity("");
        setTheState("");
        setZipcode("");

        stopAdding();
      }
    }
  }

  const [clientaddressAdd] = useMutation<{
    clientaddressAdd: ClientAddress;
  }>(ADD_CLIENTADDRESS, {
    variables: {
      clientId: clientId,
      addressType: addressType,
      description: description.trim(),
      street: street.trim(),
      city: city.trim(),
      state: state.trim().toUpperCase(),
      zipcode: zipcode.trim(),
    },
    update(cache, {data}) {
      if (!data || !data.clientaddressAdd) return;

      let addresses = cache.readFragment<ClientAddressSet>({
        id: "ClientObjectType:" + clientId,
        fragment: CLIENTADDRESS_SET,
        fragmentName: "ClientAddressSet",
      });

      let newaddresses = {};
      if (addresses) {
        newaddresses = addresses.clientaddressSet.concat(data.clientaddressAdd);
      } else {
        newaddresses = [data.clientaddressAdd];
      }

      cache.writeFragment({
        id: "ClientObjectType:" + clientId,
        fragment: CLIENTADDRESS_SET,
        fragmentName: "ClientAddressSet",
        data: {clientaddressSet: newaddresses, __typename: "ClientObjectType"},
      });
    },
  });

  return (
    <div className="bg-indigo-400">
      <div>
        <h3>Adding Address</h3>
      </div>
      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault();

            clientaddressAdd().then(() => {
              setAddressType("MP");
              setDescription("");
              setStreet("");
              setCity("");
              setTheState("");
              setZipcode("");
              stopAdding();
            });
          }}
        >
          <Prompt
            when={street !== ""}
            message="There is an unsaved Address. If you proceed the data will be lost forever."
          />
          <div>
            <div>
              <div>
                <MySelect
                  label="Address Type"
                  options={ADDRESS_TYPE_OPTIONS}
                  value={addressType}
                  setValue={setAddressType}
                  initialValue={"MP"}
                />
              </div>

              <div>
                <MyTextInputField
                  label="Street"
                  value={street}
                  setValue={setStreet}
                  initialValue={""}
                  autoFocus
                  characterLimit={50}
                />
              </div>
              <div>
                <MyTextInputField
                  label="City"
                  value={city}
                  setValue={setCity}
                  initialValue={""}
                  characterLimit={50}
                />
              </div>
            </div>
            <div>
              <div>
                <MyTextInputField
                  label="State"
                  value={state}
                  setValue={setTheState}
                  initialValue={""}
                  characterLimit={2}
                />
              </div>
              <div>
                <MyTextInputField
                  label="Zipcode"
                  value={zipcode}
                  setValue={setZipcode}
                  initialValue={""}
                  characterLimit={10}
                />
              </div>
              <div>
                <MyTextInputField
                  label="OPTIONAL Description"
                  value={description ? description : ""}
                  setValue={setDescription}
                  initialValue={""}
                  characterLimit={50}
                />
              </div>
            </div>
          </div>

          <br />
          <div>
            {street !== "" ? (
              <button type="submit">Save Address</button>
            ) : (
              <button type="submit" disabled>
                Save Address
              </button>
            )}
            <button type="button" onClick={handleDiscard}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
