import * as React from "react";

import {Link} from "react-router-dom";

import {gql, useQuery} from "@apollo/client";

import {isBefore} from "date-fns";

import {BreadcrumbNavigation} from "../../Components/BreadcrumbNavigation";
import {BreadcrumbItem} from "../../Components/BreadcrumbItem";
import {Header} from "../../Components/Header";

import {PendingCancellation} from "../../Components/PendingCancellation";

const LAPSED_POLICIES = gql`
  query CancellationListQuery {
    allPendingCancellations {
      id
      actionDate
      premium
      status
      quoteClosed
      description
      policy {
        id
        company {
          id
          name
          carrier {
            id
            name
          }
        }
        policyNumber
        client {
          id
          name
          attn
          csr {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

interface PendingCancellationType {
  status: string;
  actionDate: string;
  id: string;
  policy: {
    policyNumber: string;
    client: {
      id: string;
      name: string;
      attn: string;
      csr: {
        id: string;
        firstName: string;
        lastName: string;
      };
    };
    company: {
      carrier: {
        name: string;
      };
    };
  };
  quoteClosed: boolean;
}

interface PendingCancellations {
  allPendingCancellations: PendingCancellationType[];
}

export function NonpaysLapsed() {
  const {loading, error, data} =
    useQuery<PendingCancellations>(LAPSED_POLICIES);

  React.useEffect(() => {
    document.title = `Cancellations`;
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  return (
    <div className="flex flex-col flex-1 min-w-0 bg-white">
      <Header pageTitle="Lapsed Policies" />

      <div className="flex-1 overflow-auto bg-coolGray-200">
        <main className="h-full p-3">
          <BreadcrumbNavigation>
            <BreadcrumbItem>
              <Link
                to="/nonpays"
                className="text-sm font-medium leading-5 transition duration-150 ease-in-out text-coolGray-500 hover:text-coolGray-700"
              >
                Nonpays
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <span
                aria-current="page"
                className="text-sm font-medium leading-5 transition duration-150 ease-in-out text-coolGray-500 hover:text-coolGray-700"
              >
                Lapsed
              </span>
            </BreadcrumbItem>
          </BreadcrumbNavigation>

          <div>
            <ul className="px-3 pt-1 pb-3">
              {data &&
                data.allPendingCancellations
                  .filter((cancellation) =>
                    isBefore(
                      new Date(cancellation.actionDate.replace(/-/g, "/")),
                      new Date()
                    )
                  )
                  .filter((cancellation) => cancellation.status === "A_0")
                  .map((cancellation, index) => (
                    <PendingCancellation
                      cancellation={cancellation}
                      key={cancellation.id}
                      showCancelDate={true}
                      index={index}
                    />
                  ))}
            </ul>
          </div>
        </main>
      </div>
    </div>
  );
}
