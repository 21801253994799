import * as React from "react";

import {backendUrlHostname} from "../urls";
import {format} from "date-fns";

// Uncomment when changing link to react client page
// import {Link} from "react-router-dom";

import {ToggleReminderContactedButton} from "../Components/ToggleReminderContactedButton";

type PendingCancellationType = {
  id: string;
  actionDate: string;
  policy: {
    policyNumber: string;
    client: {
      id: string;
      name: string;
      attn: string;
      csr: {
        id: string;
        firstName: string;
        lastName: string;
      };
    };
    company: {
      carrier: {
        name: string;
      };
    };
  };
  quoteClosed: boolean;
};

type Props = {
  index: number;
  cancellation: PendingCancellationType;
  showCancelDate?: boolean;
};

export function PendingCancellation({
  index,
  cancellation,
  showCancelDate,
}: Props) {
  return (
    <li key={cancellation.id} className={index > 0 ? "mt-3" : undefined}>
      <div className="block p-5 bg-white rounded-lg shadow">
        <div className="flex justify-between">
          <p className="text-sm font-medium leading-snug text-coolGray-900">
            {/* <Link to={"/client/" + cancellation.policy.client.id}> */}
            <a
              href={
                backendUrlHostname + "/clients/" + cancellation.policy.client.id
              }
            >
              <span className="text-base">
                {cancellation.policy.client.name}
              </span>
              {cancellation.policy.client.attn && (
                <span className="text-base">
                  <br />
                  {cancellation.policy.client.attn}
                </span>
              )}
            </a>
            {/* </Link> */}
            <br />
            <br />
            {cancellation.policy.company.carrier.name}
            <br /># {cancellation.policy.policyNumber}
            <br />
            {showCancelDate ? (
              <>
                <br />
                Cancel Date:{" "}
                {format(
                  new Date(cancellation.actionDate.replace(/-/g, "/")),
                  "MMMM d"
                )}
                <br />
                <br />
              </>
            ) : null}
            {cancellation.quoteClosed ? (
              <ToggleReminderContactedButton id={cancellation.id}>
                <span
                  className={
                    "px-2 py-1 mt-3 leading-tight inline-flex items-center bg-teal-100 rounded"
                  }
                >
                  <svg
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className={"h-6 w-6 text-teal-500"}
                  >
                    <path d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                  </svg>

                  <span className={"ml-2 text-sm font-medium text-teal-900"}>
                    Contacted
                  </span>
                </span>
              </ToggleReminderContactedButton>
            ) : (
              <ToggleReminderContactedButton id={cancellation.id}>
                mark as contacted
              </ToggleReminderContactedButton>
            )}
          </p>
        </div>
      </div>
    </li>

    // ${cancellation.premium}{" "}
    // {isFuture(cancellation.actionDate) ? "is" : "was"} needed before{" "}
    // <br />
    // {cancellation.actionDate} to prevent the{" "}
    //  policy (#
    // ) <br />
    // for{" "}
    // {" "}
    // from lapsing.{" "}
  );
}
