import * as React from "react";

import {gql} from "@apollo/client";

import {Mutation} from "@apollo/client/react/components";
const ARCHIVE_ADDRESS = gql`
  mutation archiveClientAddress($clientaddressId: String!) {
    archiveClientaddress(
      clientaddressData: {clientaddressId: $clientaddressId}
    ) {
      clientaddress {
        id
        active
        addressType
        description
        street
        city
        state
        zipcode
        validatedData
        locationSet {
          id
          created
          purchaseClosingDate
          purchaseClosingDateNotes
          purchasePrice
          purchasePriceNotes
          deededOwner
          deededOwnerNotes
          firstTimeHomebuyer
          hydrantsAvailable
          hydrantsAvailableNotes
          respondingFireDepartment
          distanceToFireDepartment
          isoProtectionClass
          isoProtectionClassNotes
        }
      }
    }
  }
`;

export function DeleteAddress(props) {
  const [allowDelete, setAllowDelete] = React.useState(false);
  const [confirmText, setConfirmText] = React.useState("");

  // function handleSubmit(event) {
  //   event.preventDefault();
  //   props.mutate({
  //     variables: {
  //       addressId: props.address.id
  //     }
  //   });
  // }

  function handleUndo(event) {
    event.preventDefault();
    props.toggleDeleteForm();
  }

  function handleConfirmChange(event) {
    setConfirmText(event.target.value);
    if (event.target.value === "ARCHIVE") {
      setAllowDelete(true);
    } else {
      setAllowDelete(false);
    }
  }

  return (
    <Mutation mutation={ARCHIVE_ADDRESS}>
      {(archiveClientAddress) => (
        <div>
          <h2>Do you really want to hide this address?</h2>
          <div>ADDRESS HERE</div>
          <div>
            <p>
              Type ARCHIVE to confirm that you want to make the address
              inactive.
            </p>
            <form
              onSubmit={(event) => {
                event.preventDefault();

                archiveClientAddress({
                  variables: {
                    clientaddressId: props.address.id,
                  },
                });
              }}
            >
              <input
                type="text"
                name="confirm_text"
                onChange={handleConfirmChange}
                value={confirmText}
                autoFocus
                data-lpignore="true"
              />

              <button type="button" onClick={handleUndo}>
                Keep Address Active
              </button>
              <button disabled={!allowDelete} type="submit">
                Make Address Inactive
              </button>
            </form>
          </div>
        </div>
      )}
    </Mutation>
  );
}
