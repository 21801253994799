import * as React from "react";

import DirtyIndicator from "../Components/DirtyIndicator";
import FormLabel from "../Components/FormLabel";

import {fieldName} from "../UtilityComponents/FieldName";

type Option = {
  key: string;
  value: string;
  text: string;
};

interface GraphQLOption {
  id: string;
  name: string;
}

type Props = {
  label: string;
  options?: Option[];
  graphqloptions?: GraphQLOption[];
  value: string;
  setValue: (newValue: string) => void;
  initialValue: string;
};

export const MySelect = React.memo(function MySelect({
  label,
  options,
  graphqloptions,
  value,
  setValue,
  initialValue,
}: Props) {
  return (
    //
    <div className="w-full p-3 mb-6 md:mb-0">
      <div className="flex justify-between">
        <FormLabel htmlFor={fieldName(label)} label={label} />

        {value === initialValue ? null : <DirtyIndicator />}
      </div>
      <div className="relative">
        <select
          className="block w-full px-4 py-3 pr-8 leading-tight border rounded appearance-none text-coolGray-700 bg-coolGray-100 border-coolGray-200 focus:outline-none focus:bg-white focus:ring-indigo-500 focus:border-indigo-500"
          id={fieldName(label)}
          value={value}
          onChange={(event) => setValue(event.target.value)}
        >
          {options?.map((option) => (
            <option key={option.key} value={option.value}>
              {option.text}
            </option>
          ))}
          {graphqloptions?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
});
