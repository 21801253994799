import * as React from "react";

// import {Link} from "react-router-dom";

import {AddTodoList} from "../../Components/Todo/TodoListAdd";
import {TodoList} from "../../Components/Todo/TodoList";

import {ClearCompletedTodos} from "./ClearCompletedTodos";
import {TodoProjectEdit} from "./TodoProjectEdit";
import {CompleteProject} from "./CompleteProject";

type TodoItem = {
  id: string;
  description: string;
  completed: boolean;
};

type TodoListType = {
  id: string;
  name: string;
  unclearedTodos: TodoItem[];
};

type TodoProjectType = {
  id: string;
  name: string;
  incompleteLists: TodoListType[];
};

type Props = {
  todoproject: TodoProjectType;
};

export function TodoProject({todoproject}: Props) {
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    document.title = `To Do: ` + todoproject.name;
  });

  if (editing) {
    return (
      <TodoProjectEdit todoproject={todoproject} setEditing={setEditing} />
    );
  }

  return (
    <div>
      <div className="float-right print:hidden">
        <button type="button" onClick={() => setEditing(true)}>
          Edit
        </button>
        &nbsp; &nbsp; &nbsp;&nbsp;
        <ClearCompletedTodos />
        &nbsp; &nbsp; &nbsp;&nbsp;
        <button onClick={() => window.print()}>Print</button>
      </div>

      {todoproject.incompleteLists.map((todolist) => (
        <TodoList key={todolist.id} todolist={todolist} />
      ))}

      {Object.keys(todoproject.incompleteLists).length === 0 ? (
        <div className="print:hidden">
          <AddTodoList projectId={todoproject.id} />

          <CompleteProject todoproject={todoproject} />
        </div>
      ) : (
        <AddTodoList projectId={todoproject.id} />
      )}

      {/* <Responsive minWidth={768}>
        <Grid stackable columns={2} padded="vertically">
          {todoproject.incompleteLists.map(todolist => (
            <TodoList key={todolist.id} todolist={todolist} />
          ))}

          {Object.keys(todoproject.incompleteLists).length === 0 ? (
            <Grid.Column>
              <div className="print:hidden">
                <AddTodoList projectId={todoproject.id} />

                <Divider horizontal>Or</Divider>

                <CompleteProject todoproject={todoproject} />
              </div>
            </Grid.Column>
          ) : (
            <AddTodoList projectId={todoproject.id} />
          )}
        </Grid>
      </Responsive> */}
    </div>
  );
}
