import * as React from "react";

import {loader} from "graphql.macro";

import {useMutation} from "@apollo/client";

// import { QRCode } from "react-qr-svg";

var QRCode = require("qrcode.react");

const REGENERATE_SECRET = loader(
  "../../../Fragments/Contacts/TwoFactorAuthenticationRegenerateSecret.graphql"
);

const VERIFY_OTP = loader(
  "../../../Fragments/Contacts/TwoFactorAuthenticationVerify.graphql"
);

type Group = {
  id: string;
  name: string;
};

type UserSettings = {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  profile: {
    id: string;
    mobile: string;
    authyId: string;
    passwordChanged: string | null;
    timezone: {id: string; name: string};
    language: string;
    otpSecret: string;
    otpVerified: string;
  };
  groups: Group[];
};

type Props = {
  userSettings: UserSettings;
  verificationInput: string;
  setVerificationInput: React.Dispatch<React.SetStateAction<string>>;
};

export function StateVerificationNeeded({
  userSettings,
  verificationInput,
  setVerificationInput,
}: Props) {
  const [
    regenerateTwoFactorAuthenticationSecret,
    {error: regenerateMutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    regenerateTwoFactorAuthenticationSecret: UserSettings;
  }>(REGENERATE_SECRET, {
    variables: {
      otp: verificationInput,
    },
    onCompleted: () => {
      // send("SAVED");
      console.log("saved");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      // send("GRAPHQL_ERROR");
      console.log("error" + regenerateMutationError);
    },
  });

  const [
    verifyTwoFactorAuthentication,
    {error: verifyMutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    verifyTwoFactorAuthentication: UserSettings;
  }>(VERIFY_OTP, {
    variables: {
      otp: verificationInput,
    },
    onCompleted: () => {
      // send("SAVED");
      console.log("saved");
    },
    onError: () => {
      // alert(
      //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      // );
      // send("GRAPHQL_ERROR");
      console.log("error" + verifyMutationError);
    },
  });

  return (
    <div>
      <p>
        Scan this code with your authenticator app. Don't share it with anyone
        else.
      </p>
      <p>
        If you want to regenerate the secret key, click here{" "}
        <button
          type="button"
          onClick={() => regenerateTwoFactorAuthenticationSecret()}
        >
          Regenerate Secret
        </button>
      </p>
      <QRCode
        level="Q"
        style={{width: 256}}
        value={`otpauth://totp/AgencyPipeline:${userSettings.username}?secret=${userSettings.profile.otpSecret}&issuer=AgencyPipeline`}
      />

      <p>
        After scanning it, please enter the One-time password here:
        <input
          type="text"
          onChange={(event) => setVerificationInput(event.target.value)}
          value={verificationInput}
        />
        {verificationInput.length === 6 ? (
          <button type="button" onClick={() => verifyTwoFactorAuthentication()}>
            Submit
          </button>
        ) : null}
      </p>
    </div>
  );
}
