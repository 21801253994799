import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import IdleTimer from "react-idle-timer";

import {MyTextInputField} from "../../../FormComponents/MyTextInputField";

const DELETE_PRIOR_CANCELLATION = loader(
  "../../../Fragments/Underwriting/PriorCancellationDelete.graphql"
);

const PRIOR_CANCELLATION_SET = loader(
  "../../../Fragments/Underwriting/PriorCancellationSet.graphql"
);

const priorCancellationDeleteMachine = Machine({
  id: "priorcancellationDelete",
  initial: "idle",
  states: {
    idle: {
      on: {
        DELETE: "deleting",
      },
    },
    deleting: {
      on: {
        DELETED: "deleted",
        GRAPHQL_ERROR: "idle",
      },
    },
    graphql_error: {
      on: {DELETE: "deleting", CANCEL: "idle"},
    },
    deleted: {
      type: "final",
    },
  },
});

interface DeletedPriorCancellation {
  priorCancellationDelete: {
    id: string;
  };
}

type PriorCancellation = {
  id: string;
  date: string;
  insuranceCompany: string;
  cancellationType: string;
  cancellationNotes: string;
};

type PriorCancellationSet = {
  priorcancellationSet: PriorCancellation[];
};

type Props = {
  locationId: string;
  priorcancellation: PriorCancellation;
  abortDeletion: () => void;
};

export function DeletePriorCancellationForm({
  locationId,
  priorcancellation,
  abortDeletion,
}: Props) {
  const [state, send] = useMachine(priorCancellationDeleteMachine);
  const [confirmText, setConfirmText] = React.useState("");

  const [priorCancellationDelete] = useMutation<DeletedPriorCancellation>(
    DELETE_PRIOR_CANCELLATION,
    {
      variables: {id: priorcancellation.id},
      update(cache, {data}) {
        if (
          !data ||
          !data.priorCancellationDelete ||
          !data.priorCancellationDelete.id
        )
          return;

        let priorcancellations = cache.readFragment<PriorCancellationSet>({
          id: "LocationObjectType:" + locationId,
          fragment: PRIOR_CANCELLATION_SET,
          fragmentName: "PriorCancellationSet",
        });

        let newpriorcancellations = {};
        if (priorcancellations) {
          newpriorcancellations = priorcancellations.priorcancellationSet.filter(
            (e) => e.id !== data.priorCancellationDelete.id
          );

          cache.writeFragment({
            id: "LocationObjectType:" + locationId,
            fragment: PRIOR_CANCELLATION_SET,
            fragmentName: "PriorCancellationSet",
            data: {
              priorcancellationSet: newpriorcancellations,
              __typename: "LocationObjectType",
            },
          });
          send("DELETED");
        }
      },
    }
  );

  function handleSubmit() {
    if (confirmText === "DELETE") {
      send("DELETE");
      priorCancellationDelete();
    }
  }

  if (
    state.value === "idle" &&
    priorcancellation.cancellationType === "Q" &&
    priorcancellation.insuranceCompany === "" &&
    priorcancellation.cancellationNotes === ""
  ) {
    if (confirmText !== "DELETE") {
      setConfirmText("DELETE");
    }
    return (
      <span>
        <IdleTimer
          timeout={1000}
          startOnMount={true}
          onIdle={() => handleSubmit()}
        />
      </span>
    );
  }

  return (
    <div>
      <h2 className="text-3xl">Permanently delete this priorcancellation?</h2>
      <p className="mt-4">This action cannot be undone.</p>
      <p className="mt-4">
        Date: {priorcancellation.date}
        <br />
        Type:{" "}
        {(() => {
          switch (priorcancellation.cancellationType) {
            case "Q":
              return "Unknown";
            case "N":
              return "Nonrenewal";
            case "A":
              return "Nonrenewal - Agent No Longer Represents Carrier";
            case "P":
              return "Cancellation for Non Payment / Lapse";
            case "U":
              return "Cancellation for Underwriting Issue";
            default:
              console.log("switch didn't have a match.");
              console.log(priorcancellation.cancellationType);
              return <span>Error: {priorcancellation.cancellationType}</span>;
          }
        })()}
        <br />
        Insurance Company: {priorcancellation.insuranceCompany}
        <br />
        Notes: {priorcancellation.cancellationNotes}
      </p>
      <div className="mt-6">
        <form onSubmit={() => handleSubmit()}>
          <div className="mb-6">
            <MyTextInputField
              label="Type DELETE to continue"
              value={confirmText}
              setValue={setConfirmText}
              characterLimit={6}
              autoFocus
            />
          </div>

          <button
            type="button"
            onClick={() => abortDeletion()}
            className="float-left px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Keep Prior Cancellation
          </button>
          <button
            type="button"
            onClick={() => handleSubmit()}
            className={
              confirmText === "DELETE"
                ? "float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                : "float-right bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
            }
          >
            Delete Forever
          </button>
        </form>
      </div>
    </div>
  );
}
