import * as React from "react";
import {formatDistance, format} from "date-fns";
// import Dropzone from "react-dropzone";

import {useUser} from "../../Context/UserContext";

import {Attachment} from "../Attachment";

import {DeleteActivityNote} from "./ActivityNoteDelete";

import {ActivityNoteUpdate} from "./ActivityNoteUpdate";

interface AttachmentType {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
}

interface Tag {
  id: string;
  document: AttachmentType;
}

interface ActivityNoteType {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
}

interface Props {
  activitynote: ActivityNoteType;
}

export function ActivityNote({activitynote}: Props) {
  const user = useUser();

  // const [accept, setAccept] = React.useState("");
  // const [files, setFiles] = React.useState([]);
  // const [dropzoneActive, setDropzoneActive] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);

  function toggleDeleteConfirm() {
    setDeleteConfirm(deleteConfirm ? false : true);
  }

  // console.log(activitynote.note);

  // if (1 === 1) {
  //   return <span>{activitynote.note}</span>;
  // }
  // function onDrop(files) {
  //   setFiles({files});
  // }

  // let dropzoneRef;
  // if (activitynote.active === false) {
  //   //console.log("found an inactive note:" + activitynote.note);
  // }

  if (editing) {
    return (
      <ActivityNoteUpdate activitynote={activitynote} setEditing={setEditing} />
    );
  }
  if (!activitynote.active) {
    // Notes marked as deleted.
    return null;
  }
  // return (
  //   <div className="dropzone">
  //     <Dropzone
  //       disableClick
  //       onDrop={onDrop}
  //       style={{border: "5px solid #ffffff"}}
  //       activeStyle={{
  //         backgroundColor: "#e5f9e7",
  //         color: "#1ebc30",
  //         borderColor: "#1ebc30",
  //         border: "5px dashed",
  //         borderRadius: "15px"
  //       }}
  //       ref={node => {
  //         dropzoneRef = node;
  //       }}
  //     >
  //       {({getRootProps, getInputProps, isDragActive}) => {
  return (
    <div className="p-3 m-1 bg-indigo-100 border border-indigo-400 rounded shadow-lg">
      {/* <img
        src={
          "https://api.adorable.io/avatars/50/" +
          activitynote.versionOwner.firstName +
          activitynote.versionOwner.lastName +
          ".png"
        }
        alt={
          activitynote.versionOwner.firstName +
          " " +
          activitynote.versionOwner.lastName
        }
      /> */}
      <div style={{padding: `0`}}>
        <span className="font-medium text-indigo-700">
          {activitynote.versionOwner.firstName}{" "}
          {activitynote.versionOwner.lastName}{" "}
        </span>
        <span>
          {formatDistance(new Date(activitynote.timestamp), new Date())} ago.{" "}
          {format(new Date(activitynote.timestamp), "MMMM do yyyy, h:mm:ss a")}
        </span>
        <div>
          <div className="mt-2 text-base font-semibold leading-5 text-coolGray-900 sm:mt-0 sm:col-span-2">
            {activitynote.note}

            {/* {files.length === 0 ? "" : <h2>Dropped files</h2>}
            <ul>
              {files.map(f => (
                <li key={f.name}>
                  {f.name} - {f.size} bytes
                </li>
              ))}
            </ul> */}
          </div>
        </div>

        <div>
          {activitynote.versionOwner.id === user.id && (
            <button
              onClick={() => setEditing(true)}
              type="button"
              className="mx-3 my-1"
            >
              Edit
            </button>
          )}

          {activitynote.versionOwner.id === user.id && (
            <button
              type="button"
              onClick={toggleDeleteConfirm}
              className="mx-3 my-1"
            >
              Delete
            </button>
          )}

          <button type="button" className="mx-3 my-1">
            Add Attachment
          </button>
        </div>
        <div>
          {activitynote.documenttagSet.map((doc) => (
            <Attachment attachment={doc.document} key={doc.document.id} />
          ))}
        </div>
      </div>
      {deleteConfirm && (
        <DeleteActivityNote
          activitynote={activitynote}
          setDeleting={setDeleteConfirm}
        />
      )}
    </div>
    //       );
    //     }}
    //   </Dropzone>
    // </div>
  );
}
