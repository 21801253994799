import * as React from "react";

import {Link} from "react-router-dom";

type Props = {
  account: {
    id: string;
    name: string;
    account: string;
    bankName: string;
    routing: string;
    undepositedPaymentCount: number;
  };
};

export function BankAccount({account}: Props) {
  return (
    <Link to={"/accounting/deposit-prelist/" + account.id} className="m-6">
      <div className="max-w-lg bg-white rounded-lg shadow-xl hover:shadow-2xl">
        <div className="px-5 py-4 text-xl">
          {account.name} #{account.account}
        </div>
        <div className="px-6 py-4 bg-green-200 text-coolGray-700">
          {account.bankName}
          <br />
          Routing: {account.routing}
        </div>
        <div className="flex items-center justify-center px-4 py-3 text-coolGray-600">
          <span className="mr-2 text-3xl font-bold">
            {account.undepositedPaymentCount}
          </span>{" "}
          <span>
            {account.undepositedPaymentCount === 1 ? "Payment" : "Payments"} to
            Deposit
          </span>
        </div>
      </div>
    </Link>
  );
}
