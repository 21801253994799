import * as React from "react";

import {useUser} from "../Context/UserContext";

import {AgencyChooser} from "../Components/AgencyChooser";
import {CompanyChooser} from "../Components/CompanyChooser";
import {PolicyTypeChooser} from "../Components/PolicyTypeChooser";

// import {MyDecimalField} from "../FormComponents/MyFormComponents";

import {MyDateField} from "./MyDateField";
import {MyTextarea} from "./MyTextarea";
import {MyTextInputField} from "./MyTextInputField";
// import {MySelect} from "./MySelect";

// const GET_POLICIES = gql`
//   query getClientPoliciesForClient($clientId: String!) {
//     clientaddresses(clientId: $clientId) {
//       id
//       active
//       addressType
//       description
//       street
//       city
//       state
//       zipcode
//       validatedData
//       distanceFromOffice
//       locationSet {
//         id
//         created
//         purchaseClosingDate
//         purchaseClosingDateNotes
//         purchasePrice
//         purchasePriceNotes
//         deededOwner
//         deededOwnerNotes
//         firstTimeHomebuyer
//         hydrantsAvailable
//         hydrantsAvailableNotes
//         respondingFireDepartment
//         distanceToFireDepartment
//         isoProtectionClass
//         isoProtectionClassNotes
//       }
//     }
//   }
// `;

// const ADD_POLICY = gql`
//   mutation createPolicy(
//     $clientId: String!
//     $addressType: String!
//     $description: String!
//     $street: String!
//     $city: String!
//     $state: String!
//     $zipcode: String!
//   ) {
//     createPolicy(
//       addressData: {
//         clientId: $clientId
//         addressType: $addressType
//         description: $description
//         street: $street
//         city: $city
//         state: $state
//         zipcode: $zipcode
//       }
//     ) {
//       clientaddress {
//         id
//         active
//         addressType
//         description
//         street
//         city
//         state
//         zipcode
//         validatedData
//         distanceFromOffice
//         locationSet {
//           id
//           created
//           purchaseClosingDate
//           purchaseClosingDateNotes
//           purchasePrice
//           purchasePriceNotes
//           deededOwner
//           deededOwnerNotes
//           firstTimeHomebuyer
//           hydrantsAvailable
//           hydrantsAvailableNotes
//           respondingFireDepartment
//           distanceToFireDepartment
//           isoProtectionClass
//           isoProtectionClassNotes
//         }
//       }
//     }
//   }
// `;

type Props = {
  clientId: string;
  toggleAdding: () => void;
};

export function AddPolicy({clientId, toggleAdding}: Props) {
  const user = useUser();

  const [agency, setAgency] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [optionalDetails, setOptionalDetails] = React.useState("");
  const [automaticRenewal, setAutomaticRenewal] = React.useState(true);
  const [directBill, setDirectBill] = React.useState(true);
  const [admitted, setAdmitted] = React.useState(true);
  // const [paymentTypes, setPaymentTypes] = React.useState([]);
  const [effectiveDate, setEffectiveDate] = React.useState<string | null>(
    "2020-09-29"
  );
  const [expirationDate, setExpirationDate] = React.useState<string | null>(
    "2021-09-29"
  );
  // const [expirationDate, setExpirationDate] = React.useState();
  const [policyNumber, setPolicyNumber] = React.useState("UNASSIGNED");
  const [billingAccountNumber, setBillingAccountNumber] = React.useState("");

  const [eft, setEft] = React.useState(false);
  const [eftInFull, setEftInFull] = React.useState(false);
  const [mortgageeBill, setMortgageeBill] = React.useState(false);
  const [payInFull, setPayInFull] = React.useState(false);
  const [recurringCreditCard, setRecurringCreditCard] = React.useState(false);
  const [
    recurringCreditCardInFull,
    setRecurringCreditCardInFull,
  ] = React.useState(false);

  // "5a5bb6c8-650b-11e0-82e2-1231380d8dfe"; is the key for "Unknown"
  const [policyType, setPolicyType] = React.useState(
    "5a5bb6c8-650b-11e0-82e2-1231380d8dfe"
  );
  // const [premium, setPremium] = React.useState(0);
  // const [policyTypes, setPolicyTypes] = React.useState([]);
  // const [createApplication, setCreateApplication] = React.useState(true);
  // const [createPayment, setCreatePayment] = React.useState(true);
  // const [writtenBy, setWrittenBy] = React.useState("");
  // const [newBusinessPremium, setNewBusinessPremium] = React.useState(0);

  function stateHasChanged() {
    return false;
  }

  function resetState() {
    // do it here
  }

  function handleDiscard() {
    if (stateHasChanged() === false) {
      toggleAdding();
    } else {
      let confirmDiscardMessage =
        "Are you sure you want to discard the address?\n\nPress OK to discard or cancel to continue entering info.";
      if (window.confirm(confirmDiscardMessage)) {
        resetState();

        toggleAdding();
      }
    }
  }

  return (
    <div className="p-4 bg-white">
      <div>
        <div>
          <h3 className="text-xl">Adding Policy</h3>
        </div>
        <div>
          {/* <Mutation
            mutation={ADD_POLICY}
            update={(cache, {data: {createPolicy}}) => {
              const {clientaddresses} = cache.readQuery({
                query: GET_POLICIES,
                variables: {clientId: props.clientId}
              });

              cache.writeQuery({
                query: GET_POLICIES,
                variables: {clientId: props.clientId},
                data: {
                  clientaddresses: clientaddresses.concat([
                    createPolicy.clientaddress
                  ])
                }
              });
            }}
          >
             {(createPolicy, {data}) => ( */}
          <form
            onSubmit={(e) => {
              e.preventDefault();

              //   createPolicy({
              //     variables: {
              //       clientId: props.clientId,
              //       agency: agency,
              //       company: company,
              //       optionalDetails: optionalDetails.trim(),
              //       automaticRenewal: automaticRenewal,
              //       directBill: directBill,
              //       paymentTypes: paymentTypes,
              //       effectiveDate: effectiveDate,
              //       expirationDate: expirationDate,
              //       policyNumber: policyNumber.trim(),
              //       premium: premium,
              //       policyTypes: policyTypes,
              //       createApplication: createApplication,
              //       createPayment: createPayment,
              //       writtenBy: writtenBy,
              //       newBusinessPremium: newBusinessPremium
              //     }
              //   }).then(res => {
              //     resetState();
              //     props.toggleAdding();
              //   });
            }}
          >
            <div>
              <div className="grid grid-cols-2">
                <AgencyChooser
                  value={agency}
                  setValue={setAgency}
                  initialValue={""}
                  defaultAgencyName={user.profile.agency.name}
                />
                <CompanyChooser value={company} setValue={setCompany} />
              </div>
              <div className="grid grid-cols-4">
                <MyDateField
                  label="Effective Date YYYY-MM-DD"
                  value={effectiveDate}
                  setValue={setEffectiveDate}
                  initialValue={"2020-09-29"}
                  informUnsavedChanges={false}
                />
                <MyDateField
                  label="Expiration Date YYYY-MM-DD"
                  value={expirationDate}
                  setValue={setExpirationDate}
                  initialValue={"2021-09-29"}
                  informUnsavedChanges={false}
                />

                <MyTextInputField
                  label="Policy Number"
                  value={policyNumber}
                  setValue={setPolicyNumber}
                  initialValue={"asdf"}
                  characterLimit={25}
                  informUnsavedChanges={false}
                />
                <MyTextInputField
                  label="Billing Account Number"
                  value={billingAccountNumber}
                  setValue={setBillingAccountNumber}
                  initialValue=""
                  characterLimit={32}
                  informUnsavedChanges={false}
                />
              </div>
              <input
                id="automatic-renewal"
                type="checkbox"
                onChange={() =>
                  setAutomaticRenewal(automaticRenewal ? false : true)
                }
                checked={automaticRenewal}
              />{" "}
              <label htmlFor="automatic-renewal">Automatic Renewal</label>
              <br />
              <input
                id="direct-bill"
                type="checkbox"
                onChange={() => setDirectBill(directBill ? false : true)}
                checked={directBill}
              />{" "}
              <label htmlFor="direct-bill">Direct Bill</label>
              <br />
              <input
                id="admitted"
                type="checkbox"
                onChange={() => setAdmitted(admitted ? false : true)}
                checked={admitted}
              />{" "}
              <label htmlFor="admitted">Admitted</label>
              <br />
              <input
                id="eft"
                type="checkbox"
                onChange={() => setEft(eft ? false : true)}
                checked={eft}
              />{" "}
              <label htmlFor="eft">EFT</label>
              <br />
              <input
                id="eftInFull"
                type="checkbox"
                onChange={() => setEftInFull(eftInFull ? false : true)}
                checked={eftInFull}
              />{" "}
              <label htmlFor="eftInFull">EFT (in full)</label>
              <br />
              <input
                id="mortgageeBill"
                type="checkbox"
                onChange={() => setMortgageeBill(mortgageeBill ? false : true)}
                checked={mortgageeBill}
              />{" "}
              <label htmlFor="mortgageeBill">Mortgagee Bill</label>
              <br />
              <input
                id="payInFull"
                type="checkbox"
                onChange={() => setPayInFull(payInFull ? false : true)}
                checked={payInFull}
              />{" "}
              <label htmlFor="payInFull">Pay in Full</label>
              <br />
              <input
                id="recurringCreditCard"
                type="checkbox"
                onChange={() =>
                  setRecurringCreditCard(recurringCreditCard ? false : true)
                }
                checked={recurringCreditCard}
              />{" "}
              <label htmlFor="recurringCreditCard">Recurring Credit Card</label>
              <br />
              <input
                id="recurringCreditCardInFull"
                type="checkbox"
                onChange={() =>
                  setRecurringCreditCardInFull(
                    recurringCreditCardInFull ? false : true
                  )
                }
                checked={recurringCreditCardInFull}
              />{" "}
              <label htmlFor="recurringCreditCardInFull">
                Recurring Credit Card (in full)
              </label>
              <br />
              <br />
              PREMIUM
              {/* <MyDecimalField
                    label="Premium"
                    value={premium}
                    setValue={setPremium}
                  /> */}
              <br />
              <br />
              <PolicyTypeChooser
                value={policyType}
                setValue={setPolicyType}
                initialValue={""}
              />
              <button>Add another policy type (this is a package) </button>
              <MyTextarea
                label="OPTIONAL Policy Details"
                value={optionalDetails}
                setValue={setOptionalDetails}
                initialValue={""}
                informUnsavedChanges={false}
              />
              <br />
              <br />
              OPTIONAL CREATE APPLICATION ACTIVITY
              <br />
              -- NEW BUSINESS PREMIUM
              <br />
              -- CREDIT to
              <br />
              <br />
              <br />
              <br />
            </div>

            <br />
            <div>
              {/* {ableToSave ? ( */}
              <button type="submit">Save Policy</button>

              <button type="button" onClick={handleDiscard}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
