import * as React from "react";

import {gql, useMutation} from "@apollo/client";

import {Prompt} from "react-router";

import {DeleteAddress} from "./DeleteAddress";

const EDIT_ADDRESS_MUTATION = gql`
  mutation EditClientaddress(
    $addressId: String!
    $addressType: String!
    $description: String!
    $street: String!
    $city: String!
    $state: String!
    $zipcode: String!
  ) {
    editClientaddress(
      addressData: {
        addressId: $addressId
        addressType: $addressType
        description: $description
        street: $street
        city: $city
        state: $state
        zipcode: $zipcode
      }
    ) {
      clientaddress {
        id
        addressType
        description
        street
        city
        state
        zipcode
        validatedData
      }
    }
  }
`;

type Location = {
  id: string;
};

type ClientAddress = {
  id: string;
  validatedData: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  description: string;
  locationSet: Location[];
  distanceFromOffice: string;
  addressType: string;
};

type Props = {
  address: ClientAddress;
  toggleEditing: () => void;
};

export function EditAddress({address, toggleEditing}: Props) {
  const [addressType, setAddressType] = React.useState(address.addressType);
  const [description, setDescription] = React.useState(address.description);
  const [street, setStreet] = React.useState(address.street);
  const [city, setCity] = React.useState(address.city);
  const [state, setState] = React.useState(address.state);
  const [zipcode, setZipcode] = React.useState(address.zipcode);
  const [modified, setModified] = React.useState(false);
  const [showDeleteForm, setShowDeleteForm] = React.useState(false);

  const [editClientaddress] = useMutation<{
    editClientaddress: ClientAddress;
  }>(EDIT_ADDRESS_MUTATION, {
    variables: {
      addressId: address.id,
      addressType: addressType,
      description: description.trim(),
      street: street.trim(),
      city: city.trim(),
      state: state.trim(),
      zipcode: zipcode.trim(),
    },
  });

  function toggleDeleteForm() {
    setShowDeleteForm(showDeleteForm ? false : true);
  }

  // function handleSubmit(event) {
  //   event.preventDefault();

  // .then(res => {
  //   setAddressType(address.addressType);
  //   setDescription(address.description);
  //   setStreet(address.street);
  //   setCity(address.city);
  //   setState(address.state);
  //   setZipcode(address.zipcode);
  //   setModified(false);
  //   toggleEditing();
  // });
  // }

  function handleDiscard() {
    // event.preventDefault();
    if (
      addressType === address.addressType &&
      description === address.description &&
      street === address.street &&
      city === address.city &&
      state === address.state &&
      zipcode === address.zipcode
    ) {
      toggleEditing();
    } else {
      let confirmDiscardMessage =
        "Are you sure you want to discard the changes?\n\nPress OK to discard or cancel to continue editing.";
      if (window.confirm(confirmDiscardMessage)) {
        setAddressType(address.addressType);
        setDescription(address.description);
        setStreet(address.street);
        setCity(address.city);
        setState(address.state);
        setZipcode(address.zipcode);
        setModified(false);
        toggleEditing();
      }
    }
  }

  if (showDeleteForm) {
    return (
      <DeleteAddress address={address} toggleDeleteForm={toggleDeleteForm} />
    );
  }

  return (
    <div className="p-6 m-6 bg-white rounded-lg shadow-lg">
      <form onSubmit={() => editClientaddress()}>
        <Prompt
          when={modified}
          message="There is an unsaved Address. If you proceed the data will be lost forever."
        />
        <div>
          <div>
            <h3>Editing Address</h3>
          </div>
          <div>
            <div>
              <div>
                <div>
                  Address Type Selector Here
                  {/* <Form.Select
                  fluid
                  name="addressType"
                  label="Address Type"
                  options={[
                    {
                      key: "MP",
                      text: "Primary Mailing & Physical",
                      value: "MP"
                    },
                    {key: "PM", text: "Primary Mailing", value: "PM"},
                    {key: "PP", text: "Primary Physical", value: "PP"},
                    {key: "AP", text: "Additional Physical", value: "AP"},
                    {key: "AM", text: "Additional Mailing", value: "AM"},
                    {key: "OT", text: "Other", value: "OT"}
                  ]}
                  value={addressType}
                  onChange={(event, select) => setAddressType(select.value)}
                /> */}
                </div>

                <div>
                  <label htmlFor="street">Street</label>
                  <input
                    type="text"
                    name="street"
                    value={street}
                    onChange={(event) => setStreet(event.target.value)}
                    data-lpignore="true"
                  />
                </div>
                <div>
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                    data-lpignore="true"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    name="state"
                    value={state}
                    onChange={(event) =>
                      setState(event.target.value.substring(0, 2).toUpperCase())
                    }
                    data-lpignore="true"
                  />
                </div>
                <div>
                  <label htmlFor="zipcode">Zipcode</label>
                  <input
                    type="text"
                    name="zipcode"
                    value={zipcode}
                    onChange={(event) => setZipcode(event.target.value)}
                    data-lpignore="true"
                  />
                </div>
                <div>
                  <label htmlFor="description">OPTIONAL Description</label>
                  <input
                    type="text"
                    name="description"
                    value={description ? description : ""}
                    onChange={(event) => setDescription(event.target.value)}
                    data-lpignore="true"
                  />
                </div>
              </div>
            </div>
            <br />

            {/* {modified ? ( */}
            <div style={{float: "left"}}>
              <button
                type="submit"
                className="px-4 py-2 mr-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
              >
                Save Address
              </button>
              {/* <Button
              content="Discard Changes"
              labelPosition="left"
              icon="dont"
              negative
              onClick={handleDiscard}
            /> */}
            </div>
            {/* ) : (*/}
            <button
              onClick={() => handleDiscard()}
              className="px-4 py-2 mr-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Stop Editing
            </button>
            {/*// )}*/}

            <button
              onClick={toggleDeleteForm}
              className="px-4 py-2 mr-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Make this Address Inactive
            </button>

            <div style={{clear: "both"}} />
          </div>
        </div>
      </form>
    </div>
  );
}
