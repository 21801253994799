import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {Prompt} from "react-router";
import {MyTextarea} from "../../FormComponents/MyTextarea";

const ADD_ACTIVITYNOTE_MUTATION = loader(
  "../../Fragments/Activities/ActivityNoteAdd.graphql"
);

const ACTIVITYNOTE_SET = loader(
  "../../Fragments/Activities/ActivityNoteSet.graphql"
);

interface Attachment {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
}

interface Tag {
  id: string;
  document: Attachment;
}

interface ActivityNote {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
}

interface ActivityNoteSet {
  activitynoteSet: ActivityNote[];
}

interface Props {
  activityId: string;
  setAdding: (adding: boolean) => void;
}

export function AddActivityNote({activityId, setAdding}: Props) {
  const [noteContent, setNoteContent] = React.useState("");

  const [activitynoteAdd] = useMutation<{
    activitynoteAdd: ActivityNote;
  }>(ADD_ACTIVITYNOTE_MUTATION, {
    variables: {
      activityId: activityId,
      note: noteContent,
    },
    update(cache, {data}) {
      if (!data || !data.activitynoteAdd) return;

      let notes = cache.readFragment<ActivityNoteSet>({
        id: "FlexiblePolicyActivityObjectType:" + activityId,
        fragment: ACTIVITYNOTE_SET,
        fragmentName: "ActivityNoteSet",
      });

      let newnotes: Array<ActivityNote> = [];
      if (notes) {
        newnotes = [data.activitynoteAdd, ...notes.activitynoteSet];
      } else {
        newnotes = [data.activitynoteAdd];
      }

      cache.writeFragment({
        id: "FlexiblePolicyActivityObjectType:" + activityId,
        fragment: ACTIVITYNOTE_SET,
        fragmentName: "ActivityNoteSet",
        data: {
          activitynoteSet: newnotes,
          __typename: "FlexiblePolicyActivityObjectType",
        },
      });
    },
  });

  function handleSubmit(event: any) {
    event.preventDefault();
    activitynoteAdd().then(() => {
      setNoteContent("");

      setAdding(false);
    });
  }

  function handleDiscard() {
    if (noteContent === "") {
      setAdding(false);
    } else {
      let confirmDiscardMessage =
        "Are you sure you want to discard the note content?\n\nPress OK to discard or cancel to continue editing.";
      if (window.confirm(confirmDiscardMessage)) {
        setNoteContent("");

        setAdding(false);
      }
    }
  }

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      <Prompt
        when={noteContent !== ""}
        message="There is an unsaved Activity Note. If you proceed the note will be lost forever."
      />
      <MyTextarea
        label="Note"
        value={noteContent}
        setValue={setNoteContent}
        initialValue=""
        autoFocus
      />

      <button type="submit">Save Note</button>

      <button type="button" onClick={() => handleDiscard()}>
        {noteContent === "" ? "Cancel" : "Discard"}
      </button>
    </form>
  );
}
