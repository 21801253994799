import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";
import {PlusIcon} from "@heroicons/react/solid";

const ADD_PRESCRIPTION = loader(
  "../../Fragments/Medicare/PrescriptionAdd.graphql"
);
const PRESCRIPTION_SET = loader(
  "../../Fragments/Medicare/PrescriptionSet.graphql"
);

type Prescription = {
  id: string;
  drugName: string;
  dosage: string;
  quantityPerFill: string;
  refillFrequency: string;
  otherFrequency: string;
  notes: string;
};

type PrescriptionSet = {
  prescriptionSet: Prescription[];
};

type Props = {
  id: string;
};

export function PrescriptionAdd({id}: Props) {
  const [addPrescription] = useMutation<{
    addPrescription: Prescription;
  }>(ADD_PRESCRIPTION, {
    variables: {
      id: id,
    },
    update(cache, {data}) {
      if (!data || !data.addPrescription) return;

      let prescriptions = cache.readFragment<PrescriptionSet>({
        id: "BeneficiaryProfileObjectType:" + id,
        fragment: PRESCRIPTION_SET,
        fragmentName: "PrescriptionSet",
      });

      let newprescriptions = {};
      if (prescriptions) {
        newprescriptions = prescriptions.prescriptionSet.concat(
          data.addPrescription
        );
      } else {
        newprescriptions = [data.addPrescription];
      }

      cache.writeFragment({
        id: "BeneficiaryProfileObjectType:" + id,
        fragment: PRESCRIPTION_SET,
        fragmentName: "PrescriptionSet",
        data: {
          prescriptionSet: newprescriptions,
          __typename: "BeneficiaryProfileObjectType",
        },
      });
    },
  });

  return (
    <button
      type="button"
      onClick={() => addPrescription()}
      className="flex items-center p-1 -ml-1 bg-white rounded-md group focus:outline-none focus:ring-2 focus:ring-indigo-500"
    >
      <span className="flex items-center justify-center w-8 h-8 text-gray-400 border-2 border-gray-300 border-dashed rounded-full">
        <PlusIcon className="w-5 h-5" aria-hidden="true" />
      </span>
      <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
        Add Prescription
      </span>
    </button>
  );
}
