import * as React from "react";
import {useAuth} from "./AuthContext";

const UserContext = React.createContext();

function UserProvider(props) {
  const {
    data: {loggedInUser},
  } = useAuth();
  //   console.log("In UserContext: ");
  //   console.log(loggedInUser);
  return <UserContext.Provider value={loggedInUser} {...props} />;
}

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
}

export {UserProvider, useUser};

// import * as React from "react";;

// const UserContext = React.createContext({});

// export const UserProvider = UserContext.Provider;
// export const UserConsumer = UserContext.Consumer;
// export default UserContext;
