import * as React from "react";

import {Activity} from "./Activity";
import {ActivityAdd} from "./ActivityAdd";

interface PolicySection {
  id: string;
}

interface PolicyTerm {
  id: string;
  effectiveDate: string;
  expirationDate: string;
  premium: string;
  policysectionSet: PolicySection[];
}

interface Policy {
  id: string;
  isActive: boolean;
  agency: {
    agency: {
      name: string;
    };
    name: string;
  };
  company: {
    id: string;
    name: string;
    carrier: {
      id: string;
      name: string;
    };
  };
  description: string;
  policyType: string;
  policyNumber: string;
  effectiveDate: string;
  expirationDate: string;
  premium: number;
  firstWritten: string;
  daysSinceLastPolicyReview: string;

  policytermSet: PolicyTerm[];
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
}

interface Attachment {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
}

interface Tag {
  id: string;
  document: Attachment;
}

interface ActivityNote {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
}

interface FlexiblePolicyActivity {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNote[];
  documenttagSet: Tag[];
}

interface Props {
  filter?: string;
  policy?: Policy;
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
}

export function ActivityList({
  filter,
  policy,
  flexiblepolicyactivitySet,
}: Props) {
  const [activityQuery, setActivityQuery] = React.useState("");

  if (filter) {
    // console.log("filter: " + filter);
    var filteredActivities = flexiblepolicyactivitySet.reduce(function (
      filtered: Array<FlexiblePolicyActivity> = [],
      activity
    ) {
      if (activity.activityType === filter) {
        filtered.push(activity);
      }
      return filtered;
    },
    []);

    return (
      <div>
        FILTERED Activities:
        <button type="button">Add Activity</button>
        {filteredActivities.map((flexiblepolicyactivity) => (
          <Activity
            key={flexiblepolicyactivity.id}
            activity={flexiblepolicyactivity}
          />
        ))}
      </div>
    );
  } else {
    return (
      <>
        <div className="w-full max-w-xs mx-auto lg:max-w-full">
          <label htmlFor="search" className="sr-only">
            Search Activities
          </label>
          <div className="relative text-white focus-within:text-gray-600">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              {/* <!-- Heroicon name: search --> */}
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              id="search"
              className="block w-full py-2 pl-10 pr-3 leading-5 text-white placeholder-white bg-white border border-transparent rounded-md bg-opacity-20 focus:text-gray-900 focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
              placeholder="Search Activities"
              type="search"
              name="search"
              value={activityQuery}
              onChange={(event) => setActivityQuery(event.target.value)}
            />
          </div>
        </div>
        <section aria-labelledby="recent-hires-title">
          <div className="overflow-hidden bg-white rounded-lg shadow">
            <div className="p-6">
              <h2
                className="flex text-base font-medium text-gray-900"
                id="recent-hires-title"
              >
                <span className="flex-1">Activity History</span>
                <ActivityAdd policy={policy} />
              </h2>
              <div className="flow-root mt-6">
                <ul className="-my-5 divide-y divide-gray-200">
                  {flexiblepolicyactivitySet
                    .filter(function (activity) {
                      // This will filter out activities that don't have an activitynote that matches the search term.
                      if (activityQuery) {
                        for (const note of activity.activitynoteSet) {
                          if (
                            note.note
                              .toLowerCase()
                              .indexOf(activityQuery.toLowerCase()) !== -1
                          ) {
                            return true;
                          }
                        }
                        if (
                          activity.activityType
                            .toLowerCase()
                            .indexOf(activityQuery.toLowerCase()) !== -1
                        ) {
                          return true;
                        }
                        if (
                          activity.description
                            .toLowerCase()
                            .indexOf(activityQuery.toLowerCase()) !== -1
                        ) {
                          return true;
                        }
                        return false;
                      } else {
                        return true;
                      }
                    })
                    .map((flexiblepolicyactivity) => (
                      <Activity
                        key={flexiblepolicyactivity.id}
                        activity={flexiblepolicyactivity}
                      />
                    ))}
                </ul>
              </div>
              <div className="mt-6">
                <a
                  href="#all"
                  className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                >
                  View all
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
