import * as React from "react";

import {gql} from "@apollo/client";
import {useMutation} from "@apollo/client";

const TOGGLE_QUOTE_CLOSED = gql`
  mutation ToggleQuoteClosed($flexiblepolicyactivityId: String!) {
    toggleQuoteClosed(flexiblepolicyactivityId: $flexiblepolicyactivityId) {
      flexiblepolicyactivity {
        id
        actionDate
        premium
        quoteClosed
        policy {
          id
          company {
            id
            name
          }
          policyNumber
          client {
            id
            name
            attn
            csr {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

type PendingCancellation = {
  id: string;
};

type Props = {
  id: string;
  // children?: any;
};

export const ToggleReminderContactedButton: React.FC<Props> = ({
  id,
  children,
}) => {
  const [toggleQuoteClosed] = useMutation<{
    toggleQuoteClosed: PendingCancellation;
  }>(TOGGLE_QUOTE_CLOSED);

  return (
    <button
      onClick={() => {
        toggleQuoteClosed({
          variables: {
            flexiblepolicyactivityId: id,
          },
        });
      }}
    >
      {children}
    </button>
  );
};
