import * as React from "react";
import {backendUrlHostname} from "../urls";

// import {Link} from "react-router-dom";

type Props = {
  index: number;
  client: {
    id: string;
    name: string;
    attn?: string;
    inactive: boolean;
    clientType: {
      name: string;
    };
    mailingAddress: {
      street: string;
      city: string;
      state: string;
      zipcode: string;
    };
  };
};

export const SearchResult = ({index, client}: Props) => {
  return (
    <li className={index === 0 ? "" : "border-t border-coolGray-200"}>
      <a
        href={backendUrlHostname + "/clients/" + client.id}
        className="block transition duration-150 ease-in-out hover:bg-coolGray-100 focus:outline-none focus:bg-coolGray-100"
      >
        {/* <Link
          to={"/client/" + client.id}
          className="block transition duration-150 ease-in-out hover:bg-coolGray-50 focus:outline-none focus:bg-coolGray-50"
        > */}
        <div
          className={
            client.inactive
              ? "flex items-center px-4 py-4 sm:px-6 bg-coolGray-300"
              : "flex items-center px-4 py-4 sm:px-6"
          }
        >
          <div className="flex items-center flex-1 min-w-0">
            <div className="flex-shrink-0">
              {/* <img
                className="w-12 h-12 rounded-full"
                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              /> */}
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xl font-medium leading-5 bg-blue-100 text-blue-800">
                {client.clientType.name}
              </span>
            </div>
            <div className="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <div className="text-xl font-medium leading-7 text-indigo-600 truncate">
                  {client.name}
                  {client.attn && (
                    <>
                      <br />
                      {client.attn}
                    </>
                  )}
                </div>
                {client.mailingAddress && (
                  <div className="flex items-center mt-1 text-xl leading-7 text-coolGray-600">
                    <span>
                      {client.mailingAddress.street}
                      <br />
                      {client.mailingAddress.city},{" "}
                      {client.mailingAddress.state}{" "}
                      {client.mailingAddress.zipcode}{" "}
                    </span>
                  </div>
                )}
              </div>
              {/* <div className="hidden md:block">
                <div>
                  <div className="text-sm leading-5 text-coolGray-900">
                    Applied on
                  </div>
                  <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                    <svg
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Completed phone screening
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div>
            <svg
              className="w-5 h-5 text-coolGray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        {/* </Link> */}
      </a>
    </li>
  );
};
