import * as React from "react";

import {Link} from "react-router-dom";

import {SearchInput} from "../Components/SearchInput";

import {UserAvatar} from "./UserAvatar";

export function MainMenu() {
  return (
    <div
      style={{
        display: "grid",
        gridGap: ".5em",
        gridTemplateColumns: "210px 1fr 60px",
      }}
      className="m-2 print:hidden print:m-0"
    >
      <div style={{margin: "auto"}} className="print:hidden">
        <Link to={"/"}>
          <h3>AGENCY PIPELINE</h3>
        </Link>
      </div>
      <div className="print:hidden">
        <SearchInput />
      </div>

      <div className="relative ml-3 print:hidden">
        <UserAvatar />
      </div>
    </div>
  );
}
