import {
  useQuery,
  //   useMutation,
  // useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {request, gql} from "graphql-request";
// import {getTodos, postTodo} from "../my-api";

// interface Post {
//   id: string;
//   title: string;
// }

// Create a client
const queryClient = new QueryClient();

export function QueryTest() {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <Todos />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

const endpoint = "https://graphqlzero.almansi.me/api";

function Todos() {
  // Access the client
  // Only used in mutation
  // const queryClient = useQueryClient();

  // Queries
  const query = useQuery("todos", async () => {
    const {
      posts: {data},
    } = await request(
      endpoint,
      gql`
        query {
          posts {
            data {
              id
              title
            }
          }
        }
      `
    );
    return data;
  });

  // Mutations
  //   const mutation = useMutation(postTodo, {
  //     onSuccess: () => {
  //       // Invalidate and refetch
  //       queryClient.invalidateQueries("todos");
  //     },
  //   });

  return (
    <div>
      <ul>
        {query?.data?.map((post) => (
          <li key={post.id}>{post.title}</li>
        ))}
      </ul>

      <button
        onClick={() => {
          alert("would mutate here");
          //   mutation.mutate({
          //     id: Date.now(),
          //     title: "Do Laundry",
          //   });
        }}
      >
        Add Todo
      </button>
    </div>
  );
}

// ReactDOM.render(<App />, document.getElementById("root"));
