import * as React from "react";

import {Link} from "react-router-dom";

type FormTag = {
  id: string;
  name: string;
};

type Brand = {
  name: string;
  logoUrl: string;
};

type Form = {
  id: string;
  number: string;
  name: string;
  edition: string;
  url: string;
  active: boolean;
  brand: Brand;
  formtagSet: FormTag[];
};

type Props = {
  brand: {
    id: string;
    name: string;
    slug: string;
    logoUrl: string;
    pdfformSet: Form[] | never[];
  };
};

export const BrandCard = ({brand}: Props) => {
  let forms = brand.pdfformSet?.filter((form) => form.active === true);

  if (Object.keys(forms).length === 0 && brand.id !== "1") {
    return null;
  }

  return (
    <Link
      to={"/forms/" + brand.slug}
      className="overflow-hidden bg-white rounded-lg shadow hover:shadow-lg focus:outline-none focus:ring ring-offset-2 focus:ring-opacity-50 ring-offset-teal-600 focus:ring-teal-500 focus:border-transparent"
    >
      <div className="p-5">
        <div className="flex items-center">
          <img
            className="w-1/3 p-4"
            src={brand.logoUrl}
            alt={brand.name + " logo"}
          />
          <div className="flex-1 w-0 ml-5">
            <dl>
              <dd>
                <div className="text-lg font-medium leading-7 text-coolGray-900">
                  {brand.name}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="px-5 py-3 bg-coolGray-50">
        <div className="text-sm leading-5">
          <span className="font-medium text-teal-600 transition duration-150 ease-in-out hover:text-teal-900">
            {brand.id === "1"
              ? "All Forms"
              : forms.length + " Form" + (forms.length === 1 ? "" : "s")}
          </span>
        </div>
      </div>
    </Link>
  );
};
