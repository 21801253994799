import * as React from "react";

import {ClockIcon} from "@heroicons/react/outline";

import {backendUrlHostname} from "../urls";
// import {Link} from "reacts-router-dom";

type Download = {
  id: string;
};

type Props = {
  activity: {
    id: string;
    actionDate: string;
    activityType: string;
    policy: {
      client: {
        id: string;
        name: string;
      };
      unacknowledgedDownloads: Download[];
      company: {
        carrier: {
          id: string;
          name: string;
        };
      };
    };
    daysSinceSave: number;
  };
};

export function OpenActivity({activity}: Props) {
  let effectiveDate;
  if (activity.actionDate) {
    const date = new Date(activity.actionDate.replace(/-/g, "/"));

    effectiveDate = date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  //TODO: Add button to reassign to CSR
  return (
    <li
      className={`${
        activity.policy.unacknowledgedDownloads.length === 0
          ? ""
          : "bg-green-100"
      } border-t border-coolGray-200`}
    >
      <a
        href={backendUrlHostname + "/clients/" + activity.policy.client.id}
        className="block transition duration-150 ease-in-out hover:bg-coolGray-100 focus:outline-none focus:bg-coolGray-100"
      >
        {/* <Link
          to={"/client/" + activity.policy.client.id}
          className="block transition duration-150 ease-in-out hover:bg-coolGray-50 focus:outline-none focus:bg-coolGray-50"
        > */}
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex items-center flex-1 min-w-0">
            <div className="flex-shrink-0">
              <img
                src={
                  "https://pipeline-media.s3.amazonaws.com/logos/" +
                  activity.policy.company.carrier.id +
                  ".webp"
                }
                alt={activity.policy.company.carrier.name + " logo"}
                className="w-32"
              />
            </div>
            <div className="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <div className="text-xl font-medium leading-5 text-indigo-600 truncate">
                  {activity.policy.client.name}
                </div>
                <div className="flex items-center mt-2 leading-5 text-coolGray-900 text-l">
                  {(() => {
                    switch (activity.activityType) {
                      case "ENDORSEMENT":
                        return (
                          <span>Policy Change effective {effectiveDate}</span>
                        );
                      case "APPLICATION":
                        return (
                          <span>Application effective {effectiveDate}</span>
                        );
                      case "TERMINATION":
                        return (
                          <span>
                            Cancellation Request effective {effectiveDate}
                          </span>
                        );
                      case "PHOTO_INSPECTION_NEEDED":
                        return <span>Photo Inspection Needed</span>;
                      default:
                        return <span>Unknown</span>;
                    }
                  })()}
                </div>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="text-sm leading-5 text-coolGray-900"></div>
                  <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                    {(() => {
                      switch (activity.daysSinceSave.toString()) {
                        case "0":
                          return <span>Saved/Updated today</span>;
                        case "1":
                          return <span>Saved/Updated yesterday</span>;
                        case "2":
                          return (
                            <span>
                              Saved/Updated {activity.daysSinceSave} days ago
                            </span>
                          );
                        case "3":
                          return (
                            <span>
                              Saved/Updated {activity.daysSinceSave} days ago
                            </span>
                          );
                        case "4":
                          return (
                            <span>
                              Saved/Updated {activity.daysSinceSave} days ago
                            </span>
                          );
                        case "5":
                          return (
                            <span>
                              Saved/Updated {activity.daysSinceSave} days ago
                            </span>
                          );

                        default:
                          return (
                            <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-red-100 text-red-800 md:mt-2 lg:mt-0">
                              <ClockIcon className="self-center flex-shrink-0 w-5 h-5 mr-1 -ml-1 text-red-500" />
                              Saved/Updated {activity.daysSinceSave} days ago
                            </div>
                          );
                      }
                    })()}

                    <br />
                    <br />
                    {activity.policy.unacknowledgedDownloads.length ===
                    0 ? null : (
                      <>
                        <svg
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Open Download Received
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <svg
              className="w-5 h-5 text-coolGray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        {/* </Link> */}
      </a>
    </li>
  );
}
