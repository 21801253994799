import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

// import {Prompt} from "react-router";
import {MyTextarea} from "../../FormComponents/MyTextarea";

const UPDATE_ACTIVITYNOTE_MUTATION = loader(
  "../../Fragments/Activities/ActivityNoteUpdate.graphql"
);

interface Attachment {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
}

interface Tag {
  id: string;
  document: Attachment;
}

interface ActivityNote {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
}

interface Props {
  activitynote: ActivityNote;
  setEditing: (editing: boolean) => void;
}

export function ActivityNoteUpdate({activitynote, setEditing}: Props) {
  const [noteContent, setNoteContent] = React.useState(activitynote.note);
  const [dirty, setDirty] = React.useState(false);

  const [activitynoteUpdate] = useMutation<{
    activitynoteUpdate: ActivityNote;
  }>(UPDATE_ACTIVITYNOTE_MUTATION, {
    variables: {
      id: activitynote.id,
      note: noteContent.trim(),
    },
  });

  React.useEffect(() => {
    if (activitynote.note !== noteContent.trim()) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [activitynote.note, noteContent]);

  const confirmDiscardMessage =
    "Are you sure you want to discard the changes?\n\nPress OK to discard or cancel to continue editing.";

  function handleDiscard() {
    if (window.confirm(confirmDiscardMessage)) {
      setEditing(false);
    }
  }

  return (
    <div>
      <div>Editing Note</div>
      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            activitynoteUpdate().then(() => {
              setEditing(false);
            });
          }}
        >
          <MyTextarea
            label="Note"
            value={noteContent}
            setValue={setNoteContent}
            initialValue={activitynote.note}
            autoFocus
          />

          {dirty ? (
            <div>
              <button type="submit">Save Note</button>
              <button type="button" onClick={() => handleDiscard()}>
                Discard
              </button>
            </div>
          ) : (
            <button type="button" onClick={() => setEditing(false)}>
              Cancel
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
