import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";
import {PlusIcon} from "@heroicons/react/solid";

const ADD_PROVIDER = loader("../../Fragments/Medicare/ProviderAdd.graphql");
const PROVIDER_SET = loader("../../Fragments/Medicare/ProviderSet.graphql");

type Provider = {
  id: string;
  name: string;
  typeOfDoctor: string;
  notes: string;
};

type ProviderSet = {
  providerSet: Provider[];
};

type Props = {
  id: string;
};

export function ProviderAdd({id}: Props) {
  const [addProvider] = useMutation<{
    addProvider: Provider;
  }>(ADD_PROVIDER, {
    variables: {
      id: id,
    },
    update(cache, {data}) {
      if (!data || !data.addProvider) return;

      let providers = cache.readFragment<ProviderSet>({
        id: "BeneficiaryProfileObjectType:" + id,
        fragment: PROVIDER_SET,
        fragmentName: "ProviderSet",
      });

      let newproviders = {};
      if (providers) {
        newproviders = providers.providerSet.concat(data.addProvider);
      } else {
        newproviders = [data.addProvider];
      }

      cache.writeFragment({
        id: "BeneficiaryProfileObjectType:" + id,
        fragment: PROVIDER_SET,
        fragmentName: "ProviderSet",
        data: {
          providerSet: newproviders,
          __typename: "BeneficiaryProfileObjectType",
        },
      });
    },
  });

  return (
    <button
      type="button"
      onClick={() => addProvider()}
      className="flex items-center p-1 -ml-1 bg-white rounded-md group focus:outline-none focus:ring-2 focus:ring-indigo-500"
    >
      <span className="flex items-center justify-center w-8 h-8 text-gray-400 border-2 border-gray-300 border-dashed rounded-full">
        <PlusIcon className="w-5 h-5" aria-hidden="true" />
      </span>
      <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
        Add Provider
      </span>
    </button>
  );
}
