import * as React from "react";

import {AttachmentImage} from "./AttachmentImage";
import {AttachmentPDF} from "./AttachmentPDF";

type AttachmentType = {
  id: string;
  extension: string;
  createdOn: number;
  url: string;
  uploadedBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

type Props = {
  attachment: AttachmentType;
};

export const Attachment = ({attachment}: Props) => {
  const {extension} = attachment;
  if (extension === ".pdf") {
    // return <span> I would be a PDF attachment </span>;
    return <AttachmentPDF attachment={attachment} />;
  }
  var imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
  if (imageExtensions.indexOf(extension) > -1) {
    return <AttachmentImage attachment={attachment} />;
  }
  return <span>Not a PDF or Image</span>;
};
