import * as React from "react";

import {isBefore} from "date-fns";

import {AddClientReminder} from "../FormComponents/AddClientReminder";
import {ClientReminder} from "./ClientReminder";

type Reminder = {
  id: string;
  completed: boolean;
  reminder: string;
  remindOn: string;
  about: {
    name: string;
    attn: string;
  };
};

type HeaderProps = {
  setAdding: (arg0: boolean) => void;
};

type Props = {
  reminders: Reminder[];
  clientId: string;
};

function ClientRemindersHeader({setAdding}: HeaderProps) {
  return (
    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
      <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
        <div className="mt-2 ml-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            ClientReminders
          </h3>
        </div>
        <div className="flex-shrink-0 mt-2 ml-4">
          <button
            type="button"
            className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setAdding(true)}
          >
            Create a reminder
          </button>
        </div>
      </div>
    </div>
  );
}

export function ClientRemindersPane({reminders, clientId}: Props) {
  const [adding, setAdding] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState("current");

  let completedReminders = reminders.filter((reminder) => reminder.completed);

  let currentReminders = reminders.filter(
    (reminder) =>
      isBefore(new Date(reminder.remindOn.replace(/-/g, "/")), new Date()) &&
      !reminder.completed
  );

  let upcomingReminders = reminders.filter(
    (reminder) =>
      !isBefore(new Date(reminder.remindOn.replace(/-/g, "/")), new Date()) &&
      !reminder.completed
  );

  if (adding) {
    return (
      <AddClientReminder
        clientId={clientId}
        stopAdding={() => setAdding(false)}
      />
    );
  }

  if (reminders.length === 0) {
    return (
      <div className="my-3 bg-white rounded-lg shadow">
        <ClientRemindersHeader setAdding={setAdding} />
      </div>
    );
  }

  return (
    <div className="my-3 bg-white rounded-lg shadow">
      <ClientRemindersHeader setAdding={setAdding} />

      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            value={activeTab}
            onChange={(event) => setActiveTab(event.target.value)}
          >
            <option value="current">Current ({currentReminders.length})</option>
            <option value="upcoming">
              Upcoming ({upcomingReminders.length})
            </option>
            <option value="completed">
              Completed ({completedReminders.length})
            </option>
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px" aria-label="Tabs">
              <button
                type="button"
                onClick={() => setActiveTab("current")}
                className={`${
                  activeTab === "current"
                    ? "text-indigo-600 border-indigo-500"
                    : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent"
                } w-1/3 px-1 py-4 text-sm font-medium text-center border-b-2 border-transparent`}
              >
                Current ({currentReminders.length})
              </button>
              <button
                type="button"
                onClick={() => setActiveTab("upcoming")}
                className={`${
                  activeTab === "upcoming"
                    ? "text-indigo-600 border-indigo-500"
                    : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent"
                } w-1/3 px-1 py-4 text-sm font-medium text-center border-b-2 border-transparent`}
              >
                Upcoming ({upcomingReminders.length})
              </button>
              <button
                type="button"
                onClick={() => setActiveTab("completed")}
                className={`${
                  activeTab === "completed"
                    ? "text-indigo-600 border-indigo-500"
                    : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent"
                } w-1/3 px-1 py-4 text-sm font-medium text-center border-b-2 border-transparent`}
                aria-current="page"
              >
                Completed ({completedReminders.length})
              </button>
            </nav>
          </div>
        </div>
      </div>

      <div className="p-3">
        {activeTab === "current" ? (
          <>
            {currentReminders.map((reminder) => (
              <ClientReminder key={reminder.id} clientreminder={reminder} />
            ))}
          </>
        ) : null}

        {activeTab === "upcoming" ? (
          <>
            {upcomingReminders.map((reminder) => (
              <ClientReminder key={reminder.id} clientreminder={reminder} />
            ))}
          </>
        ) : null}

        {activeTab === "completed" ? (
          <>
            {completedReminders.map((reminder) => (
              <ClientReminder key={reminder.id} clientreminder={reminder} />
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
}
