import * as React from "react";

import {PendingCancellation} from "./PendingCancellation";

type PendingCancellationType = {
  id: string;
  actionDate: string;
  policy: {
    policyNumber: string;
    client: {
      id: string;
      name: string;
      attn: string;
      csr: {
        id: string;
        firstName: string;
        lastName: string;
      };
    };
    company: {
      carrier: {
        name: string;
      };
    };
  };
  quoteClosed: boolean;
};

type Props = {
  date: string;
  pendingCancellations: PendingCancellationType[];
};

export function NonpayDateColumn({date, pendingCancellations}: Props) {
  return (
    <div
      className={
        "mx-1.5 flex-shrink-0 flex flex-col w-80 bg-coolGray-100 rounded-lg"
      }
      style={
        pendingCancellations.length.toString() === "0"
          ? {width: `8rem`}
          : {width: `20rem`}
      }
    >
      <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
        {date}
      </h3>
      <div className="flex-1 min-h-0 overflow-y-auto">
        <ul className="px-3 pt-1 pb-3">
          {pendingCancellations.map((cancellation, index) => (
            <PendingCancellation
              cancellation={cancellation}
              key={cancellation.id}
              index={index}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
