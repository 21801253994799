import * as React from "react";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

// import {SettingsAccount} from "../../FormComponents/SettingsAccount";

const GET_USER_SETTINGS = loader(
  "../../Fragments/Contacts/UserSettingsQuery.graphql"
);

export function Profile() {
  const {loading, error, data} = useQuery(GET_USER_SETTINGS);

  React.useEffect(() => {
    document.title = `Account Settings`;
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  return (
    <div className="mt-10 divide-y divide-gray-200">
      <div className="space-y-1">
        <h3 className="text-xl font-medium leading-6 text-gray-900">Profile</h3>
        <p className="max-w-2xl text-base text-gray-500">
          This information will be displayed elsewhere on the site.
        </p>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-base font-medium text-gray-500">Name</dt>
            <dd className="flex mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {data.userSettings.firstName} {data.userSettings.lastName}
              </span>
              <span className="flex-shrink-0 ml-4">
                <button
                  type="button"
                  className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Update
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <dt className="text-base font-medium text-gray-500">Photo</dt>
            <dd className="flex mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                <img
                  className="w-24 h-24 rounded-full"
                  // src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  src={
                    "https://pipeline-media.s3.amazonaws.com/avatars/" +
                    data?.userSettings.id +
                    ".128.png"
                  }
                  alt={
                    data?.userSettings.firstName +
                    " " +
                    data?.userSettings.lastName +
                    "profile image"
                  }
                />
              </span>
              <span className="flex items-start flex-shrink-0 ml-4 space-x-4">
                <button
                  type="button"
                  className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Update
                </button>
                <span className="text-gray-300" aria-hidden="true">
                  |
                </span>
                <button
                  type="button"
                  className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Remove
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <dt className="text-base font-medium text-gray-500">Email</dt>
            <dd className="flex mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{data.userSettings.email}</span>
              <span className="flex-shrink-0 ml-4">
                <button
                  type="button"
                  className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Update
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt className="text-base font-medium text-gray-500">Username</dt>
            <dd className="flex mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{data.userSettings.username}</span>
              <span className="flex-shrink-0 ml-4">
                <button
                  type="button"
                  className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Update
                </button>
              </span>
            </dd>
          </div>{" "}
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt className="text-base font-medium text-gray-500">Mobile</dt>
            <dd className="flex mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {data.userSettings.profile.mobile}
              </span>
              <span className="flex-shrink-0 ml-4">
                <button
                  type="button"
                  className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Update
                </button>
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    // <div style={{backgroundColor: `rgb(248, 248, 248)`}}>
    //   <SettingsAccount userSettings={data.userSettings} />;
    // </div>
  );
}
