import * as React from "react";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

import {ActivityList} from "./Activities/ActivityList";

import {AddressList} from "./AddressList";

import {PersonList} from "./PersonList";

// import {ClientRemindersPane} from "./ClientRemindersPane";
import {RemindersPane} from "./RemindersPane";
import {ClientContentPane} from "../Components/ClientContentPane";
import {PolicyListPane} from "./PolicyListPane";

import {ReferralsPane} from "./ReferralsPane";
import {QuotesPane} from "./QuotesPane";
import {ScheduledPropertyPane} from "./ScheduledPropertyPane";

const CLIENT_QUERY = loader("../Fragments/Clients/ClientQuery.graphql");

// type ClientType = {
//   id: string;
//   phonenumberSet: {
//     id: string;
//     number: string;
//     label: string;
//   };
// };

type Props = {
  clientId: string;
};

export const Client = ({clientId}: Props) => {
  const {loading, error, data} = useQuery(CLIENT_QUERY, {
    variables: {clientId: clientId},
  });

  React.useEffect(() => {
    document.title = data?.client?.name ?? "Loading";
  });

  if (loading) {
    return (
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div>Loading</div>
      </div>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (data) {
    return (
      <div
        style={{
          display: `grid`,
          gridTemplateColumns: `3fr 2fr`,
          gridGap: `2.5rem`,
          padding: `1.5rem`,
          backgroundColor: `#e2ebf0`,
        }}
      >
        <div>
          <div>
            {data.client.active ? null : <>Inactive </>}
            {data.client.clientType.name} Account for
            <h2 className="text-xl font-medium leading-8 text-coolGray-900">
              {data.client.name}
              {data.client.attn && (
                <>
                  <br />
                  {data.client.attn}
                </>
              )}
            </h2>
            <button style={{backgroundColor: "#fff"}}>Edit</button>
            <div>
              {data.client.producer && (
                <div>
                  <dl>
                    <dt className="text-sm font-medium leading-5 truncate text-coolGray-500">
                      Producer
                    </dt>
                    <dd className="mt-1 text-base font-medium leading-9 text-coolGray-900">
                      {data.client.producer.firstName}{" "}
                      {data.client.producer.lastName}
                    </dd>
                  </dl>
                </div>
              )}
              {data.client.csr && (
                <div>
                  <dl>
                    <dt className="text-sm font-medium leading-5 truncate text-coolGray-500">
                      Customer Service
                    </dt>
                    <dd className="mt-1 text-base font-medium leading-9 text-coolGray-900">
                      {data.client.csr.firstName} {data.client.csr.lastName}
                    </dd>
                  </dl>
                </div>
              )}
            </div>
          </div>

          <ul>
            {/* <li>Newsletter? {data.client.receivesNewsletter ? "Yes" : "No"}</li> */}
            {/* {data?.client?.phonenumberSet?.map((number) => (
              <List.Item key={number.id}>
                {number.number} ({number.label})
              </List.Item>
            ))} */}
          </ul>

          {data.client.notes && (
            <span>
              <h4>Notes</h4> {data.client.notes}
            </span>
          )}

          <PolicyListPane client={data.client} />
          {/* <ClientRemindersPane
            reminders={data.client.clientreminderSet}
            clientId={data.client.id}
          /> */}
          <RemindersPane
            reminders={data.client.reminderSet}
            clientId={data.client.id}
          />
          <AddressList
            addresses={data.client.clientaddressSet}
            clientId={data.client.id}
          />
          <PersonList people={data.client.personaccounttagSet} />
          <ClientContentPane client={data.client} />
          <QuotesPane client={data.client} />

          <ReferralsPane client={data.client} />
        </div>
        <div>
          <div>
            <h4>Happening:</h4>

            <div>[ &nbsp; Open Activities listed here &nbsp; ]</div>
          </div>

          <div>
            <h4>Policy Terminated (Reinstated)</h4> effective May 1, 2019
            <br />
            Policy: Erie & Niagara Insurance Assoc CF0005667
            <br />
            I personally witness that the agency responsibility for relaying the
            details of this activity have been completed by way of:
            <br />
            <br />
            Download a Cancellation Request
            <br />
            admin
            <br />
            Canceled
            <br />
            Delete Note
            <br />
            admin
          </div>
          <ActivityList
            flexiblepolicyactivitySet={data.client.flexiblepolicyactivitySet}
          />
          <ScheduledPropertyPane client={data.client} />
          {/* <Tabs>

            <TabPanels>
              <TabPanel>
                <ActivityList
                  flexiblepolicyactivitySet={data.client.flexiblepolicyactivitySet}
                />
              </TabPanel>
              <TabPanel>CertificatesPane client=client</TabPanel>
            </TabPanels>
          </Tabs>*/}
        </div>
      </div>
    );
  }

  return <div>Query Error - No Data Returned</div>;
};
