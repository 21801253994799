import * as React from "react";

import {Person} from "./Person";

type BeneficiaryProfile = {
  id: string;
};

type PersonType = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  preferredName: string;
  gender: string;
  dob: string;
  age: string;
  social: string;
  maritalStatus: string;
  license: string;
  licenseDate: string;
  ddc: string;
  education: string;
  occupation: string;
  employer: string;
  email: string;
  cellPhone: string;
  workPhone: string;
  notes: string;
  beneficiaryprofileSet: BeneficiaryProfile[];
};

type PersonAccountTag = {
  person: PersonType;
};

type Props = {
  people: PersonAccountTag[];
};

export function PersonList({people}: Props) {
  const [adding, setAdding] = React.useState(false);
  const [showAll, setShowAll] = React.useState(
    people.length < 4 ? true : false
  );

  let underSixteenCount = people.filter(
    (person) => parseInt(person.person.age, 10) < 16
  ).length;

  return (
    <div>
      <div>
        <h4>People</h4>

        {adding ? null : (
          <button
            type="button"
            className="bg-green-500"
            onClick={() => setAdding(true)}
          >
            Add
          </button>
        )}
      </div>
      <div>
        {people.map((tag) => (
          <Person
            key={tag.person.id}
            person={tag.person}
            showAll={showAll || underSixteenCount === 1}
          />
        ))}

        {showAll === false && underSixteenCount > 1 ? (
          <div>
            <button type="button" onClick={() => setShowAll(true)}>
              Show {underSixteenCount} children
            </button>
          </div>
        ) : (
          <div>
            <button onClick={() => setShowAll(false)}>
              Hide {underSixteenCount} childen
            </button>
          </div>
        )}

        {adding ? (
          <div>
            <h2> Adding a Person</h2>
            <button type="button" onClick={() => setAdding(false)}>
              AddPerson clientId= clientId toggleAdding= toggleAdding
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
