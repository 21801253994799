import {gql, useMutation} from "@apollo/client";

import {isBefore, subDays} from "date-fns";

const POSTPONE_REMINDER = gql`
  mutation PostponeReminder($reminderId: String!) {
    reminderPostpone(reminderData: {reminderId: $reminderId}) {
      reminder {
        id
        client {
          id
          name
          attn
        }
        user {
          id
          firstName
          lastName
        }
        remindOn
        message
        completed
      }
    }
  }
`;

type Reminder = {
  id: string;
  remindOn: string;
};

type Props = {
  reminder: Reminder;
};

export function PostponeReminder({reminder}: Props) {
  const [postponeReminder] = useMutation<{
    postponeReminder: Reminder;
  }>(POSTPONE_REMINDER, {
    variables: {
      reminderId: reminder.id,
    },
  });

  return (
    <button
      onClick={() => {
        postponeReminder();
      }}
      type="button"
      className="px-6 py-1 m-2 border rounded text-coolGray-700 border-coolGray-500 print:hidden"
    >
      {isBefore(
        new Date(reminder.remindOn.replace(/-/g, "/")),
        subDays(new Date(), 1)
      )
        ? "Move to Today "
        : "Snooze One Day "}
      &rarr;
    </button>
  );
}
