import * as React from "react";

import {Query} from "@apollo/client/react/components";

import {gql} from "@apollo/client";

import LoginPage from "../Pages/LoginPage";

// import {FullPageSpinner} from "../components/lib";
const AuthContext = React.createContext();
function AuthProvider(props) {
  // code for pre-loading the user's information if we have their token in localStorage goes here
  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.

  //   if (weAreStillWaitingToGetTheUserData) {
  //     return <FullPageSpinner />;
  //   }

  //   if (true) {
  //   return <h1>SPINNER</h1>;
  //   }

  // const login = () => {}; // make a login request
  // const register = () => {}; // register the user
  // const logout = () => {}; // clear the token in localStorage and the user data
  // note, I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.

  return (
    <Query
      query={gql`
        query PersonQuery {
          loggedInUser {
            id
            firstName
            lastName
            profile {
              agency {
                id
                name
              }
            }
          }
        }
      `}
    >
      {({loading, error, data}) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <LoginPage />;

        // console.log(data.loggedInUser);

        return (
          <AuthContext.Provider value={{data}} {...props} />

          //   <AuthContext.Provider value={{data, login, logout, register}} {...props} />

          // <Button basic fluid>
          //   <Image
          //     avatar
          //     src={
          //       "https://pipeline-media.s3.amazonaws.com/avatars/" +
          //       data.loggedInUser.id +
          //       ".128.png"
          //     }
          //   />{" "}
          //   &nbsp; {data.loggedInUser.firstName} {data.loggedInUser.lastName}
          // </Button>
        );
      }}
    </Query>
  );
}
const useAuth = () => React.useContext(AuthContext);
export {AuthProvider, useAuth};
// the UserProvider in user-context.js is basically:
// const UserProvider = props => (
//   <UserContext.Provider value={useAuth().data.user} {...props} />
// )
// and the useUser hook is basically this:
// const useUser = () => React.useContext(UserContext)
