import * as React from "react";

import {ActivityList} from "./Activities/ActivityList";

type Attachment = {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
};

type Tag = {
  id: string;
  document: Attachment;
};

type ActivityNote = {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
};

type FlexiblePolicyActivity = {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNote[];
  documenttagSet: Tag[];
};

type Client = {
  id: string;
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
};

type Props = {
  client: Client;
};
export function ScheduledPropertyPane({client}: Props) {
  return (
    <>
      Scheduled Property Pane
      <ActivityList
        flexiblepolicyactivitySet={client.flexiblepolicyactivitySet}
        filter="SCHEDULED_PERSONAL_PROPERTY"
      />
    </>
  );
}
