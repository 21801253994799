import * as React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

// import {useUser} from "./Context/UserContext";
import {MainMenu} from "./Menus/MainMenu";

import {Client} from "./routes/client";
// import {TestClient} from "./routes/testclient";
import {Forms} from "./routes/forms";
import {Nonpays} from "./routes/nonpays";
import {Quotes} from "./routes/quotes";
import {Reminders} from "./routes/reminders";
import {Settings} from "./routes/settings";

import {Home} from "./Pages/Home";
import {DepositPrelist} from "./Pages/Reports/DepositPrelist";
import {BankAccountList} from "./Pages/Reports/BankAccountList";

import {Activities} from "./Pages/Activities";
import {AgencyUsers} from "./Pages/AgencyUsers";
import {Birthdays} from "./Pages/Birthdays";
import {Nonrenewals} from "./Pages/Nonrenewals";
import {ClientPage} from "./Pages/ClientPage";
import {Dashboard} from "./Pages/Dashboard";
import {DownloadReport} from "./Pages/DownloadReport";
import {MyDownloads} from "./Pages/MyDownloads";

import {Handbook} from "./Pages/Handbook";
import {LocationPage} from "./Pages/LocationPage";
import {MedicareBeneficiary} from "./Pages/MedicareBeneficiary";
import {PersonPage} from "./Pages/PersonPage";

import {Reports} from "./Pages/Reports";
import {SearchResults} from "./Pages/SearchResults";
import {Todos} from "./Pages/Todo/Todos";
import {Project} from "./Pages/Todo/Project";
import {ProjectAdd} from "./Pages/Todo/ProjectAdd";
import {WorkInProgress} from "./Pages/WorkInProgress";

import {Kanban} from "./Pages/Kanban";
import {QueryTest} from "./routes/querytest";
import {SimpleTest} from "./routes/simplequerytest";
import {QueryExample} from "./routes/queryexample";
import {QueryMakes} from "./routes/querymakes";

// const Loggedin = React.lazy(() => import("./Pages/Loggedin"));
// const Login = React.lazy(() => import("./Pages/Login"));

export function App() {
  // const user = useUser();
  // return (
  //   <Suspense fallback={<div>Loading...</div>}>
  //     {/* <Loggedin /> */}
  //     {user ? <Loggedin /> : <Login />}
  //   </Suspense>
  // );
  //
  return (
    <Router>
      <MainMenu />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path="/accounting/deposit-prelist/:bankAccountId"
          component={DepositPrelist}
        />

        {/* TESTING REACT-QUERY */}

        <Route path="/querytest" component={QueryTest} />
        <Route path="/simplequerytest" component={SimpleTest} />
        <Route path="/queryexample" component={QueryExample} />
        <Route path="/querymakes" component={QueryMakes} />

        <Route path="/accounting/deposit-prelist" component={BankAccountList} />
        <Route path="/activities" component={Activities} />
        <Route path="/birthdays" component={Birthdays} />
        <Route path="/nonpays" component={Nonpays} />

        <Route path="/nonrenewals" component={Nonrenewals} />
        <Route path="/client/:clientId" component={ClientPage} />
        <Route path="/newclient/:clientId" component={Client} />
        {/* <Route path="/testclient/:clientId" component={TestClient} /> */}
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/downloads/all" component={DownloadReport} />
        <Route path="/downloads" component={MyDownloads} />

        <Route path="/forms" component={Forms} />

        <Route path="/handbook" component={Handbook} />
        <Route path="/kanban" component={Kanban} />
        <Route path="/location/:locationId" component={LocationPage} />

        <Route
          path="/medicare/:beneficiaryId"
          component={MedicareBeneficiary}
        />

        <Route path="/person/:personId" component={PersonPage} />

        <Route path="/quotes" component={Quotes} />

        <Route path="/reports" component={Reports} />

        <Route path="/reminders" component={Reminders} />

        <Route path="/search/:query" component={SearchResults} />

        <Route path="/settings" component={Settings} />

        <Route path="/users" component={AgencyUsers} />
        <Route path="/todo/project/add" component={ProjectAdd} />
        <Route path="/todo/:id" component={Project} />
        <Route path="/todo" component={Todos} />
        <Route path="/work-in-progress" component={WorkInProgress} />
      </Switch>
    </Router>
  );
}
