export function Kanban() {
  return (
    <div>
      <div className="flex h-screen" id="app">
        <div
          // :className="sidebarOpen ? 'translate-x-0 ease-out transition-medium' : '-translate-x-full ease-in transition-medium'"
          className="fixed inset-y-0 left-0 z-30 w-64 px-8 py-4 overflow-auto border-r bg-coolGray-100 lg:static lg:inset-auto lg:translate-x-0"
        >
          <div className="flex items-center justify-between pl-3 pr-1 -mx-3">
            <button
              //   @click="sidebarOpen = false"
              className="text-coolGray-700 lg:hidden"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
                <path
                  d="M6 18L18 6M6 6L18 18"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  stroke="currentColor"
                />
              </svg>
            </button>
          </div>
          <nav className="mt-8">
            <h3 className="text-xs font-semibold tracking-wide uppercase text-coolGray-600">
              Issues
            </h3>
            <div className="mt-2 -mx-3">
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg bg-coolGray-200"
              >
                <span className="text-sm font-medium text-coolGray-900">
                  All
                </span>
                <span className="text-xs font-semibold text-coolGray-700">
                  36
                </span>
              </a>
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg"
              >
                <span className="text-sm font-medium text-coolGray-700">
                  Assigned to me
                </span>
                <span className="text-xs font-semibold text-coolGray-700">
                  2
                </span>
              </a>
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg"
              >
                <span className="text-sm font-medium text-coolGray-700">
                  Created by me
                </span>
                <span className="text-xs font-semibold text-coolGray-700">
                  1
                </span>
              </a>
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg"
              >
                <span className="text-sm font-medium text-coolGray-700">
                  Archived
                </span>
              </a>
            </div>
            <h3 className="mt-8 text-xs font-semibold tracking-wide uppercase text-coolGray-600">
              Tags
            </h3>
            <div className="mt-2 -mx-3">
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg"
              >
                <span className="text-sm font-medium text-coolGray-700">
                  Bug
                </span>
              </a>
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg"
              >
                <span className="text-sm font-medium text-coolGray-700">
                  Feature Request
                </span>
              </a>
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg"
              >
                <span className="text-sm font-medium text-coolGray-700">
                  Marketing
                </span>
              </a>
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg"
              >
                <span className="text-sm font-medium text-coolGray-700">
                  v2.0
                </span>
              </a>
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg"
              >
                <span className="text-sm font-medium text-coolGray-700">
                  Enhancement
                </span>
              </a>
              <a
                href="https://agencypipeline.com"
                className="flex items-center justify-between px-3 py-2 rounded-lg"
              >
                <span className="text-sm font-medium text-coolGray-700">
                  Design
                </span>
              </a>
            </div>
            <button className="flex items-center mt-2 -ml-1 text-sm font-medium text-coolGray-600">
              <svg
                className="w-5 h-5 text-coolGray-500"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 7v10m5-5H7"
                  strokeLinecap="round"
                  strokeWidth="2"
                  stroke="currentColor"
                />
              </svg>
              <span className="ml-1">New Project</span>
            </button>
          </nav>
        </div>
        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <div className="flex-shrink-0 sm:border-b-2 sm:border-coolGray-200">
            <header>
              <div className="px-4 sm:px-6">
                <div className="flex items-center justify-between py-3 border-b border-coolGray-200">
                  <div className="flex flex-1 min-w-0">
                    <button
                      // @click="sidebarOpen = true"
                      className="text-coolGray-600 lg:hidden"
                    >
                      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
                        <path
                          d="M4 6h16M4 12h16M4 18h7"
                          strokeLinecap="round"
                          strokeWidth="2"
                          stroke="currentColor"
                        />
                      </svg>
                    </button>
                    <div className="relative w-64 ml-3 flex-shrink-1 lg:ml-0">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg
                          className="w-6 h-6 text-coolGray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            strokeLinecap="round"
                            strokeWidth="2"
                            stroke="currentColor"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="block w-full py-2 pl-10 pr-4 text-sm border rounded-lg text-coolGray-900 placeholder-coolGray-600 border-coolGray-400"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="flex items-center flex-shrink-0 ml-6">
                    <button>
                      <svg
                        className="w-6 h-6 text-coolGray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          stroke="currentColor"
                        />
                      </svg>
                    </button>
                    <button className="ml-6">
                      <img
                        alt="Your profile selfie"
                        className="object-cover rounded-full h-9 w-9"
                        width="40"
                        src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=144&h=144&q=80"
                      />
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-between py-2">
                  <div className="sm:flex sm:items-center">
                    <h2 className="text-2xl font-semibold leading-tight text-coolGray-900">
                      All Issues
                    </h2>
                    <div className="flex items-center mt-1 sm:mt-0 sm:ml-6">
                      <span className="border-2 border-white rounded-full">
                        <img
                          alt="unsplash"
                          className="object-cover w-6 h-6 rounded-full"
                          src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                        />
                      </span>
                      <span className="-ml-2 border-2 border-white rounded-full">
                        <img
                          alt="unsplash"
                          className="object-cover w-6 h-6 rounded-full"
                          src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=144&h=144&q=80"
                        />
                      </span>
                      <span className="-ml-2 border-2 border-white rounded-full">
                        <img
                          alt="unsplash"
                          className="object-cover w-6 h-6 rounded-full"
                          src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=144&h=144&q=80"
                        />
                      </span>
                      <span className="-ml-2 border-2 border-white rounded-full">
                        <img
                          alt="unsplash"
                          className="object-cover w-6 h-6 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=144&h=144&q=80"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="flex">
                    <span className="hidden p-1 border rounded-lg bg-coolGray-200 sm:inline-flex">
                      <button className="px-2 py-1 rounded">
                        <svg
                          className="w-6 h-6 text-coolGray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M4 6h16M4 10h16M4 14h16M4 18h16"
                            strokeLinecap="round"
                            strokeWidth="2"
                            stroke="currentColor"
                          />
                        </svg>
                      </button>
                      <button className="px-2 py-1 bg-white rounded shadow">
                        <svg
                          className="w-6 h-6 text-coolGray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"
                            strokeWidth="2"
                            stroke="currentColor"
                          />
                        </svg>
                      </button>
                    </span>
                    <button className="flex items-center py-2 pl-2 pr-4 ml-5 text-sm font-medium text-white rounded-lg bg-coolGray-800 hover:bg-coolGray-700">
                      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
                        <path
                          d="M12 7v10m5-5H7"
                          strokeLinecap="round"
                          strokeWidth="2"
                          stroke="currentColor"
                        />
                      </svg>
                      <span className="ml-1">New Issue</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex p-1 px-4 border-t border-b bg-coolGray-200 sm:hidden">
                <button className="inline-flex items-center justify-center w-1/2 px-2 py-1 rounded">
                  <svg
                    className="w-6 h-6 text-coolGray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M4 6h16M4 10h16M4 14h16M4 18h16"
                      strokeLinecap="round"
                      strokeWidth="2"
                      stroke="currentColor"
                    />
                  </svg>
                  <span className="ml-2 text-sm font-medium leading-none text-coolGray-600">
                    List
                  </span>
                </button>
                <button className="inline-flex items-center justify-center w-1/2 px-2 py-1 bg-white rounded shadow">
                  <svg
                    className="w-6 h-6 text-coolGray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"
                      strokeWidth="2"
                      stroke="currentColor"
                    />
                  </svg>
                  <span className="ml-2 text-sm font-medium leading-none text-coolGray-900">
                    Board
                  </span>
                </button>
              </div>
            </header>
          </div>
          <div className="flex-1 overflow-auto">
            <main className="inline-flex h-full p-3 overflow-hidden">
              <div className="flex flex-col flex-shrink-0 rounded-lg bg-coolGray-100 w-80">
                <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                  Backlog
                </h3>
                <div className="flex-1 min-h-0 overflow-y-auto">
                  <ul className="px-3 pt-1 pb-3">
                    <li>
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Design shopping cart dropdown
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">Sep 9</div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-purple-100 rounded">
                              <svg
                                className="w-2 h-2 text-purple-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-purple-900">
                                Design
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Add discount code to checkout page
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            {/* Sep 14 */}

                            <span>Sep 14</span>
                          </div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-teal-100 rounded">
                              <svg
                                className="w-2 h-2 text-teal-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-teal-900">
                                Feature Request
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            {/* Sep 12 */}
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col flex-shrink-0 ml-3 rounded-lg bg-coolGray-100 w-80">
                <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                  In Progress
                </h3>
                <div className="flex-1 min-h-0 overflow-y-auto">
                  <ul className="px-3 pt-1 pb-3">
                    <li>
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Add discount code to checkout page
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 14
                          </div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-teal-100 rounded">
                              <svg
                                className="w-2 h-2 text-teal-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-teal-900">
                                Feature Request
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col flex-shrink-0 ml-3 rounded-lg bg-coolGray-100 w-80">
                <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                  Ready for Review
                </h3>
                <div className="flex-1 min-h-0 overflow-y-auto">
                  <ul className="px-3 pt-1 pb-3">
                    <li>
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Design shopping cart dropdown
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">Sep 9</div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-purple-100 rounded">
                              <svg
                                className="w-2 h-2 text-purple-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-purple-900">
                                Design
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Add discount code to checkout page
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 14
                          </div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-teal-100 rounded">
                              <svg
                                className="w-2 h-2 text-teal-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-teal-900">
                                Feature Request
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Design shopping cart dropdown
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">Sep 9</div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-purple-100 rounded">
                              <svg
                                className="w-2 h-2 text-purple-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-purple-900">
                                Design
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Add discount code to checkout page
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 14
                          </div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-teal-100 rounded">
                              <svg
                                className="w-2 h-2 text-teal-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-teal-900">
                                Feature Request
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col flex-shrink-0 ml-3 rounded-lg bg-coolGray-100 w-80">
                <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                  Done
                </h3>
                <div className="flex-1 min-h-0 overflow-y-auto">
                  <ul className="px-3 pt-1 pb-3">
                    <li>
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Design shopping cart dropdown
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">Sep 9</div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-purple-100 rounded">
                              <svg
                                className="w-2 h-2 text-purple-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-purple-900">
                                Design
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Add discount code to checkout page
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 14
                          </div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-teal-100 rounded">
                              <svg
                                className="w-2 h-2 text-teal-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-teal-900">
                                Feature Request
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Design shopping cart dropdown
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">Sep 9</div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-purple-100 rounded">
                              <svg
                                className="w-2 h-2 text-purple-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-purple-900">
                                Design
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Add discount code to checkout page
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 14
                          </div>
                          <div>
                            <span className="inline-flex items-center px-2 py-1 leading-tight bg-teal-100 rounded">
                              <svg
                                className="w-2 h-2 text-teal-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              <span className="ml-2 text-sm font-medium text-teal-900">
                                Feature Request
                              </span>
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="mt-3">
                      <a
                        href="https://agencypipeline.com"
                        className="block p-5 bg-white rounded-lg shadow"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-medium leading-snug text-coolGray-900">
                            Provide documentation on integrations
                          </p>
                          <span>
                            <img
                              alt="unsplash"
                              className="w-6 h-6 rounded-full"
                              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=144&h=144&q=80"
                            />
                          </span>
                        </div>
                        <div className="flex items-baseline justify-between mt-2">
                          <div className="text-sm text-coolGray-600">
                            Sep 12
                          </div>
                          <div></div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
