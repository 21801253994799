export function Handbook() {
  return (
    <div className="m-6">
      <div>
        <h1 style={{marginTop: `7em`, fontSize: `300%`}}>
          Stan Steele Agency, Inc
        </h1>
        <h2>AGENCY STAFF HANDBOOK</h2>
        <div>
          <strong>Effective 4-1-2019</strong>
        </div>
      </div>

      <div style={{pageBreakBefore: `always`}} />
      <br />
      <br />
      <div>
        The policies described in this handbook are not intended to create a
        contract or rights of any nature between the agency, Stan Steele Agency,
        Inc., and agency staff or any right to employment or continued
        employment.
      </div>
      <div>
        All agency staff members are employed “at-will” which means that the
        agency has the right to terminate the employment relationship at any
        time with or without notice and with or without cause.
      </div>
      <div>
        The policies contained in this handbook have been read and it is
        understood that it is the agency staff member’s responsibility to comply
        with these policies including any additions or revisions that may be
        made from time to time at the sole discretion of the agency.
      </div>
      <div>
        Please let this serve as confirmation that I have received a copy of the
        Agency Staff Handbook updated 1/1/2019. I understand that I am
        responsible upon termination or resignation for returning keys, manuals,
        and any other agency property to the Agency Principal.
      </div>
      <span>
        ____________________________________________ __________________
      </span>
      <div>Name Date</div>
      <div style={{pageBreakBefore: `always`}} />
      <h2>MISSION STATEMENT</h2>
      <div>
        The Stan Steele Agency is a very pro-active agency that strives to be a
        sales leader and role model to other Independent Agencies on the local,
        regional, and national levels. Our commitment to quality and excellence
        serves Stan Steele Agency and our internal and external customers well.
        We strive to be the best that we can be in all aspects of our business.
        We are driven by processes that create wins for all – the Agency and
        its’ employees, and most important, “the clients we serve”. We do not
        live by our past successes, but are always looking to improve and
        achieve higher levels of success.
      </div>
      <h2>General Company Policy:</h2>
      <div>
        GOOD ATTITUDE is a Company policy.
        <ul>
          <li>
            Your attitude is on public display each day you come to work, both
            to your fellow employees and to our customers.
          </li>
          <li>
            Good attitudes create a pleasant and productive work environment and
            satisfied customers. A good attitude is not accidental. It takes
            effort and practice every day. Work at having a good attitude. It is
            your job and you reap the benefits.
          </li>
        </ul>
      </div>
      <div>
        CLEANLINESS is a Company policy.
        <ul>
          <li>
            You accept employment with the full understanding that you will do
            your part every day to keep our “house” clean.
          </li>
        </ul>
      </div>
      <div>
        QUALITY WORK is a Company policy.
        <ul>
          <li>
            We claim to be a quality Company. We advertise quality. We must
            deliver quality every day to every customer on every interaction.
            This applies to small items as well as large ones.
          </li>
          <li>
            You are expected to do your very best on every task and constantly
            improve your performance. Your self-respect and pride encourage you
            to deliver quality. We expect it.
          </li>
        </ul>
      </div>
      <div>
        We can spend more time at work than we do with our families.
        Compatibility is a MUST!
      </div>
      <h2>TRADE SECRETS AND CONFIDENTIAL BUSINESS INFORMATION</h2>
      <div>
        The information, formulas, and/or processes referred to within this
        agency are proprietary in nature, constitute trade secrets, and are for
        the sole use and benefit of agents and employees of the Stan Steele
        Agency. The information, formulas, and /or processes referred to in this
        agency are for internal use only, are not to be disseminated beyond the
        intended recipient, and are not to be photocopied or otherwise
        reproduced without the express written consent of Joel Steele and The
        Stan Steele Agency. Any staff member violating this policy will be
        subject to immediate termination.
      </div>
      <div>
        All client information is confidential and will not be shared outside of
        this agency. We comply with all our carrier standards regarding
        confidential information.
      </div>
      <h2>NON-COMPETITION</h2>
      <div>
        Many hours and dollars are spent by this agency to license and train a
        staff team member. In the course of your training a great deal of
        confidential and proprietary information, which has been developed or
        obtained at a substantial cost will be disclosed to you. Due to this it
        is important that a staff team member not disclose this information even
        after termination. To do so would certainly cause financial damage to
        this agency.
      </div>
      <div>
        This agency requires a Covenant Not To Compete and Non-Disclosure
        Agreement. This document is located in Appendix A of this handbook.
      </div>
      <h2>EQUAL EMPLOYMENT OPPORTUNITY</h2>
      <div>
        It is the policy of our Company to provide equal employment
        opportunities without regard to race, color, age, religion, sex,
        national origin or handicap status. This policy relates to all phases of
        employment, including (but not limited to) recruitment, employment,
        placement, promotion, transfer, rates of pay and other forms of
        compensations, benefits, layoff, recall, termination, selection for
        training, use of all facilities and participation in all
        Company-sponsored employee activities.
      </div>
      <div>
        Do not engage in any communication, which is discriminatory, unlawful,
        derogatory, disruptive, harassing, offensive, or otherwise in violation
        of Agency policy, or inconsistent with the professional image of the
        Agency. (Prohibited uses include, but are not limited to, pornographic
        displays or communications, which may be considered slanderous, vulgar,
        or crude or which are prohibited by the Agency’s policy against
        harassment, or may be construed to be intimidating or threatening or
        might otherwise interfere with Agency business operations.
      </div>
      <div>
        With respect to the Agency’s policies against harassment, examples of
        forbidden transmissions include sexually explicit images, messages,
        cartoons or jokes; unwelcome propositions or love letters; and other
        messages which may be construed to be harassing or disparaging to others
        based on their sex, race, ethnicity, disability, sexual orientation,
        age, national origin, veteran status, religious or political beliefs.
        The Agency is an equal opportunity employer.)
      </div>
      <div>
        Our objective is to obtain qualified and/or trainable individuals for a
        position of virtue of job-related standards of education, training,
        experience and personal qualifications.
      </div>
      <h2>CUSTOMER RELATIONS</h2>
      <div>
        Everyone we come into contact with is our customer. If our clients are
        unhappy we lose.
      </div>
      <div>
        Greet every client immediately upon entering the office. Every employee
        should greet every client they come into contact with.
      </div>
      <h2>CUSTOMER COMPLAINTS</h2>
      <div>
        Occasionally, even though we have done our best, a customer may be
        dissatisfied with the products or services that the agency offers.
        Agency staff that has reason to believe a customer is upset should
        report the incident immediately to the Agency Principal or Management.
        If a customer tries to argue with agency staff, above all, be courteous
        and non-threatening. Do not engage in an argument with a customer. You
        should discuss complaints with Management only and maintain the
        confidentiality of such complaints to those who have a need to know
        only.
      </div>
      <div>
        Any notification a staff member receives of a lawsuit must be brought to
        the immediate attention of the Agency Principal or Management. No one
        but the Agency Principal has the authority to discuss client complaints
        with the media, attorneys, or investigators, without the prior written
        approval of the Agency Principal. All such inquiries should be referred
        to the Agency Principal.
      </div>
      <h2>TRAINING PERIOD:</h2>
      <div>
        The training period is intended to give new employees an opportunity to
        determine their ability to achieve a satisfactory level of performance
        and to determine whether the new position meets their expectations.
      </div>
      <div>
        It is the responsibility of the agency to provide adequate training and
        the responsibility of the employee to notify the agent in the event
        adequate training is not provided
      </div>
      <h2>DRESS CODE</h2>
      <div>
        We all have an obligation to this agency to present ourselves in a
        manner, which would be acceptable to the general public. We represent
        Stan Steele Agency, therefore the way we dress and our appearance along
        with our personality are very important qualities to consider when we
        talk to our customers. Our dress attire should be professional office
        appropriate.
      </div>
      <div>
        Good Hygiene and Cleanliness are very important along with the Dress
        Code in representing this agency. Bathing, using deodorants, brushing
        your teeth and keeping nails clean are all very important issues in your
        appearance when presenting yourself to a client.
      </div>
      <div>
        Office attire and general appearance should be professional and
        businesslike. Business casual is acceptable and expected. Cleanliness
        and neatness is expected. Avoid extremes in hairstyles, make-up, and
        clothing. Customers judge the agency in several ways, and one of those
        is how agency staff members look and dress. Customers identify
        professionalism with business attire. Professional dress is expected on
        a daily basis and contributes to the morale of all employees as well as
        setting the image of the agency.
        <ul>
          <li> All shirts and blouses must have an average cut neckline.</li>
          <li>
            {" "}
            T-shirts are not allowed except on Special Occasions (which will be
            given by the Manager).{" "}
          </li>
          <li> Shirts must be tucked in accordingly.</li>
          <li> Blouses and shirts must meet belt-line with no exposed skin.</li>
          <li>
            {" "}
            No spaghetti strap tops or dresses, see through tops or dresses, or
            tube type tops without a covered shirt or sweater type shirt
            covering the exposed inappropriate areas. On Saturdays denim jeans
            or skirts are acceptable as long as they are in good taste and are
            not baggy, holey, or torn and are worn with a presentable top and
            shoes that you would wear on any normal work day.
          </li>
          <li> No tennis shoes unless given prior permission.</li>
          <li> No flip-flops or non-dressy sandals</li>
          <li> No Shorts</li>
          <li>
            {" "}
            Fingernails and toenails must be neatly manicured. When wearing
            polish, the fingernails and toenails should be neatly painted with
            no half painted or chipped nails.
          </li>
          <li> Clothes worn must be neat and without wrinkles.</li>
          <li> Skirts must be no shorter than knee-length.</li>
          <li>
            {" "}
            When leggings are worn they must have an office appropriate top that
            is at least fingertip length and not tight in nature.{" "}
          </li>
          <li>
            {" "}
            No knit type outfits or pants (material such as t-shirt or flannel
            or sweat suit type).
          </li>
          <li> Shoes must be business attire, no casual type shoes.</li>
          <li>
            {" "}
            On Special Occasions with manager approval we will be allowed to
            wear certain type of shirts. Example: (St Patricks Day, Halloween,
            certain holiday occasions)
          </li>
          <li>
            {" "}
            No exposed piercings other than earrings. All tattoos should be kept
            covered and not be exposed by clothing. Men are not allowed to have
            any piercing showing. Hair and eye colors should be a natural color.{" "}
          </li>
        </ul>
      </div>
      <h2>OFFICE HOURS AND EMPLOYEE SCHEDULES</h2>
      <div>
        Office Staff is expected to be at their desk ready to serve the
        community before their scheduled shift.
      </div>
      <div>
        Our agency hours are: <br />
        Monday through Friday from 8:00 a.m. to 5:00 p.m. <br />
        Saturdays from 9:00 – noon.
      </div>
      <div>
        Saturday hours will be rotated. Should you need to change the schedule
        it must be approved by a manager.
      </div>
      <div>
        A minimum of one licensed staff member should be in the office at all
        times. Staff members must work at least 38 hours per week in order to be
        considered full-time.
      </div>
      <div>
        Part time employees may schedule their hours with the Agency Principal
        and Management on a case-by-case basis.
      </div>
      <div>
        Every full-time staff member will have ½ hour for lunch. Lunch shifts
        are staggered to provide for adequate staffing for our clients’
        convenience. Any changes in lunch hour should be previously approved by
        Management. Each person is expected to take his or her lunch at the time
        specified and the office should never be left unattended. If you are
        late leaving for lunch please be aware of others lunch schedules and
        make sure someone else can stay past their specified time to go to
        lunch. If someone else is not available to go later than expected it may
        be necessary to change the time after everyone else gets back from
        lunch. This is a courtesy to others in the office.
      </div>
      <h2>PUNCTUALITY</h2>
      <div>
        Being at work on time is respectful and a common courtesy. Agency staff
        members are expected to be on time in the morning and in returning from
        lunch. If there is a problem, which will make an agency staff member
        late, staff should call/text the Agency Principal or Management prior to
        the time they are expected or as soon as reasonably possible. Tardiness
        is an obvious sign of disrespect and may result in disciplinary action
        up to and including termination.
      </div>
      <h2>PERSONAL COMMUNICATION/VISITORS</h2>
      <div>
        Personal telephone calls, both outgoing and incoming, must be limited to
        emergency situations. If a personal long distance call must be made, it
        should not be charged to the agency.
      </div>
      <div>
        Personal use of the telephone must be kept brief and at a minimum. Cell
        phone usage is not allowed during regular business hours unless
        preapproved by your manager for emergencies. Cell phones should be kept
        on vibrate mode so as not to disturb or distract employees or customers
        and kept in a location as to not distract you from your job duties (In
        your car, purse, etc. NOT in or on your desks). If this is abused then
        we will be forced to not allow ANY Cell phones in the office.
      </div>
      <div>
        There is no time for personal reading or playing of games. Magazines,
        catalogues, and books should not be brought to the agency. Internet
        usage is for business purposes only. There should not be shopping or
        playing of games during working hours.
      </div>
      <div>
        Visits from family members and/or friends should be kept to a minimum.
        Children cannot come to the agency during a parent’s shift to be left
        without prior approval by management.
      </div>
      <h2>HOUSEKEEPING:</h2>
      <div>
        Each employee is expected to keep his or her work area neat and do his
        or her part in keeping the remainder of the office clean at all times.
        Desktops should remain organized during the workday to assist in
        efficient operation and professional appearance. When you leave for the
        day you should keep the top of your desk in an orderly manner and do not
        leave any customer information out for anyone to see.
        <ul>
          <li> Desks should not be dusty or messy.</li>
          <li>
            {" "}
            Drinks should have a lid on them to avoid spills and damage to
            equipment.
          </li>
        </ul>
      </div>
      <h2>PERFORMANCE REVIEWS</h2>
      <div>
        Agency members are entitled to know how they are doing in their
        position. A formal performance review will take place approximately
        every 12 months (more often if corrective action is necessary).
        Disciplinary action may call for any of the four steps: verbal warning,
        written warning, suspension with or without pay, or termination of
        employment.
      </div>
      <div>
        Examples of rules of conduct that may result in termination include but
        are not limited to:
        <ul>
          <li>
            {" "}
            Insubordinate or other disrespectful conduct toward employees,
            management, or clients.
          </li>
          <li>
            {" "}
            Any action that could result in termination of an agency staff’s
            license or agency binding authority (i.e. misuse of customer
            payments or forgery).
          </li>
          <li> Agency staff’s unwillingness to perform the work assigned.</li>
          <li>
            {" "}
            Verbal or physical abuse or threat to any other staff member or
            customer.
          </li>
          <li> Unauthorized absence from workstation during the workday.</li>
          <li>
            {" "}
            Use of any illegal drugs on premises or conducting business-related
            activities while intoxicated or under the influence of illegal
            drugs.
          </li>
          <li> Harassment of employee, vendor or customer.</li>
          <li>
            {" "}
            Possession of dangerous or unauthorized materials, such as
            explosives or fireworks, in the workplace.
          </li>
          <li> Excessive absenteeism or any absence without notice.</li>
          <li>
            {" "}
            Unauthorized use of telephones, mail systems, or other agency-owned
            equipment
          </li>
          <li>
            {" "}
            Unauthorized use of internet – including personal use of internet on
            company time.
          </li>
          <li> Unauthorized disclosure of confidential information. </li>
          <li>
            {" "}
            Inability to pass property and casualty and life licensing,
            background checks or state licensing application.
          </li>
        </ul>
      </div>
      <div>
        Employment is at the mutual consent of the Stan Steele Agency and the
        employee. Either party may terminate that relationship at any time, with
        or without cause, and with or without advance notice. Employee
        resignations should include at least two weeks’ notice and be given to
        Agency Principal only.
      </div>
      <h2>COMPENSATION</h2>
      <div>
        Staff members are paid differently depending on the position,
        performance, tenure, and merit. Salary, bonuses and/or commissions will
        be negotiated with each individual staff member at hire and are subject
        to change.
      </div>
      <div>
        Discussions with other staff members around pay can result in anger and
        frustration among employees, particularly when reasons for pay
        differences are not understood or put into context. Check Stubs should
        be kept in desk drawers until taken home and should not be left on top
        of desks or in baskets.
      </div>
      <h2>STAFF DEVELOPMENT, EDUCATION AND HIRING PROCEDURES</h2>
      <div>
        All employees hired to work permanently will undergo a probationary
        six-month period. Agency staff will be required to obtain a Property and
        Casualty license to perform licensed duties. Continuing education
        credits will be the responsibility of the staff member to manage and
        ensure that he or she maintains licenses and they are kept in good
        standing. The agency will pay for one attempt toward licensure for each
        license. Any employee leaving within 1 year of date of license will have
        to reimburse the agency for all licensing & training fees (to be
        subtracted from final paycheck).
      </div>
      <div>
        Proper screening can include a support staff test, typing test, driving
        record, and credit check as well as employment reference checks. Any
        person that is unable to pass a background check at time of licensure or
        is unable to obtain a license for any reason will be unable to maintain
        employment. Convicted felons are not allowed to work in the business of
        insurance by requirement of New York State law.
      </div>
      <div>
        Base salary or hourly earnings will be direct deposited into the
        employee’s account around the 1st and 15th of each month. Commission is
        paid on the 15th of every month for the previous month’s earnings and
        can only be paid to staff that are licensed. Pay advances on ANY wages
        are not possible.
      </div>
      <h2>BENEFITS PROVIDED</h2>
      <div>Medical coverage: No medical insurance coverage is provided.</div>
      <div>
        Retirement: The agency has a 401k Plan and matches 100% of the first 3%
        contributed by the staff member, and 50% of the next 2%. This retirement
        plan is for staff members age 21 and older who have been working in the
        agency for one year.
      </div>
      <h2>OPEN DOOR POLICY</h2>
      <div>
        Agency staff members that have a problem or topic they would like to
        discuss with the agency principal or management are welcome to do so.
        Indicate if the discussion needs to be in private. By discussing
        concerns openly and frankly, little problems can be prevented from
        becoming big ones.
      </div>
      <div>
        If you have a concern with staff, management, or the agency principal
        that is of a hurtful nature please discuss that with the person
        immediately or as soon as possible. This will prevent misunderstandings
        and communication errors. Frustrations with other staff members are sure
        to come up because we are all different people who think differently,
        work differently, and communicate differently. We should understand that
        we are different and appreciate each other for our differences. This is
        what makes us a strong team. We are all professionals and should conduct
        ourselves accordingly. This means letting go of frustrations in a timely
        manner and ensuring they do not affect our ability to perform job
        functions without distraction.
      </div>
      <div>
        Any suggestions or concerns that are not of an immediate nature may be
        addressed in our agency meeting. These discussions should be addressed
        with the utmost professionalism and courtesy. Agency staff should not
        fear that any concern-if done in a respectful and productive manner-will
        affect their position in the agency or their job security. We all need
        feedback-positive and negative-in order to achieve success.
      </div>
      <h2>ABSENCES/ SICK/PERSONAL/FUNERAL DAYS</h2>
      <div>
        Five days of sick/personal leave will be provided annually to all
        full-time salaried employees after they have successfully completed
        their six month probationary period. In order for a staff member to use
        their sick leave that person or their child must be sick. In the event
        that it is necessary to take more than five days the time missed will be
        subtracted from vacation time due. After vacation time is used pay will
        be reduced. Unused sick/personal leave benefits will NOT carry over into
        a new calendar year. Unused time will not be paid upon termination.
        Part-time employees have no sick/personal leave provided. Employees may
        be required to present a Doctor’s Excuse if requested by their Manager.
        Excessive absences are grounds for termination.
      </div>
      <div>
        Full time employees will receive 3 days paid for funeral pay for
        immediate family: Spouse, Child, Mother, Father, Brother, or sister in
        the event of a death. No funeral days will be allowed for the loss of a
        family pet.
      </div>
      <h2>VACATION</h2>
      <div>
        Full-time salaried employees receive: <br />
        5 days paid vacation for 1 year of service
        <br />
        10 days paid vacation for 5 years of service
      </div>
      <div>
        Vacation schedules must be arranged to ensure an adequate work force at
        all times. A vacation schedule will be made in January of each year with
        first choice of vacation being rotated among employees. Manager must
        approve dates desired. Vacation time must be used during the calendar
        year or forfeited. Vacation days may be taken in ½ day increments and no
        more than 5 consecutive days can be used at one time. Vacation time
        taken on a Paid Holiday is not counted against the Vacation time.
        Vacation days should be split between the 1st and 2nd half of the year
        so that we do not find ourselves shorthanded.
      </div>
      <div>
        Vacation days may be scheduled before the holiday or after but not both
        before and after the same holiday. Employee receiving first choice of
        vacation time one year will move to the last choice the following year.
        Employees not having vacation time will not be permitted to make up
        hours unless pre-approved by the agency principals.
      </div>
      <h2>HOLIDAYS</h2>
      <div>
        An announcement of holidays will be made in December each year. Holidays
        for which full-time staff members receive paid leave are typically:
      </div>
      <ul>
        <li>New Years Day </li>
        <li>President’s Day</li>
        <li>Memorial Day</li>
        <li>Independence Day</li>
        <li>Labor Day</li>
        <li>Thanksgiving Day & Black Friday</li>
        <li>Christmas Day</li>
      </ul>
      <h2>LEAVE OF ABSENCE</h2>
      <div>
        Agency staff with six or more months of service may request a leave of
        absence without pay for a period not to exceed 1 month for illness or
        compelling personal reason. Extensions may be granted only under unusual
        or unavoidable circumstance, which necessitate that an extended period
        of time away from work is needed. Leave requests must be made in writing
        and must set forth the reason(s) for the request and the length of leave
        requested. The granting of leave is done at the discretion of the Agency
        Principal.
      </div>
      <div>
        While on leave, agency staff will remain an employee of the Stan Steele
        Agency, Inc. Agency staff will not be entitled to holiday pay while on
        leave.
      </div>
      <h2>AUTHORIZED APPOINTMENTS</h2>
      <div>
        Appointments with any professional, including doctor, dentist, lawyer,
        etc, should be scheduled outside of work time whenever possible. If this
        is not possible and a staff member misses work due to these appointments
        the time may be made up as long as it is less than four hours and it is
        made up within one week. If it is longer than four hours the time will
        be deducted from pay or taken from Personal time. Previous authorization
        must be received from management for these circumstances. Appointments
        should be communicated with management with advance notice.
      </div>
      <h2>MILITARY LEAVE</h2>
      <div>
        A military leave of absence will be granted without pay to full-time
        salaried employees who are absent from work because of service in the
        U.S. uniformed services. Vacation days may be used for this purpose or
        pay will be reduced.
      </div>
      <h2>MATERNITY LEAVE</h2>
      <div>
        No additional salary will be provided during the absence of maternity
        leave. Normal Maternity Leave is 6-8 weeks without pay.
      </div>
      <h2>JURY DUTY</h2>
      <div>
        Any full-time salaried employee who is summoned for jury duty will be
        provided regular pay less what is paid by the courts each day. Employees
        must show the jury duty summons to the Manager as soon as possible so
        that the Manager may make arrangements to accommodate their absences.
        Employee must provide proof of the start and end dates of their summoned
        court. Of course, employees are expected to report for work whenever the
        court schedule permits.
      </div>
      <h2>DISABILITY ACCOMMODATION</h2>
      <div>
        Employment practices and activities are conducted on a
        non-discriminatory basis. Reasonable accommodation is available to all
        disabled agency staff. All employment decisions are based on the merits
        of the situation as it relates to the job requirements regardless of the
        disability of the individual. In the event that accommodations need to
        be provided the Agency Principal should be notified.
      </div>
      <h2>AGENCY PROPERTY </h2>
      <div>
        The Agency’s information and technology resources are dedicated to
        achieving business objectives. The Agency provides e-mail, computers,
        computer software, networks, internet, intranet, fax, telephone, and
        voice mail systems to Agency employees. Except for limited personal use
        (not during business hours), such equipment and services may be used
        only for business purposes.
      </div>
      <div>
        All Agency employees are responsible for information security. We
        demonstrate accountability and teamwork by being aware of information
        security processes and policies, and by taking the necessary steps to
        reduce the risk of security breaches.
      </div>
      <div>
        Access to Agency systems will be revoked and disciplinary action may be
        taken in the event that such systems are used to commit illegal acts, or
        to violate the Agency’s non-discrimination, harassment, pornography,
        solicitation or proprietary information policies, or any other terms of
        this policy.
      </div>
      <div>
        All employees must obtain the permission of the agency owner to install
        any software on any Agency computer or connect any personal computer to
        the Agency network. All employees should refrain from using or
        distributing software that may damage or disrupt the Agency’s work
        environment by transmitting a virus or conflicting with Agency systems.
      </div>
      <div>
        As allowed by law, the Agency reserves the right to monitor or review
        any and all data and information contained on any employee’s computer,
        other electronic device issued by the Agency. In addition, the Agency
        reserves the right to monitor or review an employee’s use of the
        internet, intranet, email or any other electronic communications without
        prior notice.
      </div>
      <div>
        All agency workspace, including file cabinets and desks are the property
        of the Stan Steele Agency, Inc. and must be available at all times. As a
        staff member you should not store personal files or paperwork in your
        desk.
      </div>
      <div>
        The use of personal locks on any agency property is strictly forbidden.
        No agency equipment, including computers, photocopiers or printers may
        be used for personal business without prior consent from the Agency
        Principal or Management.
      </div>
      <div>
        All office supplies are the property of the Stan Steele Agency. Personal
        use of any agency resources including postage is prohibited.
      </div>
      <div>
        E-mail and Internet use is for business purposes only. Internet usage
        from Stan Steele Agency owned computer equipment will be monitored. Stan
        Steele Agency may access your computer at any time they see fit to
        evaluate the usage of their technology. Abuse may lead to termination.
      </div>
      <div>
        An employee who resigns, or is terminated, suspended or discharged, will
        be required to return all items of equipment, supplies, keys, etc.,
        issued by the Company for use in the performance of his/her job. Return
        of such equipment, as well as the payment of any outstanding debts owed
        to the Company, must precede the issuance of the employee’s final
        paycheck.
      </div>
      <h2>OUTSIDE EMPLOYMENT</h2>
      <div>
        Agency staff may hold an outside job in non-related businesses or
        professions as long as they meet the performance standards of their job
        description with the agency. Unless the Agency Principal or Management
        has approved an alternative work schedule, agency staff will be subject
        to the agency’s scheduling demands, regardless of any outside work
        assignments.
      </div>
      <div>
        The office space, equipment, and materials owned by the Stan Steele
        Agency are not to be used to perform services for any outside business.
        Any conversation, distribution, ordering, or other action involving an
        outside job with co-workers should be done outside of normal business
        hours. These activities may only take place during lunch or before and
        after work.
      </div>
      <h2>OTHER BENEFITS- USED CARS</h2>
      <div>
        While the principal Joel Steele has a car dealership- employees of the
        Steele Agency will have the benefit of purchasing used cars at
        wholesale. The purchase of these cars will be limited to two (2) cars
        each calendar year.
      </div>
      <h2>HARASSMENT</h2>
      <div>
        The Agency has zero tolerance for any form of discrimination and is
        committed to providing a workplace free from any form of harassment,
        intimidation, or hostility toward an individual.
      </div>
      <div>
        The following conduct is prohibited: (a) unwelcome sexual flirtations,
        advances or propositions; (b) verbal abuse of a sexual nature, sexually
        related comments and joking, graphic or degrading comments about a
        person's physical appearance or body; (c) uninvited and unwelcome
        physical contact or touching, such as patting, pinching, brushing
        against another's body, impeding, blocking or other physical
        interference with a person's normal work or movement; (d) verbal
        harassment regarding a person's race, color, religion, sex, national
        origin, ancestry, age, medical condition, disability, veteran status, or
        any protected status under federal or state laws, including but not
        limited to nicknames, slurs, derogatory comments, negative stereotyping,
        gestures, or jokes; (e) threatening, intimidating, or hostile acts, that
        relate to a person's race, color, religion, sex, national origin,
        ancestry, age, medical condition, disability, veteran status, or any
        protected status under federal or state laws; or (f) visual forms of
        harassment including written or graphic material that demeans or shows
        hostility or aversion toward a person or group because of race, color,
        religion, sex, national origin, ancestry, age, medical condition,
        disability, veteran status, or any protected status under federal or
        state laws, such as derogatory posters, sexually-oriented cartoons,
        pictures, drawings or the display of sexually suggestive objects or
        pictures on the premises.
      </div>
      <div>
        It is the policy of the Agency that all employees are responsible for
        assuring that the workplace is free from any form of discrimination or
        harassment. Any employee who feels that he or she is being harassed in
        any manner by a co-worker, client, supplier/vendor, or other person,
        should immediately bring the matter to the attention of his/her Office
        Manager. Any employee, who believes an Office Manager is harassing them,
        should immediately bring the matter to the attention of the Owner.
      </div>
      <div>
        All complaints of discrimination or harassment should be reported
        promptly so the Agency can investigate the matter promptly, and take
        appropriate action to stop any offensive conduct. The Agency will make
        every effort to keep the investigation as confidential as possible. The
        reporting employee and witnesses may not attempt to influence other
        witnesses or discuss the allegations with employees who do not have a
        right or need to know. Likewise, the employee whose alleged conduct is
        the subject of the investigation may not attempt to influence other
        witnesses or discuss the allegations with employees who do not have a
        right or need to know. Failure to reasonably maintain the
        confidentiality of an investigation may lead to disciplinary action, up
        to and including, termination, because such comments and conduct may
        affect the outcome of the investigation and create additional tension
        which might be construed as retaliation. The employee who was the
        alleged victim of the investigation and the allegedly harassing or
        discriminating employee will be informed of the results of the
        investigation when it is concluded.
      </div>
      <div>
        The Agency takes prompt action if discrimination/harassment is
        determined to have occurred.
      </div>
      <h2>INJURIES</h2>
      <div>
        It is the responsibility of all agency staff to make the agency
        principal or management aware of any safety hazard. In the event of an
        injury on the job, the agency principal should be notified immediately.
        Worker’s compensation insurance will be maintained by the agency to
        provide coverage for work-related injuries.
      </div>
      <h2>SMOKING</h2>
      <div>
        We have a Smoke Free Building. Smoking in the building will NOT be
        allowed by any person. This includes electronic tobacco apparatus. NO
        SMOKING ANYWHERE ON THE PREMISES. Necessary cleanliness should be taken,
        hands washed, shower, mouthwash, gum, etc., so the smell of smoke will
        not linger in the office.
      </div>
      <h2>EMERGENCY PROCEDURES</h2>
      <div>
        Catastrophe Preparation
        <ol>
          <li>
            Each person in the agency should keep a complete list of agency
            staff phone numbers. Agency staff will be responsible for making
            contact with the agency principal daily to discuss the timely
            resumption of business.
          </li>
          <li>
            Agency staff members understand that they are expected to remain
            accessible and available to assist in implementation of
            post-catastrophe communication and claim handling.
          </li>
        </ol>
      </div>
      <h2>INCLEMENT WEATHER</h2>
      <div>
        Unless notified by the agency principal, agency staff members are
        expected to report to work on all regularly scheduled days, regardless
        of weather conditions. Agency staff members that are unable to report to
        work due to weather conditions must notify the agency principal as soon
        as possible.
      </div>
      <div>
        In the event that the agency closes due to severe weather conditions,
        agency staff will not be required to report to work. Agency staff will
        be paid for that day and it will not be counted as a vacation or
        personal day.
      </div>
      <h2>LOITERING:</h2>
      <div>
        No one should be found loitering on Company property at any time. If you
        notice anyone who does not appear to be an employee or customer, who
        acts in a suspicious manner, offer the individual your assistance. If
        you are not satisfied with your response, it is important to report the
        situation to the manager immediately.
      </div>
      <h2>EMPLOYEE PARKING:</h2>
      <div>
        The parking spots on the row of the parking lot furthest from the door
        have been designated for employee parking, and you are asked NOT to park
        in the front row closest to the building, which is reserved for
        customers and visitors. The agency principals may park close to the
        building because they tend to come and go during the day more
        frequently.
      </div>
      <h2>TERMINATION</h2>
      <div>
        If you leave or are terminated from your employment and earning
        commissions/bonuses, your last check will be held for 1 month after your
        last workday. This is done to make sure there are no charge backs before
        we release your check. If you have already terminated your employment,
        you will not be paid bonuses or commissions for the current month.
      </div>
      <h2>DRUG-FREE WORKPLACE</h2>
      <div>
        The use and misuse of drugs and alcohol can threaten the agency and its
        clients. The possession, use or sale of illegal drugs (including drug
        paraphernalia) is prohibited. The misuse of any legal drugs and/or the
        use of alcohol, while on Company time or during breaks or meals, is
        strictly prohibited.
      </div>
      <div>
        Any employee under the influence of alcohol or drugs that may impair
        judgment, performance or the safety of the employee or others while on
        Company property, while conducting Company business, or during work
        hours, is subject to discipline including termination. Employees are
        required to promptly notify their manager if they are taking any
        medication that may affect their judgment, performance or behavior.
      </div>
      <div>
        Stan Steele Agency, Inc. conducts post-accident drug testing of
        employees when an accident occurs during company time as allowed by law.
        Stan Steele Agency, Inc. will contract a third-party licensed laboratory
        to conduct all drug testing.
      </div>
      <div>
        Stan Steele Agency, Inc. will pay the costs of drug testing which it
        requires. Employees who dispute any results will pay the costs of any
        additional drug testing. Stan Steele Agency, Inc. may conduct drug
        and/or alcohol testing if an employee is having work performance
        problems and/or is displaying behavior that may be alcohol or drug
        related. A manager or supervisor, with the approval of Occupational
        Health and Safety, may require that the employee submit to a breath test
        and/or urinalysis. In such circumstances, the employee will be
        reimbursed for all work time lost.
      </div>
      <div>
        As a condition of employment, employees are required to notify their
        manager or supervisor in writing of any criminal drug statute conviction
        they receive. This notification must be received by the manager within
        five (5) days of the conviction.
      </div>
      <h2>CONSEQUENCE OF POSITIVE TEST RESULTS</h2>
      <div>
        A post-accident positive drug test will result in termination of
        employment. Refusal to comply with the testing requirements of this
        policy will be considered to be a positive result and will result in
        immediate termination of employment. All positive results are reviewed
        by a Medical Health Officer (MHO). The MHO will contact employees who
        test positive to ensure other possible medications or factors did not
        cause the positive result. If an employee refuses to talk to the MHO,
        the test results will be considered “positive” and will result in
        immediate termination of employment.
      </div>
      <h2>New York Paid Family Leave Benefits</h2>
      <div>
        Beginning January 1, 2018, eligible employees of the Company will be
        able to receive paid family leave benefits in accordance with the New
        York Paid Family Leave Benefits Law (PFLBL), within the New York
        disability leave laws. This program is funded through employee pay
        deductions. Benefits will increase in length of time and weekly pay
        level until fully implemented on January 1, 2021.
      </div>
      <div>
        Employees taking family leave under the PFLBL will not lose employment
        benefits including existing health insurance benefits for the duration
        of the leave. Covered employees will be reinstated to his or her
        position or comparable position upon return from leave. The Company will
        not retaliate against any employee for seeking or receiving paid leave
        or paid leave benefits provided by the PFLBL.
      </div>
      <h3>Eligibility:</h3>
      <div>
        Employees will be eligible to participate after having worked for the
        Company for 26 or more consecutive weeks (six months) or, for employees
        who regularly work a schedule less than the Company’s regular work week,
        on their 175th day of work for the Company.
      </div>
      <div>
        Eligible employees will be entitled to paid family leave for the
        following purposes:
        <ul>
          <li>
            {" "}
            To provide care, including physical or psychological care, for a
            family member’s serious health condition. For the purposes of this
            policy, a “family member” is defined as a child, parent (biological,
            foster, or adoptive parent, a parent-in-law, a stepparent, a legal
            guardian, or other person who stood in loco parentis to the employee
            when he or she was a child), grandparent, grandchild (child of the
            employee’s child), spouse, or domestic partner.{" "}
          </li>
          <li>
            {" "}
            To bond with the employee’s child during the first 12 months after
            the child’s birth, or the first 12 months after the placement of the
            child for adoption or foster care with the employee.
          </li>
          <li>
            {" "}
            Because of any qualifying exigency as interpreted under the federal
            FMLA, arising out of the fact that the spouse, domestic partner,
            child, or parent of the employee is on active duty (or has been
            notified of an impending call or order to active duty) in the U.S.
            Armed Forces.
          </li>
        </ul>
      </div>
      <div>
        PFLBL leave benefits may be payable to employees for paid family leave
        taken intermittently or for less than a full week in increments of one
        full day or one-fifth of the weekly benefit.
      </div>
      <div>
        When the necessity for family leave is foreseeable, employees must
        provide the employer with at least 30 days’ advance notice. If the leave
        is foreseeable but will begin in fewer than 30 days, the employee must
        provide as much notice as is practicable.
      </div>
      <div>
        The PFLBL benefits are not payable under the following circumstances:
        <ul>
          <li>
            {" "}
            Any time an employee is receiving total disability benefits under a
            claim for workers’ compensation, volunteer firefighters, or
            volunteer ambulance workers’ benefits. However, if an employee is
            receiving partial disability payments, the amount of family leave
            benefits combined with the benefits under those laws may not exceed
            the employee’s average weekly wage.
          </li>
          <li>
            {" "}
            To an employee not employed or who is on administrative leave from
            his or her employment.
          </li>
          <li>
            {" "}
            To an employee currently receiving sick pay or paid time off from
            the employer.
          </li>
          <li>
            {" "}
            For any day in which the claimant works at least part of the day
            during the same working hours as those for which family leave
            benefits are claimed.
          </li>
        </ul>
      </div>
      <div>
        These restrictions prevent duplicative payments of benefits but do not
        restrict the employee’s ability to take time off without receiving paid
        leave benefits.
      </div>
      <div>Implementation Schedule:</div>
      <p />
      <div>
        As of January 1, 2018, employees can receive up to eight (8) weeks off
        at 50 percent of the employee’s average weekly wage, capped to 50
        percent of the statewide average weekly wage. When fully implemented on
        January 1, 2021, employees will receive 67 percent of their average
        weekly wage, capped to 67 percent of the statewide average weekly wage.
      </div>
      <div>
        The requirement to provide paid leave, the amount of leave required, and
        the amount of pay that employees may receive will be implemented as
        follows (unless delayed by the New York Superintendent of Financial
        Services):
        <ul>
          <li>
            {" "}
            On or after January 1, 2018, employees may receive up to eight (8)
            weeks of paid benefits in any 52-week period at 50 percent of their
            average weekly wage, but in an amount not to exceed 50 percent of
            the state average weekly wage.
          </li>
          <li>
            {" "}
            On or after January 1, 2019, employees may receive up to ten (10)
            weeks of paid benefits in any 52-week period at 55 percent of the
            employee’s average weekly wage, but not to exceed 55 percent of the
            state average weekly wage.
          </li>
          <li>
            {" "}
            On or after January 1, 2020, employees may receive up to ten (10)
            weeks of paid benefits in any 52-week period at 60 percent of the
            employee’s average weekly wage not to exceed 60 percent of the state
            average weekly wage.
          </li>
          <li>
            {" "}
            On or after January 1, 2021, and for each year thereafter, employees
            may receive up to twelve (12) weeks in any 52-week period at 67
            percent of the state average weekly wage.
          </li>
        </ul>
      </div>
      <div>Coordination with Other Paid Time Off:</div>
      <div>
        The PFLBL specifically requires that:
        <ul>
          <li>
            {" "}
            No employee may receive more than the maximum duration of leave as
            permitted at the time by the phase-in schedule of family leave
            benefits (at least 8 weeks and no more than 12 weeks) during any
            52-week period, or in any period where family leave combined with
            disability benefits exceeds 26 weeks during the same 52 calendar
            weeks.
          </li>
          <li>
            {" "}
            Prior to receiving paid family leave benefits, an employee must
            provide a notice to the Company and an approved medical
            certification in a form (to be generated by the Chairman of the
            Workers’ Compensation Board). Employees may not receive family leave
            benefits for any period where such certification has not been filed.
          </li>
          <li>
            {" "}
            Employees will receive paid family leave benefits of partial regular
            wage in accordance with regulations. However, employees may choose,
            but are not required, to use accumulated but unused vacation or
            personal leave to receive their full salary during family leave.
            Employees choosing to review paid time off benefits will not receive
            paid family leave benefits during that time.
          </li>
          <li>
            {" "}
            Employees must use FMLA leave and state Paid Family Leave
            concurrently. Employees may not stack leave time to take more the
            maximum duration of leave (up to 12 weeks), as permitted at the time
            by the phase-in schedule.
          </li>
          <li>
            {" "}
            Employees may not concurrently receive New York state disability
            benefits and paid family leave benefits.
          </li>
        </ul>
      </div>
      <div>
        Any employee having questions regarding paid family leave benefits or
        wanting to make a request for leave should contact the agency
        principals.
      </div>
      <div style={{pageBreakBefore: `always`}}>
        <h2>APPENDIX A - AGREEMENT NOT TO COMPETE AND NOT TO DISCLOSE</h2>
        <div>
          I, __________________________, in consideration of the valuable
          education, licensing, training, assistance, information, and knowledge
          which has been and/or will be provided to me by the Stan Steele
          Agency, Inc., and as a result of the opportunity which the Agency has
          given me to become skilled in the profession of risk management
          advisory, I do hereby covenant and promise not to solicit or contact
          existing clients or prospects of the Stan Steele Agency, in sales
          and/or service of any type of insurance which is sold by the Stan
          Steele Agency, Inc. within 25 miles of the agency for a period of two
          years from the date of my separation from employment with the Stan
          Steele Agency, Inc., regardless of the cause of separation.
        </div>
        <div>
          I further covenant and agree that I will not disclose any confidential
          or proprietary information to which I have been privy during my
          employment to any third party either during or following my separation
          from employment. I recognize and acknowledge that a breach of this
          non-disclosure provision would cause immediate and irreparable
          financial harm to for which an order of injunctive relief would be
          necessary and appropriate.
        </div>
        <div>
          For a period of two years from the date of my separation, I further
          agree that I will not directly or indirectly own, run, manage,
          operate, control, or be connected in any manner with the ownership,
          management, operation or control of any business similar or
          competitive to the type of business conducted by said Stan Steele
          Agency, Inc. within 25 miles of the Stan Steele Agency, Inc..
        </div>
        <div>
          For a period of two years from the date of my separation, I agree I
          will neither personally or through any other person, agency, or
          organization, directly or indirectly, induce or advise any Steele
          Agency policyholders in this agency to lapse, surrender, cancel or
          transfer any Agency Insurance coverage. I also affirm that I will not
          solicit any such policyholder to purchase any insurance coverage
          competitive with the insurance coverage sold by Stan Steele Agency,
          within a 15 mile radius of the Stan Steele Agency, Inc.
        </div>
        <div>
          In the event that I breach the provisions of this agreement, or
          threaten to breach the provisions of this agreement, Stan Steele
          Agency, Inc., shall be entitled to an injunction restraining me from
          such a breach and in addition to other damages which may be
          appropriate, full reimbursement for the cost of attorney’s fees and
          costs. This provision allowing an injunction shall not be construed as
          prohibiting said Stan Steele Agency from pursuing any other available
          remedy for such a breach or threatened breach, including the recovery
          of damages from me.
        </div>
        <div>
          I acknowledge the sufficiency of the consideration provided me in
          exchange for the covenants contained herein. Further, I voluntarily
          enter into the agreement, acknowledging that I am making this choice
          of my own free will.
        </div>
        <div>
          In execution and witness of this Agreement I have affixed below my
          signature on this _________________ day of
          ________________________________.
        </div>
        <div>
          _________________________________________________ Staff Team Member
        </div>
        <div>_________________________________________________ Witness</div>
      </div>
    </div>
  );
}
