import * as React from "react";

import {
  Switch,
  Link,
  Route,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import {Profile} from "./settings/profile";
import {Security} from "./settings/security";
import {AgencySettings} from "./settings/agency";
import {Users} from "./settings/users";

// TODO Link styling is not working.

export function Settings() {
  let {path, url} = useRouteMatch();
  let {selectedPane} = useParams<Record<string, string | undefined>>();

  // consosle.log(selectedPane);
  return (
    <main
      className="flex-1 overflow-y-auto focus:outline-none"
      // tabindex="0"
    >
      <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div className="pt-10 pb-16">
          <div className="px-4 sm:px-6 md:px-0">
            <h1 className="text-3xl font-extrabold text-gray-900">Settings</h1>
          </div>
          <div className="px-4 sm:px-6 md:px-0">
            <div className="py-6">
              {/* <!-- Tabs --> */}
              <div className="lg:hidden">
                <label htmlFor="selected-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="selected-tab"
                  name="selected-tab"
                  className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                >
                  <option>General</option>

                  <option>Password</option>

                  <option>Notifications</option>

                  <option>Plan</option>

                  <option>Billing</option>

                  <option>Team Members</option>
                </select>
              </div>
              <div className="hidden lg:block">
                <div className="border-b border-gray-200">
                  <nav className="flex -mb-px">
                    {/* <!-- Current: "border-purple-500 text-purple-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" --> */}

                    <Link
                      to={`${url}/general`}
                      className={`${
                        selectedPane === "general"
                          ? "text-purple-600  border-purple-500"
                          : "text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700"
                      } px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap`}
                    >
                      General
                    </Link>

                    <Link
                      to={`${url}/security`}
                      className={`${
                        selectedPane === "security"
                          ? "text-purple-600  border-purple-500"
                          : "text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700"
                      } px-1 py-4 ml-8 text-sm font-medium border-b-2 whitespace-nowrap`}
                    >
                      Security
                    </Link>

                    <Link
                      to={`${url}/agency`}
                      className={`${
                        selectedPane === "agency"
                          ? "text-purple-600  border-purple-500"
                          : "text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700"
                      } px-1 py-4 ml-8 text-sm font-medium border-b-2 whitespace-nowrap`}
                    >
                      Agency
                    </Link>

                    <Link
                      to={`${url}/users`}
                      className={`${
                        selectedPane === "users"
                          ? "text-purple-600  border-purple-500"
                          : "text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700"
                      } px-1 py-4 ml-8 text-sm font-medium border-b-2 whitespace-nowrap`}
                    >
                      Users
                    </Link>
                  </nav>
                </div>
              </div>

              <Switch>
                <Route exact path={path}>
                  <Redirect to={`${path}/general`} />
                </Route>

                <Route exact path={`${path}/general`}>
                  <Profile />
                </Route>
                <Route exact path={`${path}/security`}>
                  <Security />
                </Route>
                <Route exact path={`${path}/agency`}>
                  <AgencySettings />
                </Route>
                <Route exact path={`${path}/users`}>
                  <Users />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
