import {gql, useMutation} from "@apollo/client";

import {isBefore, subDays} from "date-fns";

const POSTPONE_CLIENT_REMINDER = gql`
  mutation PostponeClientReminder($clientreminderId: String!) {
    clientReminderPostpone(
      reminderData: {clientreminderId: $clientreminderId}
    ) {
      clientreminder {
        id
        created
        updated
        about {
          id
          name
          attn
        }
        whoToRemind {
          id
          firstName
          lastName
        }
        remindOn
        reminder
        completed
      }
    }
  }
`;

type ClientReminder = {
  id: string;
  remindOn: string;
};

type Props = {
  reminder: ClientReminder;
};

export function PostponeButton({reminder}: Props) {
  const [postponeClientReminder] = useMutation<{
    postponeClientReminder: ClientReminder;
  }>(POSTPONE_CLIENT_REMINDER, {
    variables: {
      clientreminderId: reminder.id,
    },
  });

  return (
    <button
      onClick={() => {
        postponeClientReminder();
      }}
      type="button"
      className="px-6 py-1 m-2 border rounded text-coolGray-700 border-coolGray-500 print:hidden"
    >
      {isBefore(
        new Date(reminder.remindOn.replace(/-/g, "/")),
        subDays(new Date(), 1)
      )
        ? "Move to Today "
        : "Snooze One Day "}
      &rarr;
    </button>
  );
}
