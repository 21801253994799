import {gql} from "@apollo/client";

export const SAVE_CLIENT_REMINDER = gql`
  mutation EditClientReminder(
    $clientreminderId: String!
    $reminder: String!
    $remindOn: String!
  ) {
    clientReminderEdit(
      clientreminderData: {
        clientreminderId: $clientreminderId
        reminder: $reminder
        remindOn: $remindOn
      }
    ) {
      clientreminder {
        id
        created
        updated
        about {
          id
          name
          attn
        }
        whoToRemind {
          id
          firstName
          lastName
        }
        remindOn
        reminder
        completed
      }
    }
  }
`;

export const SAVE_REMINDER = gql`
  mutation EditReminder(
    $reminderId: String!
    $message: String!
    $remindOn: String!
  ) {
    reminderEdit(
      reminderData: {
        reminderId: $reminderId
        message: $message
        remindOn: $remindOn
      }
    ) {
      reminder {
        id
        client {
          id
          name
          attn
        }
        user {
          id
          firstName
          lastName
        }
        remindOn
        message
        completed
      }
    }
  }
`;

export const CREATE_TODO_LIST = gql`
  mutation createTodoList($id: String!, $name: String!) {
    createTodoList(id: $id, name: $name) {
      project {
        id
        name
        incompleteLists {
          id
          name
          unclearedTodos {
            id
            created
            description
            url
            completed
            cleared
            hideuntil
            priority
          }
        }
      }
    }
  }
`;

export const GET_TODOS = gql`
  query TodoListQuery {
    todoprojects {
      id
      name
      incompleteLists {
        id
        name
        unclearedTodos {
          id
          created
          description
          url
          completed
          cleared
          hideuntil
          priority
        }
      }
    }
  }
`;

export const UPDATE_TODO_PROJECT = gql`
  mutation updateTodoProject($id: String!, $name: String!) {
    updateTodoProject(id: $id, name: $name) {
      todoproject {
        id
        name
        incompleteLists {
          id
          name
          unclearedTodos {
            id
            created
            description
            url
            completed
            cleared
            hideuntil
            priority
          }
        }
      }
    }
  }
`;

export const GET_USER_SETTINGS = gql`
  query UserSettingsQuery {
    userSettings {
      id
      username
      firstName
      lastName
      email
      dateJoined
      isStaff
      isSuperuser
      groups {
        id
        name
      }
      profile {
        id
        agency {
          id
          name
          address
          address1
          address2
          phone
          fax
          awsDocumentsBucketName
          companySet {
            id
            name
          }
          agencySet {
            id
            name
          }
          clienttypeSet {
            id
            name
          }
          bankaccountSet {
            id
            name
            bankName
            routing
            account
          }
          profileSet {
            id
            user {
              id
              firstName
              lastName
              email
              dateJoined
              isActive
              isStaff
              isSuperuser
              groups {
                id
                name
              }
              lastLogin
            }
          }
        }
        authyId
        timezone {
          id
          name
        }
        language {
          id
          name
        }
        mobile
        passwordChanged
      }
    }
  }
`;

export const GET_LOCATIONPROFILE = gql`
  query LocationQuery($locationId: String!) {
    location(id: $locationId) {
      id
      created
      purchaseClosingDate
      purchaseClosingDateNotes
      purchasePrice
      purchasePriceNotes
      deededOwner
      deededOwnerNotes
      firstTimeHomebuyer
      hydrantsAvailable
      hydrantsAvailableNotes
      respondingFireDepartment
      respondingFireDepartmentNotes
      distanceToFireDepartment
      distanceToFireDepartmentNotes
      isoProtectionClass
      isoProtectionClassNotes
      acreage
      acreageNotes
      floodZone
      floodZoneNotes
      address {
        street
        city
        state
        zipcode
      }
    }
  }
`;
