import * as React from "react";

import {useQuery} from "@apollo/client";
import {gql} from "@apollo/client";

import {Header} from "../../Components/Header";
import {Sidebar} from "../../Components/Sidebar";

import {RouteComponentProps} from "react-router-dom";

import {TodoProject} from "../../Components/Todo/TodoProject";

const GET_PROJECT = gql`
  query TodoListQuery($projectId: String!) {
    todoproject(projectId: $projectId) {
      id
      name
      incompleteLists {
        id
        name
        unclearedTodos {
          id
          created
          description
          url
          completed
          cleared
          hideuntil
          priority
        }
      }
    }
  }
`;

type TParams = {id: string};

export function Project({match}: RouteComponentProps<TParams>) {
  const {loading, error, data} = useQuery(GET_PROJECT, {
    variables: {projectId: match.params.id},
  });
  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="todo" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle={"To Do: " + data.todoproject.name} />

          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="h-full p-3">
              {/* {data && (
                <TodoSidebar
                  selectedProjectId={data.todoproject.id}
                  setSelectedProjectId={setSelectedProjectId}
                  todoprojects={data.todoprojects}
                />
              )} */}

              <TodoProject todoproject={data.todoproject} />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
