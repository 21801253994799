import {gql, useMutation} from "@apollo/client";

export const GET_STANDARDIZED_ADDRESS = gql`
  mutation GetStandardizedAddress($clientaddressId: String!) {
    getStandardizedAddress(
      clientaddressData: {clientaddressId: $clientaddressId}
    ) {
      clientaddress {
        id
        active
        addressType
        description
        street
        city
        state
        zipcode
        validatedData
        distanceFromOffice
        locationSet {
          id
          created
          purchaseClosingDate
          purchaseClosingDateNotes
          purchasePrice
          purchasePriceNotes
          deededOwner
          deededOwnerNotes
          firstTimeHomebuyer
          hydrantsAvailable
          hydrantsAvailableNotes
          respondingFireDepartment
          distanceToFireDepartment
          isoProtectionClass
          isoProtectionClassNotes
        }
      }
    }
  }
`;

type Location = {
  id: string;
};

type ClientAddress = {
  id: string;
  validatedData: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  description: string;
  locationSet: Location[];
  distanceFromOffice: string;
  addressType: string;
};

type Props = {
  addressId: string;
};

export function GetStandardizedAddressButton({addressId}: Props) {
  const [getStandardizedAddress] = useMutation<{
    getStandardizedAddress: ClientAddress;
  }>(GET_STANDARDIZED_ADDRESS, {
    variables: {
      clientaddressId: addressId,
    },
  });

  return (
    <button
      onClick={() => {
        getStandardizedAddress();
      }}
    >
      Get Standardized Address
    </button>
  );
}
