import * as React from "react";

import {AddOutbuildingButton} from "./AddOutbuilding";
import {EditOutbuilding} from "./EditOutbuilding";

type Outbuilding = {
  id: string;
  buildingDescription: string;
  constructionYear: string;
  constructionType: string;
  value: number;
  setValue: (newValue: string) => void;
  initialValue: number | null;
  outbuildingNotes: string;
  heat: string;
  electric: string;
  hayStorage: string;
  usage: string;
  sha1: string;
};

type Props = {
  autoSave: boolean;
  outbuildings: Outbuilding[];
  locationId: string;
};

export default function Outbuildingss({
  locationId,
  outbuildings,
  autoSave,
}: Props) {
  return (
    <div className="flex p-8 m-8 border-t border-coolGray-400">
      <div className="w-1/3">
        <h3 className="text-lg">
          Outbuildings{" "}
          {Object.values(outbuildings).length === 0 ? " (None)" : null}
        </h3>

        <AddOutbuildingButton id={locationId} />
      </div>

      {Object.values(outbuildings).length > 0 && (
        <div className="w-2/3 p-8 bg-white rounded-lg shadow-lg">
          <div className="grid grid-cols-1 gap-4 divide-y divide-coolGray-300">
            {outbuildings.map((outbuilding) => (
              <EditOutbuilding
                key={outbuilding.id}
                locationId={locationId}
                outbuilding={outbuilding}
                autoSave={autoSave}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
