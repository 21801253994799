import * as React from "react";

import {format} from "date-fns";

import CopyToClipboard from "react-copy-to-clipboard";

import {ActivityList} from "./Activities/ActivityList";
import {CloseIcon} from "./Icons/CloseIcon";
import {ExpandIcon} from "./Icons/ExpandIcon";
import {HashtagIcon} from "./Icons/HashtagIcon";

type Attachment = {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
};

type Tag = {
  id: string;
  document: Attachment;
};

type ActivityNote = {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
};

type FlexiblePolicyActivity = {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNote[];
  documenttagSet: Tag[];
};

type DownloadMessage = {
  id: string;
  acknowledged: boolean;
  transactionTypeCode: string;
  originalFile: {
    timestamp: string;
  };
};

type PolicySection = {
  id: string;
  downloadmessageSet: DownloadMessage[];
};

type PolicyTerm = {
  id: string;
  effectiveDate: string;
  expirationDate: string;
  premium: string;
  policysectionSet: PolicySection[];
};

type PolicyType = {
  id: string;
  isActive: boolean;
  agency: {
    agency: {
      name: string;
    };
    name: string;
  };
  company: {
    id: string;
    name: string;
    carrier: {
      id: string;
      name: string;
    };
  };
  description: string;
  policyType: string;
  policyNumber: string;
  effectiveDate: string;
  expirationDate: string;
  premium: number;
  firstWritten: string;
  daysSinceLastPolicyReview: string;

  policytermSet: PolicyTerm[];
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
};

type Props = {policy: PolicyType};

export function Policy({policy}: Props) {
  const [showAll, setShowAll] = React.useState(false);

  function toggleShowAll() {
    setShowAll(showAll ? false : true);
  }

  const logourl =
    "https://pipeline-media.s3.amazonaws.com/logos/" +
    policy.company.carrier.id +
    ".webp";
  let spilloverContent = false;

  if (
    showAll ||
    !(!policy.description || 0 === policy.description.length) ||
    policy.agency.name !== policy.agency.agency.name
  ) {
    spilloverContent = true;
  }

  return (
    <>
      <tr className={showAll ? "shadow-lg" : ""}>
        <td className="px-6 py-4 leading-5 whitespace-no-wrap text-coolGray-500">
          <img
            src={logourl}
            // size="tiny"
            style={{
              display: `inline-block`,
              verticalAlign: `middle`,
              width: `100px`,
              height: `auto`,
            }}
            alt={policy.company.carrier.name + " Logo"}
          />
        </td>
        <td className="px-6 py-4 text-base leading-5 whitespace-no-wrap text-coolGray-500">
          {policy.policyType}
        </td>

        <td>
          <CopyToClipboard text={policy.policyNumber}>
            <span>
              <HashtagIcon
                className={"w-4 h-4 text-blue-400 inline-block mr-1 mb-1"}
              />
            </span>
          </CopyToClipboard>

          {policy.policyNumber}
        </td>

        <td className="px-6 py-4 text-base leading-5 whitespace-no-wrap text-coolGray-500">
          {format(new Date(policy.effectiveDate), "M/d/yy")}-
          {format(new Date(policy.expirationDate), "M/d/yy")}
        </td>
        <td className="px-6 py-4 text-base leading-5 whitespace-no-wrap text-coolGray-500">
          ${policy.premium}
        </td>
        <td className="px-6 py-4 text-sm leading-5 whitespace-no-wrap text-coolGray-500">
          <button style={{borderColor: `#bbb`}} onClick={toggleShowAll}>
            {showAll ? (
              <CloseIcon className={"w-6 h-6 text-coolGray-500"} />
            ) : (
              <ExpandIcon className={"w-6 h-6 text-coolGray-500"} />
            )}
          </button>
        </td>
      </tr>
      {spilloverContent === true ? (
        <tr
          style={{borderTopWidth: `0`}}
          className={showAll ? "bg-coolGray-50 " : ""}
        >
          <td colSpan={6} style={{borderTop: `none`}} className="px-6 py-3">
            {policy.description && (
              <>
                <span style={{color: `#999`}}>{policy.description}</span>
                <br />
              </>
            )}

            {policy.agency.name === policy.agency.agency.name ? null : (
              <>
                <label color="yellow">Agent: {policy.agency.name}</label>
                <br />
              </>
            )}

            {showAll === false ? null : (
              <>
                <br />
                <button>Edit</button> <button>Renew</button>{" "}
                <button>Add Term</button> <button>View Full Screen</button>{" "}
                <button>Filter Activities</button>
                <br />
                <br />
                <div className="px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-y-8 gap-x-4 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium leading-5 text-coolGray-500">
                        Underwriting Company
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-coolGray-900">
                        {policy.company.name}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium leading-5 text-coolGray-500">
                        Written on
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-coolGray-900">
                        {policy.firstWritten && policy.firstWritten}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium leading-5 text-coolGray-500">
                        Last Policy Review
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-coolGray-900">
                        {policy.daysSinceLastPolicyReview} days ago.
                      </dd>
                    </div>
                    {/* <div className="sm:col-span-1">
                      <dt className="text-sm font-medium leading-5 text-coolGray-500">
                        Salary expectation
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-coolGray-900">
                        $120,000
                      </dd>
                    </div> */}

                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium leading-5 text-coolGray-500">
                        Policy Terms
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-coolGray-900">
                        <ul className="border rounded-md border-coolGray-200">
                          {policy.policytermSet.map((term, index) => (
                            <li
                              key={term.id}
                              className={`${
                                index > 0 ? "border-t border-coolGray-200" : ""
                              } pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5`}
                            >
                              <div className="flex items-center flex-1 w-0">
                                <span>
                                  {term.effectiveDate} {term.expirationDate}{" "}
                                  {term.premium}
                                  <br />
                                  {term.policysectionSet.map((section) => (
                                    <span key={section.id}>
                                      {section.downloadmessageSet.map(
                                        (message) => (
                                          <span key={message.id}>
                                            {message.acknowledged ? (
                                              <span>Message:</span>
                                            ) : (
                                              <label color="green">
                                                {message.transactionTypeCode}{" "}
                                                Downloaded on{" "}
                                                {format(
                                                  new Date(
                                                    message.originalFile.timestamp
                                                  ),
                                                  "M/d/yy"
                                                )}
                                              </label>
                                            )}
                                            <br />
                                          </span>
                                        )
                                      )}
                                    </span>
                                  ))}
                                </span>
                              </div>
                              <div className={"flex-shrink-0 ml-4"}>
                                <span
                                  className={
                                    "font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
                                  }
                                >
                                  Download
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </dd>
                    </div>
                  </dl>
                </div>
                <br />
                <ActivityList
                  policy={policy}
                  flexiblepolicyactivitySet={policy.flexiblepolicyactivitySet}
                />
              </>
            )}
          </td>
        </tr>
      ) : null}
    </>
  );
}
