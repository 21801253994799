import * as React from "react";

import {useMutation} from "@apollo/client";

import {MyTextInputField} from "../../FormComponents/MyTextInputField";

import {UPDATE_TODO_PROJECT} from "../../GraphQL/Queries";

type TodoProject = {
  id: string;
  name: string;
};

type Props = {
  todoproject: TodoProject;
  setEditing: (newValue: boolean) => void;
};

export function TodoProjectEdit({todoproject, setEditing}: Props) {
  const [name, setName] = React.useState(todoproject.name);

  const [saveTodoProjectChanges] = useMutation<{
    saveTodoChanges: TodoProject;
  }>(UPDATE_TODO_PROJECT);

  return (
    <div style={{marginTop: `2em`}}>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          if (name !== todoproject.name) {
            saveTodoProjectChanges({
              variables: {
                id: todoproject.id,
                name: name,
              },
            });
          }
          setEditing(false);
        }}
      >
        <MyTextInputField
          label="Project Name"
          value={name}
          setValue={setName}
          characterLimit={64}
          autoFocus
          placeholder=""
        />
      </form>
    </div>
  );
}
