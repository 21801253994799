import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import Instance from "../../Context/Instance";
import {ProviderDelete} from "./ProviderDelete";

import IdleTimer from "react-idle-timer";

import {MyTextInputField} from "../../FormComponents/MyTextInputField";

const UPDATE_PROVIDER_MUTATION = loader(
  "../../Fragments/Medicare/ProviderUpdate.graphql"
);

type Provider = {
  id: string;
  name: string;
  typeOfDoctor: string;
  notes: string;
};

type Props = {
  autoSave: boolean;
  beneficiaryId: string;
  provider: Provider;
};

export function ProviderEdit({autoSave, beneficiaryId, provider}: Props) {
  const [name, setName] = React.useState(provider.name);

  const [typeOfDoctor, setTypeOfDoctor] = React.useState(provider.typeOfDoctor);

  const [notes, setNotes] = React.useState(provider.notes);

  const [
    updateProvider,
    // {error: mutationError},
    // {loading: mutationLoading, error: mutationError}
  ] = useMutation<{
    updateProvider: Provider;
  }>(UPDATE_PROVIDER_MUTATION, {
    variables: {
      id: provider.id,
      name: name,
      typeOfDoctor: typeOfDoctor,

      notes: notes,
    },
    // onCompleted: () => {
    //   send("SAVED");
    // },
    // onError: () => {
    //   // alert(
    //   //   `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
    //   // );
    //   send("GRAPHQL_ERROR");
    // },
  });

  return (
    <Instance.Provider value={provider.id}>
      <div className="flex items-start">
        <div className="flex-1 w-0 pt-1">
          <div className="grid grid-cols-3 gap-4 p-4 border border-gray-200 rounded-md">
            <MyTextInputField
              label="Type of Doctor"
              value={typeOfDoctor}
              setValue={setTypeOfDoctor}
              characterLimit={128}
              initialValue={provider.typeOfDoctor}
              autoFocusIfBlank
            />

            <MyTextInputField
              label="Name"
              value={name}
              setValue={setName}
              characterLimit={128}
              initialValue={provider.name}
            />

            <MyTextInputField
              label="Notes"
              value={notes}
              setValue={setNotes}
              initialValue={provider.notes}
              characterLimit={128}
            />

            {name === provider.name &&
            typeOfDoctor === provider.typeOfDoctor &&
            notes === provider.notes ? null : (
              <span>
                {autoSave ? (
                  <IdleTimer
                    timeout={1000}
                    startOnMount={true}
                    onIdle={() => updateProvider()}
                  />
                ) : (
                  <button type="button" onClick={() => updateProvider()}>
                    save
                  </button>
                )}
              </span>
            )}
          </div>
        </div>

        <div className="flex flex-shrink-0 ml-4">
          {name + typeOfDoctor + notes === "" ? (
            // DELETE IMMEDIATELY IF BLANK
            <ProviderDelete
              beneficiaryId={beneficiaryId}
              provider={provider}
              immaculate={true}
            />
          ) : (
            // OTHERWISE START DELETE WORKFLOW
            <ProviderDelete beneficiaryId={beneficiaryId} provider={provider} />
          )}
        </div>
      </div>
    </Instance.Provider>
  );
}
