import * as React from "react";

import {gql} from "@apollo/client";
import {useQuery} from "@apollo/client";

import {MainMenu} from "../Menus/MainMenu";

import {EditPerson} from "../FormComponents/EditPerson";

const GET_PERSON = gql`
  query PersonQuery($personId: String!) {
    person(id: $personId) {
      id
      firstName
      middleName
      lastName
      suffix
      preferredName
      gender
      dob
      social
      maritalStatus
      license
      licenseDate
      ddc
      education
      occupation
      employer
      motorClub
      email
      cellPhone
      workPhone
      notes
    }
  }
`;

type Props = {
  personId: string;
};

export function PersonPage({personId}: Props) {
  const {loading, error, data} = useQuery(GET_PERSON, {
    variables: {personId: personId},
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  return (
    <div>
      <MainMenu />
      <EditPerson person={data.person} />
    </div>
  );
}
