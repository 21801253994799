import * as React from "react";

import {TrashIcon} from "../Components/Icons/TrashIcon";

type Props = {
  action: () => void;
};

export function DeleteButton({action}: Props) {
  return (
    <div className="flex items-center flex-shrink-0">
      <button
        type="button"
        onClick={() => action()}
        className="flex p-2 -mr-1 align-middle transition duration-150 ease-in-out rounded-md text-coolGray-400 hover:bg-red-200 focus:outline-none focus:bg-red-300 hover:text-red-700"
        aria-label="Remove"
      >
        <TrashIcon className="w-6 h-6" />
      </button>
    </div>
  );
}
