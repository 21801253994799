import * as React from "react";

import {useParams} from "react-router-dom";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

import {FormList} from "../../Components/Forms/FormList";

const GET_BRAND = loader("../../Fragments/Forms/BrandQuery.graphql");

interface FormTag {
  id: string;
  name: string;
}

interface BrandType {
  name: string;
  logoUrl: string;
}

interface Form {
  id: string;
  number: string;
  name: string;
  edition: string;
  url: string;
  active: boolean;
  brand: BrandType;
  formtagSet: FormTag[];
}

interface FormsType {
  brand: {
    name: string;
    logoUrl: string;
    pdfformSet: Form[];
    extraFormUrl: string;
  };
}

type TParams = {brandSlug: string};

export function Brand() {
  let {brandSlug} = useParams<TParams>();

  const {loading, error, data} = useQuery<FormsType>(GET_BRAND, {
    variables: {brandSlug: brandSlug},
  });

  React.useEffect(() => {
    document.title = `Forms`;
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  return (
    <>
      {data && (
        <FormList
          brandName={data.brand.name}
          logoUrl={data.brand.logoUrl}
          forms={data.brand.pdfformSet}
          extraFormUrl={data.brand.extraFormUrl}
        />
      )}
    </>
  );
}
