import * as React from "react";

import {AddPriorCancellationButton} from "./AddPriorCancellation";
import {EditPriorCancellation} from "./EditPriorCancellation";

type PriorCancellation = {
  id: string;
  date: string;
  insuranceCompany: string;
  cancellationType: string;
  cancellationNotes: string;
};

type Props = {
  autoSave: boolean;
  priorcancellations: PriorCancellation[];
  locationId: string;
};

export default function Outbuildingss({
  locationId,
  priorcancellations,
  autoSave,
}: Props) {
  return (
    <div className="flex p-8 m-8 border-t border-coolGray-400">
      <div className="w-1/3">
        <h3 className="text-lg">
          Prior Cancellations{" "}
          {Object.values(priorcancellations).length === 0 ? " (None)" : null}
        </h3>

        <AddPriorCancellationButton id={locationId} />
      </div>

      {Object.values(priorcancellations).length > 0 && (
        <div className="w-2/3 p-8 bg-white rounded-lg shadow-lg">
          <div className="grid grid-cols-1 gap-4 divide-y divide-coolGray-300">
            {priorcancellations.map((priorcancellation) => (
              <EditPriorCancellation
                key={priorcancellation.id}
                locationId={locationId}
                priorcancellation={priorcancellation}
                autoSave={autoSave}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
