import * as React from "react";
import {CircleIcon} from "./Icons/CircleIcon";
import {CircleCheckedIcon} from "./Icons/CircleCheckedIcon";

type Props = {
  payment: {
    premium: number;
    id: string;
    policy: {
      client: {
        name: string;
      };
    };
  };
  deposited: boolean;
  togglePaymentSelection: (newValue: string) => void;
  totalSelected: number;
  toggleSelectionTypeSum: (newValue: number) => void;
};

export function PrelistItem({
  payment,
  deposited,
  togglePaymentSelection,
  toggleSelectionTypeSum,
  totalSelected,
}: Props) {
  const [selectedForDeposit, setSelectedForDeposit] = React.useState(false);

  const paymentFormatted = payment.premium.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  function toggleSelection() {
    if (!deposited) {
      setSelectedForDeposit(selectedForDeposit === false ? true : false);
      togglePaymentSelection(payment.id);
      toggleSelectionTypeSum(
        selectedForDeposit === false
          ? totalSelected + payment.premium * 100
          : totalSelected - payment.premium * 100
      );
    }
  }

  if (deposited) {
    if (selectedForDeposit) {
      return (
        <li className="flex items-center p-2 text-xl print:text-sm tabular-nums">
          {"$" + paymentFormatted + " from " + payment.policy.client.name}
        </li>
      );
    }
    return null;
  }

  return (
    <li
      onClick={toggleSelection}
      className="flex items-center p-3 text-xl hover:bg-coolGray-100"
    >
      {selectedForDeposit ? (
        <CircleCheckedIcon className="w-8 h-8 text-green-500 fill-current print:h-4 print:w-4" />
      ) : (
        <CircleIcon className="w-8 h-8 fill-current text-coolGray-600 print:h-4 print:w-4" />
      )}

      <span
        className={
          selectedForDeposit
            ? `text-green-500 ml-3 print:text-sm tabular-nums`
            : `text-coolGray-800 ml-3 print:text-sm tabular-nums`
        }
      >
        {"$" + paymentFormatted + " from " + payment.policy.client.name}
      </span>
    </li>
  );
}
