import * as React from "react";

import {gql} from "@apollo/client";
import {useMutation} from "@apollo/client";

// import {TodoItem} from "../Todo/TodoItem";

import {MyTextInputField} from "../../FormComponents/MyTextInputField";

const UPDATE_TODO_LIST = gql`
  mutation updateTodoList($id: String!, $name: String!) {
    updateTodoList(id: $id, name: $name) {
      todolist {
        id
        name
      }
    }
  }
`;

type TodoItemType = {
  id: string;
  description: string;
  completed: boolean;
};

type TodoList = {
  id: string;
  name: string;
  unclearedTodos: TodoItemType[];
};

type Props = {
  todolist: TodoList;
  setEditing: (newValue: boolean) => void;
};

export function TodoListEdit({todolist, setEditing}: Props) {
  const [name, setName] = React.useState(todolist.name);

  const [saveTodoListChanges] = useMutation<{
    saveTodoListChanges: TodoList;
  }>(UPDATE_TODO_LIST);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        if (name !== todolist.name) {
          saveTodoListChanges({
            variables: {
              id: todolist.id,
              name: name,
            },
          });
        }
        setEditing(false);
      }}
    >
      <MyTextInputField
        label="To Do List Name"
        value={name}
        setValue={setName}
        characterLimit={64}
        placeholder=""
        autoFocus
      />
    </form>
  );
}
