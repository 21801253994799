type TodoList = {
  id: string;
  name: string;
};

type Props = {
  project: {
    id: string;
    name: string;
    incompleteLists: TodoList[];
  };
  active: boolean;
  setSelectedProjectId: (newProjectId: string) => void;
};

export function TodoSidebarItem({
  project,
  active,
  setSelectedProjectId,
}: Props) {
  if (active) {
    return (
      <div className="flex items-center justify-between px-3 py-2 rounded-lg bg-coolGray-200">
        <span className="text-sm font-medium text-coolGray-900">
          {project.name}
        </span>
        <span className="text-xs font-semibold text-coolGray-700">
          {Object.keys(project.incompleteLists).length}
        </span>
      </div>
    );
  }

  return (
    <div
      className="flex items-center justify-between px-3 py-2 rounded-lg"
      onClick={() => setSelectedProjectId(project.id)}
    >
      <span className="text-sm font-medium text-coolGray-700">
        {project.name}
      </span>
      <span className="text-xs font-semibold text-coolGray-700">
        {Object.keys(project.incompleteLists).length}
      </span>
    </div>
  );
}
