import * as React from "react";

import {useQuery} from "@apollo/client";

import {Header} from "../../Components/Header";
import {Sidebar} from "../../Components/Sidebar";
import {TodoSidebar} from "../../Components/Todo/TodoSidebar";

import {TodoProject} from "../../Components/Todo/TodoProject";

import {GET_TODOS} from "../../GraphQL/Queries";

type TodoItem = {
  id: string;
  description: string;
  completed: boolean;
};

type TodoList = {
  id: string;
  name: string;
  unclearedTodos: TodoItem[];
};

type TodoProjectType = {
  id: string;
  name: string;
  incompleteLists: TodoList[];
};

type TodosType = {
  todoprojects: TodoProjectType[];
};

export function Todos() {
  const [selectedProjectId, setSelectedProjectId] = React.useState<string>("");

  const [sidebarShowAnyway, setSidebarShowAnyway] = React.useState(true);

  React.useEffect(() => {
    document.title = `To Do`;
  });

  const {loading, error, data} = useQuery<TodosType>(GET_TODOS);

  if (loading) return <p>Loading ...</p>;

  if (error) return <p>Error!</p>;

  if (data) {
    return (
      <div>
        <div className="flex h-screen" id="app">
          <Sidebar activeItem="todo" />

          <div className="flex flex-col flex-1 min-w-0 bg-white">
            {selectedProjectId === "" ? (
              <Header pageTitle="To Do" />
            ) : (
              <>
                {data &&
                  data.todoprojects
                    .filter(
                      (todoproject) => todoproject.id === selectedProjectId
                    )
                    .map((todoproject) => (
                      <Header
                        key={todoproject.id}
                        pageTitle={"To Do: " + todoproject.name}
                      />
                    ))}
              </>
            )}
            {/* <Header pageTitle="Cancellations for Non-payment" /> */}
            <div className="flex-1 overflow-auto bg-coolGray-200">
              <main className="h-full p-3">
                <div>
                  <div className="flex h-screen" id="app">
                    {/* <Sidebar activeItem="todo" /> */}

                    <TodoSidebar
                      selectedProjectId={selectedProjectId}
                      setSelectedProjectId={setSelectedProjectId}
                      todoprojects={data.todoprojects}
                      sidebarShowAnyway={sidebarShowAnyway}
                      setSidebarShowAnyway={setSidebarShowAnyway}
                    />

                    {/* {sidebarShowAnyway === false && ( */}
                    <>
                      {/* // <div className="flex-shrink-0 px-4 py-3 bg-coolGray-700 lg:w-64 lg:bg-coolGray-800"> */}
                      <div>
                        <button
                          className="block p-3 text-coolGray-400 hover:text-coolGray-200 sm:hidden"
                          onClick={() => setSidebarShowAnyway(true)}
                        >
                          <svg
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M3 6a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zM4 17a1 1 0 100 2h7a1 1 0 100-2H4z"></path>
                          </svg>
                        </button>
                      </div>{" "}
                      {/* <button className="hidden sm:flex sm:items-center sm:w-full">
              <img
                src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=144&amp;h=144&amp;q=80"
                alt=""
                className="object-cover w-8 h-8 rounded-full"
              />{" "}
              <span className="hidden ml-4 mr-2 text-sm font-medium text-white lg:inline">
                Monica White
              </span>{" "}
              <svg
                viewBox="0 0 24 24"
                className="w-6 h-6 ml-2 fill-current text-coolGray-400 lg:ml-auto"
              >
                <path d="M7.293 9.293a1 1 0 011.414 0L12 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
              </svg>
            </button> */}
                      {/* // </div> */}
                    </>
                    {/* )} */}

                    <div className="flex flex-col flex-1 min-w-0 bg-white">
                      <div className="flex">
                        <div className="flex flex-col flex-1 min-w-0">
                          <div className="flex-1 overflow-auto bg-coolGray-200">
                            <main className="h-full p-6 print:ml-6">
                              {data &&
                                data.todoprojects
                                  .filter(
                                    (todoproject) =>
                                      todoproject.id === selectedProjectId
                                  )
                                  .map((todoproject) => (
                                    <div
                                      key={todoproject.id}

                                      // to={"/todo/" + todoproject.id}
                                    >
                                      {/* {Object.keys(todoproject.incompleteLists).length}{" "}
                              List
                              {Object.keys(todoproject.incompleteLists)
                                .length === 1
                                ? null
                                : "s"} */}
                                      <TodoProject todoproject={todoproject} />
                                    </div>
                                  ))}
                            </main>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <span>There was an error</span>;
}
