import * as React from "react";

import {Link} from "react-router-dom";

import {gql, useQuery} from "@apollo/client";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

import {DownloadList} from "../Components/DownloadList";

const GET_ALL_OPEN_DOWNLOADS = gql`
  query DownloadsListQuery {
    allOpenDownloads {
      id
      # policyType
      transactionTypeCode
      originalFile {
        timestamp
      }
      policysection {
        id
        policyTerm {
          id
          policy {
            id
            company {
              id
              carrier {
                id
                name
              }
            }
            policyNumber
            policyTypeShort
            client {
              id
              name
              attn
              csr {
                id
                firstName
                lastName
              }
              producer {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

type Policy = {
  client: {
    id: string;
    name: string;
    producer: {
      firstName: string;
      lastName: string;
    };
    csr: {
      firstName: string;
      lastName: string;
    };
  };
  company: {
    carrier: {
      name: string;
    };
  };
  policyNumber: string;
  policyTypeShort: string;
};

type DownloadMessage = {
  id: string;
  policysection: {
    policyTerm: {
      policy: Policy;
    };
  };
  // policyType: string;
  transactionTypeCode: string;
  originalFile: {
    timestamp: string;
  };
};

type Downloads = {
  allOpenDownloads: DownloadMessage[];
};

export function DownloadReport() {
  const {loading, error, data} = useQuery<Downloads>(GET_ALL_OPEN_DOWNLOADS);

  React.useEffect(() => {
    document.title = `All Downloads`;
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="downloads" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle="All Downloads" />

          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="h-full p-3">
              <p>
                There are {data && data.allOpenDownloads.length} downloads.{" "}
                <em>Looking for just yours?</em> Go to{" "}
                <Link to={"/downloads"} className="text-blue-600 underline">
                  My Downloads
                </Link>
              </p>
              {data && <DownloadList downloads={data.allOpenDownloads} />}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
