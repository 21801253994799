import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

const ADD_PRIORCANCELLATION = loader(
  "../../../Fragments/Underwriting/PriorCancellationAdd.graphql"
);
const PRIORCANCELLATION_SET = loader(
  "../../../Fragments/Underwriting/PriorCancellationSet.graphql"
);

type PriorCancellation = {
  id: string;
  date: string;
  insuranceCompany: string;
  cancellationType: string;
  cancellationNotes: string;
};

type PriorCancellationSet = {
  priorcancellationSet: PriorCancellation[];
};

type Props = {
  id: string;
};

export function AddPriorCancellationButton({id}: Props) {
  const [priorCancellationAdd] = useMutation<{
    priorCancellationAdd: PriorCancellation;
  }>(ADD_PRIORCANCELLATION, {
    variables: {
      id: id,
    },
    update(cache, {data}) {
      if (!data || !data.priorCancellationAdd) return;

      let outbuildings = cache.readFragment<PriorCancellationSet>({
        id: "LocationObjectType:" + id,
        fragment: PRIORCANCELLATION_SET,
        fragmentName: "PriorCancellationSet",
      });

      let newoutbuildings = {};
      if (outbuildings) {
        newoutbuildings = outbuildings.priorcancellationSet.concat(
          data.priorCancellationAdd
        );
      } else {
        newoutbuildings = [data.priorCancellationAdd];
      }

      cache.writeFragment({
        id: "LocationObjectType:" + id,
        fragment: PRIORCANCELLATION_SET,
        fragmentName: "PriorCancellationSet",
        data: {
          priorcancellationSet: newoutbuildings,
          __typename: "LocationObjectType",
        },
      });
    },
  });

  return (
    <button
      onClick={() => priorCancellationAdd()}
      className="px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700"
    >
      +
    </button>
  );
}
