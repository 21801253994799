import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

const GET_COMPANY_CHOICES = loader(
  "../Fragments/Policies/CompanyChoices.graphql"
);

type CompanyChoice = {
  id: string;
  name: string;
};

type CompanyChoices = {
  companyChoices: CompanyChoice[];
};

type Props = {
  value: string;
  setValue: (newValue: string) => void;
};

export const CompanyChooser = ({value, setValue}: Props) => {
  const {loading, error, data} = useQuery<CompanyChoices>(GET_COMPANY_CHOICES);

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  return (
    <div className="w-full p-3 mb-6 md:mb-0">
      <div>
        <label
          className="block float-left mb-2 text-sm font-bold tracking-wide uppercase text-coolGray-700"
          htmlFor="company"
        >
          <h4>Company</h4>
        </label>
      </div>
      <div className="relative">
        <select
          id="company"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          className="block w-full px-4 py-3 pr-8 leading-tight border rounded appearance-none text-coolGray-700 bg-coolGray-200 border-coolGray-200 focus:outline-none focus:bg-white focus:border-coolGray-500"
        >
          {value === "" && <option key="blank" value={""} />}
          {data?.companyChoices.map((agency) => (
            <option key={agency.id} value={agency.id}>
              {agency.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
