import * as React from "react";

import AutoSave from "../Context/AutoSave";

export default function DirtyIndicator() {
  const autoSave = React.useContext(AutoSave);

  return (
    <span className="block mb-1 text-sm font-bold tracking-wide text-indigo-300 uppercase print:hidden">
      {autoSave ? "saving" : "unsaved"}
    </span>
  );
}
