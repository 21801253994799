import {gql, useMutation} from "@apollo/client";

const GET_CLIENT_TABS = gql`
  {
    allClientTabs {
      id
      client {
        id
        name
        attn
      }
    }
  }
`;

const DELETE_CLIENT_TAB = gql`
  mutation deleteClientTab($clientTabId: String!) {
    closeClientTab(clientTabData: {clientTabId: $clientTabId}) {
      allClientTabs {
        id
        client {
          id
          name
          attn
        }
      }
    }
  }
`;

type ClientTab = {
  id: string;
  client: {
    id: string;
    name: string;
    attn: string;
  };
};

type WorkItems = {
  allClientTabs: ClientTab[];
};

type Props = {
  id: string;
};

export function DeleteClientTab({id}: Props) {
  const [deleteClientTab] = useMutation<{
    deleteClientTab: WorkItems;
  }>(DELETE_CLIENT_TAB, {
    variables: {
      clientTabId: id,
    },
    refetchQueries: [{query: GET_CLIENT_TABS}],
  });

  return (
    <button
      className="float-right text-coolGray-700"
      type="button"
      onClick={() => {
        deleteClientTab();
      }}
    >
      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
        <path
          d="M6 18L18 6M6 6L18 18"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke="currentColor"
        />
      </svg>
    </button>
  );
}
