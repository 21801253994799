import * as React from "react";

import {Link} from "react-router-dom";

import {MedicareProfileAdd} from "../Components/MedicareProfileAdd";
import {EditPerson} from "../FormComponents/EditPerson";

type BeneficiaryProfile = {
  id: string;
};

type PersonType = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  preferredName: string;
  gender: string;
  dob: string;
  age: string;
  social: string;
  maritalStatus: string;
  license: string;
  licenseDate: string;
  ddc: string;
  education: string;
  occupation: string;
  employer: string;
  email: string;
  cellPhone: string;
  workPhone: string;
  notes: string;
  beneficiaryprofileSet: BeneficiaryProfile[];
};

type Props = {
  person: PersonType;
  showAll: boolean;
};

export function Person({person, showAll}: Props) {
  const [editing, setEditing] = React.useState(false);

  if (editing) {
    return <EditPerson person={person} setEditing={setEditing} />;
  }

  if (showAll === false && parseInt(person.age) < 16) {
    return null;
  }

  return (
    <li key={person.id}>
      <div className="float-right">
        <button type="button" onClick={() => setEditing(true)}>
          ...
        </button>
      </div>
      {/* This showed a different icon based on gender */}
      {/* {
        {
          A_0: <List.Icon circular color="yellow" name="question circle" />,
          A_1: <List.Icon circular color="pink" name="circle" />,
          A_2: <List.Icon circular inverted color="blue" name="star" />,
        }[person.gender || "A_0"]
      } */}{" "}
      <div>
        {person.firstName} {person.lastName}
        {person.age !== "0" && " Age: " + person.age + " "}
        <a
          href={"mailto:" + person.email}
          className="text-blue-500 underline hover:text-blue-700"
        >
          {person.email}
        </a>{" "}
        {person.cellPhone}
      </div>
      <div>
        Go to <Link to={"/person/" + person.id}>Person Page</Link>:
      </div>
      {person.beneficiaryprofileSet.length === 0 ? (
        <MedicareProfileAdd person={person} />
      ) : null}
      {person.beneficiaryprofileSet.map((profile) => (
        <div key={profile.id}>
          <Link to={"/medicare/" + profile.id} target="_blank">
            <button type="button">Health & Medicare Profile</button>
          </Link>
        </div>
      ))}
    </li>
  );
}
