import * as React from "react";

import {gql, useQuery} from "@apollo/client";

// import {Link} from "react-router-dom";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

import {OpenActivity} from "../Components/OpenActivity";
import {backendUrlHostname} from "../urls";

const MY_OPEN_ACTIVITIES = gql`
  query OpenActivityListQuery {
    myOpenActivities {
      id
      actionDate
      activityType
      premium
      quoteClosed
      daysSinceSave
      policy {
        id
        company {
          id
          name
          carrier {
            id
          }
        }
        policyNumber
        client {
          id
          name
          attn
          csr {
            id
            firstName
            lastName
          }
        }
        unacknowledgedDownloads {
          id
        }
      }
      transmission {
        id
        transmissionMethod
        transmissionTimestamp
        transmittedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

type Download = {
  id: string;
};

type Activity = {
  id: string;
  transmission: {};
  actionDate: string;
  activityType: string;
  policy: {
    client: {
      id: string;
      name: string;
    };
    unacknowledgedDownloads: Download[];
    company: {
      carrier: {
        id: string;
        name: string;
      };
    };
  };
  daysSinceSave: number;
};

type ActivitiesType = {
  myOpenActivities: Activity[];
};

export function Activities() {
  const {loading, error, data} = useQuery<ActivitiesType>(MY_OPEN_ACTIVITIES);

  React.useEffect(() => {
    document.title = `Activities`;
  });

  if (loading) return <p>Loading ...</p>;
  if (error)
    return (
      <p>
        Error! <br />
        <br />
        {error.graphQLErrors.map(({message}, i) => (
          <span key={i}>{message}</span>
        ))}
      </p>
    );

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="activities" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle="Activities" />

          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="relative z-0 flex-1 h-full pb-6 overflow-y-auto">
              <div className="px-4 mx-auto mt-3 sm:px-6 lg:px-8">
                View {/* <Link to="/reports/open-activities"> */}
                <a
                  href={backendUrlHostname + "/activity/open"}
                  className="text-blue-600 underline"
                >
                  All Open Activities
                </a>{" "}
                {/* </Link>{" "} */}
                for the Agency
              </div>

              <div>
                <div>
                  <h3 className="px-4 mx-auto mt-8 mb-4 text-2xl font-medium leading-6 text-coolGray-900 sm:px-6 lg:px-8">
                    Not SENT to Insurer (
                    {data &&
                      data.myOpenActivities.filter(
                        (activity) => activity.transmission === null
                      ).length}
                    )
                  </h3>
                  <div className="px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="overflow-hidden bg-white shadow sm:rounded-md ">
                      <ul>
                        {data &&
                          data.myOpenActivities
                            .filter(
                              (activity) => activity.transmission === null
                            )
                            .map((activity) => (
                              <OpenActivity
                                activity={activity}
                                key={activity.id}
                              />
                            ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <h4 className="px-4 mx-auto mt-8 mb-4 text-2xl font-medium leading-6 text-coolGray-900 sm:px-6 lg:px-8">
                      Awaiting Completion (
                      {data &&
                        data.myOpenActivities.filter(
                          (activity) => activity.transmission !== null
                        ).length}
                      )
                    </h4>
                  </div>

                  <div className="px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="mb-3 overflow-hidden bg-white shadow sm:rounded-md">
                      <ul>
                        {data &&
                          data.myOpenActivities
                            .filter(
                              (activity) => activity.transmission !== null
                            )
                            .map((activity) => (
                              <OpenActivity
                                activity={activity}
                                key={activity.id}
                              />
                            ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
