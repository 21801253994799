import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {DeleteButton} from "../../Components/DeleteButton";
import {ConfirmDeleteObject} from "../../FormComponents/ConfirmDeleteObject";

const DELETE_PRESCRIPTION_MUTATION = loader(
  "../../Fragments/Medicare/PrescriptionDelete.graphql"
);

const PRESCRIPTION_SET_FRAGMENT = loader(
  "../../Fragments/Medicare/PrescriptionSet.graphql"
);

interface DeletedPrescription {
  deletePrescription: {
    id: string;
  };
}

type Prescription = {
  id: string;
  drugName: string;
  refillFrequency: string;
  otherFrequency: string;
  dosage: string;
  quantityPerFill: string;
  notes: string;
};

type PrescriptionSet = {
  prescriptionSet: Prescription[];
};

type Props = {
  beneficiaryId: string;
  prescription: Prescription;
  immaculate?: boolean;
};

export function PrescriptionDelete({
  beneficiaryId,
  prescription,
  immaculate = false,
}: Props) {
  const [startConfirm, setStartConfirm] = React.useState(false);

  const [deletePrescription] = useMutation<DeletedPrescription>(
    DELETE_PRESCRIPTION_MUTATION,
    {
      variables: {id: prescription.id},
      update(cache, {data}) {
        if (!data || !data.deletePrescription || !data.deletePrescription.id)
          return;

        let prescriptions = cache.readFragment<PrescriptionSet>({
          id: "BeneficiaryProfileObjectType:" + beneficiaryId,
          fragment: PRESCRIPTION_SET_FRAGMENT,
          fragmentName: "PrescriptionSet",
        });

        let newprescriptions = {};
        if (prescriptions) {
          newprescriptions = prescriptions.prescriptionSet.filter(
            (e) => e.id !== data.deletePrescription.id
          );

          cache.writeFragment({
            id: "BeneficiaryProfileObjectType:" + beneficiaryId,
            fragment: PRESCRIPTION_SET_FRAGMENT,
            fragmentName: "PrescriptionSet",
            data: {
              prescriptionSet: newprescriptions,
              __typename: "BeneficiaryProfileObjectType",
            },
          });
          // send("DELETED");
        }
      },
    }
  );

  if (immaculate) {
    return <DeleteButton action={() => deletePrescription()} />;
  }

  if (startConfirm) {
    return (
      <ConfirmDeleteObject
        objectType="Prescription"
        objectDescription={
          <span>
            Drug Name: <strong>{prescription.drugName}</strong>
          </span>
        }
        abortDeletion={() => setStartConfirm(false)}
        performDeletion={() => deletePrescription()}
      />
    );
  }

  return <DeleteButton action={() => setStartConfirm(true)} />;
}
