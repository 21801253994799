import {MySelect} from "../../FormComponents/MySelect";

const ACTIVITY_TYPE_CHOICES = [
  {key: "blank", text: "", value: ""},
  {key: "application", text: "Application", value: "application"},
  {key: "audit", text: "Audit", value: "audit"},
  {key: "billing_inquiry", text: "Billing Inquiry", value: "billing_inquiry"},
  {key: "billing_notice", text: "Billing Notice", value: "billing_notice"},
  {key: "claim", text: "Claim", value: "claim"},
  {
    key: "collection_notice",
    text: "Collection Notice",
    value: "collection_notice",
  },
  {key: "communication", text: "Communication", value: "communication"},
  {
    key: "conditional_renewal",
    text: "Conditional Renewal",
    value: "conditional_renewal",
  },
  {
    key: "coverage_inquiry",
    text: "Coverage Inquiry",
    value: "coverage_inquiry",
  },
  {key: "crop", text: "Crop", value: "crop"},
  {key: "correspondence", text: "Correspondence", value: "correspondence"},
  {key: "dmv_notice", text: "DMV Notice", value: "dmv_notice"},
  {key: "endorsement", text: "Endorsement", value: "endorsement"},
  {
    key: "endorsement_quote",
    text: "Endorsement Quote",
    value: "endorsement_quote",
  },
  {
    key: "generic_policy_activity",
    text: "Generic Policy Activity",
    value: "generic_policy_activity",
  },
  {key: "legacy_activity", text: "Legacy Activity", value: "legacy_activity"},
  {
    key: "legacy_attachment",
    text: "Legacy Attachment",
    value: "legacy_attachment",
  },
  {
    key: "medicare_appointment",
    text: "Medicare Appointment",
    value: "medicare_appointment",
  },
  {key: "nonrenewal", text: "Nonrenewal", value: "nonrenewal"},
  {key: "notice_of_loss", text: "Notice of Loss", value: "notice_of_loss"},
  {key: "payment", text: "Payment", value: "payment"},
  {
    key: "pending_cancellation",
    text: "Pending Cancellation",
    value: "pending_cancellation",
  },
  {
    key: "photo_inspection_completed",
    text: "Photo Inspection Completed",
    value: "photo_inspection_completed",
  },
  {
    key: "photo_inspection_needed",
    text: "Photo Inspection Needed",
    value: "photo_inspection_needed",
  },
  {
    key: "physical_files_scanned_in",
    text: "Physical Files Scanned In",
    value: "physical_files_scanned_in",
  },
  {
    key: "proof_of_insurance",
    text: "Proof of Insurance",
    value: "proof_of_insurance",
  },
  {key: "policy_review", text: "Policy Review", value: "policy_review"},
  {key: "quote", text: "Quote", value: "quote"},
  {key: "questionnaire", text: "Questionnaire", value: "questionnaire"},
  {key: "remarket", text: "Remarket", value: "remarket"},
  {key: "returned_mail", text: "Returned Mail", value: "returned_mail"},
  {key: "risk_map", text: "Risk Map", value: "risk_map"},
  {key: "renewal", text: "Renewal", value: "renewal"},
  {
    key: "schedule_of_drivers",
    text: "Schedule of Drivers",
    value: "schedule_of_drivers",
  },
  {
    key: "schedule_of_equipment",
    text: "Schedule of Equipment",
    value: "schedule_of_equipment",
  },
  {
    key: "schedule_of_locations",
    text: "Schedule of Locations",
    value: "schedule_of_locations",
  },
  {
    key: "schedule_of_personal_property",
    text: "Schedule of Personal Property",
    value: "schedule_of_personal_property",
  },
  {key: "startup_file", text: "Startup File", value: "startup_file"},
  {key: "termination", text: "Termnination", value: "termination"},
  {
    key: "underwriting_inquiry",
    text: "Underwriting Inquiry",
    value: "underwriting_inquiry",
  },
];

type Props = {
  value: string;
  setValue: (newValue: string) => void;
  initialValue: string;
};

export const ActivityTypeChooser = ({value, setValue, initialValue}: Props) => {
  return (
    <MySelect
      label="Activity Type"
      options={ACTIVITY_TYPE_CHOICES}
      value={value}
      setValue={setValue}
      initialValue={initialValue}
    />
  );
};
