import * as React from "react";

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "../UtilityComponents/Tabs";

import {PolicyListPane} from "./PolicyListPane";

import {ReferralsPane} from "./ReferralsPane";
import {ClientRemindersPane} from "./ClientRemindersPane";
import {QuotesPane} from "./QuotesPane";

type Attachment = {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
};

type Tag = {
  id: string;
  document: Attachment;
};

type ActivityNote = {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
};

type FlexiblePolicyActivity = {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNote[];
  documenttagSet: Tag[];
};

type Reminder = {
  id: string;
  reminder: string;
  remindOn: string;
  about: {
    name: string;
    attn: string;
  };
  completed: boolean;
};

type DownloadMessage = {
  id: string;
  acknowledged: boolean;
  transactionTypeCode: string;
  originalFile: {
    timestamp: string;
  };
};

type PolicySection = {
  id: string;
  downloadmessageSet: DownloadMessage[];
};

type PolicyTerm = {
  id: string;
  effectiveDate: string;
  expirationDate: string;
  premium: string;
  policysectionSet: PolicySection[];
};

type Policy = {
  id: string;
  isActive: boolean;
  agency: {
    agency: {
      name: string;
    };
    name: string;
  };
  company: {
    id: string;
    name: string;
    carrier: {
      id: string;
      name: string;
    };
  };
  description: string;
  policyType: string;
  policyNumber: string;
  effectiveDate: string;
  expirationDate: string;
  premium: number;
  firstWritten: string;
  daysSinceLastPolicyReview: string;

  policytermSet: PolicyTerm[];
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
};

type Client = {
  id: string;
  phonenumberSet: {
    id: string;
    number: string;
    label: string;
  };
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
  clientreminderSet: Reminder[];
  policySet: Policy[];
};

type Props = {
  client: Client;
};
export function ClientContentPane({client}: Props) {
  return (
    <Tabs>
      <TabList>
        <Tab>Quotes</Tab>
        <Tab>Policies</Tab>
        <Tab>Referrals</Tab>
        <Tab>Reminders</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <QuotesPane client={client} />
        </TabPanel>
        <TabPanel>
          <PolicyListPane client={client} />
        </TabPanel>
        <TabPanel>
          <ReferralsPane client={client} />
        </TabPanel>
        <TabPanel>
          <ClientRemindersPane
            reminders={client.clientreminderSet}
            clientId={client.id}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
