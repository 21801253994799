import * as React from "react";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

import {Sidebar} from "../Components/Sidebar";

import {ReminderStream} from "../Components/reminder_stream";

const REMINDER_QUERY = loader("../Fragments/reminders/reminder_query.graphql");

type Reminder = {
  id: string;
  message: string;
  remindOn: string;
  client: {
    id: string;
    name: string;
    attn: string;
  };
  completed: boolean;
};

type RemindersType = {
  allReminders: Reminder[];
};

export function Reminders() {
  const [showAll, setShowAll] = React.useState(false);

  const {loading, error, data} = useQuery<RemindersType>(REMINDER_QUERY, {
    variables: {
      showAll: showAll,
    },
  });

  React.useEffect(() => {
    document.title = `Reminders`;
  });

  if (loading) return <p>Loading ...</p>;

  if (error) return <p>Error!</p>;

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="reminders" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="h-full p-3">
              {data?.allReminders && (
                <h1>Reminders ({data?.allReminders?.length})</h1>
              )}
              <div>
                {data?.allReminders?.map((reminder) => (
                  // <span>{reminder.message}</span>
                  <ReminderStream key={reminder.id} reminder={reminder} />
                ))}
                {showAll ? null : (
                  <button
                    className="print:hidden"
                    onClick={() => setShowAll(true)}
                  >
                    Show All Future reminders
                  </button>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
