import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {useMachine} from "@xstate/react";
import {Machine} from "xstate";

import IdleTimer from "react-idle-timer";

import {MyTextInputField} from "../../../FormComponents/MyTextInputField";

const DELETE_OUTBUILDING = loader(
  "../../../Fragments/Underwriting/OutbuildingDelete.graphql"
);

const OUTBUILDING_SET_FRAGMENT = loader(
  "../../../Fragments/Underwriting/OutbuildingSet.graphql"
);

const outbuildingDeleteMachine = Machine({
  id: "outbuildingDelete",
  initial: "idle",
  states: {
    idle: {
      on: {
        DELETE: "deleting",
      },
    },
    deleting: {
      on: {
        DELETED: "deleted",
        GRAPHQL_ERROR: "idle",
      },
    },
    graphql_error: {
      on: {DELETE: "deleting", CANCEL: "idle"},
    },
    deleted: {
      type: "final",
    },
  },
});

interface DeletedOutbuilding {
  outbuildingDelete: {
    id: string;
  };
}

type Outbuilding = {
  id: string;
  buildingDescription: string;
  constructionYear: string;
  constructionType: string;
  value: number | null;
  outbuildingNotes: string;
  heat: string;
  electric: string;
  hayStorage: string;
  usage: string;
  sha1: string;
};

type OutbuildingSet = {
  outbuildingSet: Outbuilding[];
};

type Props = {
  locationId: string;
  outbuilding: Outbuilding;
  abortDeletion: () => void;
};

export function DeleteOutbuildingForm({
  locationId,
  outbuilding,
  abortDeletion,
}: Props) {
  const [state, send] = useMachine(outbuildingDeleteMachine);
  const [confirmText, setConfirmText] = React.useState("");

  const [outbuildingDelete] = useMutation<DeletedOutbuilding>(
    DELETE_OUTBUILDING,
    {
      variables: {id: outbuilding.id},
      update(cache, {data}) {
        if (!data || !data.outbuildingDelete || !data.outbuildingDelete.id)
          return;

        let outbuildings = cache.readFragment<OutbuildingSet>({
          id: "LocationObjectType:" + locationId,
          fragment: OUTBUILDING_SET_FRAGMENT,
          fragmentName: "OutbuildingSet",
        });

        let newoutbuildings = {};
        if (outbuildings) {
          newoutbuildings = outbuildings.outbuildingSet.filter(
            (e) => e.id !== data.outbuildingDelete.id
          );

          cache.writeFragment({
            id: "LocationObjectType:" + locationId,
            fragment: OUTBUILDING_SET_FRAGMENT,
            fragmentName: "OutbuildingSet",
            data: {
              outbuildingSet: newoutbuildings,
              __typename: "LocationObjectType",
            },
          });
          send("DELETED");
        }
      },
    }
  );

  function handleSubmit() {
    if (confirmText === "DELETE") {
      send("DELETE");
      outbuildingDelete();
    }
  }

  if (
    state.value === "idle" &&
    outbuilding.buildingDescription === "" &&
    outbuilding.constructionYear === "" &&
    outbuilding.constructionType === "" &&
    outbuilding.value === 0 &&
    outbuilding.outbuildingNotes === "" &&
    outbuilding.heat === "U" &&
    outbuilding.electric === "U" &&
    outbuilding.hayStorage === "U" &&
    outbuilding.usage === ""
  ) {
    if (confirmText !== "DELETE") {
      setConfirmText("DELETE");
    }
    return (
      <span>
        <IdleTimer
          timeout={1000}
          startOnMount={true}
          onIdle={() => handleSubmit()}
        />
      </span>
    );
  }

  return (
    <div>
      <h2 className="text-3xl">Permanently delete this outbuilding?</h2>
      <p className="mt-4">This action cannot be undone.</p>
      <p className="mt-4">
        Description: {outbuilding.buildingDescription} (Value: $
        {outbuilding.value})<br />
        {(() => {
          switch (outbuilding.heat) {
            case "U":
              return <>Unknown</>;
            case "Y":
              return <>Yes</>;
            case "N":
              return <>No</>;
            default:
              return <>Unknown</>;
          }
        })()}
      </p>
      <div className="mt-6">
        <form onSubmit={() => handleSubmit()}>
          <div className="mb-6">
            <MyTextInputField
              label="Type DELETE to continue"
              value={confirmText}
              setValue={setConfirmText}
              characterLimit={6}
              autoFocus
            />
          </div>

          <button
            type="button"
            onClick={() => abortDeletion()}
            className="float-left px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Keep Outbuilding
          </button>
          <button
            type="button"
            onClick={() => handleSubmit()}
            className={
              confirmText === "DELETE"
                ? "float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                : "float-right bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
            }
          >
            Delete Forever
          </button>
        </form>
      </div>
    </div>
  );
}
