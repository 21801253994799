import * as React from "react";
import {backendUrlHostname} from "../urls";

import {SpreadsheetIcon} from "./Icons/SpreadsheetIcon";

type Props = {
  name: string;
  description: string;
  csv?: boolean;
  url: string;
};

export function Report({name, description, csv, url}: Props) {
  return (
    <div>
      <div>
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
      <div>
        {csv ? (
          <button
            type="button"
            onClick={() => window.open(backendUrlHostname + url, "_blank")}
          >
            <SpreadsheetIcon />
            Download File
          </button>
        ) : (
          <button
            type="button"
            onClick={() => window.open(backendUrlHostname + url)}
          >
            Open Report
          </button>
        )}
      </div>
    </div>
  );
}
