import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {ConfirmDeleteObject} from "../../FormComponents/ConfirmDeleteObject";

const DELETE_ACTIVITYNOTE_MUTATION = loader(
  "../../Fragments/Activities/ActivityNoteDelete.graphql"
);

interface Attachment {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
}

interface Tag {
  id: string;
  document: Attachment;
}

interface ActivityNote {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
}

interface Props {
  activitynote: ActivityNote;
  setDeleting: (deleting: boolean) => void;
}

export function DeleteActivityNote({activitynote, setDeleting}: Props) {
  const [activitynoteDelete] = useMutation<{
    activitynoteDelete: ActivityNote;
  }>(DELETE_ACTIVITYNOTE_MUTATION, {
    variables: {
      id: activitynote.id,
    },
  });

  return (
    <ConfirmDeleteObject
      objectType="Activity Note"
      objectDescription={
        <span>
          Note: <strong>{activitynote.note}</strong>
        </span>
      }
      abortDeletion={() => setDeleting(false)}
      performDeletion={() =>
        activitynoteDelete().then(() => setDeleting(false))
      }
    />
  );
}
