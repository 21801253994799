import * as React from "react";

import {ActivityTypeChooser} from "./ActivityTypeChooser";
import {AuditAdd} from "./AuditAdd";

// import {useMutation} from "@apollo/client";
// import {loader} from "graphql.macro";

// import {MyTextarea} from "../../FormComponents/MyTextarea";

// const ADD_ACTIVITYNOTE_MUTATION = loader(
//   "../../Fragments/Activities/ActivityNoteAdd.graphql"
// );

// const ACTIVITYNOTE_SET = loader(
//   "../../Fragments/Activities/ActivityNoteSet.graphql"
// );

// type Attachment = {
//   id: string;
//   extension: string;
//   createdOn: number;
//   uploadedBy: {
//     id: string;
//     username: string;
//     firstName: string;
//     lastName: string;
//   };
//   url: string;
// };

// type Tag = {
//   id: string;
//   document: Attachment;
// };

// type ActivityNote = {
//   id: string;
//   active: boolean;
//   timestamp: number;
//   note: string;
//   versionOwner: {
//     id: string;
//     firstName: string;
//     lastName: string;
//   };
//   documenttagSet: Tag[];
// };

// type ActivityNoteSet = {
//   activitynoteSet: ActivityNote[];
// };

type Policy = {
  id: string;
};

type Props = {
  policy?: Policy;
  policies?: Policy[];
  //   activityId: string;
  //   setAdding: (adding: boolean) => void;
};

export function ActivityAdd({policy, policies}: Props) {
  const [status, setStatus] = React.useState("button");
  const [activityType, setActivityType] = React.useState("");

  //   const [noteContent, setNoteContent] = React.useState("")

  //   const [activitynoteAdd] = useMutation<{
  //     activitynoteAdd: ActivityNote;
  //   }>(ADD_ACTIVITYNOTE_MUTATION, {
  //     variables: {
  //       activityId: activityId,
  //       note: noteContent,
  //     },
  //     update(cache, {data}) {
  //       if (!data || !data.activitynoteAdd) return;

  //       let notes = cache.readFragment<ActivityNoteSet>({
  //         id: "FlexiblePolicyActivityObjectType:" + activityId,
  //         fragment: ACTIVITYNOTE_SET,
  //         fragmentName: "ActivityNoteSet",
  //       });

  //       let newnotes: Array<ActivityNote> = [];
  //       if (notes) {
  //         newnotes = [data.activitynoteAdd, ...notes.activitynoteSet];
  //       } else {
  //         newnotes = [data.activitynoteAdd];
  //       }

  //       cache.writeFragment({
  //         id: "FlexiblePolicyActivityObjectType:" + activityId,
  //         fragment: ACTIVITYNOTE_SET,
  //         fragmentName: "ActivityNoteSet",
  //         data: {
  //           activitynoteSet: newnotes,
  //           __typename: "FlexiblePolicyActivityObjectType",
  //         },
  //       });
  //     },
  //   });

  //   function handleSubmit(event: any) {
  //     event.preventDefault();
  //     activitynoteAdd().then(() => {
  //       setNoteContent("");

  //       setAdding(false);
  //     });
  //   }

  //   function handleDiscard() {
  //     if (noteContent === "") {
  //       setAdding(false);
  //     } else {
  //       let confirmDiscardMessage =
  //         "Are you sure you want to discard the note content?\n\nPress OK to discard or cancel to continue editing.";
  //       if (window.confirm(confirmDiscardMessage)) {
  //         setNoteContent("");

  //         setAdding(false);
  //       }
  //     }
  //   }

  if (status === "button") {
    return (
      <div className="flex-shrink-0">
        <button
          type="button"
          className="inline-flex items-center px-3 py-0.5 rounded-full bg-blue-50 text-sm font-medium text-blue-700 hover:bg-blue-100"
          onClick={() => setStatus("adding")}
        >
          {/* <!-- Heroicon name: solid/plus --> */}
          <svg
            className="-ml-1 mr-0.5 h-5 w-5 text-blue-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          <span>Add Activity</span>
        </button>
      </div>
      // <button type="button">
      //   Add Activity
      // </button>
    );
  }

  if (activityType === "") {
    return (
      <div className="p-3 m-1 bg-white border rounded shadow-lg border-coolGray-200">
        <h3>Adding Activity</h3>
        <form>
          <ActivityTypeChooser
            value={activityType}
            setValue={setActivityType}
            initialValue={""}
          />
          <br />
          <br />
          Render policy field list specific to type that was selected. Also
          render a change type button.
          <br />
          {policies?.length} policies
          <br />
          {/* <MyTextarea
        label="Note"
        value={noteContent}
        setValue={setNoteContent}
        initialValue=""
        autoFocus
      />
      <button type="submit">Save Note</button>
      <button type="button" onClick={() => handleDiscard()}>
        {noteContent === "" ? "Cancel" : "Discard"}
      </button> */}
          <button type="button" onClick={() => setStatus("button")}>
            Cancel
          </button>
        </form>
      </div>
    );
  }
  if (activityType === "application")
    return <span> Application Form Here</span>;
  if (activityType === "audit")
    if (policy) {
      return (
        <AuditAdd
          policy={policy}
          // policies={policies}
          // policyId={"1234"}
          changeActivityType={() => setActivityType("")}
        />
      );
    } else {
      return <span>Render a policy chooser first</span>;
    }

  return (
    <span>
      Missing form for activity type: {activityType}
      <button type="button" onClick={() => setActivityType("")}>
        Change Activity Type
      </button>
    </span>
  );
}
