import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {Prompt} from "react-router";

import {MyTextarea} from "./MyTextarea";
import {MyTextInputField} from "./MyTextInputField";
import {MyDateField} from "./MyDateField";
import {MySelect} from "./MySelect";

const UPDATE_PERSON_MUTATION = loader(
  "../Fragments/Contacts/PersonUpdate.graphql"
);

const GENDER_CHOICES = [
  {key: "A_0", text: "Unknown", value: "A_0"},
  {key: "A_1", text: "Female", value: "A_1"},
  {key: "A_2", text: "Male", value: "A_2"},
];

const MARITAL_STATUS_CHOICES = [
  {key: "A_0", text: "", value: "A_0"},
  {key: "A_1", text: "Single", value: "A_1"},
  {key: "M", text: "Married", value: "M"},
  {key: "P", text: "Domestic Partnership", value: "P"},
  {key: "S", text: "Separated", value: "S"},
  {key: "D", text: "Divorced", value: "D"},
  {key: "W", text: "Widowed", value: "W"},
];

const EDUCATION_CHOICES = [
  {key: "A_0", text: "Unknown or N/A", value: "A_0"},
  {
    key: "A_-",
    text: "Did not complete High School or equivalent",
    value: "A_-",
  },
  {key: "A_1", text: "High School", value: "A_1"},
  {key: "A_2", text: "Some College", value: "A_2"},
  {key: "A_3", text: "Associate's Degree", value: "A_3"},
  {key: "A_4", text: "Bachelor's Degree", value: "A_4"},
  {key: "A_5", text: "Master's Degree", value: "A_5"},
  {key: "A_6", text: "PHD", value: "A_6"},
];

type Person = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  preferredName: string;
  gender: string;
  dob: string;
  age: string;
  social: string;
  maritalStatus: string;
  license: string;
  licenseDate: string;
  ddc: string;
  education: string;
  occupation: string;
  employer: string;
  email: string;
  cellPhone: string;
  workPhone: string;
  notes: string;
};

type Props = {
  person: Person;
  setEditing?: (active: boolean) => void;
};

export function EditPerson({person, setEditing}: Props) {
  const [firstName, setFirstName] = React.useState(person.firstName);
  const [middleName, setMiddleName] = React.useState(person.middleName);
  const [lastName, setLastName] = React.useState(person.lastName);
  const [suffix, setSuffix] = React.useState(person.suffix);
  const [preferredName, setPreferredName] = React.useState(
    person.preferredName
  );
  const [gender, setGender] = React.useState(person.gender);
  const [dob, setDob] = React.useState<string | null>(person.dob);
  const [social, setSocial] = React.useState(person.social);
  const [maritalStatus, setMaritalStatus] = React.useState(
    person.maritalStatus
  );
  const [license, setLicense] = React.useState(person.license);
  const [licenseDate, setLicenseDate] = React.useState<string | null>(
    person.licenseDate
  );
  const [ddc, setDdc] = React.useState<string | null>(person.ddc);
  const [education, setEducation] = React.useState(person.education);
  const [occupation, setOccupation] = React.useState(person.occupation);
  const [employer, setEmployer] = React.useState(person.employer);
  const [email, setEmail] = React.useState(person.email);
  const [cellPhone, setCellPhone] = React.useState(person.cellPhone);
  const [workPhone, setWorkPhone] = React.useState(person.workPhone);
  const [notes, setNotes] = React.useState(person.notes);

  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (
      person.firstName !== firstName.trim() ||
      person.middleName !== middleName.trim() ||
      person.lastName !== lastName.trim() ||
      person.suffix !== suffix.trim() ||
      person.preferredName !== preferredName.trim() ||
      person.gender !== gender ||
      person.dob !== dob ||
      (person.dob == null && dob !== "" && dob !== null) ||
      person.social !== social ||
      person.maritalStatus !== maritalStatus ||
      person.license !== license ||
      person.licenseDate !== licenseDate ||
      (person.licenseDate == null &&
        licenseDate !== "" &&
        licenseDate !== null) ||
      person.ddc !== ddc ||
      (person.ddc == null && ddc !== "" && ddc !== null) ||
      person.education !== education ||
      person.occupation !== occupation ||
      person.employer !== employer ||
      person.email !== email ||
      person.cellPhone !== cellPhone ||
      person.workPhone !== workPhone ||
      person.notes !== notes
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [
    person.firstName,
    firstName,
    person.middleName,
    middleName,
    person.lastName,
    lastName,
    person.suffix,
    suffix,
    person.preferredName,
    preferredName,
    person.gender,
    gender,
    person.dob,
    dob,
    person.social,
    social,
    person.maritalStatus,
    maritalStatus,
    person.license,
    license,
    person.licenseDate,
    licenseDate,
    person.ddc,
    ddc,
    person.education,
    education,
    person.occupation,
    occupation,
    person.employer,
    employer,
    person.email,
    email,
    person.cellPhone,
    cellPhone,
    person.workPhone,
    workPhone,
    person.notes,
    notes,
  ]);

  const [personUpdate] = useMutation<{
    personUpdate: Person;
  }>(UPDATE_PERSON_MUTATION, {
    variables: {
      id: person.id,
      firstName: firstName.trim(),
      middleName: middleName.trim(),
      lastName: lastName.trim(),
      suffix: suffix.trim(),
      preferredName: preferredName.trim(),
      gender: gender,
      dob: dob,
      social: social.trim(),
      maritalStatus: maritalStatus,
      license: license.trim(),
      licenseDate: licenseDate === null ? "" : licenseDate,
      ddc: ddc === null ? "" : ddc,
      education: education,
      occupation: occupation.trim(),
      employer: employer.trim(),
      email: email,
      cellPhone: cellPhone.trim(),
      workPhone: workPhone.trim(),
      notes: notes.trim(),
    },
  });

  // handleCancel = (event) => {
  //   event.preventDefault();
  //   this.props.toggleEditing();
  // };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        personUpdate();
      }}
      className="px-4 py-6 bg-white shadow sm:p-6 sm:rounded-lg"
    >
      <Prompt
        when={dirty}
        message="There is an unsaved Person. If you proceed the data will be lost forever."
      />
      <div>
        <h3 className="text-xl font-semibold">Personal Information</h3>

        <div>
          <div className="flex">
            <MyTextInputField
              label="First"
              value={firstName}
              setValue={setFirstName}
              initialValue={person.firstName}
              characterLimit={32}
            />

            <MyTextInputField
              label="Middle"
              value={middleName}
              setValue={setMiddleName}
              initialValue={person.middleName}
              characterLimit={32}
            />

            <MyTextInputField
              label="Last"
              value={lastName}
              setValue={setLastName}
              initialValue={person.lastName}
              characterLimit={32}
            />
          </div>

          <div className="flex">
            <MySelect
              label="Gender"
              value={gender}
              options={GENDER_CHOICES}
              setValue={setGender}
              initialValue={person.gender}
            />

            <MyTextInputField
              label="Preferred Name"
              value={preferredName}
              setValue={setPreferredName}
              initialValue={person.preferredName}
              characterLimit={50}
            />

            <MyTextInputField
              label="Suffix"
              value={suffix}
              setValue={setSuffix}
              initialValue={person.suffix}
              characterLimit={8}
            />
          </div>

          <div className="flex">
            <MyDateField
              label="Date of Birth"
              value={dob}
              setValue={setDob}
              initialValue={person.dob}
            />

            <MyTextInputField
              label="Social Security Number"
              value={social}
              setValue={setSocial}
              initialValue={person.social}
              characterLimit={11}
            />

            <MySelect
              label="Marital Status"
              value={maritalStatus}
              options={MARITAL_STATUS_CHOICES}
              setValue={setMaritalStatus}
              initialValue={person.maritalStatus}
            />
          </div>

          <h3 className="text-xl font-semibold">Driver Information</h3>

          <div className="flex">
            <MyTextInputField
              label="License Number"
              value={license}
              setValue={setLicense}
              initialValue={person.license}
              characterLimit={32}
            />

            <MyDateField
              label="Date Licensed"
              value={licenseDate}
              setValue={setLicenseDate}
              initialValue={person.licenseDate}
            />

            <MyDateField
              label="Accident Prevention Date"
              value={ddc}
              setValue={setDdc}
              initialValue={person.ddc}
            />
          </div>

          <h3 className="text-xl font-semibold">
            School &amp; Work Information
          </h3>

          <div className="flex">
            <MySelect
              label="Highest Degree"
              value={education}
              options={EDUCATION_CHOICES}
              setValue={setEducation}
              initialValue={person.education}
            />

            <MyTextInputField
              label="Occupation"
              value={occupation}
              setValue={setOccupation}
              initialValue={person.occupation}
              characterLimit={32}
            />

            <MyTextInputField
              label="Employer"
              value={employer}
              setValue={setEmployer}
              initialValue={person.employer}
              characterLimit={32}
            />
          </div>

          <h3 className="text-xl font-semibold">Contact Information</h3>

          <div className="flex">
            <MyTextInputField
              label="Email"
              value={email}
              setValue={setEmail}
              initialValue={person.email}
              characterLimit={320}
            />

            <MyTextInputField
              label="Cell Phone"
              value={cellPhone}
              setValue={setCellPhone}
              initialValue={person.cellPhone}
              characterLimit={12}
            />

            <MyTextInputField
              label="Work Phone"
              value={workPhone}
              setValue={setWorkPhone}
              initialValue={person.workPhone}
              characterLimit={32}
            />
          </div>

          <MyTextarea
            label="Notes"
            value={notes}
            setValue={setNotes}
            initialValue={person.notes}
          />

          {setEditing && (
            <button type="button" onClick={() => setEditing(false)}>
              {dirty ? "Discard Changes" : "Stop Editing"}
            </button>
          )}

          {dirty ? (
            <button type="submit">Save</button>
          ) : (
            <button type="button" className="opacity-50 cursor-not-allowed ">
              Saved
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
