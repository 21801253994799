import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {Prompt} from "react-router";
import {MyTextarea} from "../../FormComponents/MyTextarea";
import {MyTextInputField} from "../../FormComponents/MyTextInputField";

const ADD_AUDIT_MUTATION = loader(
  "../../Fragments/Activities/AuditAdd.graphql"
);

const FLEXIBLEPOLICYACTIVITY_SET = loader(
  "../../Fragments/Activities/FlexiblePolicyActivitySetPolicy.graphql"
);

type Attachment = {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
};

type Tag = {
  id: string;
  document: Attachment;
};

type ActivityNote = {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
};

type FlexiblePolicyActivity = {
  id: string;
  activityType: string;
  description: string;
  premium: number;
  activitynoteSet: ActivityNote[];
  documenttagSet: Tag[];
};

type FlexiblePolicyActivitySet = {
  flexiblepolicyactivitySet: FlexiblePolicyActivity[];
};

type Policy = {
  id: string;
};

type Props = {
  policy: Policy;
  changeActivityType: () => void;
  // setAdding: (adding: boolean) => void;
};

export function AuditAdd({policy, changeActivityType}: Props) {
  const [description, setDescription] = React.useState("");
  const [note, setNote] = React.useState("");

  const [auditAdd] = useMutation<{
    auditAdd: FlexiblePolicyActivity;
  }>(ADD_AUDIT_MUTATION, {
    variables: {
      policyId: policy.id,
      description: description,
      note: note,
    },

    // TODO Need to make the cache updating work.

    update(cache, {data}) {
      if (!data || !data.auditAdd) return;
      console.log("update started");

      let activities = cache.readFragment<FlexiblePolicyActivitySet>({
        id: "PolicyObjectType:" + policy.id,
        fragment: FLEXIBLEPOLICYACTIVITY_SET,
        fragmentName: "FlexiblePolicyActivitySetPolicy",
      });
      //   console.log(activities);

      let newactivities: Array<FlexiblePolicyActivity> = [];
      if (activities) {
        newactivities = [
          data.auditAdd,
          ...activities.flexiblepolicyactivitySet,
        ];
        // console.log("adding to existing");
      } else {
        newactivities = [data.auditAdd];
        // console.log("adding to empty");
      }
      // console.log(activities);
      // console.log(newactivities);

      // Check this block as it doesn't seem to work.
      cache.writeFragment({
        id: "PolicyObjectType:" + policy.id,
        fragment: FLEXIBLEPOLICYACTIVITY_SET,
        fragmentName: "FlexiblePolicyActivitySetPolicy",
        data: {
          flexiblepolicyactivitySet: newactivities,
          __typename: "FlexiblePolicyActivityObjectType",
        },
      });
      // console.log("should have writ fragment");
    },
  });

  function handleSubmit(event: any) {
    event.preventDefault();
    auditAdd().then(() => {
      setDescription("");
      setNote("");

      //   setAdding(false);
    });
  }

  //   function handleDiscard() {
  //     if (noteContent === "") {
  //       setAdding(false);
  //     } else {
  //       let confirmDiscardMessage =
  //         "Are you sure you want to discard the note content?\n\nPress OK to discard or cancel to continue editing.";
  //       if (window.confirm(confirmDiscardMessage)) {
  //         setNoteContent("");

  //         setAdding(false);
  //       }
  //     }
  //   }

  return (
    <div className="p-3 m-1 bg-white border rounded shadow-lg border-coolGray-200">
      <form onSubmit={(event) => handleSubmit(event)}>
        <Prompt
          when={description !== ""}
          message="The new activity has not been saved. If you proceed the data will be lost forever."
        />
        Adding Audit
        <span className="float-right">
          <button
            type="button"
            onClick={() => changeActivityType()}
            className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-indigo-700 transition duration-150 ease-in-out bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200"
          >
            Change Activity Type
          </button>
        </span>
        <br />
        <MyTextInputField
          label="Description of Audit"
          value={description}
          setValue={setDescription}
          characterLimit={96}
          autoFocusIfBlank
          informUnsavedChanges={false}
        />
        <MyTextarea
          label="Note"
          value={note}
          setValue={setNote}
          initialValue=""
          informUnsavedChanges={false}
        />
        <div className="float-right">
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700"
            >
              Save New Activity
            </button>
          </span>
        </div>
        {/* <button type="button" onClick={() => handleDiscard()}>
        {noteContent === "" ? "Cancel" : "Discard"}
      </button> */}
      </form>
    </div>
  );
}
