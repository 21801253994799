import * as React from "react";

import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";

import {PlusCircleIcon} from "@heroicons/react/outline";

const ADD_FORM_TAG = loader("../../Fragments/Forms/FormTagAdd.graphql");
const FORM_TAG_SET = loader("../../Fragments/Forms/FormTagSet.graphql");

type FormTag = {
  id: string;
  name: string;
};

type FormTagSet = {
  formtagSet: FormTag[];
};

type Props = {
  id: string;
};

export function FormTagAddButton({id}: Props) {
  const [addFormTag] = useMutation<{
    addFormTag: FormTag;
  }>(ADD_FORM_TAG, {
    variables: {
      id: id,
    },
    update(cache, {data}) {
      if (!data || !data.addFormTag) return;

      let formTags = cache.readFragment<FormTagSet>({
        id: "PDFFormObjectType:" + id,
        fragment: FORM_TAG_SET,
        fragmentName: "FormTagSet",
      });

      let newformTags = {};
      if (formTags) {
        newformTags = formTags.formtagSet.concat(data.addFormTag);
      } else {
        newformTags = [data.addFormTag];
      }

      cache.writeFragment({
        id: "PDFFormObjectType:" + id,
        fragment: FORM_TAG_SET,
        fragmentName: "FormTagSet",
        data: {
          formtagSet: newformTags,
          __typename: "PDFFormObjectType",
        },
      });
    },
  });

  return (
    <button
      type="button"
      onClick={() => addFormTag()}
      className="inline-flex items-center px-6 py-3 m-2 rounded text-coolGray-800 bg-coolGray-300 hover:bg-coolGray-400"
    >
      <PlusCircleIcon className="w-6 h-6 mr-2" />
      <span>Add a Search Term</span>
    </button>
  );
}
