import {gql, useMutation} from "@apollo/client";

const TOGGLE_TODO_LIST_COMPLETION = gql`
  mutation toggleTodoListCompletion($id: String!) {
    toggleTodoListCompletion(id: $id) {
      project {
        id
        name
        incompleteLists {
          id
          name
          unclearedTodos {
            id
            created
            description
            url
            completed
            cleared
            hideuntil
            priority
          }
        }
      }
    }
  }
`;

type TodoList = {
  id: string;
  name: string;
};

type Props = {
  todolistId: string;
};

export function CompleteList({todolistId}: Props) {
  const [removeEmptyTodoList] = useMutation<{
    removeEmptyTodoList: TodoList[];
  }>(TOGGLE_TODO_LIST_COMPLETION);

  return (
    <button
      onClick={() => {
        removeEmptyTodoList({
          variables: {
            id: todolistId,
          },
        });
      }}
      className="w-full px-4 py-2 font-bold text-red-500 bg-white border border-red-500 rounded-lg hover:bg-red-500 hover:text-white"
    >
      Delete Empty List
    </button>
  );
}
