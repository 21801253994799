import {formatDistance} from "date-fns";

type Props = {
  passwordChanged: string | null;
};

export function PasswordChangeMessage({passwordChanged}: Props) {
  if (passwordChanged === null) {
    return null;
  }

  if (passwordChanged === "") {
    return null;
  }

  return (
    <span>
      (last changed {formatDistance(new Date(passwordChanged), new Date())} ago)
    </span>
  );
}
