import * as React from "react";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

import {FormList} from "../../Components/Forms/FormList";

const ALL_FORMS_QUERY = loader("../../Fragments/Forms/all_forms_query.graphql");

interface Forms {
  forms: [
    {
      id: string;
      number: string;
      name: string;
      edition: string;
      url: string;
      active: boolean;
      brand: {
        name: string;
        logoUrl: string;
      };
      formtagSet: [
        {
          id: string;
          name: string;
        }
      ];
    }
  ];
}

export function AllForms() {
  const {loading, error, data} = useQuery<Forms>(ALL_FORMS_QUERY);

  if (loading) return <p>Loading Forms ...</p>;
  if (error) return <span>{`Error! ${error}`}</span>;

  return (
    <>
      {data && (
        <FormList
          brandName="All"
          logoUrl="https://pipeline-media.s3.amazonaws.com/logos/all-forms.webp"
          forms={data.forms}
        />
      )}
    </>
  );
}
