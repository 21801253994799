import * as React from "react";

import {
  Switch,
  Link,
  Route,
  // Redirect,
  // useParams,
  useRouteMatch,
} from "react-router-dom";

import {gql, useQuery} from "@apollo/client";

import {addDays, format, isBefore} from "date-fns";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";
import {BreadcrumbNavigation} from "../Components/BreadcrumbNavigation";
import {BreadcrumbItem} from "../Components/BreadcrumbItem";

import {PendingCancellation} from "../Components/PendingCancellation";
import {NonpayDateColumn} from "../Components/NonpayDateColumn";
import {NonpaysLapsed} from "./nonpays/lapsed";

import {CarrierSelector} from "../Components/CarrierSelector";
import {StatusSelector} from "../Components/StatusSelector";
import {UserSelector} from "../Components/UserSelector";

interface Status {
  id: string;
  name: string;
}

interface Carrier {
  id: string;
  name: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface PendingCancellationType {
  id: string;
  policy: {
    policyNumber: string;
    client: {
      id: string;
      name: string;
      attn: string;
      csr: User;
    };
    company: {
      carrier: Carrier;
    };
  };
  quoteClosed: boolean;
  actionDate: string;
  status: string;
}

const PENDING_CANCELLATIONS = gql`
  query CancellationListQuery {
    allPendingCancellations {
      id
      actionDate
      premium
      status
      quoteClosed
      description
      policy {
        id
        company {
          id
          name
          carrier {
            id
            name
          }
        }
        policyNumber
        client {
          id
          name
          attn
          csr {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

interface PendingCancellations {
  allPendingCancellations: PendingCancellationType[];
}

export function Nonpays() {
  let {path} = useRouteMatch();
  // let {selectedPane} = useParams<Record<string, string | undefined>>();

  const {loading, error, data} = useQuery<PendingCancellations>(
    PENDING_CANCELLATIONS
  );

  const NO_CSR_FILTER = {
    id: "0",
    firstName: "All",
    lastName: "CSRs",
  };

  const [csrFilter, setCsrFilter] = React.useState<User>(NO_CSR_FILTER);

  const NO_CARRIER_FILTER = {
    id: "0",
    name: "All Carriers",
  };

  const [carrierFilter, setCarrierFilter] =
    React.useState<Carrier>(NO_CARRIER_FILTER);

  const NO_STATUS_FILTER = {
    id: "0",
    name: "Any Status",
  };

  const CONTACTED_FILTER = {
    id: "1",
    name: "Contacted",
  };

  const NOT_CONTACTED_FILTER = {
    id: "2",
    name: "Not Contacted",
  };

  let stata = new Array<Status>();

  stata.unshift(NO_STATUS_FILTER, CONTACTED_FILTER, NOT_CONTACTED_FILTER);

  const [statusFilter, setStatusFilter] =
    React.useState<Status>(NO_STATUS_FILTER);

  React.useEffect(() => {
    document.title = `Nonpay Cancellations`;
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  if (data) {
    // Limit only the nonpays
    let nonpays = data.allPendingCancellations.filter(
      (cancellation) => cancellation.status === "A_0"
    );

    // How many days to show on our report page
    let howManyDaysOut = 14;

    // Create an array of dates to use a column headings
    var upcoming = [];
    for (let i = 1; i <= howManyDaysOut; i++) {
      upcoming[i] = format(addDays(new Date(), i), "MMMM d");
    }

    // Limit the nonpays we are working with to only those before howManyDaysOut
    nonpays = nonpays.filter((cancellation) =>
      isBefore(
        new Date(cancellation.actionDate.replace(/-/g, "/")),
        addDays(new Date(), howManyDaysOut)
      )
    );

    // Filter out (removing) the nonpays that belong to other CSRs
    if (csrFilter.id !== "0") {
      nonpays = nonpays.filter(
        (cancellation) => cancellation.policy.client.csr?.id === csrFilter.id
      );
    }

    // Filter out (removing) the nonpays that don't match the selected carrier
    if (carrierFilter.id !== "0") {
      nonpays = nonpays.filter(
        (cancellation) =>
          cancellation.policy.company.carrier.id === carrierFilter.id
      );
    }

    if (statusFilter.id === "1") {
      nonpays = nonpays.filter(
        (cancellation) => cancellation.quoteClosed === true
      );
    } else {
      if (statusFilter.id === "2") {
        nonpays = nonpays.filter(
          (cancellation) => cancellation.quoteClosed === false
        );
      }
    }

    // Build a list of CSRs
    let csrs = new Array<User>();

    // We'll add any CSR that is the assigned CSR on a pending cancellation
    nonpays.map((cancellation) => {
      if (cancellation.policy.client.csr) {
        let csr = cancellation.policy.client.csr;

        var index = csrs.findIndex((x) => x === csr);

        if (index === -1) {
          csrs.push(csr);
        }
        return true;
      } else {
        return false;
      }
    });

    // Reorder the list by last name, then first name
    csrs.sort((a, b) =>
      a.lastName > b.lastName
        ? 1
        : a.lastName === b.lastName
        ? a.firstName > b.firstName
          ? 1
          : -1
        : -1
    );

    // Add the catch-all to the beginning of the array.
    csrs.unshift(NO_CSR_FILTER);

    // Build a list of Carriers represented in the list of nonpays
    let carriers = new Array<Carrier>();

    nonpays.map((cancellation) => {
      let carrier = cancellation.policy.company.carrier;

      var index = carriers.findIndex((x) => x === carrier);

      if (index === -1) {
        carriers.push(carrier);
      }
      return true;
    });

    // Alphabetically sort the carriers.
    carriers.sort((a, b) => (a.name > b.name ? 1 : -1));

    // Add the catch-all to the beginning of the carrier list.
    carriers.unshift(NO_CARRIER_FILTER);

    // Create a special column for policies that already lapsed
    let lapsedPolicies = nonpays.filter((cancellation) =>
      isBefore(new Date(cancellation.actionDate.replace(/-/g, "/")), new Date())
    );

    lapsedPolicies.sort((a, b) => (a.actionDate > b.actionDate ? 1 : -1));

    return (
      <div>
        <div className="flex h-screen" id="app">
          <Sidebar activeItem="nonpays" />

          <Switch>
            <Route exact path={path}>
              <div className="flex flex-col flex-1 min-w-0 bg-white">
                <Header pageTitle="Cancellations for Non-payment" />

                <div className="flex p-3">
                  <div className="flex-1">
                    <UserSelector
                      label="CSR Filter"
                      users={csrs}
                      value={csrFilter}
                      setValue={setCsrFilter}
                    />
                  </div>
                  <div className="flex-1 pl-3">
                    <CarrierSelector
                      label="Carrier Filter"
                      carriers={carriers}
                      value={carrierFilter}
                      setValue={setCarrierFilter}
                    />
                  </div>
                  <div className="flex-1 pl-3">
                    <StatusSelector
                      label="Contacted Status Filter"
                      stata={stata}
                      value={statusFilter}
                      setValue={setStatusFilter}
                    />
                  </div>
                </div>

                <div className="flex-1 overflow-auto bg-coolGray-200">
                  <main className="inline-flex h-full py-3 px-1.5">
                    {lapsedPolicies.length.toString() === "0" ? null : (
                      // <NonpayLapsedColumn
                      //   pendingCancellations={lapsedPolicies}
                      // />
                      <div
                        className="mx-1.5 flex-shrink-0 flex flex-col w-80 bg-coolGray-100 rounded-lg"
                        style={{width: `20rem`}}
                      >
                        <Link to="/nonpays/lapsed">
                          <h3 className="flex-shrink-0 px-3 pt-3 pb-1 text-sm font-medium text-coolGray-700">
                            Lapsed
                          </h3>
                        </Link>
                        <div className="flex-1 min-h-0 overflow-y-auto">
                          <ul className="px-3 pt-1 pb-3">
                            {lapsedPolicies.map((cancellation, index) => (
                              <PendingCancellation
                                cancellation={cancellation}
                                key={cancellation.id}
                                showCancelDate
                                index={index}
                              />
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}

                    {upcoming.map((date) => (
                      <NonpayDateColumn
                        key={date}
                        date={date}
                        pendingCancellations={nonpays.filter(
                          (cancellation) =>
                            format(
                              new Date(
                                cancellation.actionDate.replace(/-/g, "/")
                              ),
                              "MMMM d"
                            ) === date
                        )}
                      />
                    ))}
                  </main>
                </div>
              </div>
            </Route>

            <Route exact path={`${path}/lapsed`}>
              <NonpaysLapsed />
            </Route>
            <Route exact path={`${path}/awaiting-reinstatement`}>
              <div>
                <BreadcrumbNavigation>
                  <BreadcrumbItem>
                    <Link
                      to="/nonpays"
                      className="text-sm font-medium leading-5 transition duration-150 ease-in-out text-coolGray-500 hover:text-coolGray-700"
                    >
                      Nonpays
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <span
                      aria-current="page"
                      className="text-sm font-medium leading-5 transition duration-150 ease-in-out text-coolGray-500 hover:text-coolGray-700"
                    >
                      Awaiting Reinstatement
                    </span>
                  </BreadcrumbItem>
                </BreadcrumbNavigation>

                <span>Awaiting Reinstatement</span>
                <br />
                <br />
                <a
                  href="https://agencypipeline.com/reports/awaiting_reinstatement"
                  className="text-blue-600 underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit page
                </a>
              </div>
            </Route>
            {/*<Route exact path={`${path}/agency`}>
              <AgencySettings />
            </Route>
            <Route exact path={`${path}/users`}>
              <Users />
            </Route> */}
          </Switch>
        </div>
      </div>
    );
  }

  return <span>There was an error</span>;
}
