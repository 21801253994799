import {InMemoryCache, ApolloClient} from "@apollo/client";

function getCookie(name) {
  var re = new RegExp(name + "=([^;]+)");
  var value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : null;
}

const csrfCookie = getCookie("csrftoken");

export const CLIENT = new ApolloClient({
  // link: createHttpLink({
  uri: "https://agencypipeline.com/graphql",
  credentials: "include",
  headers: {
    "X-CSRFToken": csrfCookie,
  },
  // }),
  cache: new InMemoryCache({addTypename: true}),
});

// export const CLIENT = new ApolloClient({
//   // link: createHttpLink({
//   uri: "http://127.0.0.1:8000/graphql",
//   credentials: "include",
//   headers: {
//     "X-CSRFToken": csrfCookie,
//   },
//   // }),
//   cache: new InMemoryCache({addTypename: true}),
// });
