import * as React from "react";

import {gql, useQuery} from "@apollo/client";

import {Header} from "../Components/Header";
import {Sidebar} from "../Components/Sidebar";

import {Birthday} from "../Components/Birthday";

import {format} from "date-fns";

const BIRTHDAY_QUERY = gql`
  query BirthdayListQuery {
    birthdayToday {
      id
      age
      firstName
      lastName
      gender
      dob
      email
    }
  }
`;

type BirthdayType = {
  id: string;
  age: number;
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  email: string;
};

type BirthdaysType = {
  birthdayToday: BirthdayType[];
};

export function Birthdays() {
  const {loading, error, data} = useQuery<BirthdaysType>(BIRTHDAY_QUERY);

  React.useEffect(() => {
    document.title = `Birthdays`;
  });

  if (loading) return <p>Loading ...</p>;

  if (error) return <p>Error!</p>;

  return (
    <div>
      <div className="flex h-screen" id="app">
        <Sidebar activeItem="birthdays" />

        <div className="flex flex-col flex-1 min-w-0 bg-white">
          <Header pageTitle="Birthdays" />

          <div className="flex-1 overflow-auto bg-coolGray-200">
            <main className="h-full p-3">
              <div>
                {data && data.birthdayToday && data.birthdayToday.length} people
                are having a birthday today ({format(new Date(), "MMMM do")}).
                Looking for a different date? Try the Birthday Report
                <br />
                <br />
                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                  <ul>
                    {/* <li>
                      <a
                        href="#"
                        className="block transition duration-150 ease-in-out hover:bg-coolGray-50 focus:outline-none focus:bg-coolGray-50"
                      >
                        <div className="flex items-center px-4 py-4 sm:px-6">
                          <div className="flex items-center flex-1 min-w-0">
                            <div className="flex-shrink-0">
                              <img
                                className="w-12 h-12 rounded-full"
                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                              />
                            </div>
                            <div className="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
                              <div>
                                <div className="text-sm font-medium leading-5 text-indigo-600 truncate">
                                  Ricardo Cooper
                                </div>
                                <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                                  <svg
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-coolGray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="truncate">
                                    ricardo.cooper@example.com
                                  </span>
                                </div>
                              </div>
                              <div className="hidden md:block">
                                <div>
                                  <div className="text-sm leading-5 text-coolGray-900">
                                    Applied on January 7, 2020
                                  </div>
                                  <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                                    <svg
                                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    Completed phone screening
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <svg
                              className="w-5 h-5 text-coolGray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="border-t border-coolGray-200">
                      <a
                        href="#"
                        className="block transition duration-150 ease-in-out hover:bg-coolGray-50 focus:outline-none focus:bg-coolGray-50"
                      >
                        <div className="flex items-center px-4 py-4 sm:px-6">
                          <div className="flex items-center flex-1 min-w-0">
                            <div className="flex-shrink-0">
                              <img
                                className="w-12 h-12 rounded-full"
                                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                              />
                            </div>
                            <div className="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
                              <div>
                                <div className="text-sm font-medium leading-5 text-indigo-600 truncate">
                                  Kristen Ramos
                                </div>
                                <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                                  <svg
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-coolGray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="truncate">
                                    kristen.ramos@example.com
                                  </span>
                                </div>
                              </div>
                              <div className="hidden md:block">
                                <div>
                                  <div className="text-sm leading-5 text-coolGray-900">
                                    Applied on January 7, 2020
                                  </div>
                                  <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                                    <svg
                                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    Completed phone screening
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <svg
                              className="w-5 h-5 text-coolGray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="border-t border-coolGray-200">
                      <a
                        href="#"
                        className="block transition duration-150 ease-in-out hover:bg-coolGray-50 focus:outline-none focus:bg-coolGray-50"
                      >
                        <div className="flex items-center px-4 py-4 sm:px-6">
                          <div className="flex items-center flex-1 min-w-0">
                            <div className="flex-shrink-0">
                              <img
                                className="w-12 h-12 rounded-full"
                                src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                              />
                            </div>
                            <div className="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
                              <div>
                                <div className="text-sm font-medium leading-5 text-indigo-600 truncate">
                                  Ted Fox
                                </div>
                                <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                                  <svg
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-coolGray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="truncate">
                                    ted.fox@example.com
                                  </span>
                                </div>
                              </div>
                              <div className="hidden md:block">
                                <div>
                                  <div className="text-sm leading-5 text-coolGray-900">
                                    Applied on January 7, 2020
                                  </div>
                                  <div className="flex items-center mt-2 text-sm leading-5 text-coolGray-500">
                                    <svg
                                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    Completed phone screening
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <svg
                              className="w-5 h-5 text-coolGray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </li> */}
                    {data &&
                      data.birthdayToday.map((person, index) => (
                        <Birthday
                          person={person}
                          key={person.id}
                          index={index}
                        />
                      ))}
                  </ul>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
