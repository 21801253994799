import * as React from "react";

import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

import {SettingsAgency} from "../../FormComponents/SettingsAgency";

const GET_USER_SETTINGS = loader(
  "../../Fragments/Contacts/UserSettingsQuery.graphql"
);

export function AgencySettings() {
  const {loading, error, data} = useQuery(GET_USER_SETTINGS);

  React.useEffect(() => {
    document.title = `Agency Settings `;
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error!</p>;

  return <SettingsAgency agency={data.userSettings.profile.agency} />;
}
