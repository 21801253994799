import * as React from "react";

import _ from "lodash";

import {OpenDownload} from "./OpenDownload";

interface SortOptions {
  column: string;
  direction: "asc" | "desc";
}

type Policy = {
  client: {
    id: string;
    name: string;
    producer: {
      firstName: string;
      lastName: string;
    };
    csr: {
      firstName: string;
      lastName: string;
    };
  };
  company: {
    carrier: {
      name: string;
    };
  };
  policyNumber: string;
  policyTypeShort: string;
};

type DownloadMessage = {
  id: string;
  policysection: {
    policyTerm: {
      policy: Policy;
    };
  };
  // policyTypeCode: string;
  transactionTypeCode: string;
  originalFile: {
    timestamp: string;
  };
};

type Props = {
  downloads: DownloadMessage[];
};

export const DownloadList = ({downloads}: Props) => {
  const [sortSelection, setSortSelection] = React.useState<SortOptions>({
    column: "originalFile.timestamp",
    direction: "asc",
  });

  function toggleSortDirection() {
    setSortSelection({
      ...sortSelection,
      direction: sortSelection.direction === "desc" ? "asc" : "desc",
    });
  }

  const handleColumnClick = (nameOfClickedColumn: string) => {
    sortSelection.column === nameOfClickedColumn
      ? toggleSortDirection()
      : setSortSelection({
          column: nameOfClickedColumn,
          direction: "asc",
        });
  };

  return (
    <div className="flex flex-col my-3">
      <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle border-b shadow border-coolGray-200 sm:rounded-lg">
          <table className="min-w-full">
            <thead>
              <tr>
                <th
                  className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                  onClick={() => handleColumnClick("originalFile.timestamp")}
                >
                  Received
                </th>
                <th
                  className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                  onClick={() =>
                    handleColumnClick(
                      "policysection.policyTerm.policy.client.name"
                    )
                  }
                >
                  Name
                </th>
                <th
                  className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                  onClick={() => handleColumnClick("transactionTypeCode")}
                >
                  Transaction
                </th>
                <th
                  className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                  onClick={() =>
                    handleColumnClick(
                      "policysection.policyTerm.policy.company.carrier.name"
                    )
                  }
                >
                  Company
                </th>
                <th
                  className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                  onClick={() => handleColumnClick("policyType")}
                >
                  Policy Type
                </th>
                <th
                  className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                  onClick={() =>
                    handleColumnClick(
                      "policysection.policyTerm.policy.policyNumber"
                    )
                  }
                >
                  Policy Number
                </th>
                <th
                  className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                  onClick={() =>
                    handleColumnClick(
                      "policysection.policyTerm.policy.client.csr.firstName"
                    )
                  }
                >
                  CSR
                </th>
                <th
                  className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b text-coolGray-500 border-coolGray-200 bg-coolGray-50"
                  onClick={() =>
                    handleColumnClick(
                      "policysection.policyTerm.policy.client.producer.firstName"
                    )
                  }
                >
                  Producer
                </th>
              </tr>
            </thead>
            <tbody>
              {_.orderBy(
                downloads,
                sortSelection.column,
                sortSelection.direction
              ).map((download, index) => (
                <OpenDownload
                  key={download.id}
                  policy={download.policysection.policyTerm.policy}
                  transactionTypeCode={download.transactionTypeCode}
                  originalFile={download.originalFile}
                  index={index}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
