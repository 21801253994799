import * as React from "react";

import {ActivityNote} from "./ActivityNote";

interface Attachment {
  id: string;
  extension: string;
  createdOn: number;
  uploadedBy: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  url: string;
}

interface Tag {
  id: string;
  document: Attachment;
}

interface ActivityNoteType {
  id: string;
  active: boolean;
  timestamp: number;
  note: string;
  versionOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documenttagSet: Tag[];
}

interface Props {
  notes: ActivityNoteType[];
}

export function ActivityNoteList({notes}: Props) {
  return (
    <>
      {notes.map((activitynote) => (
        <ActivityNote key={activitynote.id} activitynote={activitynote} />
      ))}
    </>
  );
}
