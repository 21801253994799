import {backendUrlHostname} from "../urls";
// import {Link} from "react-router-dom";

type Policy = {
  client: {
    id: string;
    name: string;
    producer: {
      firstName: string;
      lastName: string;
    };
    csr: {
      firstName: string;
      lastName: string;
    };
  };
  company: {
    carrier: {
      name: string;
    };
  };
  policyNumber: string;
  policyTypeShort: string;
};

type Props = {
  policy: Policy;
  // policyType: string;
  transactionTypeCode: string;
  originalFile: {
    timestamp: string;
  };
  index: number;
};

export const OpenDownload = ({
  policy,
  // policyType,
  transactionTypeCode,
  originalFile,
  index,
}: Props) => {
  const received = new Date(originalFile.timestamp).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
  });

  return (
    <tr className={index % 2 === 1 ? "bg-coolGray-100" : "bg-white"}>
      <td className="px-6 py-4 text-sm leading-5 whitespace-no-wrap text-coolGray-700">
        {received}
      </td>
      <td className="px-6 py-4 text-sm font-medium leading-5 whitespace-no-wrap">
        <a
          href={backendUrlHostname + "/clients/" + policy.client.id}
          className="text-blue-600 underline"
        >
          {policy.client.name}
        </a>
        {/* <Link to={"/client/" + policy.client.id}>{policy.client.name}</Link> */}
      </td>
      <td className="px-6 py-4 text-sm leading-5 whitespace-no-wrap text-coolGray-700">
        {(() => {
          switch (transactionTypeCode) {
            case "NBS":
              return <span>New Business</span>;
            case "PCH":
              return <span>Policy Change</span>;
            case "REI":
              return <span>Reinstatement</span>;
            case "RWL":
              return <span>Renewal</span>;
            case "RWQ":
              return <span>Renewal Offer</span>;
            case "XLC":
              return <span>Cancellation</span>;
            default:
              return <span>{transactionTypeCode}</span>;
          }
        })()}
      </td>
      <td className="px-6 py-4 text-sm leading-5 whitespace-no-wrap text-coolGray-700">
        {policy.company.carrier.name}
      </td>
      <td className="px-6 py-4 text-sm leading-5 whitespace-no-wrap text-coolGray-700">
        {policy.policyTypeShort}
      </td>
      <td className="px-6 py-4 text-sm leading-5 whitespace-no-wrap text-coolGray-700">
        {policy.policyNumber}
      </td>
      <td className="px-6 py-4 text-sm leading-5 whitespace-no-wrap text-coolGray-700">
        {policy.client.csr && policy.client.csr.firstName}{" "}
        {/* {policy.client.csr && policy.client.csr.lastName} */}
      </td>
      <td className="px-6 py-4 text-sm leading-5 whitespace-no-wrap text-coolGray-700">
        {policy.client.producer && policy.client.producer.firstName}{" "}
        {/* {policy.client.producer && policy.client.producer.  lastName} */}
      </td>
    </tr>
  );
};
